import React from 'react'

import Button from 'components/form/Button'
import {GlassView} from 'components/GlassView'
import {useEventVisualsContext} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'

import FreelancerOptions from './options'

import styles from './styles.module.scss'

const IMAGE_SIZE = 118

const FreelancerBooking = () => {
  const {
    palette: {accentColor, lightmode, blurTint, textContrasting, textContrastingWithOpacity},
  } = useEventVisualsContext()

  const buttonColor = lightmode ? accentColor : '#5E5E5E99'

  return (
    <div className={styles.BookingContainer}>
      {FreelancerOptions.map((option, index) => (
        <GlassView variants={['noBorder']} blurTint={blurTint} key={index} className={styles.FreelancerBookingCard}>
          <div className={styles.FreelancerBookingCard_text}>
            <h5 style={{color: textContrasting}} className='noMargin'>
              {option.prompt}
            </h5>
            <p style={{color: textContrastingWithOpacity}} className='noMargin'>
              {option.description}
            </p>
            <a style={{textDecoration: 'none'}} className='white' href={option.link} target='_blank' rel='noreferrer'>
              <Button
                className={styles.FreelancerBookingCard_btn}
                style={{backgroundColor: buttonColor, color: '#fff'}}>
                {option.button}
              </Button>
            </a>
          </div>
          <img src={option.image} alt={option.prompt} style={{width: IMAGE_SIZE, height: IMAGE_SIZE}} />
        </GlassView>
      ))}
    </div>
  )
}

export default FreelancerBooking
