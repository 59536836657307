import React from 'react'

interface FirstTimeModalProps {
  setFirstTimeModalIsOpen: (value: React.SetStateAction<boolean>) => void
}

const FirstTimeModal = (props: FirstTimeModalProps) => {
  const {setFirstTimeModalIsOpen} = props
  return (
    <>
      <div className='CommunityModal'>
        <div className='CommunityModal-I'>
          <img src='https://assets.posh.vip/b2/posh-community.png' />
          <h4>Welcome to POSH Community!</h4>
          <p>
            The live events industry thrives when new partnerships and event ideas are brought to fruition. POSH
            Community brings the best new venues, artists, and event staff to your fingertips. It’s time to easily book
            talent and form new relationships!
          </p>
          <button onClick={() => setFirstTimeModalIsOpen(false)}>Get Started</button>
        </div>
      </div>
    </>
  )
}

export default FirstTimeModal
