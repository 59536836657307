import {useState} from 'react'

import {ImageTypeToFileSizeLimitMb} from '@posh/model-types'
import {ImageUploadType, useGetImageUploadIntent} from 'apis/Images/useGetUploadIntent'
import {useToast} from 'components/toasts/ToastProvider'

const MB_IN_BYTES = 1024 * 1024

export const ACCEPTED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp', 'image/gif'].join(',')

export type UploadImageError =
  | {
      reason: 'image-too-large'
      maxImageSizeInMb: number
    }
  | {
      reason: 'error-communicating-with-posh' | 'error-communicating-with-aws'
      message: string
    }

const useDefaultUploadImageOnError = () => {
  const {showToast} = useToast()

  const defaultOnError = (error: UploadImageError) => {
    switch (error.reason) {
      case 'image-too-large':
        showToast({
          title: 'Image too large.',
          subtitle: `Please upload an image smaller than ${error.maxImageSizeInMb}MB.`,
          type: 'error',
        })
        break
      case 'error-communicating-with-posh':
        console.log(error.message)
        showToast({
          title: 'Error uploading image.',
          subtitle: 'Something went wrong communicating with POSH servers.',
          type: 'error',
        })
        break
      case 'error-communicating-with-aws':
        console.log(error.message)
        showToast({
          title: 'Error uploading image.',
          subtitle: 'Something went wrong communicating with AWS.',
          type: 'error',
        })
        break
    }
  }

  return {defaultOnError}
}

export type UseUploadImageParams = {
  imageType: ImageUploadType
  onSuccess: (imageId: string, imageUrl: string) => Promise<void> | void
  onError?: (error: UploadImageError) => Promise<void> | void
}

/**
 * This hook is used to upload images to S3 and then save the image's ID and URL to the database.
 * @param params An object with the following properties:
 * - imageType: The type of image to upload.
 * - onSuccess: A function that takes the ID and URL of the uploaded image and does something with it.
 * - onError: A function that takes an UploadImageError and does something with it (if not provided, a default error handler will be used that shows a toast).
 * @returns An object with the following properties:
 * - uploadImage: A function that takes an image file and uploads it to S3. Returns an object (or undefined if it fails) with the following properties:
 *  - id: The ID of the image in the database.
 *  - url: The URL of the image.
 * - onFileChange: A function that takes a React.ChangeEvent<HTMLInputElement> and runs uploadImage on the first file in the event's target's files array.
 * - isUploading: A boolean indicating whether an image is currently being uploaded.
 * - acceptedFileTypes: A string containing the accepted file types for the image upload.
 */
export const useUploadImage = (params: UseUploadImageParams) => {
  const {imageType, onSuccess} = params
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const {onError: onErrorOverride} = params
  const {defaultOnError} = useDefaultUploadImageOnError()
  const onError = onErrorOverride ?? defaultOnError
  const {mutateAsync: getImageUploadIntent} = useGetImageUploadIntent()

  const MAX_IMAGE_SIZE_IN_MB = ImageTypeToFileSizeLimitMb[imageType]
  const MAX_IMAGE_SIZE_IN_BYTES = MAX_IMAGE_SIZE_IN_MB * MB_IN_BYTES

  const uploadImage = async (file: File) => {
    if (file.size > MAX_IMAGE_SIZE_IN_BYTES) {
      onError({
        reason: 'image-too-large',
        maxImageSizeInMb: MAX_IMAGE_SIZE_IN_MB,
      })
      return
    }
    try {
      setIsUploading(true)
      let id, presignedUrl, url
      try {
        const uploadIntent = await getImageUploadIntent({imageType})
        id = uploadIntent.id
        presignedUrl = uploadIntent.presignedUrl
        url = uploadIntent.url
      } catch (e) {
        onError({
          reason: 'error-communicating-with-posh',
          message: e.message,
        })
        return
      }

      try {
        const s3Response = await fetch(presignedUrl, {
          method: 'PUT',
          body: file,
        })

        if (!s3Response.ok) {
          throw new Error('Error uploading image.')
        }
      } catch (e) {
        onError({
          reason: 'error-communicating-with-aws',
          message: e.message,
        })
        return
      }

      onSuccess(id, url)
    } finally {
      setIsUploading(false)
    }
  }

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return

    const file = e.target.files[0]

    await uploadImage(file)
  }

  return {uploadImage, isUploading, onFileChange, acceptedFileTypes: ACCEPTED_FILE_TYPES}
}
