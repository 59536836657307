import React, {useEffect, useState} from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'
import {LoginFlow} from 'pages/LoginPage'
import {LoginLogo} from 'pages/LoginPage/LoginLinks'
import {LoginMethodPicker} from 'pages/LoginPage/LoginMethodPicker'

import CreateAccountForm from '../CreateAccountForm'
import PasswordForm from '../PasswordForm'
import {EmailLoginForm} from './EmailLoginForm'
import {PhoneLoginForm} from './PhoneLoginForm'

const LoginForm = () => {
  const {isRSVP} = useLiveCartContext()
  const [hasAccount, setHasAccount] = useState(false)
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  //Always default to phone
  const [loginFlow, setLoginFlow] = useState<LoginFlow>('phone')
  const [showSignUp, setShowSignUp] = useState(false)
  const [signupToken, setSignupToken] = useState<string | undefined>(undefined)
  const [startedWithEmail, setStartedWithEmail] = useState(false)
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  //Only show signup form on
  const shouldShowSignup = !hasAccount && showSignUp && !!phone
  const shouldShowLoginFlowPicker = !hasAccount && !showSignUp && !startedWithEmail
  const shouldShowBackButton = (hasAccount || showSignUp) && (!!email || !!phone) && !isRSVP
  useEffect(() => {
    if (!email) {
      setHasAccount(false)
      setShowSignUp(false)
    }
  }, [email, phone])

  const swithLoginFlow = (method: LoginFlow) => {
    setHasAccount(false)
    setShowSignUp(false)
    setEmail('')
    setPhone('')
    setSignupToken(undefined)
    setLoginFlow(method)
    trackMixpanelEvent('Phone/Email Checkout Toggle-  Checkout Signin', {selectedMethod: method})
  }

  const handleResetLogin = () => {
    setHasAccount(false)
    setSignupToken(undefined)
    setShowSignUp(false)
  }

  return (
    <div className={isRSVP ? 'RSVPModal-Section' : 'CheckoutPage-Section'}>
      {shouldShowBackButton && (
        <img
          className='LoginPage-backButton'
          src='https://assets.posh.vip/b2/left-arrow-in-circular-button-black-symbol.svg'
          onClick={handleResetLogin}
        />
      )}
      {!isRSVP && <LoginLogo />}
      {shouldShowLoginFlowPicker && <LoginMethodPicker switchLoginFlow={swithLoginFlow} loginFlow={loginFlow} />}
      {shouldShowSignup ? (
        <>
          {isRSVP && <h4 className='center noMargin'>Almost there!</h4>}
          {phone && <p className='noMargin center'>{phone}</p>}
          {startedWithEmail && <PasswordForm />}
          <CreateAccountForm setHasAccount={setHasAccount} signupToken={signupToken} signupPhoneNumber={phone} />
        </>
      ) : (
        <>
          {loginFlow === 'email' ? (
            <EmailLoginForm
              email={email}
              setHasAccount={setHasAccount}
              setEmail={setEmail}
              hasAccount={hasAccount}
              setShowSignUp={setShowSignUp}
              setLoginFlow={setLoginFlow}
              setStartedWithEmail={setStartedWithEmail}
            />
          ) : (
            <>
              {startedWithEmail && !!email && <h4 className='center'>Sign up with your phone number</h4>}
              <PhoneLoginForm
                phone={phone}
                setHasAccount={setHasAccount}
                setPhone={setPhone}
                hasAccount={hasAccount}
                setSignupToken={setSignupToken}
                setShowSignUp={setShowSignUp}
                showSignUp={showSignUp}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default LoginForm
