import React from 'react'

import {Person2 as UserIcon} from 'components/assets/Icons'

import {BaseNavigationItem} from '../BaseNavigationItem'
import {MobileBaseNavigationItemProps} from '../BaseNavigationItem/mobile'

import styles from './styles.module.scss'

const AVI_SIZE = 24

function Avi({avi, isSelected}: {avi?: string; isSelected: boolean}) {
  if (avi) return <img src={avi} alt='avatar' data-is-selected={isSelected} />
  return <UserIcon height={AVI_SIZE} width={AVI_SIZE} />
}

export function MobileAccountNavigationItem(props: Omit<MobileBaseNavigationItemProps, 'icon'> & {avi?: string}) {
  return (
    <BaseNavigationItem.Mobile
      {...props}
      icon={<Avi avi={props.avi} isSelected={props.isSelected} />}
      className={styles.Account}
    />
  )
}
