import React, {PropsWithChildren, useCallback, useState} from 'react'

import classNames from 'classnames'
import {deleteUrl} from 'components/assets/Icons'
import {Modal} from 'components/coreComponents/Modal'

import './styles.scss'

export interface PoshStyledModalProps {
  isOpen: boolean
  onClose: () => void
  className?: string
  width?: number
  noborder?: boolean
  center?: boolean
  contentClassName?: string
  hideHeader?: boolean
  style?: React.CSSProperties
}

export default function PoshStyledModal({
  isOpen,
  onClose,
  children,
  className,
  width,
  noborder,
  center,
  contentClassName,
  hideHeader,
  style,
}: PropsWithChildren<PoshStyledModalProps>) {
  const [closing, setClosing] = useState<boolean>(false)
  const closeModal = () => {
    if (!isOpen) return
    setClosing(true)
    setTimeout(() => {
      onClose()
      setClosing(false)
    }, 200)
  }

  const backgroundClassNames = classNames('Modal', className)
  const modalContentClassNames = classNames('Modal-content', noborder, center, closing, contentClassName)

  const Background = useCallback(() => {
    return <div className={backgroundClassNames} />
  }, [backgroundClassNames])

  return (
    <Modal isOpen={isOpen} onClose={onClose} Background={Background}>
      <div className='Modal-content-position'>
        <div className={modalContentClassNames} style={{...style, maxWidth: `${width}`}}>
          {!hideHeader && (
            <div className='Modal-header'>
              <img className='Modal-content--deleteButton' src={deleteUrl} onClick={closeModal} />
            </div>
          )}
          {children}
        </div>
      </div>
    </Modal>
  )
}
