import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {Recipient, Status} from 'apis/Groups'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useFetchTextBlastStatus} from 'apis/Groups/useFetchTextBlastStatus'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {isEmpty} from 'lodash'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import BackAndTitle from '../PageComponents/BackAndTitle'

import './styles.scss'

interface TextBlastTableProps {
  title: string
  status: Status
  recipients: Recipient[]
  open?: boolean
}

const TextBlastTable = (props: TextBlastTableProps) => {
  const {title, status, recipients, open} = props
  const [isSectionOpen, setIsSectionOpen] = useState(open ?? false)

  const getColor = (status?: Status) => {
    if (status === 'delivered') return 'green'
    else if (status === 'sent') return 'orange'
    else if (status === 'failed') return 'red'
    else return 'blue'
  }

  return (
    <div className='TextBlastTable'>
      <h2
        className={isSectionOpen ? 'TextBlastStatus-Header' : 'TextBlastStatus-Header Closed'}
        onClick={() => {
          setIsSectionOpen(!isSectionOpen)
        }}>
        <div className='TextBlastStatus-Header'>
          <span className={`TextBlastStatus-Recipients ${title}`}>{recipients.length}</span>{' '}
          <span className='TextBlastStatus-Title'>{title}</span>
        </div>
        <img src='https://assets.posh.vip/b2/whitetriangle.svg' />
      </h2>
      {isSectionOpen && (
        <table>
          <tr className='TextBlastTable-firstColSmall'>
            <th>Name</th>
            <th>Number</th>
            <th>Status</th>
          </tr>
          {recipients.map(r => (
            <tr key={r.sid}>
              <td>{r.firstName}</td>
              <td>{r.phone}</td>
              <td style={{color: `${getColor(status)}`}}>{status.charAt(0).toUpperCase() + status.slice(1)}</td>
            </tr>
          ))}
        </table>
      )}
    </div>
  )
}

const TextBlastBreakdown = () => {
  const {groupId, blastId} = useGroupMatchParams()
  const {data: group, isLoading} = useFetchGroup(groupId)
  const {data: textblastData, isFetching} = useFetchTextBlastStatus(groupId ?? '', blastId ?? '')
  const [delivered, setDelivered] = useState<Recipient[]>()
  const [failed, setFailed] = useState<Recipient[]>()
  const [queued, setQueued] = useState<Recipient[]>()
  const [sent, setSent] = useState<Recipient[]>()
  const {domain} = useResourcePageParams()

  const navigate = useNavigate()

  useEffect(() => {
    if (textblastData) {
      const totalDelivered = textblastData.filter(r => r.status === 'delivered')
      const totalSent = textblastData.filter(r => r.status === 'sent')
      const totalFailed = textblastData.filter(r => r.status === 'failed' || r.status === 'undelivered')
      const totalQueued = textblastData.filter(r => r.status === 'queued')
      setDelivered(totalDelivered)
      setQueued(totalQueued)
      setSent(totalSent)
      setFailed(totalFailed)
    }
  }, [textblastData])

  return (
    <div className='GroupPage'>
      <div className='GroupPage-content'>
        <div className='GroupPage-content-inner'>
          <BackAndTitle title={'Text Blast Breakdown'} columnMobile>
            <>
              {!group?.groupPhoneNumber && !isLoading && (
                <div
                  className='TextBlastStatus-actionBtn'
                  onClick={() => navigate(`/${domain}/groups/${groupId}/marketing/custom-number`)}>
                  <img src='https://assets.posh.vip/b2/phone.svg' style={{width: '36px', height: '36px'}} />
                  <h5>You’re eligible for your own phone number!</h5>
                </div>
              )}
            </>
          </BackAndTitle>
          <p>
            Below is the delivery status breakdown of the text blast. If you are experiencing a high fail rate, our
            partner carrier may have blocked your messages. Reach out to support@posh.vip to receive assistance.
          </p>
          {isFetching ? (
            <div className='TextBlastStatus-loader'>
              <p>Fetching TextBlast Data</p>
              <SpinLoader height={80} />
            </div>
          ) : (
            <div className='TextBlastStatus'>
              {delivered && !isEmpty(delivered) && (
                <div className='TextBlastStatus-gridItem'>
                  <TextBlastTable title={'Delivered'} recipients={delivered} status='delivered' />
                </div>
              )}
              {failed && !isEmpty(failed) && (
                <div className='TextBlastStatus-gridItem'>
                  <TextBlastTable title={'Failed'} recipients={failed} status='failed' />
                </div>
              )}
              {queued && !isEmpty(queued) && (
                <div className='TextBlastStatus-gridItem'>
                  <TextBlastTable title={'Queued'} recipients={queued} status='queued' />
                </div>
              )}
              {sent && !isEmpty(sent) && (
                <div className='TextBlastStatus-gridItem'>
                  <TextBlastTable title={'Sent'} recipients={sent} status='sent' />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TextBlastBreakdown
