import React from 'react'

import useSessionContext from 'domains/Auth/SessionContext'

import {DetailEntity} from '..'

interface EntityDetailsProps {
  selectedDetailEntity: DetailEntity
  showBookingRequest: boolean
  setShowBookingRequest: (showBookingRequest: boolean) => void
  handleSignUpRedirect: () => void
}

const EntityDetails = (props: EntityDetailsProps) => {
  const {selectedDetailEntity, showBookingRequest, setShowBookingRequest, handleSignUpRedirect} = props
  const {userId} = useSessionContext()
  return (
    <div className={'cDetailsModal-details'}>
      {!userId && <div className='cDetailsModal-details-locked' />}

      <div>
        <h2>{selectedDetailEntity.title}</h2>

        <table className='cDetailsModal-stats'>
          <tbody>
            {selectedDetailEntity.clarifier && (
              <tr>
                <td>
                  <img
                    src={
                      selectedDetailEntity.communityCategory == 'venue'
                        ? 'https://assets.posh.vip/b2/pin-b.svg'
                        : 'https://assets.posh.vip/b2/community/price-tag.svg'
                    }
                  />
                </td>
                <td>{selectedDetailEntity.clarifier}</td>
              </tr>
            )}
            {selectedDetailEntity.price && (
              <tr>
                <td>
                  <img src='https://assets.posh.vip/b2/community/price-tag.svg' />
                </td>
                <td>{selectedDetailEntity.price}</td>
              </tr>
            )}
            {selectedDetailEntity.people && (
              <tr>
                <td>
                  <img src='https://assets.posh.vip/b2/community/people.svg' />
                </td>
                <td>{selectedDetailEntity.people}</td>
              </tr>
            )}
            {selectedDetailEntity.spotifyFollowers && (
              <tr>
                <td>
                  <img src='https://assets.posh.vip/b2/community/spotify.svg' />
                </td>
                <td className='Community-card-info-stats-spotify'>{`${selectedDetailEntity.spotifyFollowers}`}</td>
              </tr>
            )}
          </tbody>
        </table>

        <p>{selectedDetailEntity.description}</p>

        {selectedDetailEntity.website && (
          <a className='Community-card-info-stats-website' href={selectedDetailEntity.website}>
            {selectedDetailEntity.title} Website
          </a>
        )}
      </div>

      <div className='cDetailsModal-actionRow'>
        {selectedDetailEntity.email && !showBookingRequest && userId && (
          <button className='cDetailsModal-actionRow-rtb' onClick={() => setShowBookingRequest(true)}>
            Request to Book
          </button>
        )}
        {selectedDetailEntity.email && !showBookingRequest && !userId && (
          <button className='cDetailsModal-actionRow-rtb' onClick={() => handleSignUpRedirect()}>
            {`Sign Up to Request to Book this ${selectedDetailEntity.communityCategory}`}
          </button>
        )}
        {selectedDetailEntity.instagram && userId && (
          <button
            className='cDetailsModal-actionRow-insta'
            onClick={() => window.open(`https://www.instagram.com/${selectedDetailEntity.instagram!}`)}>
            <img src='https://assets.posh.vip/b2/messenger.svg' />
          </button>
        )}
      </div>
    </div>
  )
}

export default EntityDetails
