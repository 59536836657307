import React from 'react'
import Loader from 'react-loader-spinner'

import {AccountSearchResponse} from 'apis/Accounts/useSearchAccounts'
import {filledPlusUrl} from 'components/assets/Icons'

interface AccountSearchAttendeesListProps {
  accounts: AccountSearchResponse[]
  onAddUser: (account: AccountSearchResponse) => Promise<void>
  isLoading: boolean
  userBeingAdded: string
}

interface AccountSearchAttendeeItemProps {
  account: AccountSearchResponse
  onAddUser: (account: AccountSearchResponse) => Promise<void>
  userBeingAdded: string
}

const AccountSearchAttendeeItem = (props: AccountSearchAttendeeItemProps) => {
  const {account, userBeingAdded, onAddUser} = props
  return (
    <div className='AccountSearchModal-attendee'>
      <div className='AccountSearchModal-attendee-infoWrapper'>
        <img src={account.avi} />
        <div className='AccountSearchModal-attendee-nameWrapper'>
          <p className='noMargin'>{account.fullName}</p>
          {account.exactMatch === true && <div className='AccountSearchModal-attendee-tag'>Exact Match</div>}
        </div>
      </div>
      {userBeingAdded && userBeingAdded === account.accountId ? (
        <div className='AccountSearchModal-attendee-addingUserLoad'>
          <Loader type='TailSpin' color='white' height={20} width={20} />
        </div>
      ) : (
        <div className='AccountSearchModal-attendee-addUser'>
          <img src={filledPlusUrl} onClick={async () => await onAddUser(account)} />
        </div>
      )}
    </div>
  )
}

const AccountSearchAttendeesList = (props: AccountSearchAttendeesListProps) => {
  const {accounts, onAddUser, isLoading, userBeingAdded} = props
  return (
    <>
      {isLoading ? (
        <div className='AccountSearchModal-loader'>
          <Loader type='TailSpin' color='white' height={30} width={30} />
        </div>
      ) : (
        <div className='AccountSearchModal-attendeesList'>
          {accounts.map((account, index) => (
            <AccountSearchAttendeeItem
              account={account}
              onAddUser={onAddUser}
              userBeingAdded={userBeingAdded}
              key={index}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default AccountSearchAttendeesList
