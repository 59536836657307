import React, {useState} from 'react'

import {useFetchEventTickets} from 'apis/Events/useFetchEventTickets'
import useCSVUpload from 'apis/Groups/useCSVUpload'
import Button from 'components/form/Button'
import Select from 'components/form/Select'
import {useToast} from 'components/toasts/ToastProvider'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {UploadType} from '.'
import {CSVModalCofig} from './modalConfigs'
import {PreviewTable} from './previewTable'

interface CSVUploadSecondStepProps {
  config: CSVModalCofig
  csvData: Record<string, string | number>[]
  uploadType: UploadType
  onClose: () => void
}

const ERROR_UPLOADING_CSV_TITLE = 'There was an error uploading your CSV'

export const CSVUploadSecondStep = (props: CSVUploadSecondStepProps) => {
  const {config, csvData, uploadType, onClose} = props
  const {confirmText} = config
  const {eventId, groupId} = useResourcePageParams()
  const {showToast} = useToast()
  const {mutateAsync: uploadCSV, isLoading} = useCSVUpload()
  const {data: tickets} = useFetchEventTickets(eventId!)
  const [ticketType, setTicketType] = useState<string>()

  const uploadCsv = async () => {
    try {
      if (csvData.length === 0) throw new Error('No CSV data to upload')
      if (uploadType === 'complimentaryTickets' && !ticketType) throw new Error('Please select a ticket type')
      if (!groupId) throw new Error('No group found')
      if (!eventId) throw new Error('No event found')
      const response = await uploadCSV({
        groupId,
        eventId,
        type: uploadType,
        clarifier: ticketType,
        data: csvData,
      })
      showToast({
        type: 'success',
        title: 'Success',
        subtitle: `Successfully Sent ${response.success} ${
          uploadType === 'complimentaryTickets' ? 'Complimentary Orders' : 'Promo Codes'
        }`,
      })
      onClose()
    } catch (error: any) {
      if (error.response)
        showToast({type: 'error', title: ERROR_UPLOADING_CSV_TITLE, subtitle: error.response.data.error})
      else if (error.message) showToast({type: 'error', title: ERROR_UPLOADING_CSV_TITLE, subtitle: error.message})
    }
  }
  return (
    <>
      <h3>Confirm Upload</h3>
      <PreviewTable config={config} csvData={csvData} />
      {tickets && tickets.length > 0 && (
        <Select value={ticketType} onChange={e => setTicketType(e.target.value)}>
          <option value=''>Select a ticket type...</option>
          {tickets.map(ticket => (
            <option key={ticket.ticket.id} value={ticket.ticket.id}>
              {ticket.ticket.name}
            </option>
          ))}
        </Select>
      )}
      <br />
      <br />
      <div className='buttonWrapper center'>
        <Button className='gold' disabled={isLoading || !ticketType} isLoading={isLoading} onClick={uploadCsv}>
          {isLoading ? 'Uploading CSV...' : confirmText}
        </Button>
      </div>
    </>
  )
}
