import React from 'react'
import {useNavigate} from 'react-router-dom'

import {LikedNotificationMetadata} from '@posh/types'
import {GuestlistNotificationClickWhere, useMixpanel} from 'apis/MixPanelHandler'
import {verifiedBadge} from 'components/assets/Icons'
import useSessionContext from 'domains/Auth/SessionContext'

export const GuestlistLikeNotification = (props: LikedNotificationMetadata) => {
  const {likerAvi, likerName, likerUrl, likerPoshVerified, likerInstagramVerified, eventName, eventAvi, eventUrl} =
    props
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {userId} = useSessionContext()
  const mixpanelClick = (where: GuestlistNotificationClickWhere) => {
    const properties = {
      whoClickedId: userId ?? '',
      whoLikedUrl: likerUrl,
      eventUrl,
    }
    trackMixpanelEvent('Guestlist Like Notification Click', {where, ...properties})
  }

  const onClickProfilePic = () => {
    trackMixpanelEvent('Event Click- Notifications Tab', {notificationType: 'guestlistLike'})
    navigate(`/p/${likerUrl}`)
  }

  const onClickEventFlyer = () => {
    mixpanelClick('event_flyer')
    navigate(`/e/${eventUrl}`)
  }

  const onClickProfileName = () => {
    trackMixpanelEvent('Profile Name Click- Notifications Tab', {notificationType: 'guestlistLike'})
  }

  return (
    <div className='Notification-content'>
      <img src={likerAvi} onClick={onClickProfilePic} id='profilePic' />
      <p className='text-small'>
        <a href={`/p/${likerUrl}`} onClick={onClickProfileName}>
          {likerName}
        </a>
        {likerPoshVerified && (
          <span>
            <img id='verifiedBadge' src='https://assets.posh.vip/b2/verify.png' />
          </span>
        )}
        {likerInstagramVerified && <img id='verifiedBadge' src={verifiedBadge} />} liked you on the guestlist for{' '}
        <a href={`/e/${eventUrl}`} onClick={() => mixpanelClick('event_name')}>
          {eventName}
        </a>
      </p>
      <img src={eventAvi} onClick={onClickEventFlyer} />
    </div>
  )
}
