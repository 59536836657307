import React from 'react'
import {useLocation} from 'react-router-dom'

import {CuratorStatus} from 'apis/Groups'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import Page, {PageVariant} from 'components/Page'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import Stripe from 'stripe'

import {useFetchGroupDisputes} from '../../../apis/Groups/useFetchGroupDisputes'
import DisputesTable from './DisputesTable'

import './styles.scss'

interface DisputerAttributes {
  accountId: string
  name: string
  email: string
  phoneNumber: string
}

interface DisputedEventAttributes {
  id: string
  name: string
}

interface DisputedOrderAttributes {
  id: string
  trackingLink: string
  amount: number
  fee: number
  number: number
}

export interface DisputeRowAttributes {
  disputeId: string
  disputeCreatedAt: string
  disputer: DisputerAttributes
  disputedEvent: DisputedEventAttributes
  disputedOrder: DisputedOrderAttributes
  status: Stripe.Dispute.Status
  curatorStatus: CuratorStatus
}

export default function DisputesPage() {
  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)
  const {data: disputes} = useFetchGroupDisputes(groupId ?? '')
  const search = useLocation().search
  let totalOwed = 0
  if (disputes) {
    disputes.map(d => {
      if (d.stripeDisputeStatus == 'lost') {
        totalOwed += d.amount * 100 + d.fee
      }
    })
  }

  return (
    <>
      <PoshHelmet title={`Disputes - ${group?.name}`} />
      <Page className='DisputesPage' variant={PageVariant.GRAY}>
        <div className='DisputesPage-container'>
          <div className='DisputesPage-backLink'>
            <a href={`/owner/groups/${groupId}/finance` + search}>← Back to Finance</a>
          </div>
          <h2>Disputes</h2>
          <hr />
          {/* <div className='DisputesPage-totalBar'>{`Total Amount Owed: ${getCurrencySymbol(
            group?.currency as CurrencyCode | undefined,
          )}${(totalOwed / 100).toFixed(2)}`}</div> */}
          <p className='DisputesPage-subtitle'>
            POSH automatically fights disputes (chargebacks) for you. View their status and other info below.
          </p>
          <DisputesTable disputes={disputes?.reverse() ?? []} />
        </div>
      </Page>
    </>
  )
}
