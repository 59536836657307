import React, {useRef} from 'react'
import Loader from 'react-loader-spinner'

import useFetchCheckoutSummary from 'apis/Checkout/useFetchCheckoutSummary'
import {rightArrow} from 'components/assets/Icons'
import {TextFieldVariants} from 'components/form/TextField'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'

import {CartError} from '../CartError'
import CheckoutField from '../CheckoutField/CheckoutField'

const PromoCodeForm = ({
  onPromoCodeApplied,
  onPromoCodeBeingApplied,
}: {
  onPromoCodeApplied: () => void
  onPromoCodeBeingApplied: () => void
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const enterButtonRef = useRef<HTMLButtonElement | null>(null)
  const cartId = urlParams.get('cartId') as string
  const {setFormErrors, formErrors, cartFormData, applyPromoCode} = useLiveCartContext()
  const {isFetching: isFetchingCheckoutSummary} = useFetchCheckoutSummary(
    {cartId},
    {
      onSuccess: ({promoCodeInfo}) => {
        if (!promoCodeInfo || !promoCodeValue) return
        if (promoCodeInfo.validCode) {
          setFormErrors({...formErrors, promo_code: ''})
        } else if (!promoCodeInfo.validCode) {
          setFormErrors({...formErrors, promo_code: 'Invalid promo code.'})
        }
      },
    },
  )
  const showError = formErrors['promo_code'] !== '' && !isFetchingCheckoutSummary
  const promoCodeValue = cartFormData['promo_code']?.value as string
  const disablePromoCodeSubmitButton = !promoCodeValue || isFetchingCheckoutSummary

  const onBlur = async () => {
    if (!promoCodeValue) return
    await applyPromoCode(promoCodeValue)
    onPromoCodeApplied()
  }

  const onFocus = () => {
    onPromoCodeBeingApplied()
  }

  const onClickApplyPromoCode = async () => {
    if (!promoCodeValue) return
    await applyPromoCode(promoCodeValue)
    onPromoCodeApplied()
  }

  return (
    <div className='CheckoutPage-Section-PromoCode fade'>
      <div className='CheckoutPage-Section-PromoCode-Input fade'>
        <CheckoutField
          field_type={'input'}
          field_key={'promo_code'}
          value={promoCodeValue}
          variant={TextFieldVariants.DARK}
          placeholder={'Promo Code'}
          nativeProps={{onBlur, onFocus}}
          isCustomField={false}
        />
        <button
          ref={enterButtonRef}
          disabled={disablePromoCodeSubmitButton}
          onClick={onClickApplyPromoCode}
          style={{borderRadius: '0 40px 40px 0'}}>
          {isFetchingCheckoutSummary ? (
            <Loader type='TailSpin' color='black' height={15} width={15} />
          ) : (
            <img src={rightArrow} />
          )}
        </button>
      </div>
      {showError && <CartError error={formErrors.promo_code} center />}
    </div>
  )
}

export default PromoCodeForm
