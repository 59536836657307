import React from 'react'

import {Badge} from 'apis/Accounts'

import './styles.scss'

const ProfileBadges = ({badges}: {badges: Badge[]}) => {
  return (
    <div className='ProfileBadges'>
      {badges.map((badge, i) => (
        <div className='ProfileBadges-badge' key={i}>
          <img src={badge.icon} />
          <span>{badge.name}</span>
        </div>
      ))}
    </div>
  )
}

export default ProfileBadges
