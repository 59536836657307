// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Label__A-7Qq{color:#fff;font-size:16px;font-style:normal;font-weight:600;line-height:normal}.Subtitle__sa93O{color:#fff;font-size:16px;font-style:normal;font-weight:200;line-height:normal;margin-top:6px}", "",{"version":3,"sources":["webpack://./src/components/DynamicForm/Inputs/shared/InputLabel/InputLabel.module.scss"],"names":[],"mappings":"AAAA,cACE,UAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAGF,iBACE,UAAA,CAGA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAEA,cAAA","sourcesContent":[".Label {\n  color: #fff;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n\n.Subtitle {\n  color: #fff;\n\n  /* Desktop/Paragraph Regular */\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 200;\n  line-height: normal;\n\n  margin-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Label": "Label__A-7Qq",
	"Subtitle": "Subtitle__sa93O"
};
export default ___CSS_LOADER_EXPORT___;
