import React, {useEffect, useRef} from 'react'
import {Outlet, useLocation} from 'react-router-dom'

import {DB_BACKED_FEATURES, useIsFeatureEnabled} from 'components/FeatureFlag'
import {useDimensions} from 'hooks/useDimensions'

import {PrimaryHeader} from '../Header'
import {TertiaryNavigation} from '../Tertiary'
import {PrimaryNavigation} from '.'

import styles from './styles.module.scss'

function useScrollToTopOnNavigate() {
  const {pathname} = useLocation()
  const outletRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (outletRef.current) outletRef.current.scrollTo(0, 0)
  }, [pathname])

  return outletRef
}

function FeatureDisabled() {
  const outletRef = useScrollToTopOnNavigate()

  return (
    <div className={styles.container}>
      <div className={styles.outlet} ref={outletRef} data-is-feature-flag-enabled={false}>
        <Outlet />
      </div>
    </div>
  )
}

function Layout() {
  const outletRef = useScrollToTopOnNavigate()

  return (
    <div className={styles.container}>
      <PrimaryNavigation.Desktop>
        <TertiaryNavigation.Desktop />
      </PrimaryNavigation.Desktop>
      <PrimaryNavigation.Mobile />
      <div className={styles.outlet} ref={outletRef} data-is-feature-flag-enabled={true}>
        <PrimaryHeader.Desktop.UsesContext />
        <Outlet />
      </div>
    </div>
  )
}

export function PrimaryNavigationLayout() {
  const {isFeatureEnabled: isRedesignEnabled} = useIsFeatureEnabled(DB_BACKED_FEATURES.WEB_NAVIGATION_REDESIGN)
  const location = useLocation()
  const {isTablet} = useDimensions()

  // Because of issues with how the bottom action row is positioned in CE/VE
  // we opted to hide the nav on the event visuals page
  const hideNav = location.pathname.endsWith('/visuals') && isTablet

  if (!isRedesignEnabled) return <FeatureDisabled />
  if (hideNav) return <FeatureDisabled />
  return <Layout />
}
