// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableHeader__RYtQp{text-align:left;display:flex;min-width:120px;align-items:center;gap:6px;color:#fff;font-size:14px;font-weight:600;line-height:normal;letter-spacing:-0.42px}", "",{"version":3,"sources":["webpack://./src/components/TableUI/TableHeader/TableHeader.module.scss"],"names":[],"mappings":"AAEA,oBAEE,eAAA,CAEA,YAAA,CACA,eAAA,CACA,kBAAA,CACA,OAAA,CAGA,UAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":["@import '@global-styles/_color_pallete';\n\n.TableHeader {\n  /* Alignment */\n  text-align: left;\n  // padding: 5px;\n  display: flex;\n  min-width: 120px;\n  align-items: center;\n  gap: 6px;\n\n  /* Typography */\n  color: #fff;\n  font-size: 14px;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: -0.42px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableHeader": "TableHeader__RYtQp"
};
export default ___CSS_LOADER_EXPORT___;
