import React from 'react'

import {KickbackUsageResponse} from 'apis/Kickback/useFetchKickbackUsage'

import './styles.scss'

interface RecentKickbacksProps {
  recentKickbacks: KickbackUsageResponse[]
}

interface RecentKickbackCardProps {
  recentKickback: KickbackUsageResponse
}

const RecentKickbackCard = (props: RecentKickbackCardProps) => {
  const {recentKickback} = props
  return (
    <div className='RecentKickbackCard'>
      <div className='RecentKickbackCard-imageContainer'>
        <div className='RecentKickbackCard-imageContainer-imageWrapper account'>
          <img src={recentKickback.accountAvi} />
        </div>
        <div className='RecentKickbackCard-imageContainer-imageWrapper affiliate'>
          <img src={recentKickback.affiliateAvi} />
        </div>
      </div>
      <p className='noMargin text-small center'>{recentKickback.description}</p>
    </div>
  )
}

const RecentKickbacks = (props: RecentKickbacksProps) => {
  const {recentKickbacks} = props
  return (
    <div className='RecentKickbacks'>
      {recentKickbacks.map((recentKickback, index) => (
        <RecentKickbackCard key={index} recentKickback={recentKickback} />
      ))}
    </div>
  )
}

export default RecentKickbacks
