import React from 'react'

import {captureException, ErrorBoundary} from '@sentry/react'
import {Warning} from 'components/assets/Icons'
import {generateSupportEmailHref} from 'helpers/generateSupportEmailHref'

import {useEventManagementContext} from '../EventManagementContext'
import {CrossSection} from '../OverviewUpdate/Analytics/CrossSection/CrossSection'
import {Text} from '../OverviewUpdate/Analytics/Shared/Text/Text'
import {OverviewUpdated} from '../OverviewUpdate/Overview'

import './styles.scss'

const generateTopLevelAnalyticsSupportEmail = ({
  groupId,
  eventId,
  error,
}: {
  groupId: string
  eventId: string
  error: Error
}) => {
  return generateSupportEmailHref(
    `Error Viewing Event Analytics Breakdown`,
    `Information for Support: \n\n Group ID: ${groupId} \n Event ID: ${eventId} \n\n Error: ${JSON.stringify(
      error,
    )} \n\n Please provide any additional context or details here:`,
  )
}

export const generateTrackingLink = ({
  eventHex,
  eventUrl,
  trackingLink,
}: {
  eventHex?: string
  eventUrl?: string
  trackingLink: string
}) => {
  if (!eventHex && !eventUrl) {
    throw new Error('Either eventHex or eventUrl is required')
  }
  const url = eventHex ? `https://posh.vip/f/${eventHex}` : `https://posh.vip/e/${eventUrl}`
  return `${url}?t=${trackingLink}`
}

const AnalyticsOverviewFallback = ({error, eventId, groupId}: {error: Error; eventId: string; groupId: string}) => {
  return (
    <CrossSection style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}>
      <Warning color='#fff' width={50} height={50} />
      <CrossSection.Row>
        <Text size='xl' bold>
          Oh no!
        </Text>
      </CrossSection.Row>
      <CrossSection.Row>
        <Text>
          Something went wrong while fetching your analytics data. Please contact{' '}
          <a
            style={{color: '#FFCC00'}}
            href={generateTopLevelAnalyticsSupportEmail({
              groupId,
              eventId,
              error,
            })}>
            support
          </a>{' '}
          if this error persists.
        </Text>
      </CrossSection.Row>
    </CrossSection>
  )
}
export const OverviewWrapper = () => {
  const {
    event: {_id: eventId},
    group: {_id: groupId},
  } = useEventManagementContext()
  return (
    <ErrorBoundary
      fallback={({error}) => <AnalyticsOverviewFallback error={error} eventId={eventId} groupId={groupId} />}
      onError={(error, info) => {
        captureException(error, {extra: {info, where: 'web event analytics page'}})
      }}>
      <OverviewUpdated />
    </ErrorBoundary>
  )
}
