import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import CheckoutButton from 'components/form/CheckoutButton'

interface ActionButtonsProps {
  handleCreateNewAccount: () => void
}

export const ActionButtons = (props: ActionButtonsProps) => {
  const {handleCreateNewAccount} = props
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const handleForgotPassword = () => {
    navigate('/reset-password/new')
  }
  return (
    <div className='CheckoutPage-Section-AccountButtons'>
      <CheckoutButton
        className={'Account'}
        onClick={() => {
          trackMixpanelEvent('Forgot Password-  Email Auth Form')
          handleForgotPassword()
        }}>
        <img src='https://assets.posh.vip/b2/login.svg' />
        <span style={{color: 'white'}}>FORGOT PASSWORD</span>
      </CheckoutButton>
      <CheckoutButton
        className={'Account'}
        onClick={() => {
          trackMixpanelEvent('Create New Account-  Email Auth Form')
          handleCreateNewAccount()
        }}>
        <img src='https://assets.posh.vip/b2/add-friend.svg' />
        <span style={{color: 'white'}}>CREATE NEW ACCOUNT</span>
      </CheckoutButton>
    </div>
  )
}
