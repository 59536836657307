import React from 'react'

import {useUploadImage} from 'hooks/uploadImage/useUploadImage'

interface EventPlaylistImageUploadProps {
  value?: string
  onChange: (value: string) => void
}

export const EventPlaylistImageUpload = (props: EventPlaylistImageUploadProps) => {
  const {value, onChange} = props

  const {uploadImage, isUploading, acceptedFileTypes} = useUploadImage({
    imageType: 'event-playlist',
    onSuccess: (_imageId, imageUrl) => {
      onChange(imageUrl)
    },
  })

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
      <input
        accept={acceptedFileTypes}
        type='file'
        onChange={e => {
          const file = e.target.files?.[0]
          if (file) uploadImage(file)
        }}
        disabled={isUploading}
      />
      <div>{value && <img src={value} alt='Event Playlist Image' style={{height: 400, width: 'auto'}} />}</div>
    </div>
  )
}
