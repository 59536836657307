import React, {useState} from 'react'
import {useParams} from 'react-router-dom'

import {ClientCustomCheckoutField} from '@posh/model-types'
import {useFetchCustomCheckoutFields} from 'apis/CustomCheckoutFields/useFetchCustomCheckoutFields'
import {useSaveCustomCheckoutFields} from 'apis/CustomCheckoutFields/useSaveCustomCheckoutFields'
import CustomCheckoutFieldsCreator from 'components/CustomCheckoutFieldsCreator/CustomCheckoutFieldsCreator'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'

import './styles.scss'

export const NewCustomCheckoutFields = () => {
  const {groupId, eventId} = useParams()
  const {isFetching, isLoading} = useFetchCustomCheckoutFields(
    {eventId: eventId!},
    {
      onSuccess: data => {
        setCustomCheckoutFields(data)
      },
    },
  )
  const {mutate: saveCustomCheckoutFields} = useSaveCustomCheckoutFields()
  const [customCheckoutFields, setCustomCheckoutFields] = useState<ClientCustomCheckoutField[]>([])
  const onClickSaveChanges = () => {
    saveCustomCheckoutFields({fields: customCheckoutFields, eventId: eventId!, groupId: groupId!})
  }

  if (isLoading) {
    return (
      <div className='NewCustomCheckoutFields'>
        <SpinLoader />
      </div>
    )
  }

  return (
    <div className='NewCustomCheckoutFields'>
      <p className='noMargin'>
        Custom checkout fields allow you to gather additional info from attendees during the checkout process.
      </p>
      <p className='noMargin'>They appear at the beginning of checkout.</p>
      <CustomCheckoutFieldsCreator
        checkoutFields={customCheckoutFields}
        setCustomCheckoutFields={setCustomCheckoutFields}
      />
      <Button className='gold light' onClick={onClickSaveChanges}>
        {isFetching ? <SpinLoader color='#000' /> : 'Save Changes'}
      </Button>
    </div>
  )
}
