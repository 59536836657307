import React from 'react'
import {Link} from 'react-router-dom'

import {useGetEventPlaylistsForEditor} from 'apis/Events/playlists/manage/useGetEventPlaylistsForEditor'
import {ExternalLink} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {Card} from 'pages/EventManagementPages/OverviewUpdate/Analytics/Shared/Card/Card'

import styles from './ManageEventPlaylistsPage.module.scss'

export const ManageEventPlaylistsPage = () => {
  const {data, isSuccess} = useGetEventPlaylistsForEditor()

  return (
    <div className={styles.PageContainer}>
      <h1>Manage Event Playlists</h1>
      <Link to='create' style={{textDecoration: 'inherit'}}>
        <Button style={{marginBottom: 20}}>Create New Playlist</Button>
      </Link>
      {isSuccess && (
        <div className={styles.PageContent}>
          {data.playlists
            .sort(
              (playlistA, playlistB) =>
                new Date(playlistB.updatedAt).getTime() - new Date(playlistA.updatedAt).getTime(),
            )
            .map(playlist => (
              <Link
                to={`/manage-event-playlists/${playlist._id}`}
                key={playlist._id}
                style={{textDecoration: 'inherit'}}>
                <Card className={styles.EventPlaylistCard}>
                  <img src={playlist.backgroundImage} alt={playlist.title} />
                  <div className={styles.EventPlaylistInfo}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      <h3>{playlist.title}</h3>
                      <Link to={`/c/${playlist.slug}`} style={{textDecoration: 'none'}}>
                        <ExternalLink color='white' width={16} height={16} />
                      </Link>
                    </div>
                    <p className='noMargin'>
                      Last Updated{' '}
                      {new Intl.DateTimeFormat('en-US', {dateStyle: 'medium', timeStyle: 'medium'}).format(
                        new Date(playlist.updatedAt),
                      )}
                    </p>
                    <p className='noMargin'>{playlist.views} Views</p>
                  </div>
                </Card>
              </Link>
            ))}
        </div>
      )}
    </div>
  )
}
