import React from 'react'

import {BookingRequestWithBookerInfo} from 'apis/Community'

import {getDate, getTime} from '../dashboardHelpers'

interface BookingRequestProps {
  bookingRequest: BookingRequestWithBookerInfo
}

const Booking = (props: BookingRequestProps) => {
  const {bookingRequest} = props
  const {bookingDate, startTime, endTime, location, booker, opened} = bookingRequest

  return (
    <div className={opened ? 'ComSupDash-requests-request' : 'ComSupDash-requests-request New'}>
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{color: '#fc0'}}>{getDate(bookingDate)}</div>
          <span className='New-text'>{opened ? '' : 'NEW!'}</span>
        </div>
        <div>{`${getTime(startTime)} - ${getTime(endTime)}`}</div>
        <div>{location}</div>
      </div>
      <div className='ComSupDash-requests-request-booker'>
        <img src={booker.avi ? booker.avi : 'https://assets.posh.vip/b2/default-avi.jpg'} />
        <span>{`${booker.firstName} ${booker.lastName}`}</span>
      </div>
    </div>
  )
}

export default Booking
