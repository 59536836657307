import React from 'react'

interface SideArtProps {
  isCommunityRoute: boolean
}

export const SideArt = (props: SideArtProps) => {
  const {isCommunityRoute} = props
  return (
    <div className={isCommunityRoute ? 'OnboardPage-sideCommunity' : 'OnboardPage-side'}>
      {isCommunityRoute ? (
        <>
          <a className='OnboardPage-side-communityLogo' href='https://posh.vip/community'>
            <img src='https://assets.posh.vip/b2/posh-community.png' />
          </a>
          <img className='OnboardPage-side-communityArt' src='https://images.posh.vip/high-res-community-comic.jpg' />
        </>
      ) : (
        <>
          <a className='OnboardPage-side-logo' href='https://posh.vip'>
            <img src='https://assets.posh.vip/b2/posh-rectangle-typeface-black.svg' />
          </a>
          <img className='OnboardPage-side-art' src='https://assets.posh.vip/b2/comic2.png' />
        </>
      )}
    </div>
  )
}
