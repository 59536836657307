import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export async function getPasswordResetEmail(email: string): Promise<boolean> {
  const response = await PoshApi.requestPasswordReset(email)
  return response.data.success
}

export function useSendPasswordEmail() {
  return useMutation<boolean, unknown, {email: string}>(params => getPasswordResetEmail(params.email), {
    retry: false,
  })
}
