import React, {useMemo, useState} from 'react'

import {formatEventPlaylistDate} from '@posh/model-types'
import {Currency} from '@posh/utils'
import {
  GetEventsForEventPlaylistInput,
  useGetEventsForEventPlaylist,
} from 'apis/Events/playlists/manage/useGetEventsForEventPlaylist'
import {Panel} from 'components/Panel/Panel'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {TableCell} from 'components/TableUI/TableCell/TableCell'

import {EventPlaylistSearchEvents} from './EventPlaylistSearchEvents'

import styles from './EventPlaylistEventsInput.module.scss'

interface EventPlaylistEventsInputProps {
  value: string[]
  onChange: (eventIds: string[]) => void
  sortOrder: GetEventsForEventPlaylistInput['sortOrder']
}

export const EventPlaylistEventsInput = (props: EventPlaylistEventsInputProps) => {
  const {value, onChange, sortOrder} = props

  const [isAddEventsPanelOpen, setIsAddEventsPanelOpen] = useState(false)
  const {data, isLoading, isSuccess, refetch, dataUpdatedAt} = useGetEventsForEventPlaylist(
    {ids: value, sortOrder},
    {keepPreviousData: true},
  )

  const handleAddEvents = (ids: string[]) => {
    onChange([...value, ...ids])
  }

  const handleRemoveEvent = (id: string) => {
    const newEventIds = value.filter(eventId => eventId !== id)
    onChange(newEventIds)
  }

  const events = useMemo(() => {
    return (
      data?.events.map(event => {
        return {
          id: event.id,
          eventAttributes: {
            name: event.name,
            flyer: event.flyer,
            url: event.url,
          },
          groupAttributes: {
            name: event.groupName,
            avi: event.groupAvi,
          },
          eventStartDate: {
            startUtc: event.startUtc,
            timezone: event.timezone,
          },
          eventNumTicketsSold: event.ticketsSold,
          groupGmv: {
            totalGmv: event.groupGmv,
            currency: event.groupCurrency,
          },
        }
      }) ?? []
    )
  }, [data?.events])

  const columnConfigs = useMemo<ColumnConfig<NonNullable<typeof events>[number]>[]>(() => {
    return [
      {
        header: 'Event',
        key: 'eventAttributes',
        render: event => (
          <div className={styles.EventAttributes}>
            <img src={event.flyer} alt={`${event.name}`} />
            <TableCell.Text text={event.name} />
          </div>
        ),
      },
      {
        header: 'Group',
        key: 'groupAttributes',
        render: group => (
          <div className={styles.GroupAttributes}>
            <img src={group.avi} alt={`${group.name} Profile Picture`} />
            <TableCell.Text text={group.name} />
          </div>
        ),
      },
      {
        header: 'Start Date',
        key: 'eventStartDate',
        render: ({startUtc, timezone}) => (
          <TableCell.Text text={Object.values(formatEventPlaylistDate(startUtc, timezone)).join(' ')} />
        ),
      },
      {
        header: 'Tickets Sold',
        key: 'eventNumTicketsSold',
        render: ticketsSold => <TableCell.Text text={ticketsSold.toString()} />,
      },
      {
        header: 'Group GMV (Total)',
        key: 'groupGmv',
        render: ({totalGmv, currency}) => <TableCell.Text text={Currency.format(totalGmv, currency)} />,
      },
    ]
  }, [])

  const itemsPerPage = Math.max(5, events.length + 1)

  return (
    <div className={styles.EventPlaylistEventsInput}>
      {isLoading && <div>Loading...</div>}
      {isSuccess && (
        <CRUDTable
          // fun little hack to make sure the items per page updates as the number of events changes
          key={`event-playlist-events-${itemsPerPage}`}
          resourceName='Event'
          data={events}
          columns={columnConfigs}
          actionButtons={[
            {icon: 'external-link', onClick: item => window.open(`/e/${item.eventAttributes.url}`, '_blank')},
            {icon: 'trash', onClick: item => handleRemoveEvent(item.id)},
          ]}
          refresh={refetch}
          lastUpdated={dataUpdatedAt}
          itemsPerPage={itemsPerPage}
          createButton={{
            title: 'Add Event',
            onClick: () => setIsAddEventsPanelOpen(true),
          }}
        />
      )}
      <Panel panelStyle={{width: '100%'}} isOpen={isAddEventsPanelOpen} onClose={() => setIsAddEventsPanelOpen(false)}>
        <EventPlaylistSearchEvents existingEventIds={value} onAddEvents={ids => handleAddEvents(ids)} />
      </Panel>
    </div>
  )
}
