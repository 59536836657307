import React from 'react'
import {useNavigate} from 'react-router-dom'

import {EventModel} from 'apis/Events/types'
import {EventAnalyticsResponse} from 'apis/Events/useFetchEventAnalytics'
import getEventFlyer from 'apis/Util/getEventFlyer'
import Button from 'components/form/Button'

import {useResourcePageParams} from '../../PoshAppLayout'

const PastEvent = ({
  sortedEvents,
  lastEvent,
  analytics,
  groupId,
  currencySymbol,
}: {
  sortedEvents: EventModel[]
  lastEvent?: EventModel | null
  analytics: EventAnalyticsResponse | null | undefined
  groupId: string
  currencySymbol?: string
}) => {
  const navigate = useNavigate()
  const {domain} = useResourcePageParams()

  return (
    // <div className='OverviewPage-pastEventWrapper'>
    <div className='OverviewPage-pastEvent'>
      <img src={getEventFlyer(sortedEvents[sortedEvents.length - 1])} />
      <div>
        <span>Congrats on the success of {lastEvent?.name}!</span>
        <div className='SmallAnalyticsGrid'>
          <div className='SmallAnalyticsGrid-statistic'>
            <div className='SmallAnalyticsGrid-statistic--label'>Tickets Sold</div>
            <div className='SmallAnalyticsGrid-statistic--number'>{analytics?.totalTickets}</div>
          </div>
          <div className='SmallAnalyticsGrid-statistic'>
            <div className='SmallAnalyticsGrid-statistic--label'>Revenue</div>
            <div className='SmallAnalyticsGrid-statistic--number'>
              {currencySymbol ?? '$'}
              {analytics?.totalRevenue.toFixed(2)}
            </div>
          </div>
        </div>
        <Button
          className='light dark fullWidth'
          onClick={() =>
            navigate(`/${domain}/groups/${groupId}/events/${sortedEvents[sortedEvents.length - 1]._id}/overview`)
          }>
          View Event Analytics
        </Button>
      </div>
    </div>
  )
}

export default PastEvent
