import React from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import useAddTeamMember, {AddTeamMemberBody} from 'apis/Roles/useAddTeamMember'
import useSessionContext from 'domains/Auth/SessionContext'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {admin, doorman, host} from '../../../../components/assets/Icons'
import Button from '../../../../components/form/Button'
import useTeamManagementModalContext from '../TeamManagementModalContext'
import RoleOption, {RoleOptionAttributes} from './RoleOption'

const options: RoleOptionAttributes[] = [
  {
    title: 'Doorman',
    description: 'Doorpeople can scan tickets and view the guestlist of any group event.',
    icon: doorman,
    roleKey: 'doorman',
  },
  {
    title: 'Host',
    description: "Hosts can view their sales and any revenue they've generated for events they're added to.",
    icon: host,
    roleKey: 'host',
  },
  {
    title: 'Admin',
    description: 'Admins can view all analytics and edit any details for any event they are added to.',
    icon: admin,
    roleKey: 'admin',
  },
]

interface Props {
  accountId: string
  onClickAddTeamMember?: (selectedRole: string) => void
}

export default function ChooseRoleForm(props: Props) {
  const {accountId, onClickAddTeamMember} = props
  const {
    setActivePanel,
    selectedRole,
    setSelectedRole,
    setErrorMessage,
    accountRoleToEdit,
    updatedPermissionConstaints,
    entityId,
    scope,
    setSuccessMessage,
  } = useTeamManagementModalContext()
  const {trackEvent} = useMixpanel()
  const {groupId} = useResourcePageParams()
  const {userId, accountRoles} = useSessionContext()
  const {mutateAsync: addTeamMember, isLoading} = useAddTeamMember()

  const handleEditPermissionsClick = () => {
    setErrorMessage('')
    setSuccessMessage('')
    if (!selectedRole) {
      setErrorMessage('Please select a role to edit permissions for')
      return
    }
    setActivePanel('edit_permissions')
  }

  const handleAddTeamMemberClick = async () => {
    try {
      setErrorMessage('')
      setSuccessMessage('')
      if (!groupId) throw new Error('Something went wrong. Please refresh and try again.')
      if (!selectedRole) throw new Error('Please select a role for your new team member')
      trackEvent('Add Click- Group Team Select Role Modal', {role: selectedRole})
      if (!accountRoles) throw new Error('Something went wrong. Please refresh and try again.')
      onClickAddTeamMember?.(selectedRole)
      const entityAccountRole = accountRoles.find(ar => ar.entity == entityId)
      const groupAccountRole = accountRoles.find(ar => ar.entity == groupId)
      const accountRole = entityAccountRole || groupAccountRole
      if (!accountRole) throw new Error('Something went wrong. Please refresh and try again.')
      const payload: AddTeamMemberBody = {
        accountId: accountId,
        role: selectedRole!,
        scope: scope,
        entity: entityId,
        parentEntity: scope == 'event' ? groupId! : undefined,
        assignedBy: userId!,
        permissionConstraints: updatedPermissionConstaints,
        currentUserAccountRoleId: accountRole._id,
      }
      const res = await addTeamMember(payload)
      setSuccessMessage(res)
      return
    } catch (err) {
      if (err.response) setErrorMessage(err.response.data.error)
      else setErrorMessage(err.message)
    }
  }

  return (
    <>
      <div className='AddTeamMemberModal-inputWrapper'>
        {options.map(o => (
          <RoleOption
            key={o.roleKey}
            optionAttributes={o}
            selected={selectedRole == o.roleKey}
            onClick={() => setSelectedRole(o.roleKey)}
            disabled={!!accountRoleToEdit}
          />
        ))}
      </div>
      <div className='AddTeamMemberModal-textWrapper'>
        <a className='AddTeamMemberModal-changePermission' onClick={handleEditPermissionsClick}>
          Edit Permissions
        </a>
      </div>
      <div className='AddTeamMemberModal-bottomInputWrapper'>
        <Button className='AddTeamMemberModal-submit' disabled={isLoading} onClick={handleAddTeamMemberClick}>
          + Add {selectedRole}
        </Button>
      </div>
    </>
  )
}
