import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useFetchAccountByUsername} from 'apis/Accounts/useFetchAccountByUsername'
import {EventModel} from 'apis/Events/types'
import IAffiliation from 'apis/Kickback/Affiliation'
import {OrderModel} from 'apis/Orders'
import {Card, CardSH} from 'components/Card'
import {useResourcePageParams} from 'pages/PoshAppLayout'

const OrderCardAffiliation = ({
  affiliation,
  event,
  order,
}: {
  affiliation: IAffiliation
  event: EventModel
  order: OrderModel
}) => {
  const {domain, groupId} = useResourcePageParams()
  const navigate = useNavigate()

  const {data: affiliate} = useFetchAccountByUsername(affiliation.affiliatePublicId!)

  return (
    <Card
      title={'Kickback Affiliate'}
      className='OrderPage-affiliateCard'
      animationIndex={2}
      info={"This order was made through this affiliate's Kickback link."}>
      <img
        className='OrderPage-avi'
        src={
          affiliate?.avi ??
          (affiliate?.instagram?.avi != '' ? affiliate?.instagram?.avi : null) ??
          'https://assets.posh.vip/b2/blank-avi.png'
        }
      />
      <div className='OrderPage-affiliateCard-name'>
        <a
          className='text-xl'
          onClick={() => navigate(`/${domain}/groups/${groupId}/marketing/attendee/${affiliate?._id}`)}>
          {affiliate?.firstName} {affiliate?.lastName}
        </a>
      </div>
      <CardSH title={'On This Order'}>
        <table>
          <tbody>
            <tr>
              <td>Revenue Kickbacked</td>
              <td>${order.kickbackAmount?.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </CardSH>
      <CardSH title={`${event.name} Stats`}>
        <table>
          <tbody>
            <tr>
              <td>Clicks</td>
              <td>{affiliation.clicks}</td>
            </tr>
            <tr>
              <td>Tickets Sold</td>
              <td>{affiliation.totalTickets}</td>
            </tr>
            <tr>
              <td>Revenue Generated</td>
              <td>${affiliation.revenueGenerated.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </CardSH>
    </Card>
  )
}

export default OrderCardAffiliation
