import React from 'react'
import QRCode from 'react-qr-code'

import classNames from 'classnames'

import {Fingerprint} from '../SVGs/svgs'

interface TicketProps {
  accentColor: string
  flyer: string
  name: string
  orderNumber?: number
  orderId: string
  zoomOnQr: boolean
  eventName: string
  lightMode: boolean
}

const Ticket = (props: TicketProps) => {
  const {accentColor, flyer, name, orderNumber, orderId, zoomOnQr, eventName, lightMode} = props
  const svgBackground = lightMode ? 'white' : '#000'
  return (
    <div className={classNames('Ticket', {zoomed: zoomOnQr}, {lightMode})}>
      <img className='Ticket-flyer' src={flyer} />

      <svg viewBox='0 0 900 200' preserveAspectRatio='none'>
        <polygon points='0,0 210,0 180,200 0,200' fill={svgBackground} />
      </svg>

      <div className='Ticket-frame' style={{border: `5px solid ${accentColor}`}} />

      <div className={classNames('Ticket-fingerprint', {lightMode})}>
        <Fingerprint accentColor={accentColor} />
      </div>

      <svg viewBox='0 0 900 200' preserveAspectRatio='none'>
        <polygon points='753,0 763,0 755,200 745,200' fill={svgBackground} />
      </svg>

      <div className={classNames('Ticket-qrContainer', {zoomed: zoomOnQr})}>
        <QRCode
          value={`https://posh.vip/scan-ticket/${orderId}`}
          bgColor={svgBackground}
          fgColor={accentColor}
          size={150}
        />
      </div>

      <div style={{color: accentColor}} className='Ticket-name'>
        {name} - Order #{orderNumber}
      </div>

      <div style={{color: accentColor}} className='Ticket-eventName'>
        {eventName.substring(0, 50)}
      </div>

      <div className='Ticket-gradient' />
    </div>
  )
}

export default Ticket
