import React from 'react'

import {CustomEventPageFont, CustomEventPageFonts} from '@posh/model-types'

export function getEventTitleFont(eventTitleFont: CustomEventPageFont | string | undefined) {
  // Circular-Std is already included in the app's font list and is not a google font
  if (eventTitleFont === 'Circular-Std') return undefined
  return eventTitleFont
}

export function EventTitleFontStyleProvider({eventTitleFont}: {eventTitleFont?: CustomEventPageFont | string}) {
  const isValidFont =
    eventTitleFont &&
    getEventTitleFont(eventTitleFont) &&
    CustomEventPageFonts.includes(eventTitleFont as CustomEventPageFont)
  if (isValidFont)
    return (
      <link rel='stylesheet' href={`https://fonts.googleapis.com/css?family=${encodeURIComponent(eventTitleFont)}`} />
    )
  return null
}
