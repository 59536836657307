import React from 'react'

import './styles.scss'

interface FlyerPickerParams {
  image?: string | null
  title?: string
  openModal: () => void
}

/**
 * @deprecated
 * Do not use this component anymore! It is deprecated but is still used by the Duplicate Event Modal.
 * Please delete it once it is no longer used.
 *
 * 4/29/2024 - @henryvecchione
 */
export const FlyerPicker = (params: FlyerPickerParams) => {
  const {image, openModal, title} = params

  return (
    <div className='FlyerPicker' onClick={openModal}>
      {title && <label>{title}</label>}
      <div className='FlyerPicker-flyer' onClick={openModal}>
        <img className='FlyerPicker-flyer-icon' src='https://assets.posh.vip/b2/image-input.svg' />
        {image && <img src={image} className='FlyerPicker-selectedFlyer' />}
      </div>
    </div>
  )
}
