import React, {useState} from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import {CreateEventOverlayBackground} from 'components/assets/Images'
import {GlassMorphicButton} from 'components/form/Button'
import {MultiSwitch} from 'components/form/MultiSwitch'
import {PageOverlay, PageOverlayProps} from 'components/PageComponents/EventVisuals/Page/PageOverlay/PageOverlay'
import useSessionContext from 'domains/Auth/SessionContext'
import {useDefaultBackNavigation} from 'hooks/navigation/useDefaultBackNavigation'

import styles from './styles.module.scss'

interface InitializeEventParams {
  isPaidEvent: boolean
}

interface CreateEventOnboardingOverlayProps extends PageOverlayProps {
  onCreateEvent: (params: InitializeEventParams) => void
  isSubmitting?: boolean
}

const eventTypes = ['Sell Tickets', 'RSVP Only']
type TEventTypes = (typeof eventTypes)[number]
const PURPLE_SWITCH_ACCENT_COLOR_WITH_OPACITY = `rgba(171,134,229, 0.2)`
const DUPLICATE_COMPONENT_ARRAY = [1, 2, 3]

export const CreateEventOnboardingOverlay = (props: CreateEventOnboardingOverlayProps) => {
  const {onCreateEvent, isSubmitting, isOpen} = props
  const {trackEvent} = useMixpanel()
  const {currentUser} = useSessionContext()

  const {goBack: onPressCancel} = useDefaultBackNavigation()

  const [selectedType, setSelectedType] = useState<TEventTypes>(eventTypes[0])

  const initializePendingEvent = () => {
    const isPaidEvent = selectedType === 'Sell Tickets'
    onCreateEvent({
      isPaidEvent,
    })
    trackEvent('Create Event - Continue Creating', {isAuthed: !!currentUser, isFreeEvent: !isPaidEvent})
  }

  const buttonTitle = isSubmitting ? 'Loading' : 'Continue'
  const backgroundStyles = {
    background: `url(${CreateEventOverlayBackground}) white 50% / cover no-repeat`,
  }

  const switchType = (type: TEventTypes) => {
    setSelectedType(type)
    type === 'Sell Tickets' ? trackEvent('Create Event - Sell Tickets') : trackEvent('Create Event - RSVP Only')
  }

  return (
    <PageOverlay isOpen={isOpen} style={backgroundStyles}>
      <div className={styles.CreateEventOverlayContent}>
        <div className={styles.titleWrapper}>
          {DUPLICATE_COMPONENT_ARRAY.map((_, index) => (
            <h2 aria-hidden='true' key={index} className='noMargin center'>{`Let's launch your event`}</h2>
          ))}
        </div>
        <MultiSwitch.Glass
          options={eventTypes}
          selected={selectedType}
          onSelect={switchType}
          style={{maxWidth: '370px'}}
          accentColor={PURPLE_SWITCH_ACCENT_COLOR_WITH_OPACITY}
        />
        <div className={styles.Buttons}>
          <GlassMorphicButton
            onClick={initializePendingEvent}
            disabled={isSubmitting}
            isLoading={isSubmitting}
            glassViewStyle={{padding: '10px 35px'}}
            style={{padding: 'unset'}}
            color={PURPLE_SWITCH_ACCENT_COLOR_WITH_OPACITY}
            className={'outline'}>
            <h4 className='noMargin'>{buttonTitle}</h4>
          </GlassMorphicButton>
          <p onClick={() => onPressCancel()}>Cancel</p>
        </div>
      </div>
    </PageOverlay>
  )
}
