import React from 'react'

import {AccountRoleResponse} from 'apis/Roles'

import {AccountModel} from '../../apis/Accounts'

export interface AccountRoleGroupIdMap {
  [key: string]: AccountRoleResponse
}

export type SessionContextState = {
  userId?: string | null
  setUserId: (id: string | undefined | null) => void
  token?: string | null
  setToken: (token: string | undefined | null) => void
  currentUser?: AccountModel
  setCurrentUser: (account: AccountModel | undefined) => void
  accountRoles?: AccountRoleResponse[]
  setAccountRoles: (accountRoles: AccountRoleResponse[] | undefined) => void
  accountRoleGroupIdMap?: AccountRoleGroupIdMap | undefined
  setGroupsWhereAccountIsOwnerMap: (map: Record<string, string>) => void
  groupsWhereAccountIsOwnerMap?: Record<string, string>
  setAccountRoleGroupIdMap: (accountRoleGroupIdMap: AccountRoleGroupIdMap | undefined) => void
  updateLocalAccountAvi: (avi: string) => void
  unauthenticateSession: () => void
}

export const SessionContext = React.createContext<SessionContextState | null>(null)

export default function useSessionContext() {
  const context = React.useContext(SessionContext)
  if (!context) throw 'SessionContext must be provided'
  return context
}
