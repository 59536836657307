import React from 'react'

import Multiselect from 'multiselect-react-dropdown'

import multiSelectStyles from './multi-select-styles'

import './styles.scss'

export interface SelectOption {
  name: string
  id: string
  selected: boolean
}

interface ComponentOptions {
  name: string
  id: string
}

interface MultiSelectProps {
  options: SelectOption[]
  updateOptions: (options: SelectOption[]) => void
  name?: string
  emptyMessage: string
  placeholder: string
}

export const PoshMultiSelect = (props: MultiSelectProps) => {
  const {options, placeholder, name, emptyMessage, updateOptions} = props
  const selectedValues: ComponentOptions[] = options
    .filter(option => option.selected)
    .map(option => {
      return {name: option.name, id: option.id}
    })

  const componentOptions = options.map(option => {
    return {name: option.name, id: option.id}
  })

  const handleSelect = (selectedList: ComponentOptions[]) => {
    const updateOptionsList = componentOptions.map(item => {
      if (selectedList.find(option => option.id === item.id)) return {name: item.name, id: item.id, selected: true}
      else return {name: item.name, id: item.id, selected: false}
    })
    updateOptions(updateOptionsList)
  }

  const handleRemove = (selectedList: ComponentOptions[], selectedItem: ComponentOptions) => {
    const newList = componentOptions.map(item => {
      if (selectedList.find(option => option.id === item.id && option.id !== selectedItem.id))
        return {name: item.name, id: item.id, selected: true}
      else return {name: item.name, id: item.id, selected: false}
    })
    updateOptions(newList)
  }

  return (
    <div className='PoshMultiSelect'>
      {options?.length > 0 ? (
        <>
          {name && (
            <div>
              <h6>{name}</h6>
            </div>
          )}
          <Multiselect
            selectedValues={selectedValues}
            options={componentOptions} // Options to display in the dropdown
            onSelect={sl => handleSelect(sl)} // Function will trigger on select event
            onRemove={(sl, si) => handleRemove(sl, si)} // Function will trigger on remove event
            displayValue='name' // Property name to display in the dropdown options
            style={multiSelectStyles}
            closeOnSelect={false}
            placeholder={placeholder}
          />
        </>
      ) : (
        <p>{emptyMessage}</p>
      )}
    </div>
  )
}
