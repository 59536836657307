import React from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Fade from 'react-reveal/Fade'

import {EventLineUp, EventPublic} from 'apis/Events/types'

import EventSection from '../EventSection'
import {LineupItem} from './LineupItem'

import styles from './styles.module.scss'

export function EventPageLineupSection(props: {event: EventPublic; onClickItem: (item: EventLineUp) => void}) {
  const {event, onClickItem} = props
  if (!event.lineup || event.lineup.length === 0) return null

  const {accentColor, lightmode} = event
  return (
    <EventSection accentColor={accentColor} header={event.performanceCategory ?? 'Lineup'}>
      <div className={styles.EventPageLineupSection}>
        {event.lineup?.map(artist => (
          <Fade bottom key={artist._id}>
            <LineupItem item={artist} onClick={onClickItem} accentColor={accentColor} lightmode={lightmode} />
          </Fade>
        ))}
      </div>
    </EventSection>
  )
}
