import React, {useState} from 'react'

import {useUpdateRefundRequest} from 'apis/AdminDashboard/useUpdateRefundRequest'
import Button from 'components/form/Button'
import {useToast} from 'components/toasts/ToastProvider'

import {PendingDynamicBodyProps, RefundStatus} from '../../types'
import {buildEventUrl, formatDollars} from '../utils'

export const PendingDynamicBody = (props: PendingDynamicBodyProps) => {
  const {mutateAsync: updateRefundRequest} = useUpdateRefundRequest()
  const {refund, confirmationModalStates, contactModalStates, requestDetailsStates} = props
  const {
    text: setConfirmationText,
    click: setConfirmationHandlers,
    open: setIsConfirmationModalOpen,
  } = confirmationModalStates
  const {setContactOwners, setIsContactModalOpen} = contactModalStates
  const {setIsRequestDetailsModalOpen, setRequestDetails} = requestDetailsStates
  const {showToast} = useToast()
  const [isRequestHandled, setIsRequestHandled] = useState(false)

  const handleContactClick = () => {
    setContactOwners(refund.owners)
    setIsContactModalOpen(true)
  }

  const handleRequestDetailsClick = () => {
    setRequestDetails({
      reason: refund.reason,
      description: refund.description,
      supportInstructions: refund.supportInstructions,
    })
    setIsRequestDetailsModalOpen(true)
  }

  const handleConfirmationClick = async (status: string, includesFees: boolean) => {
    setIsConfirmationModalOpen(false)
    setIsRequestHandled(true)
    showToast({type: 'success', title: `Handling refund request for ${refund.event.name}...`})
    try {
      await updateRefundRequest({
        status,
        eventId: refund.eventId.toString(),
        includesFees,
      })
      showToast({type: 'success', title: `Refund request for ${refund.event.name} successfully ${status}`})
    } catch (err) {
      setIsRequestHandled(false)
      showToast({type: 'error', title: err.message})
    }
  }

  const handleDenyRequestClick = () => {
    setConfirmationText({
      title: 'Deny Request',
      bodyText: 'Are you sure you want to deny this refund request?',
      confirmationMessage: 'Refund request denied',
      confirmButtonText: 'Deny Request',
    })
    setConfirmationHandlers({
      onClose: () => {
        setIsConfirmationModalOpen(false)
      },
      onClick: async () => {
        await handleConfirmationClick(RefundStatus.DENIED, false)
      },
    })
    setIsConfirmationModalOpen(true)
  }

  const handleApproveRequestClick = () => {
    setConfirmationText({
      title: 'Approve Request',
      bodyText: 'Are you sure you want to approve this refund request without fees?',
      confirmationMessage: 'Refund request approved',
      confirmButtonText: 'Approve Request',
    })
    setConfirmationHandlers({
      onClose: () => {
        setIsConfirmationModalOpen(false)
      },
      onClick: async () => {
        await handleConfirmationClick(RefundStatus.APPROVED, false)
      },
    })
    setIsConfirmationModalOpen(true)
  }

  const handleApproveFeesClick = () => {
    setConfirmationText({
      title: 'Approve Request With Fees',
      bodyText: 'Are you sure you want to approve this refund request with fees?',
      confirmationMessage: 'Refund request with fees approved',
      confirmButtonText: 'Approve Request',
    })
    setConfirmationHandlers({
      onClose: () => {
        setIsConfirmationModalOpen(false)
      },
      onClick: async () => {
        await handleConfirmationClick(RefundStatus.APPROVED, true)
      },
    })
    setIsConfirmationModalOpen(true)
  }

  return (
    <>
      <tr key={refund._id.toString()}>
        <td>{refund.group.name}</td>
        <td>
          <a className='white' href={buildEventUrl(refund.event.url)}>
            {refund.event.name}
          </a>
        </td>
        <td>${formatDollars(refund.event.gmv)}</td>
        <td>
          <div className='FullRefundDashboard-ButtonGrid'>
            <Button className='small' onClick={handleRequestDetailsClick}>
              View Request Details
            </Button>
            <Button className='small' onClick={handleContactClick}>
              Contact Owner
            </Button>
            <Button className='red small' onClick={handleDenyRequestClick} disabled={isRequestHandled}>
              Deny Request
            </Button>
            <Button className='red small' onClick={handleApproveRequestClick} disabled={isRequestHandled}>
              Refund Tickets
            </Button>
            <Button className='red small' onClick={handleApproveFeesClick} disabled={isRequestHandled}>
              Refund Tickets & Fees
            </Button>
          </div>
        </td>
      </tr>
    </>
  )
}
