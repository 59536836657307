import React from 'react'

import './styles.scss'

const MobileAppLanding = () => {
  return (
    <>
      <div className='MALWrapper'>
        <img className='MAL-appIcon' src='https://assets.posh.vip/b3/app-ctas/POSH+App+Icon+3.0.png' />
        <img className='MAL-textImg' src='https://assets.posh.vip/b2/posh-rectangle-typeface.svg' />
        <div className='MAL-CTAs'>
          <a href='https://apps.apple.com/us/app/posh-curator/id1556928106'>
            <img src='https://assets.posh.vip/b3/app-ctas/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' />
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.posh.mobile&hl=en_US'>
            <img src='https://assets.posh.vip/b3/app-ctas/google-play-badge.png' />
          </a>
        </div>
      </div>
    </>
  )
}

export default MobileAppLanding
