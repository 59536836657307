import React, {PropsWithChildren} from 'react'
import {DragDropContext, Droppable, OnDragEndResponder} from 'react-beautiful-dnd'

import {ClientCustomCheckoutField, CustomFieldType} from '@posh/model-types'
import CustomCheckoutFieldTemplate from 'components/CustomCheckoutFieldsCreator/CustomCheckoutFieldTemplate'
import {nanoid} from 'nanoid'

import AddFieldTypes from './AddFieldTypes'

import './CustomCheckoutFieldsCreator.styles.scss'

const CheckoutFieldsTemplates = (props: PropsWithChildren<any>) => {
  return (
    <Droppable droppableId={'checkout-fields'}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{display: 'flex', flexDirection: 'column', gap: '32px'}}>
          {props.children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

const CustomCheckoutFieldsCreator = ({
  checkoutFields,
  setCustomCheckoutFields,
}: {
  checkoutFields: ClientCustomCheckoutField[]
  setCustomCheckoutFields: React.Dispatch<React.SetStateAction<ClientCustomCheckoutField[]>>
}) => {
  const onDelete = (index: number) => {
    const newCustomCheckoutFields = [...checkoutFields]
    const deletedField = newCustomCheckoutFields[index]
    if (deletedField.isNew) {
      newCustomCheckoutFields.splice(index, 1)
    } else {
      newCustomCheckoutFields[index].delete = true
    }
    setCustomCheckoutFields(newCustomCheckoutFields)
  }
  const onChangeRequired = (required: boolean, index: number) => {
    const newCustomCheckoutFields = [...checkoutFields]
    newCustomCheckoutFields[index].required = required
    setCustomCheckoutFields(newCustomCheckoutFields)
  }
  const onChangePrompt = (prompt: string, index: number) => {
    const newCustomCheckoutFields = [...checkoutFields]
    newCustomCheckoutFields[index].prompt = prompt
    setCustomCheckoutFields(newCustomCheckoutFields)
  }
  const onChangeOptions = (options: string[], index: number) => {
    const newCustomCheckoutFields = [...checkoutFields]
    newCustomCheckoutFields[index].options = options
    setCustomCheckoutFields(newCustomCheckoutFields)
  }
  const onAddFieldTemplate = (checkoutField: CustomFieldType) => {
    const newFieldTemplate: ClientCustomCheckoutField = {
      type: checkoutField,
      id: nanoid(),
      prompt: '',
      required: false,
      isNew: true,
    }
    if (checkoutField === 'dropdown' || checkoutField === 'checkboxes') {
      newFieldTemplate.options = ['', '']
    }
    setCustomCheckoutFields([...checkoutFields, newFieldTemplate])
  }

  const onDragEnd: OnDragEndResponder = result => {
    const {destination, source} = result
    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    const newCustomCheckoutFields = [...checkoutFields]
    const [removed] = newCustomCheckoutFields.splice(source.index, 1)
    newCustomCheckoutFields.splice(destination.index, 0, removed)
    setCustomCheckoutFields(newCustomCheckoutFields)
  }
  return (
    <div className='CustomCheckoutFieldsCreator'>
      <DragDropContext onDragEnd={onDragEnd}>
        <CheckoutFieldsTemplates>
          {checkoutFields.map((checkoutField, index) => {
            if (checkoutField.delete) return null
            else
              return (
                <CustomCheckoutFieldTemplate
                  key={checkoutField.id}
                  onDelete={() => onDelete(index)}
                  onChangeRequired={required => onChangeRequired(required, index)}
                  onChangePrompt={prompt => onChangePrompt(prompt, index)}
                  onChangeOptions={options => onChangeOptions(options, index)}
                  index={index}
                  {...checkoutField}
                />
              )
          })}
        </CheckoutFieldsTemplates>
      </DragDropContext>
      <AddFieldTypes onPressAddFieldType={onAddFieldTemplate} />
    </div>
  )
}

export default CustomCheckoutFieldsCreator
