import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {EventModel} from 'apis/Events/types'
import getEventFlyer from 'apis/Util/getEventFlyer'
import {useGroupMatchParams} from 'domains/Groups/helpers'

import MatchAllInput from './matchAllInput'

import './styles.scss'

interface EventFilterProps {
  events: EventModel[]
  selectedEvents: string[] | undefined
  setEvents: React.Dispatch<React.SetStateAction<string[]>>
  matchAllEvents: boolean
  setMatchAllEvents: React.Dispatch<React.SetStateAction<boolean>>
}

interface EventPreviewProps {
  event: EventModel
  selectedEvents: string[] | undefined
  setEvents: React.Dispatch<React.SetStateAction<string[]>>
}

const EventPreview = (props: EventPreviewProps) => {
  const {event, selectedEvents, setEvents} = props
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    if (selectedEvents && selectedEvents?.includes(event._id)) setIsSelected(true)
    else setIsSelected(false)
  }, [selectedEvents])

  const handleSelectEvent = () => {
    let currentlySelected: string[] = []
    if (selectedEvents) currentlySelected = [...selectedEvents]
    if (!isSelected) {
      currentlySelected.push(event._id)
      setEvents(currentlySelected)
    } else {
      const index = currentlySelected.indexOf(event._id)
      if (index !== -1) {
        currentlySelected.splice(index, 1)
        setEvents(currentlySelected)
      }
    }
  }

  return (
    <div className='EventPreview'>
      <a onClick={() => handleSelectEvent()}>
        <img className={isSelected ? 'EventPreview-selected' : 'EventPreview-notSelected'} src={getEventFlyer(event)} />
        <p className='EventPreview-name'>{event.name}</p>
      </a>
    </div>
  )
}

const EventFilters = (props: EventFilterProps) => {
  const {events, selectedEvents, setEvents, matchAllEvents, setMatchAllEvents} = props
  const {groupId} = useGroupMatchParams()
  const search = useLocation().search
  return (
    <div className='EventModalSection'>
      {events && events.length > 0 ? (
        <>
          <div className='FilterModal-topRow'>
            <h6>
              Filter By Events{' '}
              {selectedEvents && selectedEvents.length > 0 && (
                <i style={{color: '#fc0'}}>{selectedEvents?.length} Events Selected</i>
              )}
            </h6>
            <MatchAllInput setMatchAll={setMatchAllEvents} matchAll={matchAllEvents} />
          </div>
          <div className='EventModalSection-eventsWrap'>
            {events.map(event => (
              <EventPreview key={event._id} event={event} selectedEvents={selectedEvents} setEvents={setEvents} />
            ))}
          </div>
        </>
      ) : (
        <p>
          You haven&apos;t created any events yet.{' '}
          <a
            style={{color: '#fc0', cursor: 'pointer'}}
            href={`https://posh.vip/owner/groups/${groupId}/create_event` + search}>
            Create an Event
          </a>
        </p>
      )}
    </div>
  )
}

export default EventFilters
