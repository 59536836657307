import React from 'react'
import CSVReader from 'react-csv-reader'

import Button from 'components/form/Button'

import {CSVModalCofig} from './modalConfigs'

interface CSVUploadFirstStepProps {
  config: CSVModalCofig
  setCsvData: React.Dispatch<React.SetStateAction<Record<string, string | number>[]>>
}

export const CSVUploadFirstStep = (props: CSVUploadFirstStepProps) => {
  const {config, setCsvData} = props
  const {description, title} = config
  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, '_'),
  }

  return (
    <>
      <h3>{title} - CSV Upload</h3>
      {description}
      <div className='buttonWrapper column'>
        <a href={config.template} download>
          <Button>
            <img src='https://assets.posh.vip/b2/download.svg' />
            Download Template
          </Button>
        </a>
        <CSVReader
          cssClass='poshBtn noPadding gold'
          onFileLoaded={data => setCsvData(data)}
          parserOptions={parseOptions}
          label={
            <>
              <img src='https://assets.posh.vip/b2/upload-black.svg' />
              Upload CSV
            </>
          }
        />
      </div>
    </>
  )
}
