type InputType = 'select' | 'text' | 'radio' | 'number' | 'location' | 'price' | 'avi' | 'gallery'

interface InputOption {
  value: string
  label: string
  nextStepKey?: string
  key?: string
}

export interface Input {
  key?: string
  inputType: InputType
  options?: InputOption[]
  placeholder?: string
  isRequired?: boolean
  errorMessage?: string
  locationErrorMessage?: string
  locationType?: string[]
  perHour?: boolean
  minImages?: number
}

export interface OnboardingStep {
  key: string
  question: string
  inputs: Input[]
  nextStepKey?: string
  prevStepKey: string
}

const CommunityOnboardingSteps: OnboardingStep[] = [
  {
    key: 'profileType',
    prevStepKey: '',
    question: 'What are you registering as?',
    inputs: [
      {
        inputType: 'select',
        options: [
          {
            value: 'staff',
            label: 'Staff',
            nextStepKey: 'role',
            key: 'communityCategory',
          },
          {
            value: 'venue',
            label: 'Venue',
            nextStepKey: 'venueName',
            key: 'communityCategory',
          },
          {
            value: 'artist',
            label: 'Artist',
            nextStepKey: 'artistName',
            key: 'communityCategory',
          },
        ],
      },
    ],
  },

  // Venue Flow

  {
    key: 'venueName',
    prevStepKey: 'profileType',
    question: "What is your venue's name?",
    inputs: [
      {
        inputType: 'text',
        placeholder: 'ex. The POSH Bar',
        key: 'name',
        isRequired: true,
        errorMessage: 'Please enter a name for your venue',
      },
    ],
    nextStepKey: 'venueAddress',
  },

  {
    key: 'venueAddress',
    prevStepKey: 'venueName',
    question: "What is your venue's address?",
    inputs: [
      {
        inputType: 'location',
        placeholder: 'ex. 1 Main St, New York, NY',
        key: 'address',
        isRequired: true,
        errorMessage: 'Please enter an address for your venue.',
        locationType: ['geocode', 'establishment'],
      },
    ],
    nextStepKey: 'venueCapacity',
  },

  {
    key: 'venueCapacity',
    prevStepKey: 'venueAddress',
    question: 'How many people can your venue fit?',
    inputs: [
      {
        inputType: 'number',
        placeholder: 'ex. 500',
        key: 'maxCapacity',
        isRequired: true,
        errorMessage: 'Please enter a capacity for your venue.',
      },
    ],
    nextStepKey: 'moreVenueInfo',
  },

  {
    key: 'moreVenueInfo',
    prevStepKey: 'venueCapacity',
    question: 'If you have any of the below, feel free to add them!',
    inputs: [
      {
        inputType: 'text',
        placeholder: 'Website',
        key: 'website',
      },
      {
        inputType: 'text',
        placeholder: 'Instagram Username',
        key: 'instagram',
      },
      {
        inputType: 'price',
        placeholder: 'Est. Buyout Price',
        key: 'baseBuyout',
      },
      {
        inputType: 'number',
        placeholder: 'Age Limit',
        key: 'ageLimit',
      },
    ],
    nextStepKey: 'venueGallery',
  },

  {
    key: 'venueGallery',
    prevStepKey: 'moreVenueInfo',
    question: 'Add some pictures of your venue!',
    inputs: [
      {
        inputType: 'gallery',
        key: 'galleryImages',
        isRequired: true,
        errorMessage: 'Please add at least two image for your venue.',
        minImages: 2,
      },
    ],
    nextStepKey: '',
  },

  // Staff Flow

  {
    key: 'role',
    prevStepKey: 'profileType',
    question: 'What is your primary role?',
    inputs: [
      {
        inputType: 'select',
        options: [
          {
            value: 'videographer',
            label: 'Videographer',
            key: 'staffType',
          },
          {
            value: 'photographer',
            label: 'Photographer',
            key: 'staffType',
          },
          {
            value: 'bartender',
            label: 'Bartender',
            key: 'staffType',
          },
          {
            value: 'securityGuard',
            label: 'Security Guard',
            key: 'staffType',
          },
          {
            value: 'graphicDesigner',
            label: 'Graphic Designer',
            key: 'staffType',
          },
        ],
      },
    ],
    nextStepKey: 'staffLocation',
  },

  {
    key: 'staffLocation',
    prevStepKey: 'role',
    question: 'Where are you located?',
    inputs: [
      {
        inputType: 'location',
        placeholder: 'ex. Brooklyn, NY',
        key: 'location',
        isRequired: true,
        errorMessage: 'Please enter a valid location.',
        locationErrorMessage: 'Please enter only city and state.',
        locationType: ['(regions)'],
      },
    ],
    nextStepKey: 'moreStaffInfo',
  },

  {
    key: 'moreStaffInfo',
    prevStepKey: 'staffLocation',
    question: 'If you have any of the below, feel free to add them!',
    inputs: [
      {
        inputType: 'text',
        placeholder: 'Website',
        key: 'website',
      },
      {
        inputType: 'text',
        placeholder: 'Instagram Username',
        key: 'instagram',
      },
      {
        inputType: 'price',
        placeholder: 'Hourly Rate',
        key: 'hourlyRate',
        perHour: true,
      },
      {
        inputType: 'price',
        placeholder: 'Minimum Price',
        key: 'minimumPrice',
      },
      {
        inputType: 'number',
        placeholder: 'Years of Experience',
        key: 'yearsOfExperience',
      },
    ],
    nextStepKey: 'staffAvi',
  },

  {
    key: 'staffAvi',
    prevStepKey: 'moreStaffInfo',
    question: 'Add a profile picture!',
    inputs: [
      {
        inputType: 'avi',
        key: 'avi',
        isRequired: true,
        errorMessage: 'Please add a profile picture.',
      },
    ],
    nextStepKey: 'staffGallery',
  },

  {
    key: 'staffGallery',
    prevStepKey: 'staffAvi',
    question: 'Add some pictures of your work!',
    inputs: [
      {
        inputType: 'gallery',
        key: 'galleryImages',
        isRequired: true,
        errorMessage: 'Please add at least one image of you at work.',
        minImages: 1,
      },
    ],
    nextStepKey: '',
  },

  // Artist Flow

  {
    key: 'artistName',
    prevStepKey: 'profileType',
    question: 'What is your artist name?',
    inputs: [
      {
        inputType: 'text',
        placeholder: 'ex. Drake',
        key: 'artistName',
        isRequired: true,
        errorMessage: 'Please enter a name.',
      },
    ],
    nextStepKey: 'artistLocation',
  },

  {
    key: 'artistLocation',
    prevStepKey: 'artistName',
    question: 'Where are you located?',
    inputs: [
      {
        inputType: 'location',
        placeholder: 'ex. Brooklyn, NY',
        key: 'location',
        isRequired: true,
        errorMessage: 'Please enter a valid location.',
        locationErrorMessage: 'Please enter only city and state.',
        locationType: ['(regions)'],
      },
    ],
    nextStepKey: 'artistType',
  },

  {
    key: 'artistType',
    prevStepKey: 'artistLocation',
    question: 'What is your primary role?',
    inputs: [
      {
        inputType: 'select',
        options: [
          {
            value: 'instrumentalist',
            label: 'Instrumentalist',
            key: 'artistType',
          },
          {
            value: 'dj',
            label: 'DJ',
            key: 'artistType',
          },
          {
            value: 'vocalist',
            label: 'Vocalist',
            key: 'artistType',
          },
          {
            value: 'band',
            label: 'Band',
            key: 'artistType',
          },
        ],
      },
    ],
    nextStepKey: 'moreArtistInfo',
  },

  {
    key: 'moreArtistInfo',
    prevStepKey: 'artistType',
    question: 'If you have any of the below, feel free to add them!',
    inputs: [
      {
        inputType: 'text',
        placeholder: 'Website',
        key: 'website',
      },
      {
        inputType: 'text',
        placeholder: 'Instagram Username',
        key: 'instagram',
      },
      {
        inputType: 'text',
        placeholder: 'Spotify URL',
        key: 'spotifyUrl',
      },
      {
        inputType: 'price',
        placeholder: 'Base/Hourly Booking Fee',
        key: 'baseBookingFee',
      },
    ],
    nextStepKey: 'artistAvi',
  },

  {
    key: 'artistAvi',
    prevStepKey: 'moreArtistInfo',
    question: 'Add a profile picture!',
    inputs: [
      {
        inputType: 'avi',
        key: 'avi',
        isRequired: true,
        errorMessage: 'Please add a profile picture.',
      },
    ],
    nextStepKey: 'artistGallery',
  },

  {
    key: 'artistGallery',
    prevStepKey: 'artistAvi',
    question: 'Add some pictures of you performing!',
    inputs: [
      {
        inputType: 'gallery',
        key: 'galleryImages',
        isRequired: true,
        errorMessage: 'Please add at least one image of you performing.',
        minImages: 1,
      },
    ],
    nextStepKey: '',
  },
]

export default CommunityOnboardingSteps
