import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface CSVUploadProps {
  data: Record<string, string | number>[]
  clarifier?: string
  groupId: string
  eventId: string
  type: string
}

async function csvUpload(props: CSVUploadProps) {
  const response = await PoshApi.csvUpload(props.groupId, props.eventId, props.type, props.data, props.clarifier)
  return response.data
}

export default function useCSVUpload() {
  return useMutation((props: CSVUploadProps) => csvUpload(props), {
    retry: false,
  })
}
