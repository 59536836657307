import React from 'react'
import {Draggable} from 'react-beautiful-dnd'

import {ClientCustomCheckoutField, CustomFieldType} from '@posh/model-types'
import {SixGrip, TrashIcon} from 'components/assets/Icons'
import PoshSwitch from 'components/form/PoshSwitch'

const TemplateTitle: Record<CustomFieldType, string> = {
  input: 'TEXT INPUT',
  checkboxes: 'CHECKBOXES',
  dropdown: 'DROPDOWN',
  file_upload: 'FILE UPLOAD',
}
const PromptPlaceholder: Record<CustomFieldType, string> = {
  input: 'Ask a question ex. “What’s your favorite color?”',
  checkboxes: 'Ask a question ex. “What’s your favorite color?”',
  dropdown: 'Ask a question ex. “What’s your favorite color?”',
  file_upload: 'Prompt them to upload something ex. “Upload a photo of your ID”',
}
interface CheckoutFieldTemplateOptionProps {
  isFinalOption: boolean
  type: Extract<CustomFieldType, 'dropdown' | 'checkboxes'>
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void
  index: number
}

const CheckoutFieldTemplateOption = ({
  isFinalOption,
  onChange,
  value,
  type,
  index,
}: CheckoutFieldTemplateOptionProps) => {
  const borderStyle = isFinalOption ? {border: 'none'} : {}
  const placeholderText = isFinalOption ? 'Add option' : `Option ${index + 1} ex. "Blue"`
  return (
    <div className='CustomCheckoutFieldTemplate-Option'>
      <label>{type === 'dropdown' ? `${index + 1}.` : <div />}</label>
      <input placeholder={placeholderText} style={borderStyle} value={value} onChange={e => onChange(e, index)} />
    </div>
  )
}
interface DeleteAndRequiredProps {
  required: boolean
  onChangeRequired: (checked: boolean) => void
  onDelete: () => void
}

const CheckoutFieldTemplateDeleteAndRequired = ({required, onChangeRequired, onDelete}: DeleteAndRequiredProps) => {
  return (
    <div className='CustomCheckoutFieldTemplate-DeleteAndRequired'>
      <button onClick={onDelete}>
        <TrashIcon color={'#909193'} width={20} height={20} />
      </button>
      <div>
        <p className='noMargin'>Required</p>
        <PoshSwitch switchOptions={{checked: required, onChange: onChangeRequired}} />
      </div>
    </div>
  )
}

type CustomCheckoutFieldTemplateProps = ClientCustomCheckoutField & {
  onDelete: () => void
  onChangeRequired: (required: boolean) => void
  onChangePrompt: (prompt: string) => void
  onChangeOptions: (options: string[]) => void
  index: number
}

const CustomCheckoutFieldTemplate = ({
  onDelete,
  onChangeRequired,
  onChangePrompt,
  onChangeOptions,
  type,
  required,
  prompt,
  options,
  ...props
}: CustomCheckoutFieldTemplateProps) => {
  const onChangeOption = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (isCheckoutFieldWithOptions) {
      const newOptions = [...options!]
      newOptions[index] = e.target.value
      const indexIsFinalOption = index === options!.length - 1
      if (e.target.value === '' && !indexIsFinalOption && options!.length !== 2) newOptions.splice(index, 1)
      if (indexIsFinalOption) newOptions.push('')
      onChangeOptions(newOptions)
    }
  }
  const onChangePromptValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangePrompt(e.target.value)
  }

  const isFileUpload = type === 'file_upload'
  const isCheckoutFieldWithOptions = type === 'checkboxes' || type === 'dropdown'
  return (
    <div className='CustomCheckoutFieldTemplate'>
      <h3>{TemplateTitle[type]}</h3>
      {isFileUpload && (
        <p>
          <strong>Let attendees upload files </strong>
        </p>
      )}
      <input placeholder={PromptPlaceholder[type]} value={prompt} onChange={onChangePromptValue}></input>
      {isFileUpload && (
        <p>
          Files will be uploaded to your Event CRM and Organization CRM. Be cautious when sharing the event and
          downloading files from strangers.
        </p>
      )}
      {isCheckoutFieldWithOptions &&
        options!.map((option, index) => (
          <CheckoutFieldTemplateOption
            key={index}
            isFinalOption={index === options!.length - 1}
            type={type}
            value={option}
            onChange={onChangeOption}
            index={index}
          />
        ))}
      <CheckoutFieldTemplateDeleteAndRequired
        required={required}
        onChangeRequired={onChangeRequired}
        onDelete={onDelete}
      />
    </div>
  )
}

const DraggableCustomCheckoutFieldTemplate = (props: CustomCheckoutFieldTemplateProps) => {
  return (
    <Draggable draggableId={props.id} index={props.index}>
      {({draggableProps, innerRef, dragHandleProps}) => (
        <div
          {...draggableProps}
          ref={innerRef}
          style={{display: 'flex', alignItems: 'center', gap: '10px', ...draggableProps.style}}>
          <CustomCheckoutFieldTemplate {...props} />
          <div {...dragHandleProps}>
            <SixGrip />
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default DraggableCustomCheckoutFieldTemplate
