import {NATIVE_OS_HEADER_KEY} from '@posh/types'
import axios from 'axios'
import {removeLocalStorageItems} from 'domains/Auth/SessionContextProvider'

import {AccountProfile} from './Accounts'
import {ConfirmAndAuthenticateAccountParams} from './Accounts/useConfirmAndAuthenticateAccount'
import {CreateAccountWithTempPasswordParams} from './Accounts/useCreateAccountWithTempPassword'
import {ResetPasswordParams} from './Accounts/useResetPassword'
import {
  CheckVerificationCodeAndUpdateAccountParams,
  VerifyNewLoginMethodParams,
} from './Accounts/useVerifyNewLoginMethod'
import {CreateCampaignLinkParams} from './AdminDashboard/useCreateCampaignLink'
import {DeleteCampaignLinkParams} from './AdminDashboard/useDeleteCampaignLink'
import {UpdateCampaignLinkParams} from './AdminDashboard/useUpdateCampaignLink'
import {UpdateRefundRequestParams} from './AdminDashboard/useUpdateRefundRequest'
import {CheckPhoneCodeParams} from './Auth'
import {SendUnconfirmedAccountSignupCodeParams} from './Auth/useSendUnconfirmedAccountSignupCode'
import {DeleteCustomFieldParams} from './Carts/useDeleteCustomCheckoutField'
import {CheckoutRedirectParams} from './Carts/useHandleCheckoutRedirect'
import {UpdateCustomFieldParams} from './Carts/useUpdateCustomCheckoutField'
import {UpdateCustomFieldsParams} from './Checkout/useAddCustomFields'
import {CommunityCategory, FetchAllParams, Tag} from './Community'
import {ContactEntityMessage} from './Community/useContactEntity'
import {CreateArtist} from './Community/useCreateArtist'
import {CreateBookingRequestData} from './Community/useCreateBookingRequest'
import {CreateCommunityCheckoutSessionParams} from './Community/useCreateCommunityCheckoutSession'
import {CreateStaffMember} from './Community/useCreateStaffMember'
import {CreateVenue} from './Community/useCreateVenue'
import {FetchBookingRequestsParams} from './Community/useFetchBookingRequests'
import {CommunityByCategoryParams} from './Community/useFetchCommunityByCategory'
import {FetchStaffGroupParams} from './Community/useFetchCommunityStaffMembersByStaffType'
import {UpdateArtist} from './Community/useUpdateArtist'
import {UpdateBookingRequestData} from './Community/useUpdateBookingRequest'
import {UpdateCommunityClicksParams} from './Community/useUpdateCommunityClicks'
import {UpdatePendingCommunity} from './Community/useUpdatePendingCommunity'
import {UpdateStaffMember} from './Community/useUpdateStaffMember'
import {UpdateVenue} from './Community/useUpdateVenue'
import {CreateEventTableParams, FetchEventAttendeesParams, GalleryModel, TableSeatMap} from './Events/types'
import {AddCommentReactionParams} from './Events/useAddCommentReaction'
import {CheckEventPasswordProps} from './Events/useCheckEventPassword'
import {CheckTicketPasswordProps} from './Events/useCheckTicketPassword'
import {CreateEventCustomFeeProps} from './Events/useCreateEventCustomFee'
import {CreatePromoCodeParams} from './Events/useCreatePromoCode'
import {CreateRefundRequestParams} from './Events/useCreateRefundRequest'
import {CreateTicketLinkParams} from './Events/useCreateTicketLink'
import {CreateTicketTypeParams} from './Events/useCreateTicketType'
import {DeleteEventCommentProps} from './Events/useDeleteEventComment'
import {DeleteEventCustomFeeProps} from './Events/useDeleteEventCustomFee'
import {EnableKickbacksParams} from './Events/useEnableKickbacks'
import {ExportReportParams} from './Events/useExportReport'
import {FetchAllEventAttendeesParams} from './Events/useFetchAllEventAttendees'
import {FetchCommentReplyParams} from './Events/useFetchCommentReplies'
import {ShouldDisplayProfilePicModal} from './Events/useFetchShouldDisplayProfilePictureModal'
import {RemovePromoCodeParams} from './Events/useRemovePromoCode'
import {RemoveTicketLinkParams} from './Events/useRemoveTicketLink'
import {UpdateFormChanges} from './Events/useSetRsvpSettings'
import {UpdateEventCustomFeeProps} from './Events/useUpdateEventCustomFee'
import {UpdateEventAttributes_DEPRECATED} from './Events/useUpdateEventDEPRECATED'
import {EditEventTableParams} from './Events/useUpdateEventTable'
import {UpdateGalleryParams} from './Events/useUpdateGallery'
import {UpdatePromoCodeParams} from './Events/useUpdatePromoCode'
import {UpdateTicketParams} from './Events/useUpdateTicket'
import {UpdateTicketGroupsParams} from './Events/useUpdateTicketGroups'
import {UpdateTicketLinkParams} from './Events/useUpdateTicketLink'
import {FetchGroupAttendeesParams, UpdateGroupAttributes} from './Groups'
import {AddDebitCardParams} from './Groups/useAddDebitCard'
import {CancelSubscriptionParams} from './Groups/useCancelGroupSubscription'
import {ChangePayoutIntervalParams} from './Groups/useChangePayoutInterval'
import {CreateCustomNumberCheckoutSessionParams} from './Groups/useCreateCustomNumberCheckoutSession'
import {CreateGroupCustomFeeProps} from './Groups/useCreateGroupCustomFee'
import {DeleteGroupCustomFeeProps} from './Groups/useDeleteGroupCustomFee'
import {FetchAllAttendeesParams} from './Groups/useFetchAllGroupAttendees'
import {GroupAnalyticsProps} from './Groups/useFetchGroupGraph'
import {FetchGroupWithCustomNumberParams} from './Groups/useFetchGroupWithCustomNumber'
import {TestWebhookParams} from './Groups/useTestWebhook'
import {UpdateGroupCustomFeeProps} from './Groups/useUpdateGroupCustomFee'
import {UpdateKickbackOfferProps} from './Kickback/useCreateKickBackOffer'
import {FetchKickBackPayoutLinkProps} from './Kickback/useFetchKickBackPayoutLink'
import {GenerateAffiliationProps} from './Kickback/useGenerateAffiliation'
import {UpdateKickBackActivationProps} from './Kickback/useUpdateKickBackActivation'
import {UpdateKickBackOfferLimitProps} from './Kickback/useUpdateKickbackOfferLimit'
import {UpdateKickBackValueProps} from './Kickback/useUpdateKickBackValue'
import {AddToMailChimpListParams} from './MailChimp/useAddUsersToMailChimpList'
import {AuthenticateMailChimpUserParams} from './MailChimp/useAuthenticateMailChimpUser'
import {CreateMailChimpListParams} from './MailChimp/useCreateMailChimpList'
import {FetchMailChimpAuthLinkParams} from './MailChimp/useFetchMailChimpAuthLink'
import {baseURL, buildUrl} from './NavigationHelper'
import {SetNotificationActionTakenParams} from './Notifications/useSetNotificationActionTaken'
import {SetNotificationAsReadParams} from './Notifications/useSetNotificationAsRead'
import {AggregateOrdersParams} from './Orders/useAggregateOrders'
import {CancelOrderParams} from './Orders/useCancelOrder'
import {FetchOrdersForTicketParams} from './Orders/useFetchOrderForTickets'
import {RefundOrderParams} from './Orders/useRefundOrder'
import {FreeOrderParams} from './Orders/useSendFreeTickets'
import {ConfirmPendingEventParams} from './PendingEvent/useConfirmPendingEvent'
import {UseCreateInitialPendingEventParams} from './PendingEvent/useCreateInitialPendingEvent'
import {UseFetchPendingEventParams} from './PendingEvent/useFetchPendingEvent'
import {UpdatePendingEventParams} from './PendingEvent/useUpdatePendingEvent'
import {UpdatePendingOrderParams} from './PendingOrders/useUpdatePendingOrder'
import {IPermissionConstraints} from './Roles'
import {RoleKey, ScopeKey} from './Roles/Role'
import {AddTeamMemberBody} from './Roles/useAddTeamMember'
import {CreateTagParams} from './Tags/useAddAccountsToTag'
import {ImageSearchParams} from './Util/useImageSearch'

interface EventCommentParams {
  eventId: string
  limit: number
}

const apiV2 = axios.create({
  baseURL: buildUrl(`/api/web/v2`),
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
})

const mobileApiV2 = axios.create({
  baseURL: buildUrl(`/api/mobile/v2`),
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
})

mobileApiV2.interceptors.request.use(
  request => {
    const token = localStorage.getItem('token')

    if (token) {
      request.headers!['X-JWT-Token'] = token
    }

    return request
  },
  error => Promise.reject(error),
)

apiV2.interceptors.request.use(
  request => {
    const campaignId = document.cookie
      .split('; ')
      .find(row => row.startsWith('campaignId'))
      ?.split('=')[1]

    if (campaignId) {
      request.headers!['x-campaign-id'] = campaignId
    }

    const token = localStorage.getItem('token')

    if (token) {
      request.headers!['X-JWT-Token'] = token
    }

    request.headers![NATIVE_OS_HEADER_KEY] = 'web'

    return request
  },
  error => Promise.reject(error),
)

mobileApiV2.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status == 401) {
      window.location.href = `${baseURL}/login`
    }

    return Promise.reject(error)
  },
)

apiV2.interceptors.response.use(
  response => {
    const refreshToken = response.headers['X-Renewed-JWT-Token'] as string

    if (refreshToken) {
      localStorage.setItem('token', refreshToken)
    }

    return response
  },
  error => {
    if (error?.response?.status == 401 && window.location.pathname[1] !== 'e') {
      removeLocalStorageItems()
      window.location.href = `${baseURL}/login`
    }

    return Promise.reject(error)
  },
)
apiV2.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 301) {
      const redirectBaseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://posh.vip'
      window.location.href = `${redirectBaseUrl}/rate-limit-exceeded`
    }

    return Promise.reject(error)
  },
)
export default class PoshApi {
  static requestPasswordReset(email: string) {
    return apiV2.post('/auth/reset_password_request', {
      body: {email},
    })
  }

  static passwordReset(params: ResetPasswordParams) {
    return apiV2.post('/auth/change_password', params)
  }

  static verifyPhoneNumber() {
    return apiV2.post('/auth/send_phone_verification')
  }

  static sendVerificationCode(params: VerifyNewLoginMethodParams) {
    return apiV2.post('/auth/send_verification_code', params)
  }
  static checkVerificationCodeAndUpdateAccount(params: CheckVerificationCodeAndUpdateAccountParams) {
    return apiV2.post('/auth/check_verification_code_and_update_account', params)
  }

  static checkVerificationCode(token: string) {
    return apiV2.post('/auth/check_phone_verification', {
      token,
    })
  }
  static checkLoginCode(params: CheckPhoneCodeParams) {
    return apiV2.post('/auth/check_login_code_and_authenticate', params)
  }

  static checkSignUpCode(params: CheckPhoneCodeParams) {
    return apiV2.post('/auth/check_signup_code_and_redirect', params)
  }

  static verifyEmail(accountId: string, token: string) {
    return apiV2.post(`/auth/${accountId}/verify-email`, {
      token,
    })
  }

  static confirmAndAuthenticateAccount(params: ConfirmAndAuthenticateAccountParams) {
    return apiV2.post('/auth/confirm_and_authenticate_account', params)
  }

  static invalidateAccount(accountId: string) {
    return apiV2.post(`/auth/${accountId}/invalidate`)
  }

  static sendUnconfirmedAccountSignupCode(params: SendUnconfirmedAccountSignupCodeParams) {
    return apiV2.post('/auth/send_unconfirmed_account_signup_code', params)
  }
  // Accounts
  static fetchUnconfirmedAccount(accountId: string) {
    return apiV2.get(`/accounts/${accountId}/unconfirmed`)
  }

  static createAccountWithTempPassword(params: CreateAccountWithTempPasswordParams) {
    return apiV2.post('/accounts/create-with-temp-password', params)
  }

  static searchAccountsByQuery(query: string) {
    return apiV2.get(`/accounts/by-query/${query}`)
  }

  static fetchAccountData(accountId: string) {
    return apiV2.get(`/accounts/${accountId}`)
  }

  static accountExistsByEmail(email: string) {
    return apiV2.get(`/accounts/check_for_account/${email}`)
  }

  static fetchAccountByUsername(username: string) {
    return apiV2.get(`/accounts/by-username/${username}`)
  }

  static fetchAttendedGroups(accountId: string) {
    return apiV2.get(`/accounts/${accountId}/attendedGroups`)
  }

  static fetchAccountPaymentLink(accountId: string) {
    return apiV2.get(`/accounts/${accountId}/paymentLink`)
  }

  static fetchAccountGroups(accountId: string) {
    return apiV2.get(`/accounts/${accountId}/groups`)
  }

  static fetchAccountStripes(accountId: string) {
    return apiV2.get(`/accounts/${accountId}/stripes`)
  }

  static fetchAccountOrdersAndEvents(accountId: string, groupId?: string) {
    return apiV2.get(`/accounts/${accountId}/${groupId}/orders`)
  }
  static fetchUpcomingAccountOrdersAndEvents(accountId: string, groupId?: string) {
    return apiV2.get(`/accounts/${accountId}/${groupId}/upcomingorders`)
  }
  // Groups
  static fetchGroupAnalytics(groupId: string, role: RoleKey, hostId: string, trackingLink?: string) {
    return apiV2.get(`/groups/${groupId}/analytics`, {
      params: {
        role,
        userId: hostId,
        trackingLink,
      },
    })
  }

  static fetchGroupData(groupId: string) {
    return apiV2.get(`/groups/${groupId}/group_data`)
  }

  static fetchGroupGraphData(props: GroupAnalyticsProps) {
    return apiV2.get(`/groups/${props.groupId}/group_graph`, {
      params: props,
    })
  }

  static fetchGroupEvents(groupId: string) {
    return apiV2.get(`/groups/${groupId}/events`)
  }

  static fetchGroupOrders(groupId: string) {
    return apiV2.get(`/groups/${groupId}/orders`)
  }

  static fetchGroupTextBlasts(groupId: string, limit: number) {
    return apiV2.get(`/groups/${groupId}/textblasts/${limit}`)
  }

  static fetchTextBlastStatus(groupId: string, blastId: string) {
    return apiV2.get(`/groups/${groupId}/textblast/${blastId}`)
  }

  static fetchGroupHosts(groupId: string) {
    return apiV2.get(`/groups/${groupId}/hosts`)
  }

  static createGroupCustomFee(props: CreateGroupCustomFeeProps) {
    return apiV2.post(`/groups/${props.groupId}/create-custom-fee`, {
      ...props,
    })
  }
  static updateGroupCustomFee(props: UpdateGroupCustomFeeProps) {
    return apiV2.post(`/groups/${props.groupId}/update-custom-fee`, {
      ...props,
    })
  }
  static deleteGroupCustomFee(props: DeleteGroupCustomFeeProps) {
    return apiV2.post(`/groups/${props.groupId}/delete-custom-fee`, {
      ...props,
    })
  }

  static createCustomNumberCheckoutSession(params: CreateCustomNumberCheckoutSessionParams) {
    return apiV2.post('/groups/create-custom-number-checkout-session', {
      ...params,
    })
  }

  static getAvailableNumbers(areaCode: string) {
    return apiV2.get(`/groups/get-available-numbers/${areaCode}`)
  }

  static fetchGroupWithCustomNumber(params: FetchGroupWithCustomNumberParams) {
    return apiV2.get(`/groups/${params.groupId}/group-custom-number/${params.phone}`)
  }

  static fetchHostEvents(groupId: string, trackingLink: string) {
    return apiV2.get(`/groups/${groupId}/host_events`, {
      params: {trackingLink},
    })
  }

  static fetchGroup(groupId: string) {
    return apiV2.get(`/groups/${groupId}`)
  }

  static updateGroup(groupId: string, updates: UpdateGroupAttributes) {
    return apiV2.patch(`/groups/${groupId}`, updates)
  }

  static testWebhook(params: TestWebhookParams) {
    const {groupId, webhook} = params
    return apiV2.post(`/groups/${groupId}/test-webhook/${webhook}`)
  }

  static deleteGroup(groupId: string) {
    return apiV2.patch(`/groups/delete/${groupId}`)
  }

  static fetchGroupAttendees(params: FetchGroupAttendeesParams) {
    return apiV2.get(`/groups/${params.groupId}/attendees`, {
      params: params,
    })
  }

  static fetchAllGroupAttendees(params: FetchAllAttendeesParams) {
    return apiV2.get(`/groups/${params.groupId}/fetch-all-attendees`, {
      params: params,
    })
  }

  static fetchGroupAttendee(groupId: string, attendee: string) {
    return apiV2.get(`/groups/${groupId}/attendees/${attendee}`)
  }

  static fetchGroupEventsAndTags(groupId: string) {
    return apiV2.get(`/groups/${groupId}/events_and_tags`)
  }

  static fetchGroupDisputes(groupId: string) {
    return apiV2.get(`/groups/${groupId}/disputes`)
  }
  static removeHost(groupId: string, hostId: string) {
    return apiV2.get(`/groups/${groupId}/remove_host/${hostId}`)
  }

  /**
   * @deprecated as of PENG-7568 - deprecating manualPayouts
   *   should also deprecate associated hooks
   *   {@link apps/client/src/apis/Groups/useChangePayoutInterval.ts}
   */
  static changePayoutInterval(params: ChangePayoutIntervalParams) {
    return apiV2.post(`/groups/${params.groupId}/change_payout_interval`, {
      manualPayouts: params.manualPayouts,
    })
  }

  static addDebitCard(params: AddDebitCardParams) {
    return apiV2.post(`/groups/${params.groupId}/add_debit_card`, {token: params.token})
  }

  static fetchGroupActiveSubscriptions(groupId: string) {
    return apiV2.get(`/groups/${groupId}/subscriptions/get-active`)
  }

  static cancelGroupSubscription(params: CancelSubscriptionParams) {
    return apiV2.post(`/groups/${params.groupId}/subscriptions/cancel-subscription/${params.subscriptionId}`)
  }

  // Events
  static fetchShouldDisplayProfilePictureModal(params: ShouldDisplayProfilePicModal) {
    return apiV2.get(`/events/${params.eventId}/should-display-profile-picture-modal`)
  }

  static createEventTrackingLink(value: string, eventId: string, groupId: string) {
    return apiV2.post(`events/${eventId}/tracking_links/create`, {
      value,
      groupId,
    })
  }

  static getEventParentComments(params: EventCommentParams) {
    return apiV2.get(`events/${params.eventId}/parent-comments`, {
      params,
    })
  }

  static fetchCommentReplies(params: FetchCommentReplyParams) {
    return apiV2.get(`events/${params.eventId}/comment-replies`, {
      params,
    })
  }

  static addCommentReaction(params: AddCommentReactionParams) {
    return apiV2.post(`events/${params.eventId}/comments/add-comment-reaction`, params)
  }

  static deleteEventComment(props: DeleteEventCommentProps) {
    return apiV2.post(`events/${props.eventId}/comments/delete/${props.commentId}`)
  }

  static addTicketTypeToEvent(params: CreateTicketTypeParams) {
    const {eventId, ticket} = params
    return apiV2.post(`/events/${eventId}/create_ticket_type`, {
      ...ticket,
    })
  }

  static updateTicket(params: UpdateTicketParams) {
    const {eventId, ticket} = params
    return apiV2.post(`/events/${eventId}/update-ticket`, ticket)
  }

  static fetchEventAnalytics(eventId: string, role: string, hostId?: string) {
    return apiV2.get(`/events/${eventId}/web-analytics`, {
      params: {
        role,
        hostId,
      },
    })
  }

  static fetchEventPurchaseLocations(eventId: string) {
    return apiV2.get(`/events/${eventId}/analytics/purchase-locations`)
  }

  static fetchEventAttendeeLocations(eventId: string) {
    return apiV2.get(`/events/${eventId}/analytics/attendee-locations`)
  }

  static fetchEventAttendees(params: FetchEventAttendeesParams) {
    return apiV2.get(`/events/${params.eventId}/attendees`, {
      params: params,
    })
  }

  static fetchAllEventAttendees(params: FetchAllEventAttendeesParams) {
    return apiV2.get(`/events/${params.eventId}/all-attendees`, {
      params: params,
    })
  }

  static fetchEventTickets(eventId: string) {
    return apiV2.get(`/events/${eventId}/tickets`)
  }
  static fetchEventTicketsUrl(eventId: string, password?: string) {
    return apiV2.get(`/events/${eventId}/ticketsUrl`, {params: {password}})
  }

  static fetchEventTrackingLinks(eventId: string, groupId: string) {
    return apiV2.get(`/events/${eventId}/tracking_links`, {
      params: {groupId},
    })
  }

  static fetchEventPromoCodesAndUses(eventId: string) {
    return apiV2.get(`/events/${eventId}/promo_codes_and_uses`)
  }

  static fetchAllEventTrackingLinks(eventId: string, groupId: string) {
    return apiV2.get(`/events/${eventId}/all_tracking_links`, {
      params: {groupId},
    })
  }

  static fetchEventTrackingLinksHost(eventId: string) {
    return apiV2.get(`/events/${eventId}/host_tracking_links`)
  }

  static updateEvent(eventId: string, groupId: string, eventData: UpdateEventAttributes_DEPRECATED) {
    return apiV2.patch(
      `/events/${eventId}`,
      {
        groupId,
        eventData,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  }

  static updateTicketGroups(params: UpdateTicketGroupsParams) {
    const {eventId, ticketGroups} = params
    return apiV2.patch(`/events/${eventId}/ticket-groups`, {
      ticketGroups,
    })
  }

  static fetchEvent(eventId: string) {
    return apiV2.get(`/events/${eventId}`)
  }

  static fetchEventByUrl(eventUrl: string) {
    return apiV2.get(`/events/by-url/${eventUrl}`)
  }

  static fetchEventByHexUrl(hexUrl: string) {
    return apiV2.get(`/events/by-hex-url/${hexUrl}`)
  }

  // Promo Codes

  static createPromoCode(params: CreatePromoCodeParams) {
    const {eventId, ...rest} = params
    return apiV2.post(`/events/${eventId}/create-promo-code`, {...rest})
  }

  static updatePromoCode(params: UpdatePromoCodeParams) {
    const {eventId, promoCodeId, updates} = params
    return apiV2.patch(`/events/${eventId}/update-promo-code`, {promoCodeId, updates})
  }

  static removePromoCode(params: RemovePromoCodeParams) {
    return apiV2.post(`/events/${params.eventId}/remove_promocode`, {promoCodeId: params.promoCodeId})
  }

  static enableKickbacks(params: EnableKickbacksParams) {
    return apiV2.post(`/events/${params.eventId}/enable-kickbacks`)
  }

  static addHostsToEvents(eventIds: string[], hostTrackingLinks: string[]) {
    return apiV2.post('/events/add_hosts_to_events', {
      eventIds,
      hostTrackingLinks,
    })
  }

  static aggregateEvents(pipeline: any[]) {
    return apiV2.post('/events/aggregateEvents', {
      pipeline,
    })
  }
  static checkEventPassword(props: CheckEventPasswordProps) {
    return apiV2.post(`/events/${props.eventId}/check-event-password`, {
      password: props.password,
    })
  }

  static checkTicketPassword(props: CheckTicketPasswordProps) {
    return apiV2.post(`/events/${props.eventId}/check-ticket-password`, {
      password: props.password,
      usedPasswords: props.usedPasswords,
    })
  }
  static createEventCustomFee(props: CreateEventCustomFeeProps) {
    return apiV2.post(`/events/${props.eventId}/create-custom-fee`, {
      ...props,
    })
  }
  static updateEventCustomFee(props: UpdateEventCustomFeeProps) {
    return apiV2.post(`/events/${props.eventId}/update-custom-fee`, {
      ...props,
    })
  }
  static deleteEventCustomFee(props: DeleteEventCustomFeeProps) {
    return apiV2.post(`/events/${props.eventId}/delete-custom-fee`, {
      ...props,
    })
  }

  static createTicketLink(params: CreateTicketLinkParams) {
    const {eventId, ...rest} = params
    return apiV2.post(`/events/${eventId}/create-ticket-link`, {
      ...rest,
    })
  }

  static updateTicketLink(params: UpdateTicketLinkParams) {
    const {eventId, parentTicketId, ...updates} = params
    return apiV2.patch(`/events/${eventId}/update-ticket-link`, {
      parentTicketId,
      ...updates,
    })
  }

  static removeTicketLink(params: RemoveTicketLinkParams) {
    const {eventId, parentTicketId} = params
    return apiV2.post(`/events/${eventId}/remove-ticket-link`, {
      parentTicketId,
    })
  }

  // Kickback
  static fetchAffiliationData(affiliationId: string) {
    return apiV2.get(`/kickback/get-affiliation-data/${affiliationId}`)
  }

  static updateKickBackActivation(params: UpdateKickBackActivationProps) {
    return apiV2.post(`/kickback/updateKickBackActivation/${params.id}`, {enabled: params.enabled})
  }

  static updateKickBackValue(params: UpdateKickBackValueProps) {
    return apiV2.post(`/kickback/updateKickBackValue/${params.id}`, {value: params.value})
  }

  static updateKickBackOfferLimit(params: UpdateKickBackOfferLimitProps) {
    return apiV2.post(`/kickback/updateKickBackOfferLimit/${params.id}`, {limitedTo: params.limitedTo})
  }

  static createKickBackOffer(params: UpdateKickbackOfferProps) {
    return apiV2.post(`/kickback/createKickBackOffer`, {kickback: params})
  }

  static generateAffiliation(params: GenerateAffiliationProps) {
    return apiV2.post(`/kickback/generate-affiliation/${params.eventId}`, {userId: params.userId})
  }

  static checkIsAffiliate(userId: string) {
    return apiV2.get(`/kickback/${userId}/check-is-affiliate`)
  }

  static fetchAllKickbackUsage(eventId: string) {
    return apiV2.get(`/kickback/all-kickback-uses/${eventId}`)
  }

  static fetchKickbackPayoutLink(params: FetchKickBackPayoutLinkProps) {
    return apiV2.get(`/kickback/${params.userId}/setup_link`, {
      params: {
        returnUrl: params.pathname,
        refreshUrl: params.pathname,
      },
    })
  }

  // Payments
  static createFreeOrder(cartId: string) {
    return apiV2.post(`/checkout/create-free-order`, {
      cartId,
    })
  }

  /**
   * @deprecated as of PENG-7540 to move away from paymentsEnabled flag
   */
  static fetchPayoutLink(groupId: string, returnUrl: string, refreshUrl: string) {
    return apiV2.get(`/groups/${groupId}/setup_link`, {
      params: {
        returnUrl,
        refreshUrl,
      },
    })
  }

  /**
   *  @deprecated as of PENG-7540 to move away from paymentsEnabled flag
   */
  static checkPayoutStatus(groupId: string, connectId: string) {
    return apiV2.get(`/groups/${groupId}/check_payout_status`, {
      params: {
        connectId,
      },
    })
  }

  static handleCheckoutRedirect(params: CheckoutRedirectParams) {
    return apiV2.post(`/checkout/handle-stripe-checkout-redirect`, {
      ...params,
    })
  }

  // Tags
  static addAccountsToTag(tagId: string, accountIds: string[], groupId: string) {
    return apiV2.post(`/tags/${tagId}/add_to_accounts `, {
      accountIds,
      groupId,
    })
  }

  static removeTag(tagId: string, accountId: string, groupId: string) {
    return apiV2.post(`/tags/${tagId}/remove `, {
      accountId,
      groupId,
    })
  }

  static deleteTag(tagId: string, groupId: string) {
    return apiV2.post(`/tags/${tagId}/delete_tag`, {
      groupId,
    })
  }

  static createTag(tagData: CreateTagParams) {
    return apiV2.post(`/tags/create`, {
      ...tagData,
    })
  }

  // Order
  static fetchOrder(orderId: string) {
    return apiV2.get(`orders/${orderId}`)
  }
  static fetchOrderPageData(orderId: string) {
    return apiV2.get(`orders/${orderId}/orderPage`)
  }
  static fetchOrderAndName(orderId: string) {
    return apiV2.get(`orders/with_name/${orderId}`)
  }

  static fetchOrders(promoCode: string, eventId?: string) {
    return apiV2.get(`orders/buypromocode/${promoCode}`, {
      params: {
        eventId,
      },
    })
  }

  static fetchOrderReceiptByCartId(cartId: string) {
    return apiV2.get(`orders/by_cart_id/${cartId}/receipt`)
  }

  static fetchOrderReceipt(orderId: string) {
    return apiV2.get(`orders/${orderId}/receipt`)
  }

  static scanOrder(orderId: string) {
    return mobileApiV2.post(`/scan_order/${orderId}`)
  }

  static fetchHostByTrackingLink(trackingLink: string) {
    return apiV2.get(`/orders/${trackingLink}/host`)
  }

  static uploadImage(file: File) {
    const formData = new FormData()
    formData.append('file', file, file.name)
    return apiV2.post('util/upload_img', formData)
  }

  static uploadWalletPass(file: File) {
    const formData = new FormData()
    formData.append('file', file, file.name)
    return apiV2.post('util/upload_wallet_pass', formData)
  }

  static logError(message: string) {
    return apiV2.post(`util/error`, {
      message,
    })
  }

  static imageSearch(params: ImageSearchParams) {
    return apiV2.get('util/search-images', {params})
  }

  static downloadUnsplashImage(downloadUrl: string) {
    return apiV2.get(`util/download-unsplash-image`, {
      params: {
        downloadUrl,
      },
    })
  }

  static csvUpload(
    groupId: string,
    eventId: string,
    type: string,
    data: Record<string, string | number>[],
    clarifier?: string,
  ) {
    return apiV2.post(`groups/${groupId}/csv_upload`, {
      eventId,
      type,
      clarifier,
      data,
    })
  }

  static exportReport(params: ExportReportParams) {
    const {eventId, exportKeys, options} = params
    return apiV2.post(
      `events/${eventId}/export_report`,
      {
        exportKeys,
        options,
      },
      {
        responseType: 'text',
        headers: {
          Accept: 'text/event-stream',
        },
      },
    )
  }

  static fetchEventExportKeys(eventId: string) {
    return apiV2.get(`events/${eventId}/fetch-export-keys`)
  }

  static trackingLinkClick(eventId: string, trackingLink: string) {
    return apiV2.post(`events/${eventId}/link/${trackingLink}`)
  }

  static affiliateTrackingLinkClick(eventId: string, affiliateTrackingLink: string) {
    return apiV2.post(`events/${eventId}/affiliate-link/${affiliateTrackingLink}`)
  }

  static sendOrderReceipt(orderId: string, currency: string) {
    return apiV2.post(`orders/${orderId}/receipts/send`, {
      currency,
    })
  }

  static checkGroupSubdomain(subdomain: string) {
    return apiV2.get(`util/group_subdomain/${subdomain}`)
  }

  static checkGroupUrl(url: string) {
    return apiV2.get(`util/group_url/${url}`)
  }

  static subscribeToHosted(email: string) {
    return apiV2.post(`util/hosted-subscribe/`, {
      email,
    })
  }

  static getHostedByPoshContent() {
    return apiV2.get(`util/hosted-by-posh-content`)
  }

  static refundOrder(params: RefundOrderParams) {
    return apiV2.post(`/orders/${params.orderId}/refund`, params)
  }

  static cancelFreeOrder(params: CancelOrderParams) {
    const {orderId, isRelisting} = params
    return apiV2.post(`/orders/${orderId}/cancel`, {
      isRelisting,
    })
  }

  static async initializeIgScraper(accountId: string, instagramUserName: string) {
    return axios({
      method: 'POST',
      url: 'http://ec2-18-221-164-102.us-east-2.compute.amazonaws.com/request-IG-AVI',
      params: {
        aID: accountId,
        IGUsername: instagramUserName.toLowerCase(),
      },
      headers: {},
    })
  }

  static fetchOrdersForTicket(params: FetchOrdersForTicketParams) {
    const {eventId, ticketId} = params
    return apiV2.get(`/orders/getTicketOrders/${eventId}/${ticketId}`)
  }

  static retrieveCustomerPaymentMethods(accountId: string) {
    return apiV2.get(`/accounts/${accountId}/paymentMethods`)
  }

  static addPaymentMethod(accountId: string, paymentMethodId: string) {
    return apiV2.post(`accounts/${accountId}/add_payment_method`, {
      paymentMethodId,
    })
  }

  static aggregateOrders(params: AggregateOrdersParams) {
    return apiV2.post(`/orders/aggregateOrders`, params)
  }

  static sendFreeTickets(params: FreeOrderParams) {
    return apiV2.post('/orders/createFreeOrder', params)
  }

  static fetchComplimentaryOrders(eventId: string) {
    return apiV2.get(`/orders/complimentary/${eventId}`)
  }
  // Venues
  static fetchVenue(venueId: string) {
    return apiV2.get(`/venues/${venueId}`)
  }
  static fetchVenueByUrl(venueUrl: string) {
    return apiV2.get(`/venues/venue_url/${venueUrl}`)
  }

  // Tables
  static updateEventTable(eventId: string, tableId: string, tableParams: EditEventTableParams) {
    return apiV2.post(`/events/${eventId}/tables/${tableId}/edit`, {
      ...tableParams,
    })
  }

  static createEventTable(eventId: string, tableParamsCollection: CreateEventTableParams[]) {
    return apiV2.post(`events/${eventId}/tables/create`, {
      tables: tableParamsCollection,
    })
  }

  static removeEventTable(eventId: string, tableId: string) {
    return apiV2.post(`events/${eventId}/tables/${tableId}/delete`)
  }

  // Gallery
  static createGallery(eventId: string, gallery: GalleryModel) {
    return apiV2.post(`events/${eventId}/gallery/create`, gallery)
  }

  static updateGallery(eventId: string, updates: UpdateGalleryParams) {
    return apiV2.post(`events/${eventId}/gallery/update`, updates)
  }

  static getGallery(eventUrl: string) {
    return apiV2.get(`events/${eventUrl}/gallery`)
  }
  static getGalleryById(eventId: string) {
    return apiV2.get(`events/${eventId}/galleryById`)
  }

  static getRsvpSettings(eventId: string) {
    return apiV2.get(`events/${eventId}/rsvp-settings`)
  }

  static setRsvpSettings(eventId: string, body: UpdateFormChanges) {
    return apiV2.post(`events/${eventId}/rsvp-settings`, body)
  }

  static fetchAnnouncements() {
    return apiV2.get('announcements/fetchAnnouncements')
  }

  // Roles / Teams
  static addTeamMember(role: AddTeamMemberBody) {
    return apiV2.post(`roles/add_new_team_member`, {
      ...role,
    })
  }

  static fetchApplicablePermissionsForRoleAndScope(roleKey: RoleKey, scopeKey: ScopeKey) {
    return apiV2.get(`roles/default_permissions/${roleKey}/${scopeKey}`)
  }

  static fetchTeam(entityId: string, scopeKey: string, accountRoleId: string) {
    return apiV2.get(`roles/team/${entityId}/${scopeKey}?accountRoleId=${accountRoleId}`)
  }

  static fetchPreviousTeamForEvent(eventId: string, accountRoleId: string) {
    return apiV2.get(`roles/previous_team/${eventId}?accountRoleId=${accountRoleId}`)
  }

  static fetchAccountRolePermissions(accountRoleId: string) {
    return apiV2.get(`roles/permissions/${accountRoleId}`)
  }

  static editAccountPermissions(
    roleKey: RoleKey,
    roleId: string,
    assignedBy: string,
    permissionConstraints: IPermissionConstraints,
  ) {
    return apiV2.post(`roles/edit_account_role/`, {
      roleKey,
      roleId,
      assignedBy,
      permissionConstraints,
    })
  }

  static deleteAccountRole(roleId: string, assignedBy: string) {
    return apiV2.post(`roles/delete_account_role/`, {
      accountRoleId: roleId,
      assignedBy,
    })
  }

  // Text Blasts

  static fetchTextBlast(textBlastId: string) {
    return apiV2.get(`blasts/fetch_text_blast/${textBlastId}`)
  }

  // News Letter
  static poshSmsSubscribe(phoneNumber: string) {
    return apiV2.post('newsletter/sms_subscribe', {
      phoneNumber,
    })
  }

  // Cart
  static hostsBreakdown(eventId: string, hostId: string) {
    return apiV2.get(`events/${eventId}/host_breakdown/${hostId}`)
  }

  static trackingLinkBreakdown(eventId: string, trackingLink: string) {
    return apiV2.get(`events/${eventId}/tracking_link_breakdown/${trackingLink}`)
  }
  static affiliateTrackingLinkBreakdown(eventId: string, affiliateLink: string) {
    return apiV2.get(`events/${eventId}/affiliate_tracking_link_breakdown/${affiliateLink}`)
  }

  static scanTable(tableId: string, eventId: string, tableSeatMap: TableSeatMap) {
    return apiV2.post(`admissions/event/${eventId}/scan-table/${tableId}`, {
      tableSeatMap,
    })
  }

  //Community

  static fetchPendingCommunity = () => {
    return apiV2.get('community/pending')
  }

  static updatePendingCommunity = (params: UpdatePendingCommunity) => {
    return apiV2.post('community/update-pending-member', params)
  }

  static fetchCommunityFilters(selectedCategory: CommunityCategory) {
    return apiV2.get(`community/filters/${selectedCategory}`)
  }

  static updateCommunityClicks(params: UpdateCommunityClicksParams) {
    return apiV2.post(`community/update-clicks`, params)
  }

  static fetchCommunityByCategory(params: CommunityByCategoryParams) {
    return apiV2.get(`community/by-category/${params.selectedCategory}`, {params})
  }

  static fetchCommunityVenue(venueId: string) {
    return apiV2.get(`/community/${venueId}/venue`)
  }

  static fetchAllCommunityVenues(params: FetchAllParams) {
    return apiV2.get('/community/all/venues', {params: params})
  }

  static createVenue(venueData: CreateVenue) {
    return apiV2.post('/community/create-venue', venueData)
  }

  static fetchCommunityArtist(artistId: string) {
    return apiV2.get(`/community/${artistId}/artist`)
  }

  static fetchAllCommunityArtists(params: FetchAllParams) {
    return apiV2.get('/community/all/artists', {params: params})
  }

  static fetchCommunityArtistsByTag(tags: Tag[]) {
    return apiV2.get(`/community/artists-by-tags`, {params: tags})
  }

  static createArtist(artistData: CreateArtist) {
    return apiV2.post('/community/create-artist', artistData)
  }

  static fetchCommunityStaffMember(staffMemberId: string) {
    return apiV2.get(`/community/${staffMemberId}/staff-member`)
  }

  static fetchAllCommunityStaffMembers() {
    return apiV2.get('/community/all/staff-members')
  }

  static fetchCommunityStaffMembersByStaffType(params: FetchStaffGroupParams) {
    const {staffType} = params
    return apiV2.get(`/community/${staffType}/staff-members`, {params: params})
  }

  static createStaffMember(memberData: CreateStaffMember) {
    return apiV2.post('/community/create-staff-member', memberData)
  }

  static updateStaffMember(memberData: UpdateStaffMember) {
    return apiV2.post('/community/update-staff-member', {
      ...memberData,
    })
  }

  static updateVenue(venueData: UpdateVenue) {
    return apiV2.post('/community/update-venue', {
      ...venueData,
    })
  }

  static updateArtist(artistData: UpdateArtist) {
    return apiV2.post('/community/update-artist', {
      ...artistData,
    })
  }

  static contactEntity(messageData: ContactEntityMessage) {
    return apiV2.post(`/community/contact-entity`, messageData)
  }

  static createCommunityCheckoutSession(params: CreateCommunityCheckoutSessionParams) {
    return apiV2.post('/community/create-checkout-session', {
      params,
    })
  }

  static createCustomerPortalSession() {
    return apiV2.post('/community/create-customer-portal-session')
  }

  static createBookingRequest(bookingRequestData: CreateBookingRequestData) {
    return apiV2.post('/community/create-booking-request', bookingRequestData)
  }

  static fetchBookingRequests(params: FetchBookingRequestsParams) {
    return apiV2.get('/community/fetch-booking-requests', {params: params})
  }

  static fetchBookingRequestById(bookingRequestId: string) {
    return apiV2.get(`/community/fetch-booking-request/${bookingRequestId}`)
  }

  static fetchCommunityEntity() {
    return apiV2.get('/community/entity')
  }

  static fetchCommunityEntityByUrl(communityCategory: CommunityCategory, communityUrl: string) {
    return apiV2.get(`/community/entity-by-url/${communityCategory}/${communityUrl}`)
  }

  static updateBookingRequest(bookingRequestData: UpdateBookingRequestData) {
    return apiV2.post('/community/update-booking-request', bookingRequestData)
  }

  //Checkout Fields

  static fetchCustomCheckoutFields(eventId: string) {
    return apiV2.get(`checkout-field/${eventId}/get-custom-fields`)
  }

  static addCustomField(params: UpdateCustomFieldsParams) {
    return apiV2.post(`checkout-field/${params.eventId}/add-custom-checkout-field`, {
      customField: params.customField,
    })
  }

  static updateCustomField(params: UpdateCustomFieldParams) {
    return apiV2.post(`checkout-field/${params.eventId}/update-custom-checkout-field`, {
      customField: params.customField,
    })
  }

  static deleteCustomCheckoutField(params: DeleteCustomFieldParams) {
    return apiV2.post(`checkout-field/${params.eventId}/delete-custom-checkout-field`, {
      customFieldId: params.customFieldId,
    })
  }

  // Pending Orders
  static updatePendingOrder(params: UpdatePendingOrderParams) {
    return apiV2.post(`pending-orders/${params.eventId}/update-status`, {ids: params.ids, status: params.status})
  }
  static fetchNewApprovals(eventId: string, ticketId: string) {
    return apiV2.get(`pending-orders/${eventId}/fetch-new-approvals`, {params: {ticketId: ticketId}})
  }

  //Disputes
  static fetchDispute(disputeId: string) {
    return apiV2.get(`/disputes/${disputeId}`)
  }

  // Admin Dashboard
  static fetchAdminDashboardData() {
    return apiV2.get('/admin/dashboard')
  }
  static fetchAdminGroupDashboardData() {
    return apiV2.get('/admin/group-dashboard')
  }
  static fetchAdminTextBlasts() {
    return apiV2.get('/admin/text-blasts')
  }
  static fetchAdminTopFree() {
    return apiV2.get('/admin/top-free')
  }
  static fetchKPIs() {
    return apiV2.get('/admin/kpis')
  }
  static fetchAdminCampaignLinks() {
    return apiV2.get('/admin/campaign-links')
  }
  static updateCampaignLink(params: UpdateCampaignLinkParams) {
    const {campaignLinkId, updates} = params
    return apiV2.patch(`/admin/update-campaign-link`, {campaignLinkId, updates})
  }
  static createCampaignLink(params: CreateCampaignLinkParams) {
    return apiV2.post(`/admin/create-campaign-link`, {campaignLink: params})
  }
  static deleteCampaignLink(params: DeleteCampaignLinkParams) {
    return apiV2.post(`/admin/delete-campaign-link`, {campaignLinkId: params.campaignLinkId})
  }
  static fetchFlaggedOrders() {
    return apiV2.get('/admin/flagged-orders')
  }
  static fetchRefundRequests() {
    return apiV2.get('admin/refund-requests')
  }

  static checkPoshAdmin() {
    return apiV2.get('/admin/legacyOnboarding')
  }

  static updateRefundRequest(params: UpdateRefundRequestParams) {
    const {eventId, status, includesFees} = params
    return apiV2.patch(`admin/refund-requests/${eventId}`, {status, includesFees})
  }

  //POSH Profile
  static fetchUpcomingEvent(username: string) {
    return apiV2.get('/posh-profile/upcoming-event', {params: {username}})
  }
  static fetchPastEvents(username: string) {
    return apiV2.get('/posh-profile/past-events', {params: {username}})
  }

  static fetchAccountProfileByUsername(username: string) {
    return apiV2.get(`/posh-profile/profile-by-username/${username}`)
  }

  static updatePoshProfile(update: AccountProfile) {
    return apiV2.post(`posh-profile/update-posh-profile`, update)
  }

  // Pending Events
  static createInitialPendingEvent(params: UseCreateInitialPendingEventParams) {
    return apiV2.post(`pending-events/create`, params)
  }
  static fetchPendingEvent(params: UseFetchPendingEventParams) {
    return apiV2.get(`pending-events/${params.pendingEventId}`, {params: {groupId: params.groupId}})
  }
  static updatePendingEvent(params: UpdatePendingEventParams) {
    return apiV2.post(`pending-events/${params.id}/update`, {
      groupId: params.groupId,
      pendingEvent: params.pendingEvent,
    })
  }
  static confirmPendingEvent(params: ConfirmPendingEventParams) {
    return apiV2.post(`pending-events/${params.pendingEventId}/confirm`, {groupId: params.groupId})
  }

  // MailChimp
  static fetchMailChimpAuthLink(params: FetchMailChimpAuthLinkParams) {
    return apiV2.get(`mailchimp/${params.groupId}/fetch-auth-link/${params.scope}`)
  }
  static authenticateMailChimpUser(params: AuthenticateMailChimpUserParams) {
    return apiV2.post(`mailchimp/authenticate-user`, params)
  }
  static isMailChimpUserAuthenticated(groupId: string) {
    return apiV2.get(`mailchimp/${groupId}/is-user-authenticated`)
  }
  static createMailChimpList(params: CreateMailChimpListParams) {
    return apiV2.post(`mailchimp/create-list`, params)
  }
  static addToMailChimpList(params: AddToMailChimpListParams) {
    return apiV2.post(`mailchimp/add-to-list`, params)
  }
  static fetchMailChimpLists(groupId: string) {
    return apiV2.get(`mailchimp/${groupId}/lists`)
  }

  // Refund Requests
  static createRefundRequest(params: CreateRefundRequestParams) {
    const {eventId, reason, description, contact} = params
    return apiV2.post(`events/${eventId}/refund-request`, {
      reason,
      description,
      contact,
    })
  }

  static checkForRefundRequest(eventId: string) {
    return apiV2.get(`events/${eventId}/check-for-refund-request`)
  }

  // Notifications
  static setNotificationAsRead({notificationId}: SetNotificationAsReadParams) {
    return apiV2.post(`/notifications/${notificationId}/read`)
  }

  static setNotificationActionTaken({notificationId}: SetNotificationActionTakenParams) {
    return apiV2.post(`/notifications/${notificationId}/action`)
  }

  // Campaign Links
  static fetchCampaignLinkByShortUrl(campaignShortUrl: string, gclid?: string) {
    return apiV2.get(`/campaign-links/by-shortUrl/${campaignShortUrl}/${gclid}`)
  }
}
