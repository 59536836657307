import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {facebookPixelTriggerTrack, POSH_PIXEL_ID} from 'apis/FbPixelHelpers'
import Button from 'components/form/Button'
import {PoshRoutes} from 'models/PoshRoutes'
import CommunityPathModal from 'pages/GroupPage/Community/PathModal'

import {useNavigateToCreateEvent} from '../../../CreateEvent/useNavigateToCreateEvent'

const UnauthorizedPoshHeaderActionSection = ({community}: {community: boolean}) => {
  const navigate = useNavigate()
  const {navigateToCreateEvent} = useNavigateToCreateEvent()
  const handleOnClickSignup = () => {
    if (community) {
      setPathModalOpen(true)
    } else {
      facebookPixelTriggerTrack(
        'Lead',
        {
          content_category: 'create_event_flow',
          content_name: 'initiated_create_event_flow_from_landing_page',
        },
        POSH_PIXEL_ID,
      )
      navigateToCreateEvent({optimizelyEntryPoint: 'Navigation Bar Header'})
    }
  }
  const handleOnClickLogin = () => navigate(PoshRoutes.LOGIN)

  const [pathModalOpen, setPathModalOpen] = useState<boolean>(false)

  return (
    <>
      <div className='PoshHeader-right PoshHeader-right-container auth'>
        {community && (
          <a
            className='PoshHeader-loginButton'
            onClick={() => {
              navigate('/community/explore')
            }}>
            Explore
          </a>
        )}
        <a className='PoshHeader-loginButton' onClick={handleOnClickLogin}>
          Login
        </a>
        <Button className='gold round light' onClick={handleOnClickSignup} style={{margin: 0, padding: '12px 20px'}}>
          Create An Event
        </Button>
      </div>
      {pathModalOpen && <CommunityPathModal setPathModalOpen={setPathModalOpen} />}
    </>
  )
}

export default UnauthorizedPoshHeaderActionSection
