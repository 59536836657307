// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimePeriodOption__NcwaD{display:flex;align-items:center;justify-content:center;gap:8px;padding:7px 13px;border-radius:7px;border:1px solid #fff;background:rgba(0,0,0,0);opacity:.8;font-weight:400;cursor:pointer}.TimePeriodOption__NcwaD.highlighted__f8gXY{border:3px solid #fff;background:rgba(255,255,255,.25);opacity:1;font-weight:bold}", "",{"version":3,"sources":["webpack://./src/pages/EventManagementPages/OverviewUpdate/Analytics/Overview/TimePeriodOption.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CACA,gBAAA,CACA,iBAAA,CACA,qBAAA,CACA,wBAAA,CACA,UAAA,CACA,eAAA,CACA,cAAA,CAEA,4CACE,qBAAA,CACA,gCAAA,CACA,SAAA,CACA,gBAAA","sourcesContent":[".TimePeriodOption {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  padding: 7px 13px;\n  border-radius: 7px;\n  border: 1px solid white;\n  background: transparent;\n  opacity: 0.8;\n  font-weight: 400;\n  cursor: pointer;\n\n  &.highlighted {\n    border: 3px solid white;\n    background: rgba(255, 255, 255, 0.25);\n    opacity: 1;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TimePeriodOption": "TimePeriodOption__NcwaD",
	"highlighted": "highlighted__f8gXY"
};
export default ___CSS_LOADER_EXPORT___;
