import React from 'react'

import useSessionContext from 'domains/Auth/SessionContext'

import './styles.scss'

interface CommunityConfirmationPageProps {
  communityCategory?: string
}

const CommunityConfirmationPage = (props: CommunityConfirmationPageProps) => {
  const {communityCategory} = props
  const {currentUser} = useSessionContext()

  return (
    <div className='CommunityConfirmationContainer'>
      <img
        onClick={() => location.replace('https://www.posh.vip/dashboard/community')}
        className='CommunityConfirmation-logo'
        src='https://assets.posh.vip/b2/posh-community.png'
      />
      <div className='CommunityConfirmation'>
        <div className='CommunityConfirmation-container'>
          <h1 className='CommunityConfirmation-title'>{`You've Applied to join POSH Community!`}</h1>
          <div className='CommunityConfirmation-subtext'>
            <h3 className='CommunityConfirmation-subTitle'>{`If approved, we'll notify you at:`}</h3>
            <h3 className='CommunityConfirmation-email'>{currentUser?.email}</h3>
            <h3 className='CommunityConfirmation-phoneNumber'>{currentUser?.phone}</h3>
          </div>
        </div>
        <div className='CommunityConfirmation-container balloons'>
          <img src='https://assets.posh.vip/b2/community-anim.png' />
        </div>
      </div>
    </div>
  )
}

export default CommunityConfirmationPage
