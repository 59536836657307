import React from 'react'

export interface TOSCheck {
  tos: boolean
  smsOptIn: boolean
}

interface TOSCheckForm {
  setCheckForms: React.Dispatch<React.SetStateAction<TOSCheck>>
  checkForms: TOSCheck
  paragraphStyles?: React.CSSProperties
}

const TOSCheckForm = (props: TOSCheckForm) => {
  const {setCheckForms, checkForms} = props
  return (
    <table className='OnboardPage-privacyPolicy'>
      <tr>
        <td>
          <div className='OnboardPage-privacyPolicy-container'>
            <input
              className='Checkbox'
              type='checkbox'
              checked={checkForms.tos}
              onChange={e => {
                setCheckForms({...checkForms, tos: e.target.checked})
              }}
            />
            <span className='checkmark' />
          </div>
        </td>
        <td>
          <p className='OnboardPage-tos' style={props.paragraphStyles}>
            I have read and agree to the <a href='https://posh.vip/tos'>Terms of Service</a> and
            <a href='https://posh.vip/privacy'>&nbsp;Privacy Policy.</a>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <div className='OnboardPage-privacyPolicy-container'>
            <input
              className='Checkbox'
              type='checkbox'
              checked={checkForms.smsOptIn}
              onChange={e => {
                setCheckForms({...checkForms, smsOptIn: e.target.checked})
              }}
            />
            <span className='checkmark' />
          </div>
        </td>
        <td>
          <p className='OnboardPage-tos' style={props.paragraphStyles}>
            I would like to receive alert text notifications about receipts and other POSH important event-related
            messages at the phone number provided. Message and data rates may apply. Message frequency varies. Text HELP
            for help Text STOP to unsubscribe.
          </p>
        </td>
      </tr>
    </table>
  )
}

export default TOSCheckForm
