// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RowLabel__KMtqU{display:inline-flex;padding:2px 10px;align-items:flex-end;gap:10px;border-radius:61px;font-size:15px;font-style:normal;font-weight:300;line-height:normal}", "",{"version":3,"sources":["webpack://./src/components/TableUI/TableRowLabel/TableRowLabel.module.scss"],"names":[],"mappings":"AAEA,iBAEE,mBAAA,CACA,gBAAA,CACA,oBAAA,CACA,QAAA,CAEA,kBAAA,CAIA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":["@import '@global-styles/_color_pallete';\n\n.RowLabel {\n  /* Alignment */\n  display: inline-flex;\n  padding: 2px 10px;\n  align-items: flex-end;\n  gap: 10px;\n\n  border-radius: 61px;\n\n  /* Typography */\n  // color: #afefff;\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 300;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RowLabel": "RowLabel__KMtqU"
};
export default ___CSS_LOADER_EXPORT___;
