import React from 'react'

import './styles.scss'

const RateLimitExceeded = () => {
  return (
    <div className='RateLimitExceeded-Container'>
      <h1 className='RateLimitExceeded-Header'>RATE LIMIT EXCEEDED</h1>
      <div className='RateLimitExceeded-ImageContainer'>
        <img src='https://images.posh.vip/MutomboNotToday.gif' alt='Mutombo waving finger and mouthing not today.' />
        <p className='RateLimitExceeded-Text'>
          You have exceeded the allowed rate limit. Please try again later or if this is a mistake, contact POSH
          customer support at support@posh.vip.
        </p>
        <a href='https://posh.vip' style={{color: 'white', fontSize: '20px'}}>
          Go to POSH
        </a>
      </div>
    </div>
  )
}

export default RateLimitExceeded
