import React from 'react'

import classNames from 'classnames'

import {GlassView} from '../../GlassView'

import styles from './styles.module.scss'

interface MultiSwitchProps<T extends React.ReactNode> {
  options: T[]
  selected: T
  onSelect: (option: T) => void
  accentColor?: string
  selectedTextColor?: string
  style?: React.CSSProperties
}

/**
 * A multi-option switch component, primarily used for labels or categories
 * @param options - The list of options to choose from ie. ['Option 1', 'Option 2', 'Option 3']
 * @param selected - The currently selected option
 * @param onSelect - The function to call when an option is selected
 * @param accentColor - The color of the switch border and unselected options (default: 'white')
 * @param selectedTextColor - The color of the text of the selected option (default: 'black')
 */
export const MultiSwitch = <T extends React.ReactNode>(props: MultiSwitchProps<T>) => {
  const {options, selected, onSelect, accentColor = 'white', selectedTextColor = 'black', style} = props
  return (
    <div className={styles.MultiSwitch} style={{borderColor: accentColor, ...style}}>
      {options.map((option, index) => {
        const isSelected = option === selected
        const textColor = isSelected ? selectedTextColor : accentColor
        const backgroundColor = isSelected ? accentColor : 'transparent'
        return (
          <div
            key={index}
            style={{backgroundColor}}
            className={classNames(styles.option, {[styles.selected]: option === selected})}
            onClick={() => onSelect(option)}>
            <h4 className='noMargin' style={{color: textColor}}>
              {option}
            </h4>
          </div>
        )
      })}
    </div>
  )
}

const DEFAULT_ACCENT_COLOR = 'rgba(255, 255, 255, 0.2)'
const DEFAULT_SELECTED_TEXT_COLOR = 'white'
const DEFAULT_DESELECTED_TEXT_COLOR = 'rgba(255, 255, 255, 0.7)'
const PIXEL_OFFSET = 3

const GlassMultiSwitch = <T extends React.ReactNode>(
  props: MultiSwitchProps<T> & {
    deselectedTextColor?: string
  },
) => {
  const {
    options,
    selected,
    onSelect,
    accentColor = DEFAULT_ACCENT_COLOR,
    deselectedTextColor = DEFAULT_DESELECTED_TEXT_COLOR,
    selectedTextColor = DEFAULT_SELECTED_TEXT_COLOR,
    style,
  } = props

  const selectedTabStyles = (() => {
    const selectedTabIndex = options.indexOf(selected)
    const left = `calc(${(selectedTabIndex * 100) / options.length}% + ${PIXEL_OFFSET}px)`
    const width = `calc(${100 / options.length}% - ${PIXEL_OFFSET * 2}px)`
    const top = `${PIXEL_OFFSET}px`
    const height = `calc(100% - ${PIXEL_OFFSET * 2}px)`
    const backgroundColor = accentColor
    return {left, top, width, height, backgroundColor}
  })()

  return (
    <div className={styles.GlassMultiSwitch} style={style}>
      <GlassView className={styles.SelectedGlassOption} style={selectedTabStyles} />
      {options.map((option, index) => {
        const isSelected = option === selected
        const textColor = isSelected ? selectedTextColor : deselectedTextColor
        return (
          <div
            key={index}
            className={classNames(styles.option, {[styles.selected]: option === selected})}
            onClick={() => onSelect(option)}>
            <h4 className='noMargin' style={{color: textColor, transition: '0.3s'}}>
              {option}
            </h4>
          </div>
        )
      })}
    </div>
  )
}

/**
 * A glassmorphic multi-option switch component, primarily used for labels or categories
 */
MultiSwitch.Glass = GlassMultiSwitch
