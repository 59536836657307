import React from 'react'
import {Helmet} from 'react-helmet'
import {useParams} from 'react-router-dom'

import useGetVenueByUrl from 'apis/Util/useCheckVenueUrl'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import PoshMobileNav from 'pages/PoshMobileNav'
import Profile from 'pages/Profile'

const VenueProfile = () => {
  const {url} = useParams<{url: string}>()

  const {data, refetch, isFetched, isFetching} = useGetVenueByUrl(url ?? '')

  if (!isFetched) refetch()

  const venue = data?.venue
  const {pastEvents, upcomingEvents} = data ?? {pastEvents: [], upcomingEvents: []}

  return (
    <>
      <Helmet>
        <title>{`${venue?.name}`}</title>
        <meta property='og:title' content={venue?.name} />
        <meta property='og:description' content={venue?.name} />
        <meta property='og:image' content={venue?.coverImage} />
        <meta property='og:url' content={`https://posh.vip/e/${venue?.url}`} />
        <meta property='og:type' content='website' />
        <meta property='twitter:title' content={venue?.name} />
        <meta property='twitter:description' content={venue?.name} />
        <meta property='twitter:image' itemProp='image' content={venue?.coverImage} />
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:domain' content='posh-react.herokuapp.com' />
      </Helmet>

      <PoshHeader isTranslucent desktopOnly />

      <div className='PProfileWrapper'>
        <Profile
          isFetching={isFetching}
          type='venue'
          avi={venue?.coverImage}
          title={venue?.name}
          subtitle={venue?.address}
          statistics={[
            {label: 'events', value: pastEvents.length + upcomingEvents.length},
            {label: 'attendees', value: data?.numOfAttendees ?? 0},
          ]}
          instagram={venue?.instagram}
          upcomingEvent={upcomingEvents[0] ?? null}
          pastEvents={pastEvents}
          upcomingEvents={upcomingEvents}
          verified={venue?.verified}
          website={venue?.website}
          accentColor={'#ff0088'}
        />
      </div>

      <PoshMobileNav />
    </>
  )
}

export default VenueProfile
