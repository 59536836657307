import React, {useState} from 'react'

import {FetchEventResponsePublic} from 'apis/Events/useFetchEvent'
import Button from 'components/form/Button'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'

import {RSVPModal} from '.'

import './styles.scss'

interface RSVPModalWrapper extends PoshStyledModalProps {
  eventData: FetchEventResponsePublic
}

export const RSVPModalWrapper = (props: RSVPModalWrapper) => {
  const {eventData} = props
  const preCheckoutModal = eventData.event.preCheckoutModal
  const [displayInfoModal, setDisplayInfoModal] = useState(preCheckoutModal?.shouldDisplay ?? false)

  const {applyButtonText, isApprovalOnlyRSVPEvent} = eventData.event
  const rsvpButtonText = isApprovalOnlyRSVPEvent && applyButtonText ? applyButtonText : undefined
  return (
    <>
      {displayInfoModal ? (
        <PoshStyledModal
          contentClassName='EventPage-rsvpSaveModal'
          isOpen={true}
          onClose={() => setDisplayInfoModal(false)}
          noborder={true}>
          <div className='RSVPInfoModal-container'>
            <img src={preCheckoutModal?.image} />
            <div className='RSVPInfoModal-rightContainer'>
              <h2>{preCheckoutModal?.mainTitle}</h2>
              <div className='buttonWrapper fullWidth center'>
                <Button onClick={() => window.open(preCheckoutModal?.buttonLink)}>
                  {preCheckoutModal?.buttonText}
                </Button>
                <Button
                  className='outline'
                  onClick={() => {
                    setDisplayInfoModal(false)
                  }}>
                  Continue to RSVP
                </Button>
              </div>
            </div>
          </div>
        </PoshStyledModal>
      ) : (
        <RSVPModal noborder isOpen={props.isOpen} onClose={props.onClose} rsvpButtonText={rsvpButtonText} />
      )}
    </>
  )
}
