import {DEFAULT_ACCOUNT_AVI} from '@posh/model-types'
import {AccountModel} from 'apis/Accounts'
import getAvi from 'apis/Util/getAvi'

interface ShouldBlurActivityProps {
  isOwner: boolean
  isAdmin: boolean
  isAttendee: boolean
  currentUser?: AccountModel | undefined
}

const shouldBlurActivity = (props: ShouldBlurActivityProps) => {
  const {isOwner, isAdmin, currentUser, isAttendee} = props
  if (isOwner || isAdmin) return false
  else if (isAttendee) return getAvi(currentUser) === DEFAULT_ACCOUNT_AVI
  else return true
}

const shouldShowAddAvi = (props: ShouldBlurActivityProps) => {
  const {isOwner, isAdmin, currentUser, isAttendee} = props
  if (isOwner || isAdmin) return false
  else if (isAttendee) return getAvi(currentUser) === DEFAULT_ACCOUNT_AVI
  else return false
}

export {shouldBlurActivity, shouldShowAddAvi}
