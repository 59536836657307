import React from 'react'

import {TBooking, TBookingProvider, TBookingStatus, TBookingType} from 'apis/Services/useGetServiceBookings'
import Button from 'components/form/Button'

import './Booking.styles.scss'
interface BookingProps {
  booking: TBooking
  setSelectedBookingCancel: React.Dispatch<React.SetStateAction<TBooking | undefined>>
}

export const Booking = (props: BookingProps) => {
  const {booking, setSelectedBookingCancel} = props
  const {_id, type, status, provider} = booking

  const onClickCancel = () => {
    setSelectedBookingCancel(booking)
  }

  return (
    <div className='Booking'>
      <img
        src={provider ? provider.image : 'https://assets.posh.vip/b1/assets/blank-avi.png'}
        className='Booking-image'
        alt={`Booking provider${provider ? ' ' + provider.name : ''}`}
      />
      <BookingText provider={provider} type={type} />
      <BookingStatus status={status} />
      <Button className='dark fullWidth' disabled={status === 'canceled'} onClick={onClickCancel}>
        Cancel
      </Button>
      <p className='Booking-id'>
        Booking ID - <span>{_id.substring(_id.length - 6)}</span>
      </p>
    </div>
  )
}

const BookingText = ({provider, type}: {provider: TBookingProvider; type: TBookingType}) => {
  if (provider) {
    return (
      <div className='Booking-text'>
        <h4>{provider.name}</h4>
        <p>{type}</p>
      </div>
    )
  }

  return (
    <div className='Booking-text'>
      <h4>{type}</h4>
    </div>
  )
}

const BookingStatus = ({status}: {status: TBookingStatus}) => {
  const {bookingText} = getBookingStatusText(status)
  return <div className={`Booking-status ${status}`}>{bookingText}</div>
}

const getBookingStatusText = (status: TBookingStatus): {bookingText: string} => {
  switch (status) {
    case 'confirmed':
      return {bookingText: 'Confirmed'}
    case 'pending':
      return {bookingText: 'Confirming details...'}
    case 'canceled':
      return {bookingText: 'Canceled'}
    default:
      throw new Error('Not a valid booking status (should never get here)')
  }
}
