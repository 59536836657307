import React, {useMemo} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'

import useFetchEvent from 'apis/Events/useFetchEvent'
import {ArrowLeft, Eye} from 'components/assets/Icons'
import {KickbackIcon} from 'components/assets/KickbackIcon'
import RequirePermissions from 'components/RequirePermissions'
import isDevEnv from 'helpers/isDevEnv'
import {isEventKickbackEligible} from 'helpers/isEventKickbackEligible'
import {includes} from 'lodash'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {useCheckForNewApprovals} from '../../apis/Events/useCheckEventHasNewApprovals'
import {EventPageSection, EVENTS_ROUTE_MAP} from './EventRoutes'

import './MobileEventPageNavigation.scss'

const MobileEventPageNavigation = () => {
  const {pathname} = useLocation()
  const {domain, eventId, groupId} = useResourcePageParams()
  const navigate = useNavigate()
  const {data} = useCheckForNewApprovals({eventId: eventId!}, {enabled: !!eventId})
  const {data: event} = useFetchEvent(eventId!)

  const selectedSection = pathname.substring(
    pathname.lastIndexOf('events') + 7 + eventId!.length + 1,
  ) as EventPageSection
  const subSection = selectedSection.substring(selectedSection.lastIndexOf('/') + 1)
  const sections = Object.values(EventPageSection)
  const search = useLocation().search

  const pendingOrders = data?.pendingOrders || 0
  const hasAtLeastOneTicketWithApproval = useMemo(
    () => event?.event?.tickets?.some(ticket => ticket.approvalRequired),
    [event?.event?.tickets],
  )
  const shouldShowApprovalTab = hasAtLeastOneTicketWithApproval || pendingOrders > 0

  return (
    <div className='MobileEventPageNavigation-navItemContainer'>
      <a
        style={{cursor: 'pointer'}}
        onClick={() => {
          if (!includes(sections, subSection)) {
            navigate(-1)
          } else {
            navigate(`/${domain}/groups/${groupId}/overview` + search)
          }
        }}>
        <ArrowLeft className='MobileEventPageNavigation-backButton' />
      </a>
      {Object.keys(EVENTS_ROUTE_MAP).map(eventSection => {
        const {icon, activeIcon, requiredPermissions} = EVENTS_ROUTE_MAP[eventSection as EventPageSection]
        const isActive = selectedSection == eventSection
        const id = isActive ? 'selected' : undefined
        const isKickback = selectedSection === 'kickback'
        if (!shouldShowApprovalTab && eventSection == EventPageSection.APPROVALS) return null
        if (
          !isEventKickbackEligible({isRSVPEvent: event?.event?.isRSVPEvent, country: event?.group.country}) &&
          eventSection == EventPageSection.KICKBACK
        )
          return null
        if (eventSection == EventPageSection.TABLES) {
          return null
        }
        // TODO: Remove feature flag when Clik goes live
        if (!isDevEnv() && eventSection == EventPageSection.SERVICES) return null

        if (requiredPermissions) {
          return (
            <RequirePermissions requiredPermissions={requiredPermissions} key={eventSection}>
              <Link
                id={id}
                key={eventSection}
                to={`/${domain}/groups/${groupId}/events/${eventId}/${eventSection}` + search}
                className={`MobileEventPageNavigation-navItemContainer-link${isActive ? '-selected' : ''}`}>
                {eventSection === EventPageSection.KICKBACK ? (
                  // Kickback Requires permissions so it doesn't need to be in the other else statement
                  // I didnt want to deal with adding component capabilities to this icon rendering code
                  <KickbackIcon
                    size={15}
                    primaryColor={isActive ? '#ffcc00' : 'black'}
                    secondaryColor={isActive ? 'black' : '#ffcc00'}
                  />
                ) : (
                  <img
                    className='MobileEventPageNavigation-navItemContainer-link-icon'
                    src={isActive ? activeIcon : icon}
                  />
                )}
              </Link>
            </RequirePermissions>
          )
        } else {
          return (
            <Link
              id={id}
              key={eventSection}
              to={`/${domain}/groups/${groupId}/events/${eventId}/${eventSection}` + search}
              className={
                isActive
                  ? `MobileEventPageNavigation-navItemContainer-link-selected ${isKickback && 'kickback'}`
                  : 'MobileEventPageNavigation-navItemContainer-link'
              }>
              <img
                className='MobileEventPageNavigation-navItemContainer-link-icon'
                src={isActive ? activeIcon : icon}
              />
            </Link>
          )
        }
      })}
      <a
        className='MobileEventPageNavigation-eyeUrl'
        onClick={() => window.open(`/e/${event?.event?.url}` + search, '_blank')}>
        <Eye className='MobileEventPageNavigation-eye' />
      </a>
    </div>
  )
}

export default MobileEventPageNavigation
