import React from 'react'

import {Badge} from 'apis/Accounts'
import {EventPublic} from 'apis/Events/types'
import {IGroupLink} from 'apis/Groups'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'

import ProfileBadges from './Badges'
import PreviouslyAttendedEvents from './PastEvents'
import ProfileSocials from './Socials'
import Statistics, {Statistic} from './Statistics'
import UpcomingEvent from './UpcomingEvent'
import UpcomingEvents from './UpcomingEvents'

import './styles.scss'

const Profile = ({
  isFetching,
  type,
  avi,
  title,
  subtitle,
  statistics,
  instagram,
  twitter,
  linkedIn,
  upcomingEvent,
  upcomingEvents,
  badges,
  pastEvents,
  verified,
  firstName,
  customLinks,
  cover,
  website,
  accentColor,
  location,
  bio,
}: {
  isFetching?: boolean
  type: 'account' | 'organization' | 'venue'
  avi?: string
  title?: string
  subtitle?: string
  statistics?: Statistic[]
  instagram?: string
  twitter?: string
  linkedIn?: string
  upcomingEvent?: EventPublic | null
  upcomingEvents?: EventPublic[]
  badges?: Badge[]
  pastEvents?: EventPublic[] | null
  verified?: boolean
  firstName?: string
  customLinks?: IGroupLink[]
  cover?: string | undefined
  website?: string
  accentColor?: string
  location?: string
  bio?: string
}) => {
  if (isFetching)
    return (
      <div className={`PProfile ${type}`}>
        <div className='PProfile-banner'></div>
        <div className='PProfile-avi placeholder' />
        <div className='PProfile-body'>
          <div>
            <h2 className='PProfile-body-title'>&nbsp;</h2>
            <p className='text-small'>&nbsp;</p>
          </div>
          <SpinLoader height={100} />
        </div>
      </div>
    )

  const backAccentColor = accentColor ?? '#ffffff'
  const background = `linear-gradient(170.96deg, ${backAccentColor}44 0%, ${backAccentColor}00 69.17%), linear-gradient(180deg, #1d1d1d 0%, #0b0b0b 100%)`

  return (
    <div className={`PProfile ${type}`} style={{background}}>
      <div className='PProfile-banner'>
        <img src={cover ?? avi} className={cover ? 'cover' : 'avi'} />
      </div>
      <img className={`PProfile-avi ${type}`} src={avi} />
      <div className='PProfile-body'>
        <div>
          <h2 className='PProfile-body-title'>
            {title}
            {verified && <img src='https://assets.posh.vip/b2/verify.png' />}
          </h2>
          <p className='text-small'>{subtitle}</p>
        </div>
        <Statistics statistics={statistics} type={type} />
        {bio && <p className='noMargin'>{bio}</p>}
        {(location || website) && (
          <div className='PProfile-locationLine'>
            {location && <p className='text-small'>{location}</p>}
            {location && website && <div className='PProfile-locationLine-separator' />}
            {website && (
              <p className='text-small'>
                <a href={website} rel='noreferrer' target='_blank' style={{color: accentColor ?? '#fc0'}}>
                  {website}
                </a>
              </p>
            )}
          </div>
        )}
        <ProfileSocials
          instagram={instagram}
          twitter={twitter}
          linkedIn={linkedIn}
          location='profile page'
          hideLikeButton
        />
        {customLinks && customLinks.length > 0 && (
          <div className='PProfile-customLinks'>
            {customLinks.map((link, i) => (
              <a href={link.url} key={i} target='_blank' rel='noreferrer'>
                <Button className='dark'>{link.title}</Button>
              </a>
            ))}
          </div>
        )}
        {upcomingEvent && (
          <UpcomingEvent type={type} title={title} upcomingEvent={upcomingEvent} firstName={firstName} />
        )}
        {upcomingEvents && upcomingEvents.length > 1 && <UpcomingEvents events={upcomingEvents} />}
        {badges && (
          <>
            <hr />
            <p className='PProfile-subsectionTitle'>Badges</p>
            <ProfileBadges badges={badges} />
          </>
        )}
        {pastEvents && pastEvents.length > 1 && <PreviouslyAttendedEvents type={type} pastEvents={pastEvents} />}
      </div>
    </div>
  )
}

export default Profile
