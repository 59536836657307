import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {AccountProfile, Badge} from '.'

export interface PublicAccountResponse {
  _id: string
  firstName: string
  lastName: string
  avi: string
  profile: AccountProfile | null
  badges: Badge[]
  isCurrentUser: boolean
}

export async function fetchAccountProfileByUsername(username: string): Promise<PublicAccountResponse | null> {
  const response = await PoshApi.fetchAccountProfileByUsername(username)
  const data = response.data
  return data.account
}

export function useFetchAccountProfileByUsername(username: string) {
  return useQuery(['account', username], () => fetchAccountProfileByUsername(username), {
    enabled: !!username,
  })
}
