import React, {useState} from 'react'
import PhoneInput from 'react-phone-number-input'

import {isValidPhoneNumber} from '@posh/utils'
import {useLoginWithPhone} from 'apis/Auth/useLoginWithPhone'
import {useSignUp} from 'apis/Auth/useSignUp'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {useToast} from 'components/toasts/ToastProvider'
import {tokenCheckoutFormSchema} from 'domains/LiveCart/LiveCartContext'
import TOSCheckForm from 'pages/OwnerPage/SignUp/TOSCheckForm'

import {CreateEventFormDataModalStepProps} from '../util/createEventFormData'

const CreateAccountForm = (props: CreateEventFormDataModalStepProps) => {
  const {
    createEventFormData: {
      loginFlow,
      userData: {firstName, lastName, email, phone, password, confirmPassword, signUpToken},
    },
    setCreateEventFormData,
  } = props
  const isCreatingAccountFromEmail = loginFlow === 'email'
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {showToast} = useToast()
  const {mutateAsync: signUp, isLoading} = useSignUp({
    onError: error => {
      showToast({type: 'error', title: 'There was an error creating your account.', subtitle: error.message})
    },
  })
  const {mutateAsync: sendLoginCode, data: loginWithPhoneRes, isLoading: isSendingCode} = useLoginWithPhone()
  const [checkForms, setCheckForms] = useState({
    tos: true,
    smsOptIn: true,
  })
  const [hasSubmittedOnceWithoutSMSOptIn, setHasSubmittedOnceWithoutSMSOptIn] = useState(false)

  const sendLoginCodeAndSendToVerify = async () => {
    try {
      const validphone = tokenCheckoutFormSchema.validateSyncAt(
        'phone_number',
        {phone_number: phone},
        {abortEarly: false},
      )
      if (validphone) {
        const {success, hasAccount: hasExistingAccount, needsToConfirmAccount} = await sendLoginCode({phone})
        if (success) {
          setCreateEventFormData(u => ({
            ...u,
            hasAccount: true,
            hasExistingAccount,
            needsToConfirmAccount,
            loginStep: {type: 'verifySMS', previous: u.loginStep},
          }))
        } else
          showToast({
            type: 'error',
            title:
              'Could not find account associated with phone number. Refresh and login with email or create a new account',
          })
      }
    } catch (error: any) {
      const validationError = error.inner[0]
      const {message} = validationError
      showToast({type: 'error', title: message})
    }
  }

  const handleSignUp = async () => {
    try {
      trackMixpanelEvent(
        'Create Event Signup and go live -  Almost there last step to launch event and for new posh users',
      )
      if (!firstName || !lastName) return showToast({type: 'error', title: 'Please fill out all fields'})
      if (!email) return showToast({type: 'error', title: 'Please input a valid email'})
      if (!phone) return showToast({type: 'error', title: 'Please input a valid phone number'})
      if (isCreatingAccountFromEmail) {
        if (!password || !confirmPassword) return showToast({type: 'error', title: 'Please fill out all fields'})
        if (!isValidPhoneNumber(phone)) return showToast({type: 'error', title: 'Please enter a valid phone number.'})
        if (password !== confirmPassword) return showToast({type: 'error', title: 'Passwords do not match'})
      }
      if (!checkForms.tos)
        return showToast({type: 'error', title: 'Must read and accept terms of service and privacy policy to sign up'})
      if (!checkForms.smsOptIn && !hasSubmittedOnceWithoutSMSOptIn) {
        setHasSubmittedOnceWithoutSMSOptIn(true)
        return showToast({
          type: 'warning',
          title: 'Please opt in to text notifications for important event-related alerts.',
        })
      }

      if (!isValidPhoneNumber(phone)) {
        return showToast({type: 'error', title: 'Invalid mobile number. Please enter a valid mobile number.'})
      }

      if (!signUpToken) {
        sendLoginCodeAndSendToVerify()
        return
      }

      await signUp({
        email,
        firstName,
        lastName,
        smsOptIn: checkForms.smsOptIn,
        phone,
        verificationToken: signUpToken,
        password,
      })

      if (isCreatingAccountFromEmail) {
        trackMixpanelEvent('Signup Success- Almost there last step to launch event and for new posh users, email')
      } else {
        trackMixpanelEvent('Signup Success- Almost there last step to launch event and for new posh users, phone')
      }

      setCreateEventFormData(u => ({
        ...u,
        hasAccount: true,
      }))
    } catch (error: any) {
      if (isCreatingAccountFromEmail) {
        trackMixpanelEvent('Signup Failure- Almost there last step to launch event and for new posh users, email', {
          errorMessage: error.response.data.error,
        })
      } else {
        trackMixpanelEvent('Signup Failure- Almost there last step to launch event and for new posh users, phone', {
          errorMessage: error.response.data.error,
        })
      }
      showToast({type: 'error', title: error.response.data.error})
      if (error.response.data.error === 'Verification token is not valid. Please try again.') {
        //If verification token is not valid, clear the token and let the user try again
        setCreateEventFormData(u => ({
          ...u,
          userData: {
            ...u.userData,
            signUpToken: undefined,
          },
        }))
      }
    }
  }

  const getButtonLabel = () => {
    if (isLoading) return `Working Our Magic...`
    if (!signUpToken) return `Proceed to Verify Phone Number`
    if (isCreatingAccountFromEmail && signUpToken) return `Confirm Information and Go Live!`
    return `Sign up and Go Live!`
  }

  return (
    <div className='formWrapper straight'>
      <h3>Almost There!</h3>
      <p className='center noMargin'>{isCreatingAccountFromEmail ? email : phone}</p>
      <div className='inputWrapper'>
        <Input
          className='fullWidth'
          value={firstName}
          onChange={e => setCreateEventFormData(u => ({...u, userData: {...u.userData, firstName: e.target.value}}))}
          type={'customer'}
          required={false}
          placeholder={'First Name'}
        />
        <Input
          className='fullWidth'
          value={lastName}
          onChange={e => setCreateEventFormData(u => ({...u, userData: {...u.userData, lastName: e.target.value}}))}
          type={'customer'}
          required={false}
          placeholder={'Last Name'}
        />
      </div>
      {isCreatingAccountFromEmail ? (
        <>
          <PhoneInput
            className='AccountSettings-phoneInput'
            value={phone}
            onChange={e => setCreateEventFormData(u => ({...u, userData: {...u.userData, phone: e ?? ''}}))}
            type={'customer'}
            required={false}
            defaultCountry={'US'}
            placeholder={'Phone Number'}
            disabled={!!signUpToken}
          />
          <Input
            className='fullWidth'
            value={password}
            onChange={e => setCreateEventFormData(u => ({...u, userData: {...u.userData, password: e.target.value}}))}
            type={'password'}
            required={false}
            placeholder={'Password'}
          />
          <Input
            className='fullWidth'
            value={confirmPassword}
            onChange={e =>
              setCreateEventFormData(u => ({...u, userData: {...u.userData, confirmPassword: e.target.value}}))
            }
            type={'password'}
            required={false}
            placeholder={'Confirm Password'}
          />
        </>
      ) : (
        <Input
          className='fullWidth'
          value={email}
          onChange={e => setCreateEventFormData(u => ({...u, userData: {...u.userData, email: e.target.value}}))}
          type={'customer'}
          required={true}
          placeholder={'Email'}
        />
      )}
      <TOSCheckForm checkForms={checkForms} setCheckForms={setCheckForms} />
      <Button className={'createEventGrad'} disabled={isLoading || checkForms.tos === false} onClick={handleSignUp}>
        {getButtonLabel()}
      </Button>
    </div>
  )
}

export default CreateAccountForm
