import React from 'react'
import Loader from 'react-loader-spinner'

import {NotificationType} from '@posh/types'
import {FetchNotificationsOutput} from 'apis/Notifications/useFetchNotifications'

import Notification from '../Notification'

type NotificationTrayProps = {
  popperRef: React.MutableRefObject<null>
  closeNotificationTray: () => void
  notifications: FetchNotificationsOutput
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  popperBoundingRect: DOMRect | null
  isOpen: boolean
}

const LOADER_SIZE = 30

const NOTIFICATION_POSITION_TOP_OFFSET = 15

function getNotificationTrayPosition(popperBoundingRect: DOMRect | null) {
  if (!popperBoundingRect) return undefined

  const pos = {
    top: popperBoundingRect.bottom + NOTIFICATION_POSITION_TOP_OFFSET,
    right: window.innerWidth - popperBoundingRect.right - popperBoundingRect.width,
  }

  return pos
}

const NotificationTray = (props: NotificationTrayProps) => {
  const {popperRef, closeNotificationTray, notifications, isError, isLoading, isSuccess, popperBoundingRect, isOpen} =
    props

  const notifTrayPosition = getNotificationTrayPosition(popperBoundingRect)

  if (!isOpen) return null

  return (
    <div className='NotificationTray' ref={popperRef} style={notifTrayPosition}>
      <div className='NotificationTray-arrow'></div>
      <div className='NotificationTray-content'>
        <div className='NotificationTray-header'>Notifications</div>
        <div className='NotificationTray-notifications'>
          {isLoading && (
            <div className='NotificationTray-loading'>
              <Loader type='TailSpin' color='white' height={LOADER_SIZE} width={LOADER_SIZE} />
            </div>
          )}
          {!isLoading && isError && (
            <div className='Notification disabledText is-read'>Something went wrong fetching your notifications.</div>
          )}
          {isSuccess && (
            <>
              {notifications.length > 0 ? (
                // TODO: Remove this filter once FollowedByNotification has the 'followerUsername' field so we can navigate to the user's profile
                notifications
                  .filter(notif => notif.type !== NotificationType.FOLLOWED_BY_USER)
                  .map(notif => (
                    <Notification key={notif._id} notification={notif} closeNotificationTray={closeNotificationTray} />
                  ))
              ) : (
                <div className='Notification disabledText is-read'>You have no notifications.</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default NotificationTray
