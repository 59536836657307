import React from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import {trackCreateEventEntryPoint, useNavigateToCreateEvent} from 'pages/CreateEvent/useNavigateToCreateEvent'

import LandingPageEventCard from '../EventCard'
import {HomePageComponentProps} from '../interface'
import SocialLinks from '../SocialLinks'

import './styles.scss'

const CoverSection = (props: HomePageComponentProps) => {
  const {changePage} = props
  const {createEventUrl} = useNavigateToCreateEvent()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const users = [
    'https://assets.posh.vip/b1/attendee-avi/60a5d1c78001910015ed1eeb.jpg',
    'https://assets.posh.vip/b1/attendee-avi/60ad7b631fc9310015a13168.jpg',
    'https://assets.posh.vip/b1/attendee-avi/60b1250e75673d001517dd7c.jpg',
  ]

  return (
    <div className='LandingPage-section-inner'>
      <div className='CoverSection-text'>
        <h1>
          Scale your brand with <span>experiences</span>
        </h1>
        <div className='CoverSection-text-join'>
          <div className='CoverSection-text-join-avatars'>
            {users.map((user, index) => (
              <img key={index} src={user} />
            ))}
          </div>
          <p>
            Join <b>1 million+</b> event-goers, creators and organizers in the most advanced live event ecosystem on the
            planet.
          </p>
        </div>
        <div className='buttonWrapper'>
          <Button
            className='LandingPage-btn'
            onClick={() => {
              trackCreateEventEntryPoint('Landing Page')
              changePage(createEventUrl)
              trackMixpanelEvent('Create Event- Posh home page Top of page in hero visual')
            }}>
            Launch An Event
          </Button>
          <Button className='LandingPage-btn outlineBtn' onClick={() => changePage('/explore')}>
            Explore Events
          </Button>
        </div>
        <div className='LandingPage-appCTA'>
          <a
            href='https://apps.apple.com/us/app/posh-curator/id1556928106'
            target='_blank'
            rel='noreferrer'
            onClick={() => trackMixpanelEvent('App Download Button- Hero Landing Page')}>
            <img src='https://assets.posh.vip/b3/app-ctas/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' />
          </a>
        </div>
      </div>

      <div className='CoverSection-illustration'>
        <div className='CoverSection-illustration-bg' />
        <LandingPageEventCard changePage={changePage} />
      </div>

      <div className='CoverSection-socialLinks'>
        <SocialLinks />
      </div>
    </div>
  )
}

export default CoverSection
