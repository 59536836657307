import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useZendesk} from 'react-use-zendesk'

import {useAuthenticateZendeskChat} from 'apis/Zendesk/useAuthenticateZendeskChat'
import useSessionContext from 'domains/Auth/SessionContext'

import {shouldShowZendeskWidget} from './zendeskHelpers'

const useInitiateZendesk = () => {
  const {pathname} = useLocation()
  const {currentUser} = useSessionContext()
  const {data, isFetching, isSuccess, refetch} = useAuthenticateZendeskChat(undefined, {
    retry: false,
  })

  const {show, hide, loginUser, logoutUser} = useZendesk()

  useEffect(() => {
    if (!isSuccess || isFetching) return
    const {token} = data
    if (token) loginUser(token)
    else logoutUser()
  }, [data])

  useEffect(() => {
    refetch()
  }, [currentUser])

  useEffect(() => {
    const shouldRenderZendeskWidget = shouldShowZendeskWidget(pathname, currentUser)
    if (shouldRenderZendeskWidget) {
      show()
    } else {
      hide()
    }
  }, [pathname, currentUser])
}

export default useInitiateZendesk
