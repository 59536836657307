import React from 'react'
import {Route, Routes} from 'react-router-dom'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

import {useGroupMatchParams} from '../../../domains/Groups/helpers'
import SMSCampaignLayout from '../../SMSCampaign/Layout'
import TextBlastBreakdown from '../TextBlastBreakdown'
import AttendeeProfilePage from './AttendeeProfilePage'
import AttendeesListView from './AttendeesListView'
import {CustomNumberConfirmation, CustomNumberSelection} from './CustomNumberSelection'
import SmsBlastsPage from './SmsBlastsPage'

import './styles.scss'

const Marketing = () => {
  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)

  return (
    <>
      <PoshHelmet title={`Marketing - ${group?.name}`} />
      <Routes>
        <Route
          path=''
          element={
            <RequirePermissions
              requiredPermissions={[requirePermissionAndScope('view_global_attendee_list', ['group'])]}
              allowPartialMatch>
              <AttendeesListView />
            </RequirePermissions>
          }
        />
        <Route path='view-sms' element={<SmsBlastsPage />} />
        <Route path='custom-number' element={<CustomNumberSelection />} />
        <Route path='custom-number/confirmation' element={<CustomNumberConfirmation />} />
        <Route path='smscampaign/:blastId/*' element={<SMSCampaignLayout />} />
        <Route path='textblast/:blastId' element={<TextBlastBreakdown />} />
        <Route
          path='attendee/:attendeeId'
          element={
            <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_attendee', ['group', 'event'])]}>
              <AttendeeProfilePage />
            </RequirePermissions>
          }
        />
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default Marketing
