import {buildYoutubeVideoUrl} from '@posh/model-types'
import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

import {PendingEvent, UpdatePendingEvent} from '.'
import {GetPendingEventRawOutput} from './useGetPendingEvent'

type UpdatePendingEventOptions = ReactQueryOptions['pendingEvents']['updatePendingEvent']
export type UpdatePendingEventInput = RouterInputs['pendingEvents']['updatePendingEvent']
export type UpdatePendingEventOutput = RouterOutput['pendingEvents']['updatePendingEvent']

export function useUpdatePendingEvent(opts?: UpdatePendingEventOptions) {
  return trpc.pendingEvents.updatePendingEvent.useMutation(opts)
}

export function transformGetPendingEventOutputToUpdatePendingEventInput(
  data?: Extract<GetPendingEventRawOutput, {isNewSchema: true}>['pendingEvent'],
): UpdatePendingEventInput {
  if (!data) {
    const DUMMY_PENDING_EVENT: UpdatePendingEventInput = {
      _id: '',
      location: {
        coordinates: [0, 0],
        type: 'Point',
      },
      song: undefined,
      eventTitleFont: 'Circular-Std',
      lightmode: false,
      accentColor: 'white',
      attendanceDisplayDisabled: true,
      guestlistEnabled: true,
      activityEnabled: true,
      displayOnThirdPartySites: true,
      displayEndTime: true,
      tickets: [
        {
          id: '0',
          name: 'Default Ticket',
          price: 10,
        },
      ],
    }
    return DUMMY_PENDING_EVENT
  }
  return {
    ...data,
    _id: data.eventId,
    location: {
      coordinates: [data.location.lng, data.location.lat],
      type: 'Point',
    },
    venue: {
      address: data.venueAddress,
      name: data.venueName,
      images: data.gallery,
    },
    youtubeLink: data.youtubeVideoId ? buildYoutubeVideoUrl(data.youtubeVideoId) : undefined,
    song: data.song
      ? {
          ...data.song,
          albumCover: data.song.albumCover ?? '',
        }
      : undefined,
    parentCategory: data.eventCategory.parentCategory,
    subCategory: data.eventCategory.subCategory,
    passwordEnabled: data.isPasswordProtected,
    timezone: undefined, // we set timezone in the backend, it should not be set on the form
  }
}

/** TODO CREATE_EVENT_THREE_DEPRECATE Delete this after new CE is deployed */
export interface UpdatePendingEventParams {
  id: string
  pendingEvent: UpdatePendingEvent
  groupId?: string | null
}

const updatePendingEvent = async (params: UpdatePendingEventParams) => {
  const response = await PoshApi.updatePendingEvent(params)
  const updatedPendingEvent = response.data.pendingEvent
  return updatedPendingEvent
}

export function useDeprecatedUpdatePendingEvent() {
  return useMutation<PendingEvent, any, UpdatePendingEventParams>(params => updatePendingEvent(params))
}
