import React from 'react'
import {useNavigate} from 'react-router-dom'

import {OrderModel} from 'apis/Orders'
import {Card} from 'components/Card'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import {useDimensions} from 'hooks/useDimensions'
import moment from 'moment'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import OrderDescriptors, {UntaggedOrderModel} from '../../OrderDescriptors'

const OrderCardOrders = ({orders}: {orders?: OrderModel[]}) => {
  const {domain, groupId} = useResourcePageParams()
  const navigate = useNavigate()
  const {isMobile} = useDimensions()
  return (
    <Card title={'Orders'} animationIndex={0}>
      <table className='OrderPage-ordersTable'>
        {orders?.map(order => {
          const orderDescriptorsProps: UntaggedOrderModel = {
            ...order,
            type: 'untagged',
          }
          return (
            <tr className='OrderPage-ordersTable-ftd' key={order.number}>
              <td>
                <table>
                  <tr>
                    {!isMobile && (
                      <td>
                        <div className='OrderPage-ordersTable-iconHolder'>
                          <img src='https://assets.posh.vip/b2/shopping-bag.svg' />
                        </div>
                      </td>
                    )}
                    <td className='OrderPage-ordersTable-secondtd'>
                      <a
                        className='OrderPage-ordersTable-name'
                        onClick={() => navigate(`/${domain}/groups/${groupId}/orders/${order._id}`)}>
                        Order #{order.number}
                      </a>
                      <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_attendee', ['group'])]}>
                        <br />${order.subtotal.toFixed(2)}
                      </RequirePermissions>
                    </td>
                    <td>{order.eventName}</td>
                    <td className='OrderPage-ordersTable-date'>
                      {moment(order.createdAt).format('MMMM Do, YYYY h:mm A')}
                    </td>
                  </tr>
                  <tr>
                    {!isMobile && <td></td>}
                    <td colSpan={3}>
                      <OrderDescriptors order={orderDescriptorsProps} />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          )
        })}
      </table>
    </Card>
  )
}

export default OrderCardOrders
