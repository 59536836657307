import React, {ReactNode} from 'react'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

import './Row.styles.scss'

type RecipientsModalRowProps = {
  imageSrc: string
  name: string
  phoneNumber: string
  onClick?: () => void
  sticker?: ReactNode
}

const RecipientRow = ({imageSrc, name, phoneNumber, sticker, onClick}: RecipientsModalRowProps) => {
  return (
    <div className='RecipientRow'>
      <img className='RecipientRow__profilePic' src={imageSrc} />
      <p className='noMargin' id='recipient-row-name' onClick={onClick}>
        {name}
      </p>
      <p className='noMargin'>{phoneNumber}</p>
      {sticker}
    </div>
  )
}

const RecipientRowSkeleton = () => {
  return (
    <SkeletonTheme baseColor='rgb(68 68 68)' highlightColor='rgb(100,100,100)' duration={0.9}>
      <div className='RecipientRow'>
        <Skeleton containerClassName='skeleton-flex RecipientRow__profilePic' circle={true} width={40} height={40} />
        <Skeleton containerClassName='skeleton-flex' width={180} />
        <Skeleton containerClassName='skeleton-flex' style={{marginLeft: '30px'}} />
      </div>
    </SkeletonTheme>
  )
}

export {RecipientRow, RecipientRowSkeleton}
