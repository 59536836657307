import React from 'react'
import {useNavigate} from 'react-router-dom'

import {CommunityEntityResponse} from 'apis/Community'
import Button from 'components/form/Button'
import loginRedirect from 'components/helpers/loginRedirect'
import signupRedirect from 'components/helpers/signupRedirect'

interface ComUAModalProps {
  communityEntity: CommunityEntityResponse
  isVenue: boolean
  onClose: () => void
}

const ComUAModal = (props: ComUAModalProps) => {
  const {communityEntity, isVenue, onClose} = props
  const redirectUrl = `/community/book?c=${communityEntity.type}&u=${communityEntity.url}`
  const navigate = useNavigate()
  return (
    <div className='ComUAModal-holder'>
      <div className='ComUAModal'>
        <img
          className='ComUAModal-Avi'
          src={isVenue && communityEntity.galleryImages ? communityEntity.galleryImages[0].url : communityEntity.avi}
        />
        <div>
          <h2 style={{fontWeight: '700'}}>Book {props.communityEntity.name} with POSH Community</h2>
          <div className='ComUAModal-actions'>
            <Button onClick={() => signupRedirect(navigate, redirectUrl, true)}>SIGN UP FREE</Button>
            <Button onClick={() => loginRedirect(navigate, redirectUrl)} className='outline'>
              LOGIN
            </Button>
          </div>
        </div>
        <span onClick={onClose}>Close</span>
      </div>
    </div>
  )
}

export default ComUAModal
