import React from 'react'

import {CurrencyCode} from '@posh/types'
import {EventFee} from 'apis/Groups/useGetGroupForFinancials'
import {deleteIcon} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import './styles.scss'

interface CustomFeesProps {
  fees?: EventFee[]
  currency: CurrencyCode
  setIsShowingAddCustomFees: React.Dispatch<React.SetStateAction<boolean>>
  setFeeToEdit: React.Dispatch<React.SetStateAction<string>>
  setFeeToDelete: React.Dispatch<React.SetStateAction<string>>
}

interface CustomFeeProps {
  fee: EventFee
  currency: CurrencyCode
  setFeeToEdit: React.Dispatch<React.SetStateAction<string>>
  setFeeToDelete: React.Dispatch<React.SetStateAction<string>>
}

const CustomFee = (props: CustomFeeProps) => {
  const {fee, currency, setFeeToEdit, setFeeToDelete} = props
  const renderedFeeAmount = () => {
    if (fee.type === 'percent') {
      return `${(fee.amount * 100).toFixed(2)}%`
    } else {
      return `${getCurrencySymbol(currency)}${fee.amount.toFixed(2)}`
    }
  }
  return (
    <div className='CustomFee'>
      <div className='CustomFee-info'>
        <p className='noMargin text-small'>{fee.name}</p>
        <h4 className='noMargin'>{renderedFeeAmount()}</h4>
      </div>
      <div className='CustomFee-actions'>
        <img onClick={() => setFeeToEdit(fee.name)} src='https://assets.posh.vip/b2/pencil+(2).svg' />
        <img onClick={() => setFeeToDelete(fee.name)} src={deleteIcon} />
      </div>
    </div>
  )
}

const CustomFees = (props: CustomFeesProps) => {
  const {fees, currency, setIsShowingAddCustomFees, setFeeToDelete, setFeeToEdit} = props
  return (
    <div className='CustomFees'>
      {fees && fees.length > 0 ? (
        <>
          {fees.map((fee, index) => (
            <CustomFee
              setFeeToDelete={setFeeToDelete}
              key={index}
              fee={fee}
              currency={currency}
              setFeeToEdit={setFeeToEdit}
            />
          ))}
          <img onClick={() => setIsShowingAddCustomFees(true)} src='https://assets.posh.vip/b2/add.svg' />
        </>
      ) : (
        <Button onClick={() => setIsShowingAddCustomFees(true)} className='fullWidth'>
          Add Custom Fee
        </Button>
      )}
    </div>
  )
}

export default CustomFees
