import React, {useState} from 'react'

import {useInvalidateAccount} from 'apis/Accounts/useInvalidateAccount'
import Button from 'components/form/Button'
import {useToast} from 'components/toasts/ToastProvider'

interface InvalidateAccountPromptProps {
  unconfirmedAccountId: string
  validateAccount: () => void
}

export const InvalidateAccountPrompt = (props: InvalidateAccountPromptProps) => {
  const {unconfirmedAccountId, validateAccount} = props
  const {showToast} = useToast()
  const {mutateAsync: invalidateAccount} = useInvalidateAccount()
  const [shouldShowRedirect, setShouldShowRedirect] = useState(false)
  const handleRemoveData = async () => {
    try {
      await invalidateAccount({accountId: unconfirmedAccountId})
      setShouldShowRedirect(true)
      return
    } catch (error) {
      showToast({type: 'error', title: 'There was an issue invalidating the account, please contact support@posh.vip'})
    }
  }

  return (
    <>
      {shouldShowRedirect ? (
        <>
          <h3>Successfully Removed Your Data</h3>
          <a href='/'>Redirect Home</a>
        </>
      ) : (
        <>
          <h3>Are you sure you want to delete your POSH account, this action is irreversible</h3>
          <Button onClick={validateAccount} className='dark'>
            Cancel
          </Button>
          <Button onClick={handleRemoveData}>Remove My Data</Button>
        </>
      )}
    </>
  )
}
