import React, {useState} from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import AccountSearchModal, {AccountParams} from 'components/modals/Modal/AccountSearchModal'
import AddTeamMemberModal from 'pages/EventManagementPages/TeamManagementModal/AddTeamMemberModal'
import useTeamManagementModalContext from 'pages/EventManagementPages/TeamManagementModal/TeamManagementModalContext'

interface AddTeamMemberWrapperProps {
  onTeamManagementModalClose: () => void
  isAddingTeamMember: boolean
  onClickContinue?: () => void
  onClickAddTeamMember?: (role: string) => void
}

const AddTeamMemberWrapperComponent = (props: AddTeamMemberWrapperProps) => {
  const {onTeamManagementModalClose, isAddingTeamMember, onClickContinue, onClickAddTeamMember} = props
  const {trackEvent} = useMixpanel()
  const {successMessage, errorMessage, setErrorMessage, reset, scope} = useTeamManagementModalContext()
  const [userBeingAdded, setUserBeingAdded] = useState('')
  const [userBeingAddedIsNonPosh, setUserBeingAddedIsNonPosh] = useState(false)
  const handleShowThirdStep = async (accountId: string) => {
    setUserBeingAdded(accountId)
  }

  const onClose = () => {
    if (userBeingAddedIsNonPosh) {
      trackEvent('X Click- Group Non-Posh User Modal')
    }
    setUserBeingAdded('')
    onTeamManagementModalClose()
    reset()
  }

  const onAddPoshUser = async (account: AccountParams) => {
    trackEvent('Exact Match Team Add- Group Team Page', {email: account.email, phone: account.phone})
    setUserBeingAddedIsNonPosh(false)
    await handleShowThirdStep(account.accountId)
  }

  const onAddNonPoshUser = async (account: AccountParams) => {
    trackEvent('Continue Click- Group Non-Posh User Modal')
    setUserBeingAddedIsNonPosh(true)
    await handleShowThirdStep(account.accountId)
  }

  return (
    <AccountSearchModal
      width={700}
      isOpen={isAddingTeamMember}
      onSecondaryButtonClick={onClickContinue}
      onClose={onClose}
      onAddNonPoshUser={onAddNonPoshUser}
      onAddPoshUser={onAddPoshUser}
      successMessage={successMessage}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      userBeingAdded={userBeingAdded}
      primaryTitle={`Add a Team Member to your ${scope === 'event' ? 'Event' : 'Group'}`}
      primaryButtonText='+ Add a Non-POSH User'
      secondaryTitle='Add Details for Team Member'
      secondaryButtonText='Continue'
      noborder={true}
      ThirdComponent={
        <AddTeamMemberModal userBeingAdded={userBeingAdded} onClickAddTeamMember={onClickAddTeamMember} />
      }
    />
  )
}

export default AddTeamMemberWrapperComponent
