import React, {useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {Tag} from 'apis/Groups'

import plusIcon from '../../../../assets/images/icons/plus-yellow.svg'
import useAttendeesCrmContext from '../AttendeesCrmContext'
import {TagContainer} from '../TagContainer'
import {TagsModal} from '../TagsModal'

const AttendeeRowTags = ({tags, userId, links}: {tags: Tag[]; userId: string; links?: string[] | undefined}) => {
  const {setSelectedAttendeeIds} = useAttendeesCrmContext()
  const [isTagModalOpen, setIsTagModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const handleTagUser = () => {
    setSelectedAttendeeIds([userId])
    setIsTagModalOpen(true)
  }
  const handleCloseTagModal = () => {
    setSelectedAttendeeIds([])
    setIsTagModalOpen(false)
    queryClient.invalidateQueries(['groupAttendeesPaged'])
    queryClient.invalidateQueries(['eventAttendees'])
  }
  return (
    <>
      <div className='TagsContainer'>
        <div className='TagsContainer-wrapper'>
          <button className='TagsContainer-addTag' onClick={() => handleTagUser()}>
            <img src={plusIcon} />
          </button>
          <div className='TagsContainer-tagsWrapper'>
            {tags.map(tag => (
              <TagContainer key={tag.name} tag={tag} userId={userId} deletable={true} />
            ))}
            {links &&
              links.map(link => (
                <>
                  {link && (
                    <div className='TagsContainer-tag' style={{background: 'gray', color: 'black'}}>
                      {link}
                    </div>
                  )}
                </>
              ))}
          </div>
        </div>
      </div>
      <TagsModal isOpen={isTagModalOpen} onClose={() => handleCloseTagModal()} />
    </>
  )
}

export default AttendeeRowTags
