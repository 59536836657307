import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {KickbackOffer} from './KickbackOffer'

export type UpdateKickbackOfferProps = Partial<Omit<KickbackOffer, '_id'>>

const createKickBackOffer = async (params: UpdateKickbackOfferProps) => {
  const response = await PoshApi.createKickBackOffer(params)
  return response.data
}

export function useCreateKickBackOffer() {
  return useMutation<unknown, unknown, UpdateKickbackOfferProps>(params => createKickBackOffer(params))
}
