import React from 'react'
import {Route, Routes} from 'react-router-dom'

import PoshAccounts from 'pages/AdminDashboardPages/Accounts'
import AdminDashboard from 'pages/AdminDashboardPages/AdminDashboard'
import CampaignLinks from 'pages/AdminDashboardPages/CampaignLinks'
import PoshEvents from 'pages/AdminDashboardPages/Events'
import GroupDashboard from 'pages/AdminDashboardPages/GroupDashboard'
import KPIDashboard from 'pages/AdminDashboardPages/KPIDashboard'
import PoshOrgs from 'pages/AdminDashboardPages/Orgs'
import ReleaseNotificationDashboard from 'pages/AdminDashboardPages/ReleaseNotificationDashboard'
import TextBlastsDashboard from 'pages/AdminDashboardPages/TextBlastsDashboard'
import TopFreeDashboard from 'pages/AdminDashboardPages/TopFreeDashboard'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

import Analytics from '../AdminDashboardPages/CampaignLinks/Analytics'
import ConnectAccounts from '../AdminDashboardPages/ConnectAccounts'
import {FullRefundDashboard} from '../AdminDashboardPages/FullRefundDashboard'
import CommunityAdminRoutes from './CommunityAdminRoutes'
import FraudAdminRoutes from './FraudAdminRoutes'

import '../AdminDashboardPages/styles.scss'

const AdminRoutes = () => {
  return (
    <>
      <PoshHeader isTranslucent />
      <div className='AdminDashboard'>
        <Routes>
          <Route path='/' element={<AdminDashboard />} />
          {/* <Route path='/groups-by-gmv' element={<GroupsByGMV />} /> */}
          <Route path='/b' element={<GroupDashboard />} />
          <Route path='/top-free' element={<TopFreeDashboard />} />
          <Route path='/textblasts' element={<TextBlastsDashboard />} />
          <Route path={'community/*'} element={<CommunityAdminRoutes />} />
          <Route path={'fraud/*'} element={<FraudAdminRoutes />} />
          <Route path={'refund-requests'} element={<FullRefundDashboard />} />
          <Route path={'/campaign-links'} element={<CampaignLinks />} />
          <Route path={'/events'} element={<PoshEvents />} />
          <Route path={'/campaign-links/:linkId/analytics'} element={<Analytics />} />
          <Route path={'/accounts'} element={<PoshAccounts />} />
          <Route path={'/orgs'} element={<PoshOrgs />} />
          <Route path={'/connect-verification'} element={<ConnectAccounts />} />
          <Route path='/release' element={<ReleaseNotificationDashboard />} />
          <Route path='/kpi' element={<KPIDashboard />} />
          <Route path={'*'} element={<PageNotFound />} />
        </Routes>
      </div>
    </>
  )
}

export default AdminRoutes
