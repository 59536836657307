import React, {useEffect, useState} from 'react'
import Loader from 'react-loader-spinner'

import {CommunityCategory, OpenToOtherDatesChoice} from 'apis/Community'
import useCreateBookingRequest, {CreateBookingRequestData} from 'apis/Community/useCreateBookingRequest'
import useFetchCommunityEntityByUrl from 'apis/Community/useFetchCommunityEntityByUrl'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import PoshDatePicker from 'components/form/PoshDatePicker'
import useSessionContext from 'domains/Auth/SessionContext'
import {getDate} from 'pages/CommunityDashboard/dashboardHelpers'
interface ErrorMessage {
  [key: string]: string
}

import './styles.scss'

const CommunityBooking = () => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const urlParams = new URLSearchParams(window.location.search)
  const communityCategory = urlParams.get('c') as CommunityCategory
  const communityUrl = urlParams.get('u') as string
  const isVenue = communityCategory === 'venue'
  const {currentUser, userId} = useSessionContext()
  const {data: communityEntity, isFetching: isFetchingCommunityEntity} = useFetchCommunityEntityByUrl(
    communityCategory,
    communityUrl,
  )
  const {mutateAsync: createBookingRequest} = useCreateBookingRequest()
  const [bookingDetails, setBookingDetails] = useState<CreateBookingRequestData>()
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({})
  const [hasSentBookingRequest, setHasSentBookingRequest] = useState(false)

  useEffect(() => {
    if (communityEntity) {
      setBookingDetails({
        communityId: communityEntity.id,
        message: '',
        location: isVenue ? communityEntity.location : '',
        bookingDate: new Date(),
        startTime: undefined,
        endTime: undefined,
        communityCategory,
        eventName: '',
        eventType: isVenue ? 'General Party' : undefined,
      })
    }
  }, [communityEntity])

  const canSendBookingRequest = () => {
    if (!bookingDetails) return false
    if (hasSentBookingRequest) return true
    const {message, location, bookingDate, startTime, endTime, eventName, communityId, eventType} = bookingDetails
    if (
      eventName &&
      (location || eventType) &&
      bookingDate &&
      startTime &&
      endTime &&
      message &&
      communityId &&
      userId
    ) {
      return false
    }
    return true
  }

  const fieldValidator = (fields: CreateBookingRequestData) => {
    const errorState: ErrorMessage = {}

    if (fields.startTime && fields.endTime && fields.startTime >= fields.endTime) {
      errorState['startTime'] = 'Start time must be before end time.'
    }

    if (!fields.message) {
      if (communityCategory === 'artist') {
        errorState['message'] = 'Extra notes are required.'
      } else {
        errorState['message'] = 'Message is required.'
      }
    }

    if (fields.message.length > 500) {
      errorState['message'] = 'Message cannot exceed 500 characters.'
    }

    if (communityCategory === 'artist') {
      if (!fields.organizationName) {
        errorState['organizationName'] = 'Organization name is required.'
      }
      if (!fields.otherDates) {
        errorState['otherDates'] = 'Other date availability is required.'
      }
      if (!fields.venueName) {
        errorState['venueName'] = 'Name of venue is required.'
      }
      if (!fields.venueCapacity) {
        errorState['venueCapacity'] = 'Capacity of venue is required.'
      }
      if (!fields.averageTicketPrice) {
        errorState['averageTicketPrice'] = 'Average ticket price is required.'
      }
      if (!fields.approximateBudget) {
        errorState['approximateBudget'] = 'Approximate artist budget is required.'
      }
    }

    const isEmpty = Object.values(errorState).every(value => value === '')

    if (!isEmpty) {
      setErrorMessage(errorState)
      return false
    }
    return true
  }

  const handleBookingRequest = async () => {
    if (!bookingDetails) return
    const fieldsAreValid = fieldValidator(bookingDetails)
    try {
      if (fieldsAreValid) {
        setErrorMessage({})
        const response = await createBookingRequest(bookingDetails!)
        const accountName = currentUser?.firstName + ' ' + currentUser?.lastName
        const accountEmail = currentUser?.email ?? ''
        const accountId = currentUser?._id ?? ''
        const communityName = communityEntity?.type ?? ''
        trackMixpanelEvent('Community Booking Creation', {
          accountName,
          accountEmail,
          accountId,
          communityName,
          communityCategory,
        })
      }
      //if (response.message) SEND USER TO NEXT SUCCESSFUL REQUEST PAGE
      setHasSentBookingRequest(true)
    } catch (error: any) {
      if (error.response.data.error) {
        setErrorMessage({booking: error.response.data.error})
      } else {
        setErrorMessage({booking: 'Something went wrong. Please try again later.'})
      }
    }
  }

  if (isFetchingCommunityEntity)
    return (
      <div className='Loader'>
        <Loader type='Rings' color='#51D300' height={100} width={100} />
      </div>
    )

  if (!communityEntity || !bookingDetails)
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <h1>{`No community ${communityCategory} with URL ${communityUrl} found.`}</h1>
      </div>
    )

  const {name, avi, galleryImages} = communityEntity

  return (
    <div className='ComBookingPage'>
      <div className='ComBookingPage-left'>
        <img className='ComBookingPage-left-Bg' src={isVenue && galleryImages ? galleryImages[0].url : avi} />
        <div />
        <img className='ComBookingPage-left-Avi' src={isVenue && galleryImages ? galleryImages[0].url : avi} />
        <h3>{name}</h3>
      </div>
      <div className='ComBookingPage-right'>
        <h1>{`Tell ${name} about your event`}</h1>
        <hr />
        <div className='ComBookingPage-right-form'>
          <input
            className='PoshInput'
            placeholder='Event Name'
            onChange={e => setBookingDetails({...bookingDetails, eventName: e.target.value})}
          />
          {communityCategory !== 'venue' && (
            <input
              className='PoshInput'
              placeholder='Location / Address'
              onChange={e => setBookingDetails({...bookingDetails, location: e.target.value})}
            />
          )}
          {communityCategory === 'venue' && (
            <select onChange={e => setBookingDetails({...bookingDetails, eventType: e.target.value})}>
              <option value={'General Party'}>General Party</option>
              <option value={'Corporate Event'}>Corporate Event</option>
              <option value={'Birthday Party'}>Birthday Party</option>
              <option value={'Band'}>Band</option>
              <option value={'Fundraiser'}>Fundraiser</option>
              <option value={'Greek Life'}>Greek Life</option>
            </select>
          )}
          <PoshDatePicker
            name={'Select Start Time'}
            value={bookingDetails.startTime && getDate(bookingDetails.startTime)}
            setDates={(localDate, utcDate: any) => {
              setBookingDetails({...bookingDetails, startTime: utcDate, bookingDate: utcDate})
            }}
            timezone={'UTC'}
          />
          {errorMessage.startTime && <p className='ErrorMessage'>{errorMessage.startTime}</p>}
          <PoshDatePicker
            name={'Select End Time'}
            value={bookingDetails.endTime && getDate(bookingDetails.endTime)}
            setDates={(localDate, utcDate: any) => {
              setBookingDetails({...bookingDetails, endTime: utcDate})
            }}
            timezone={'UTC'}
          />
          {communityCategory === 'artist' && (
            <>
              <select
                onChange={e =>
                  setBookingDetails({
                    ...bookingDetails,
                    otherDates: e.target.value.toLowerCase() as OpenToOtherDatesChoice,
                  })
                }
                className='cDetailsModal-otherDates'>
                <option selected disabled>
                  Are you open to other dates?
                </option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
              </select>

              {errorMessage.otherDates && <p className='ErrorMessage'>{errorMessage.otherDates}</p>}

              <input
                value={bookingDetails.organizationName}
                onChange={e => setBookingDetails({...bookingDetails, organizationName: e.target.value})}
                className='PoshInput'
                placeholder='Organization Name'
              />
              {errorMessage.organizationName && <p className='ErrorMessage'>{errorMessage.organizationName}</p>}
              <input
                value={bookingDetails.venueName}
                onChange={e => setBookingDetails({...bookingDetails, venueName: e.target.value})}
                className='PoshInput'
                placeholder='Venue Name'
              />
              {errorMessage.venueName && <p className='ErrorMessage'>{errorMessage.venueName}</p>}
              <input
                value={bookingDetails.venueCapacity}
                onChange={e => setBookingDetails({...bookingDetails, venueCapacity: e.target.value})}
                className='PoshInput'
                placeholder='Venue Capacity'
              />
              {errorMessage.venueCapacity && <p className='ErrorMessage'>{errorMessage.venueCapacity}</p>}
              <input
                value={bookingDetails.averageTicketPrice}
                onChange={e => setBookingDetails({...bookingDetails, averageTicketPrice: e.target.value})}
                className='PoshInput'
                placeholder='Average Ticket Price'
              />
              {errorMessage.averageTicketPrice && <p className='ErrorMessage'>{errorMessage.averageTicketPrice}</p>}
              <input
                value={bookingDetails.approximateBudget}
                onChange={e => setBookingDetails({...bookingDetails, approximateBudget: e.target.value})}
                className='PoshInput'
                placeholder='Approximate Artist Budget'
              />
              {errorMessage.approximateBudget && <p className='ErrorMessage'>{errorMessage.approximateBudget}</p>}
            </>
          )}
          <textarea
            className='poshTextArea'
            placeholder={communityCategory === 'artist' ? 'Extra notes...' : 'Tell us about your event...'}
            value={bookingDetails.message}
            onChange={e => setBookingDetails({...bookingDetails, message: e.target.value})}
          />
          {errorMessage.message && <p className='ErrorMessage'>{errorMessage.message}</p>}
        </div>
        <Button disabled={canSendBookingRequest()} onClick={handleBookingRequest}>
          Submit Booking Request
        </Button>
        {errorMessage.booking && <p className='ErrorMessage'>{errorMessage.booking}</p>}
      </div>
    </div>
  )
}

export default CommunityBooking
