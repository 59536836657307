export type KickbackType = 'percentage' | 'flat'
export type KickbackEquation = 'perTicket' | 'perOrder'
export type EntityType = 'event' | 'group'

export const kickbackTypeOptions: {label: string; value: KickbackType}[] = [
  {label: '%', value: 'percentage'},
  {label: '$', value: 'flat'},
]

export const kickbackEquationOptions: {label: string; value: KickbackEquation}[] = [
  {label: 'Per Ticket', value: 'perTicket'},
  {label: 'Per Order', value: 'perOrder'},
]

export interface KickbackOffer {
  _id: string
  entity: string
  entityType: EntityType
  equation: KickbackEquation
  appliesTo: string
  type: KickbackType
  value: number
  enabled: boolean
  createdAt: Date
  deleted?: boolean
  pullFromPoshFee?: boolean
  limitedTo?: string[]
}
