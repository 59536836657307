import React, {useState} from 'react'

interface EntityPictureGalleryProps {
  gallery: string[]
}

const EntityPictureGallery = (props: EntityPictureGalleryProps) => {
  const {gallery} = props
  const [galleryPicture, setGalleryPicture] = useState<string>(gallery[0])

  const handleNextPhoto = () => {
    const nextIndex = gallery.indexOf(galleryPicture) + 1
    if (nextIndex < gallery.length) {
      setGalleryPicture(gallery[nextIndex])
    } else {
      setGalleryPicture(gallery[0])
    }
  }

  return (
    <>
      <img src={galleryPicture} className='cDetailsModal-cover' />
      <div className='cDetailsModal-nextButton'>
        <img src='https://assets.posh.vip/b2/next.png' onClick={() => handleNextPhoto()} />
      </div>
    </>
  )
}

export default EntityPictureGallery
