import {AccountModel} from 'apis/Accounts'
import useSessionContext from 'domains/Auth/SessionContext'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

import {setSessionContext} from './setSessionContext'

type SignupOptions = ReactQueryOptions['auth']['signup']
export type SignupInput = RouterInputs['auth']['signup']
export type SignupOutputs = RouterOutput['auth']['signup']
export type SignupOutput = Extract<SignupOutputs, {platform: 'web'}> & {account: AccountModel}

export function useSignUp(opts?: SignupOptions) {
  const sesssionContext = useSessionContext()
  return trpc.auth.signup.useMutation({
    ...opts,
    onSuccess: (data, variables, context) => {
      setSessionContext(data as SignupOutput, sesssionContext)
      opts?.onSuccess?.(data, variables, context)
    },
  })
}
