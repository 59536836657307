import React from 'react'

import {ImageApis} from 'apis/Util'
import classNames from 'classnames'

interface ImageApiSelectorProps {
  setSelectedApi: React.Dispatch<React.SetStateAction<ImageApis>>
  selectedApi: ImageApis
}

export const ImageApiSelector = (props: ImageApiSelectorProps) => {
  const {setSelectedApi, selectedApi} = props
  return (
    <div className={`ImageSearchModal-selector`}>
      <div onClick={() => setSelectedApi('giphy')} className={classNames({active: selectedApi === 'giphy'})}>
        Gifs
      </div>
      <div onClick={() => setSelectedApi('unsplash')} className={classNames({active: selectedApi === 'unsplash'})}>
        Pics
      </div>
    </div>
  )
}
