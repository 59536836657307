export interface QAPair {
  question: string
  answer: string
}

const FAQMap: QAPair[] = [
  {
    question: 'What kind of events is POSH best for?',
    answer:
      'From dinner party RSVPs to music festival tickets, our suite of tools optimize workflows and maximize sales for events of any size. POSH is mostly used to scale the following communities; festivals, nightlife groups, fashion & wellness brands, startups/VCs, community organizations, and fraternities/sororities.',
  },
  {
    question: 'How does POSH help you reach new audiences?',
    answer:
      'POSH makes it easier than ever to sell out through an ever-growing list of channels! Our most powerful tools include; Marketing CRM with Integrated SMS & Email Blasts, Kickback (Attendee-to-Affiliate Conversion Tool), and a powerful marketplace that displays your event to hundreds of thousands of event-lovers near you!',
  },
  {
    question: 'How does POSH make money?',
    answer:
      'POSH is free for event organizers! We charge attendees a processing fee of 10% + $0.99/ticket on top of the price determined by the organizer of each event. Free RSVP events do not incur fees.',
  },
  {
    question: 'How do payouts work?',
    answer:
      'Powered by Stripe, POSH is able to process 135+ global currencies. Once you sign up for POSH, you’ll go through a simple onboarding flow to select your currency and add your bank account details. POSH gives you access to your capital as soon as your first ticket sells! Choose to receive daily payouts or initiate manual payouts whenever you want.',
  },
  {
    question: 'Can I ________ on POSH?',
    answer:
      'If you have an idea or question we want to hear it. The only way we succeed, is if you do. Whether it be a new marketing tool we should add, a helpful integration, or you’re looking for advice on artist bookings, we are here to help! Email us at concierge@posh.vip to chat.',
  },
]

export default FAQMap
