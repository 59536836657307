import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useFetchOrdersAndEvents} from 'apis/Accounts/useFetchOrdersAndEvents'
import {GroupAccount} from 'apis/Groups/useGetGroupAccount'
import {Card, CardSH} from 'components/Card'
import AttendeeAlerts from 'pages/GroupPage/PageComponents/AttendeeAlerts'
import {useResourcePageParams} from 'pages/PoshAppLayout'

interface OrderCardCustomerProps {
  account: GroupAccount
}

const OrderCardCustomer = (props: OrderCardCustomerProps) => {
  const {account} = props
  const {joinedDate, avi, firstName, lastName, instagram, smsOptIn} = account
  const {domain, groupId} = useResourcePageParams()
  const navigate = useNavigate()

  const {data: orderAndEvents} = useFetchOrdersAndEvents(account._id)

  const onlyUnique = (value: string, index: number, self: string[]) => {
    return self.indexOf(value) === index
  }
  const uniqueEvents = orderAndEvents?.map(oe => oe.event?.name).filter(onlyUnique)
  const lifetimeSpend = orderAndEvents?.map(oe => oe.order?.subtotal).reduce((partialSum, a) => partialSum + a, 0)

  return (
    <Card title={'Attendee'} className='OrderPage-customerCard' animationIndex={20}>
      <img className='OrderPage-avi' src={avi} />
      <div className='OrderPage-customerCard-name'>
        <a
          className='text-xl'
          onClick={() => navigate(`/${domain}/groups/${groupId}/marketing/attendee/${account._id}`)}>
          {firstName} {lastName}
        </a>
      </div>
      <div className='OrderPage-customerCard-subtitle'>Joined POSH in {joinedDate}</div>
      <AttendeeAlerts textDisabled={!smsOptIn} />
      <CardSH title={`Group Statistics`}>
        <table className='OrderPage-customerCard-info'>
          <tr>
            <td>Events Attended</td>
            <td style={{textAlign: 'right'}}>{uniqueEvents?.length}</td>
          </tr>
          <tr>
            <td>Lifetime Spend</td>
            <td style={{textAlign: 'right'}}>${lifetimeSpend?.toFixed(2)}</td>
          </tr>
        </table>
      </CardSH>
      <CardSH title={'Contact Info'}>
        <table className='OrderPage-customerCard-info'>
          <tr>
            <td>
              <img src='https://assets.posh.vip/b2/email+(1).svg' />
            </td>
            <td>{account.email}</td>
          </tr>
          <tr>
            <td>
              <img src='https://assets.posh.vip/b2/phone-call.svg' />
            </td>
            <td>{account.phone}</td>
          </tr>
          {instagram && (
            <tr>
              <td>
                <img src='https://assets.posh.vip/b2/instagram+(3).svg' />
              </td>
              <td>
                <a target='_blank' href={`https://instagram.com/${instagram}`} rel='noreferrer'>
                  {instagram}
                </a>
              </td>
            </tr>
          )}
        </table>
      </CardSH>
    </Card>
  )
}

export default OrderCardCustomer
