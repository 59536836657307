import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'
export interface SendUnconfirmedAccountSignupCodeParams {
  phone: string
  accountId: string
}

export async function sendUnconfirmedAccountSignupCode(
  params: SendUnconfirmedAccountSignupCodeParams,
): Promise<string> {
  const response = await PoshApi.sendUnconfirmedAccountSignupCode(params)
  return response.data
}

export function useSendUnconfirmedAccountSignupCode() {
  return useMutation((params: SendUnconfirmedAccountSignupCodeParams) => sendUnconfirmedAccountSignupCode(params))
}
