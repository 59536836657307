import React from 'react'

import {PlainX} from 'components/assets/Icons'

import styles from './styles.module.scss'

const ICON_SIZE = 24

export function GalleryImage(props: {src: string; deleteImage: () => void}) {
  const {src, deleteImage} = props
  return (
    <div className={styles.GalleryImage}>
      <div className={styles.deleteButtonContainer} onClick={deleteImage}>
        <PlainX color={'white'} width={ICON_SIZE} height={ICON_SIZE} />
      </div>
      <img src={src} alt='Venue Image' />
    </div>
  )
}
