import React, {useState} from 'react'
import {CodeBlock, dracula} from 'react-code-blocks'

import {Webhook, WebhookType} from 'apis/Groups'
import {useTestWebhook} from 'apis/Groups/useTestWebhook'
import Button from 'components/form/Button'
import PoshSwitch from 'components/form/PoshSwitch'
import {useToast} from 'components/toasts/ToastProvider'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {WebhookMap} from '../Map'

interface WebhookSectionProps {
  webhook: WebhookMap
  webhooks: Webhook[]
  updateWebhookStatus: (type: WebhookType, enabled: boolean) => void
}

const WebhookSection = (props: WebhookSectionProps) => {
  const {webhook, webhooks, updateWebhookStatus} = props
  const {name, type, exampleResponse, description} = webhook
  const {mutateAsync: testWebhook, isLoading} = useTestWebhook()
  const {groupId} = useResourcePageParams()
  const {showToast} = useToast()
  const [isShowingExample, setIsShowingExample] = useState(false)

  const handleTest = async () => {
    try {
      if (!groupId) throw new Error('No group id')
      const {type} = webhook
      const response = await testWebhook({
        groupId,
        webhook: type,
      })
      return showToast({type: 'success', title: response})
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              <h4 className='noMargin'>{name}</h4>
              <p>{description}</p>
            </td>
          </tr>
          <tr>
            <td>
              <PoshSwitch
                className='flex'
                title='Enable Webhook'
                switchOptions={{
                  checked: webhooks.find(w => w.type === type)?.enabled ?? false,
                  onChange: checked => updateWebhookStatus(type, checked),
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <Button disabled={isLoading} isLoading={isLoading} className='dark light' onClick={handleTest}>
                Test Webhook
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      <div className='Webhooks-example'>
        <div
          className={`Webhooks-example-dropdown ${isShowingExample ? 'deployed' : ''}`}
          onClick={() => setIsShowingExample(!isShowingExample)}>
          View Example Response Body
          <img src='https://assets.posh.vip/b2/right-arrow-secondary-triangle.svg' />
        </div>
        {isShowingExample && (
          <CodeBlock text={exampleResponse} language={'javascript'} theme={dracula} showLineNumbers={false} />
        )}
      </div>
      <hr />
    </>
  )
}

export default WebhookSection
