import React, {useEffect} from 'react'
import Loader from 'react-loader-spinner'

import {rightArrow} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {TextFieldVariants} from 'components/form/TextField'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'
import CheckoutField from 'pages/NewCheckoutPage/CheckoutField/CheckoutField'
import CustomCheckoutFields from 'pages/NewCheckoutPage/CustomCheckoutFields'

export interface EmailLoginFormFirstStepProps {
  email?: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  handleEmailCheckClick: () => Promise<void>
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => Promise<void>
  isValidatingEmail: boolean
}

export const EmailLoginFormFirstStep = (props: EmailLoginFormFirstStepProps) => {
  const {email, setEmail, handleEmailCheckClick, onKeyDown, isValidatingEmail} = props
  const {cartFormData, isRSVP} = useLiveCartContext()

  const hideButton = !email || isRSVP

  const className = isRSVP
    ? 'RSVPModal-Section'
    : `CheckoutPage-Section${hideButton ? '-WithoutButton' : '-WithButton'}`
  useEffect(() => {
    setEmail(cartFormData['email']?.value ?? '')
  }, [cartFormData, setEmail])

  return (
    <div className={className}>
      <CheckoutField
        field_type={'input'}
        field_key={'email'}
        value={cartFormData['email']?.value}
        variant={TextFieldVariants.DARK}
        placeholder={'Enter your email to purchase!'}
        nativeProps={{
          onKeyDown: e => onKeyDown(e),
          autoFocus: true,
          placeholder: 'Enter your email...',
          className: 'CheckoutLargeTypeInput',
        }}
        isCustomField={false}
      />
      {isRSVP && <CustomCheckoutFields />}
      {isRSVP ? (
        <Button
          className='fit center'
          onClick={handleEmailCheckClick}
          disabled={isValidatingEmail}
          isLoading={isValidatingEmail}>
          Continue
        </Button>
      ) : (
        <button disabled={isValidatingEmail} onClick={handleEmailCheckClick}>
          {isValidatingEmail ? (
            <Loader type='TailSpin' color='black' height={15} width={15} />
          ) : (
            <img src={rightArrow} />
          )}
        </button>
      )}
    </div>
  )
}
