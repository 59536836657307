import React, {useEffect, useState} from 'react'

import {getGroupAvi} from '@posh/model-types'
import {DEFAULT_ACCENT_COLOR} from '@posh/types'
import {AccountModel, GroupAccountRoleUnion} from 'apis/Accounts'
import {useFetchAccountGroups} from 'apis/Accounts/useFetchAccountGroups'
import {useCreateGroup} from 'apis/Groups/useCreateGroup'
import {useMixpanel} from 'apis/MixPanelHandler'
import {useDEPRECATEDConfirmPendingEvent} from 'apis/PendingEvent/useConfirmPendingEvent'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PlainTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput/Text'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import {usePendingEventPageParams} from 'pages/PoshAppLayout'

import '../styles.scss'

// TODO: Remove this component after the new create event is fully implemented
export const DEPRECATEDGroupSelector = () => {
  const {currentUser} = useSessionContext()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {data: accountGroupUnions, isFetching, refetch: refetchAccountGroups} = useFetchAccountGroups()
  const {mutateAsync: confirmPendingEvent, isLoading: isConfirmingPendingEvent} = useDEPRECATEDConfirmPendingEvent()
  const {mutateAsync: createGroup, isLoading: isCreatingGroup} = useCreateGroup({
    onError: error => {
      showToast({type: 'error', title: error.message ?? 'Something went wrong, please refresh and try again'})
    },
  })
  const {pendingEventId} = usePendingEventPageParams()
  const {showToast} = useToast()
  const [selectedGroupId, setSelectedGroupId] = useState('')
  const [groupsToDisplay, setGroupsToDisplay] = useState<GroupAccountRoleUnion[]>()
  const showLoader = isFetching || isConfirmingPendingEvent || isCreatingGroup

  const handleCreateGroupAndGoLive = async () => {
    if (!pendingEventId) return showToast({type: 'error', title: 'No event id'})
    if (!currentUser) return showToast({type: 'error', title: 'Please refresh the page and try again'})
    const {groupId} = await createGroup({
      groupName: `Events by ${currentUser.firstName}`,
      organizationType: 'personal',
      pendingEventId,
    })
    await handleGoLive(groupId)
  }

  const handleGoLive = async (groupId?: string) => {
    if (!pendingEventId) {
      showToast({type: 'error', title: 'No event id'})
      return
    }
    if (!currentUser) {
      showToast({type: 'error', title: 'Please refresh the page and try again'})
      return
    }
    if (!selectedGroupId && !groupId) {
      showToast({type: 'error', title: 'Please select a group'})
      return
    }
    try {
      const eventUrl = await confirmPendingEvent({
        pendingEventId,
        groupId: selectedGroupId.length > 0 ? selectedGroupId : groupId!,
      })
      refetchAccountGroups()
      window.location.href = eventUrl
      return
    } catch (error) {
      showToast({type: 'error', title: error.response.data.error})
    }
  }

  useEffect(() => {
    if (isFetching) return
    const createDefaultGroup = async () => await handleCreateGroupAndGoLive()
    const applicableRoles = accountGroupUnions?.filter(
      group => group.accountRole.roleKey === 'owner' || group.accountRole.roleKey === 'admin',
    )
    if (accountGroupUnions && applicableRoles && applicableRoles.length > 0) setGroupsToDisplay(applicableRoles)
    else createDefaultGroup()
  }, [isFetching])

  return (
    <div className='GroupSelector'>
      {showLoader ? (
        <SpinLoader height={60} />
      ) : (
        <>
          {groupsToDisplay && (
            <>
              <h3>Select Organization to Go Live</h3>
              <div className='GroupSelector-groups'>
                {groupsToDisplay?.map(g => (
                  <div
                    className={`GroupSelector-group ${selectedGroupId === g.group._id ? 'selected' : ''}`}
                    key={g.group._id}
                    onClick={() => setSelectedGroupId(g.group._id)}>
                    <img src={getGroupAvi(g.group)} />
                    <p>{g.group.name}</p>
                  </div>
                ))}
              </div>
              <Button
                className='createEventGrad'
                onClick={() => {
                  trackMixpanelEvent(
                    'Create Event Group Selector Go Live-  Group Selector Go Live last stage for existing users',
                  )
                  handleGoLive()
                }}
                disabled={!selectedGroupId}>
                Go Live!
              </Button>
            </>
          )}
        </>
      )}
    </div>
  )
}

interface CreateEventGroupSelectorParams {
  currentUser: AccountModel
  pendingEventId: string
  setSelectedGroupId: (groupId: string) => void
}

export const CreateEventGroupSelector = (params: CreateEventGroupSelectorParams) => {
  const {currentUser, pendingEventId, setSelectedGroupId} = params
  const {data: accountGroupUnions, isFetching, refetch: refetchAccountGroups} = useFetchAccountGroups()
  const {trackEvent} = useMixpanel()
  const {mutateAsync: createGroup, isLoading: isCreatingGroup} = useCreateGroup({
    onError: error => {
      showToast({type: 'error', title: 'Something went wrong, please refresh and try again', subtitle: error.message})
    },
    onSuccess: data => {
      setSelectedGroupId(data.groupId)
      refetchAccountGroups()
    },
  })
  const {showToast} = useToast()
  const [locallySelectedGroupId, setLocallySelectedGroupId] = useState<string | undefined>(undefined)
  const [newGroupName, setNewGroupName] = useState<string | undefined>(undefined)
  const [groupsToDisplay, setGroupsToDisplay] = useState<GroupAccountRoleUnion[]>()
  const showLoader = isFetching

  const handleCreateGroupAndOpenLaunchModal = async () => {
    trackEvent('Create Event - Create Org continue clicked')
    await createGroup({
      groupName: newGroupName,
      organizationType: 'personal',
      pendingEventId,
    })
  }

  const showLaunchModal = () => {
    trackEvent('Create Event - Existing org continue clicked')
    if (!locallySelectedGroupId) return showToast({type: 'error', title: 'Please select a group'})
    setSelectedGroupId(locallySelectedGroupId)
  }

  useEffect(() => {
    if (isFetching) return
    const applicableRoles = accountGroupUnions?.filter(
      group => group.accountRole.roleKey === 'owner' || group.accountRole.roleKey === 'admin',
    )
    if (accountGroupUnions && applicableRoles && applicableRoles.length > 0) setGroupsToDisplay(applicableRoles)
  }, [isFetching])

  return (
    <div className='GroupSelector'>
      {showLoader ? (
        <SpinLoader height={40} color={DEFAULT_ACCENT_COLOR} />
      ) : (
        <>
          {groupsToDisplay ? (
            <>
              <h4>Select an organization to go live</h4>
              <div className='GroupSelector-groups'>
                {groupsToDisplay?.map(g => (
                  <div
                    className={`GroupSelector-group ${locallySelectedGroupId === g.group._id ? 'selected' : ''}`}
                    key={g.group._id}
                    onClick={() => setLocallySelectedGroupId(g.group._id)}>
                    <img src={getGroupAvi(g.group)} />
                    <p>{g.group.name}</p>
                  </div>
                ))}
              </div>
              <Button className='createEventGrad' onClick={showLaunchModal} disabled={!locallySelectedGroupId}>
                Continue
              </Button>
            </>
          ) : (
            <>
              <h4>Name your organization to continue</h4>
              <p>
                Your organization is the brand or group that is hosting your event. You can change your organization
                name later.
              </p>
              <PlainTextInput
                lightMode={false}
                autoFocus
                accentColor={DEFAULT_ACCENT_COLOR}
                type='text'
                placeholder={`Events by ${currentUser.firstName}`}
                value={newGroupName}
                onChange={e => setNewGroupName(e.target.value)}
              />
              <Button
                isLoading={isCreatingGroup}
                className='createEventGrad'
                onClick={handleCreateGroupAndOpenLaunchModal}
                disabled={!newGroupName}>
                Continue
              </Button>
            </>
          )}
        </>
      )}
    </div>
  )
}
