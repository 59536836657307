import {useMutation} from '@tanstack/react-query'
import {AuthResponse} from 'apis/Auth'
import {setSessionContext} from 'apis/Auth/setSessionContext'
import PoshApi from 'apis/PoshApi'
import useSessionContext from 'domains/Auth/SessionContext'

export interface ConfirmAndAuthenticateAccountParams {
  accountId: string
  smsOptIn: boolean
  verificationCode?: string
  password?: string
  confirmPassword?: string
  avi?: string
  email?: string
}

interface ConfirmAndAuthenticateAccountResponse extends AuthResponse {
  redirectLink: string
}

const confirmAndAuthenticateAccount = async (
  params: ConfirmAndAuthenticateAccountParams,
): Promise<ConfirmAndAuthenticateAccountResponse> => {
  const response = await PoshApi.confirmAndAuthenticateAccount(params)
  const data: ConfirmAndAuthenticateAccountResponse = response.data
  return data
}

export function useConfirmAndAuthenticateAccount() {
  const sessionContext = useSessionContext()
  return useMutation<ConfirmAndAuthenticateAccountResponse, unknown, ConfirmAndAuthenticateAccountParams>(
    ['confirmAccountData'],
    params => confirmAndAuthenticateAccount(params),
    {
      onSuccess: data => setSessionContext(data, sessionContext),
    },
  )
}
