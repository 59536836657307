import React from 'react'

import {useCopyLink} from 'hooks/useCopyLink'

import './styles.scss'

interface HeaderCopyBlockProps {
  text: string
}

const HeaderCopyBlock = (props: HeaderCopyBlockProps) => {
  const {text} = props
  const {copyLink, justCopied} = useCopyLink()

  return (
    <div className='HeaderCopyBlock' onClick={() => copyLink(text)}>
      <h4 className='center'>
        {justCopied ? <span>Copied!</span> : <span className='HeaderCopyBlock-link'>{text}</span>}
      </h4>
      <img src='https://assets.posh.vip/b2/clipboard.svg'></img>
    </div>
  )
}

export default HeaderCopyBlock
