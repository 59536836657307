import React from 'react'
import {useNavigate} from 'react-router-dom'

import {Venue} from 'apis/Community'
import useUpdateCommunityClicks from 'apis/Community/useUpdateCommunityClicks'
import {useMixpanel} from 'apis/MixPanelHandler'
import useSessionContext from 'domains/Auth/SessionContext'

interface VenuesProps {
  venues: Venue[]
}

const Venues = (props: VenuesProps) => {
  const {venues} = props
  const {mutateAsync: updateCommunityClicks} = useUpdateCommunityClicks()
  const {currentUser} = useSessionContext()
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const navigateToVenue = async (url: string) => {
    const venue = venues?.find(v => v.url === url)
    if (!venue) return
    await updateCommunityClicks({id: venue._id, category: 'venue'})
    const accountEmail = currentUser?.email
    const accountId = currentUser?._id
    const accountName = currentUser?.firstName + ' ' + currentUser?.lastName
    trackMixpanelEvent('Community Profile Clicked', {
      communityCategory: 'venue',
      communityName: venue.name,
      accountEmail,
      accountId,
      accountName,
    })
    navigate(`/c/venue/${url}`)
  }

  const shortenDescription = (description: string) => {
    if (description && description.length > 115) {
      return description.slice(0, 115) + '...'
    }
  }

  return (
    <div className='Community-catHolder venue'>
      {venues?.map(venue => (
        <>
          <div
            key={venue._id}
            className='Community-card'
            onClick={() => navigateToVenue(venue.url)}
            style={{backgroundImage: `url('${venue.galleryImages[0].url}')`}}>
            <div className='Community-card-filter' />
            <div className='Community-card-info'>
              <div className='Community-card-info-title'>{venue.name}</div>
              <div className='Community-card-info-desc'>
                {venue.description && venue.description.length > 115
                  ? shortenDescription(venue.description)
                  : venue.description}
              </div>
              <div className='Community-card-info-stats'>
                {venue.baseBuyout && (
                  <div>
                    <img src='https://assets.posh.vip/b2/community/price-tag.svg' />
                    <span>{`$${venue.baseBuyout?.toLocaleString('en-US')} Buyout`}</span>
                  </div>
                )}
                <div>
                  <img src='https://assets.posh.vip/b2/community/people.svg' />
                  <span>{`${venue.maxCapacity} Capacity`}</span>
                </div>
                <div className='Community-card-info-clarifier'>{venue.address}</div>
              </div>
            </div>
            <div className='Community-card-type'>
              {venue.tags && venue.tags[0] && venue.tags[0].value ? venue.tags[0].value : null}
            </div>
          </div>
        </>
      ))}
    </div>
  )
}

export default Venues
