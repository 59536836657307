import React from 'react'

import {Ticket} from 'apis/Events/types'
import useCartContext from 'domains/Cart/CartContext'

import {FilledMinus, FilledPlus} from '../../components/assets/Icons'

import './styles.scss'
// import {getTicketsAvailable as getTicketsLeftParent} from './TicketActionRow'

interface TicketCountInputProps {
  ticket: Ticket
  iconColor: string
  colorModeModifier: string
}

const TicketCountInput = ({ticket, iconColor, colorModeModifier}: TicketCountInputProps) => {
  const {ticketsCount, removeTicket, addTicket, handleSetTicketCount} = useCartContext()

  const ticketCount = ticketsCount[ticket.id]
  const ticketsLeft = ticket.quantityAvailable

  return (
    <div className='EventPage-ticketCounterInput'>
      <button
        className='EventPage-button'
        onClick={() => removeTicket(ticket)}
        style={{boxShadow: `0 1px 12px -2px ${iconColor}`}}>
        <FilledMinus className='EventPage-filledIcon' style={{fill: iconColor}} />
      </button>
      <input
        type='number'
        value={ticketCount.quantity}
        className={'EventPage-ticketCounterInput-input ' + colorModeModifier}
        onChange={e => {
          if (ticketsLeft && (e.target.value as unknown as number) < ticketsLeft)
            handleSetTicketCount(ticket, parseInt(e.target.value))
        }}
      />
      <button
        className='EventPage-button'
        onClick={() => {
          const count = ticketsCount[ticket.id] ? ticketsCount[ticket.id].quantity : ticket.purchaseMin ?? 0
          if (ticketsLeft && count < ticketsLeft) addTicket(ticket)
        }}
        style={{boxShadow: `0 1px 12px -2px ${iconColor}`}}>
        <FilledPlus className='EventPage-filledIcon' style={{fill: iconColor}} />
      </button>
    </div>
  )
}

export default TicketCountInput
