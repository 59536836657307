import React from 'react'

import Button from 'components/form/Button'

import {HomePageComponentProps} from '../interface'

import './styles.scss'

const LandingPageEventCard = (props: HomePageComponentProps) => {
  const {changePage} = props

  const guests = [
    'https://assets.posh.vip/b1/attendee-avi/61eda3a8f221270033bd4b44.jpg',
    'https://assets.posh.vip/b1/attendee-avi/62213ef99c5a9e00343b9b28.jpg',
    'https://assets.posh.vip/b1/attendee-avi/625d576de7e5b100336de769.jpg',
    'https://assets.posh.vip/b1/attendee-avi/62928a325972b4003343755e.jpg',
    'https://assets.posh.vip/b1/attendee-avi/629b175e08fbbc003351fba6.jpg',
    'https://images.posh.vip/images/069b85a7-6036-410f-b311-01d6ee53dd31.jpg',
  ]

  return (
    <div className='LandingPageEventCardHolder'>
      <div className='LandingPageEventCardHolder-iconA'>
        <img src='https://assets.posh.vip/b2/chrome-horn-kickback.png' />
      </div>

      <div className='LandingPageEventCard'>
        <img src='https://assets.posh.vip/b2/lp-party.jpg' />
        <h3>Emma&apos;s Fashion Week Party</h3>
        <div className='LandingPageEventCard-previewList'>
          <div className='LandingPageEventCard-previewList-guests'>
            {guests.map((guest, index) => (
              <img key={index} src={guest} />
            ))}
          </div>
          <p className='EventPage-totalAttending'>987+ Going</p>
        </div>
        <div className='buttonWrapper'>
          <Button className='LandingPageEventCard-btn' onClick={() => changePage('/explore')}>
            Purchase Tickets
          </Button>
          <Button className='LandingPageEventCard-btn kickbackBtn'>Earn Rewards</Button>
        </div>
      </div>

      <div className='LandingPageEventCardHolder-iconB'>
        <img src='https://assets.posh.vip/b2/chrome51-blocks-kickback.png' />
      </div>

      <div className='LandingPageEventCardHolder-iconC'>
        <img src='https://assets.posh.vip/b2/chrome-spiral-kickback.png' />
      </div>
    </div>
  )
}

export default LandingPageEventCard
