import React from 'react'
import {useNavigate} from 'react-router-dom'

const BackAndTitle = ({
  title,
  children,
  center,
  columnMobile,
  onNavigate,
}: {
  title?: string
  children?: JSX.Element
  center?: boolean
  columnMobile?: boolean
  onNavigate?: () => void
}) => {
  const navigate = useNavigate()

  return (
    <>
      <div
        className={`GroupPage-BackAndTitle ${center ? 'center' : ''} ${columnMobile ? 'columnMobile' : ''}`}
        style={{width: title ? '100%' : '50px', marginBottom: title ? '15px' : '0'}}>
        <img
          src='https://assets.posh.vip/b2/left-arrow-in-circular-button-black-symbol.svg'
          onClick={() => (onNavigate ? onNavigate() : navigate(-1))}
        />
        {title && <h2>{title}</h2>}
        {children && <div className='GroupPage-BackAndTitle-action'>{children}</div>}
      </div>
    </>
  )
}

export default BackAndTitle
