import React, {useEffect} from 'react'
import {DefaultValues, FieldValues, useForm, UseFormReturn} from 'react-hook-form'

import {zodResolver} from '@hookform/resolvers/zod'
import Button from 'components/form/Button'
import {ZodSchema} from 'zod'

import styles from './FormBase.module.scss'

export interface FormBaseProps<T extends FieldValues> {
  children: (form: UseFormReturn<T>) => JSX.Element
  defaultValues?: DefaultValues<T>
  schema: ZodSchema<T>
  onSubmit: (data: T) => void
  onCancel: () => void
  isSubmitting?: boolean
  submitText?: string
}

export const FormBase = <T extends FieldValues>(props: FormBaseProps<T>) => {
  const {children, defaultValues, schema, onSubmit, onCancel, isSubmitting = false, submitText = 'Submit'} = props

  const form = useForm<T>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: 'onTouched',
  })

  const {
    formState: {isDirty, isSubmitSuccessful, defaultValues: formDefaultValues},
    reset,
    handleSubmit,
  } = form

  const onClickCancel = () => {
    reset(undefined)
    onCancel()
  }

  useEffect(() => {
    if (formDefaultValues !== defaultValues) {
      reset(defaultValues, {keepValues: true, keepDirty: false, keepDefaultValues: false})
    }
  }, [defaultValues, isSubmitSuccessful, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.Form}>
      {children(form)}
      <div className={styles.FormSubmitRow}>
        <Button className='dark' onClick={onClickCancel}>
          Cancel
        </Button>
        <Button type='submit' className='gold' disabled={!isDirty || isSubmitting} isLoading={isSubmitting}>
          {submitText}
        </Button>
      </div>
    </form>
  )
}
