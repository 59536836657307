import React from 'react'

import classNames from 'classnames'

import {TableIcon, TTableIcon} from '../TableIcons/TableIcon'

import styles from './TableButton.module.scss'

export interface TableButtonProps {
  text: string
  disabled?: boolean
  onClick: () => void
  icon?: TTableIcon
}

const DEFAULT_ICON_SIZE = 16

export const TableButton = (props: TableButtonProps) => {
  const {text, disabled = false, icon, onClick} = props
  return (
    <button
      className={classNames(styles.TableButton, {[styles.disabled]: disabled})}
      disabled={disabled}
      onClick={onClick}>
      {icon && <TableIcon name={icon} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />} {text}
    </button>
  )
}
