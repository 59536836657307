import React from 'react'

import {DetailEntity} from '..'

import './styles.scss'

const CommunityStaffModal = ({
  selectedDetailEntity,
  setDetailsModalOpen,
}: {
  selectedDetailEntity: DetailEntity
  setDetailsModalOpen: (value: React.SetStateAction<boolean>) => void
}) => {
  return (
    <div className='StaffModal'>
      <img className='StaffModal-avi' src={selectedDetailEntity.image} />
      <div className='StaffModal-info'>
        <div className='StaffModal-name'>{selectedDetailEntity.title}</div>
        <div className='StaffModal-type'>Photographer</div>
        <div className='StaffModal-location'>
          <img src='https://assets.posh.vip/b2/maps-and-flags.svg' />
          {selectedDetailEntity.clarifier}
        </div>
      </div>
      <button className='StaffModal-view'>View Profile</button>
      <div className='StaffModal-portfolio'>
        <img src='https://i.imgur.com/Qfz5rO5.png' />
        <img src='https://i.imgur.com/ISL956A.png' />
      </div>
      <img
        src='https://assets.posh.vip/b2/cancel.svg'
        className='StaffModal-x'
        onClick={() => setDetailsModalOpen(false)}
      />
    </div>
  )
}

export default CommunityStaffModal
