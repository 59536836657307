import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {ImageApis} from '.'

export interface ImageSearchParams {
  selectedApi: ImageApis
  search?: string
}

export interface ImageSearchResponse {
  image: string
  photographer?: string
  photographerUrl?: string
  downloadUrl?: string
}

const imageSearch = async (params: ImageSearchParams) => {
  const response = await PoshApi.imageSearch(params)
  const images = response.data.images
  return images
}

export function useImageSearch() {
  return useMutation<ImageSearchResponse[], any, ImageSearchParams>(params => imageSearch(params))
}
