import React, {ButtonHTMLAttributes, useEffect, useState} from 'react'
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'

import useFetchEvent from '../../../apis/Events/useFetchEvent'
import {editPencilUrl} from '../../../components/assets/Icons'
import Button from '../../../components/form/Button'
import {PoshHelmet} from '../../../components/PoshHelmet/PoshHelmet'
import {useResourcePageParams} from '../../PoshAppLayout'
import EditTable from './Edit'
import InteractiveVenueMap from './TableMap/InteractiveVenueMap'

const EDIT_TABLE_PATH = `edit`
const SCAN_TABLES_PATH = `scan`
const SELECT_TABLE_MAP_PATH = `create`

import {EventTable, VenueMap} from 'apis/Events/types'
import PoshMarkdownEditor from 'components/form/PoshMarkdownEditor'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {isUndefined} from 'lodash'
import TableFilter from 'pages/EventPage/TableFilter'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

import useDeleteEventTable from '../../../apis/Events/useDeleteEventTable'
import {
  UpdateEventAttributes_DEPRECATED,
  useUpdateEvent_DEPRECATED,
} from '../../../apis/Events/useUpdateEventDEPRECATED'
import {EditEventTableParams, useUpdateEventTable} from '../../../apis/Events/useUpdateEventTable'
import AdmissionsPage from '../../HomePage/AdmissionsPage'
import SaveDiscardBottomBar from '../SaveDiscardBottomBar'

import './styles.scss'

const PencilIcon = () => <img className='PoshTables-pencilIcon' src={editPencilUrl} />

interface CreateTablesButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isCreatingTables: boolean
}
const CreateTablesButton = ({isCreatingTables, onClick}: CreateTablesButtonProps) => {
  return (
    <Button onClick={onClick} className='light gold'>
      {isCreatingTables ? (
        'Stop Creating'
      ) : (
        <>
          <PencilIcon />
          Create Tables
        </>
      )}
    </Button>
  )
}

interface DeleteTablesButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isDeletingTables: boolean
}
const DeleteTablesButton = ({isDeletingTables, onClick}: DeleteTablesButtonProps) => {
  return (
    <Button onClick={onClick} className={`light dark`}>
      {isDeletingTables ? 'Done' : 'Remove tables'}
    </Button>
  )
}

const VenueMapEmptyState = () => (
  <p className='Tables-emptyStateText'>This venue does not have a venue map, try creating / selecting one!</p>
)

export default function TablesLayout() {
  const navigate = useNavigate()
  const {eventId, domain, groupId} = useResourcePageParams()
  const {data: eventResponse, isFetching: eventIsFetching, refetch: refetchEvent} = useFetchEvent(eventId!)
  const {mutateAsync: updateEventTable} = useUpdateEventTable()
  const {mutateAsync: deleteEventTable} = useDeleteEventTable()
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()

  const isLoading = eventIsFetching
  const {search, pathname} = useLocation()

  const [tableDescription, setTableDescription] = useState<string | undefined>()
  const [presentChangesOnTable, setPresentChangesOnTable] = useState(false)
  const [isCreatingTables, setIsCreatingTables] = useState(false)
  const [isDeletingTables, setIsDeletingTables] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [tablesToDisplay, setTablesToDisplay] = useState<EventTable[]>([])
  const [changesOnTables, setChangesOnTables] = useState<EditEventTableParams[]>([])
  const [selectedTables, setSelectedTables] = useState<EventTable[] | null>(null)

  const [venueMap, setVenueMap] = useState<VenueMap | undefined>(undefined)

  const venue = eventResponse?.venue
  const tableFilterKeys = eventResponse?.tableFilterKeys

  useEffect(() => {
    if (venue) {
      setVenueMap(venue!.venueMap)
    }
  }, [venue])

  useEffect(() => {
    const noChangesToTable = isEmpty(changesOnTables)
    if (noChangesToTable) setPresentChangesOnTable(false)
    else setPresentChangesOnTable(true)
  }, [changesOnTables, tableDescription])

  useEffect(() => {
    console.log('selectedTables', selectedTables)
  }, [selectedTables])

  useEffect(() => {
    if (selectedTables) {
      navigate(`/${domain}/groups/${groupId}/events/${eventId}/tables/edit` + search)
    }
  }, [domain, eventId, groupId, history, selectedTables])

  useEffect(() => {
    if (eventResponse && eventResponse.event.tables && eventResponse.event.tables.length > 0) {
      const tables = eventResponse.event.tables.filter(table => table.filterKey === selectedFilter)
      if (tables.length > 0) setTablesToDisplay(tables)
      else if (selectedFilter === 'Both' && eventResponse.tableFilterKeys) {
        const firstKey = eventResponse.tableFilterKeys[0]
        const secondKey = eventResponse.tableFilterKeys[1]
        if (firstKey && secondKey) {
          const tablesWithFirstKey = eventResponse.event.tables.filter(table => table.filterKey === firstKey)
          const tablesWithSecondKey = eventResponse.event.tables.filter(table => table.filterKey === secondKey)
          const tablesWithSameMapPosition: EventTable[] = []
          tablesWithFirstKey.forEach(t => {
            tablesWithSecondKey.forEach(s => {
              if (
                t.mapPosition &&
                s.mapPosition &&
                t.mapPosition[0] === s.mapPosition[0] &&
                t.mapPosition[1] === s.mapPosition[1]
              )
                tablesWithSameMapPosition.push(t, s)
            })
          })
          setTablesToDisplay(tablesWithSameMapPosition)
        }
      } else setTablesToDisplay(eventResponse.event.tables)
    }
  }, [selectedFilter, eventResponse])

  useEffect(() => {
    if (eventResponse && eventResponse.event.tables && eventResponse.event.tables.length > 0) {
      setSelectedFilter('')
      if (
        eventResponse.tableFilterKeys &&
        eventResponse.tableFilterKeys.length > 0 &&
        !isUndefined(eventResponse.tableFilterKeys)
      ) {
        const filterToSet = selectedFilter !== '' ? selectedFilter : eventResponse.tableFilterKeys[0]
        setSelectedFilter(filterToSet)
      } else setTablesToDisplay(eventResponse.event.tables)
    }
  }, [eventResponse])

  const onCreateTablesClicked = () => {
    setIsCreatingTables(!isCreatingTables)
  }

  const onDeleteTablesClicked = () => {
    setIsDeletingTables(!isDeletingTables)
  }

  const onScanTablesClicked = () => {
    navigate(`/${domain}/groups/${groupId}/events/${eventId}/tables/scan`)
  }

  const resetSelectedTables = () => {
    setTableDescription(undefined)
    setChangesOnTables([])
    setSelectedTables(null)
  }

  const saveUpdatedTableChanges = async () => {
    if (!selectedTables) return
    const tableUpdatePromises = selectedTables.map(async (table, index) => {
      await updateEventTable({
        eventId: eventResponse!.event!._id,
        tableId: table!._id,
        tableParams: changesOnTables[index],
      })
    })
    return await Promise.all(tableUpdatePromises)
  }

  const onSaveClicked = async () => {
    if (tableDescription && !presentChangesOnTable) {
      const updateEventAttrs: UpdateEventAttributes_DEPRECATED = {
        tableDescription: tableDescription,
      }
      await updateEvent({eventId: eventId!, groupId: groupId!, eventData: updateEventAttrs})
      await refetchEvent()
      resetSelectedTables()
    } else if (presentChangesOnTable && !tableDescription) {
      await saveUpdatedTableChanges()
      await refetchEvent()
      resetSelectedTables()
      navigate(-1)
    } else if (tableDescription && presentChangesOnTable) {
      const updateEventAttrs: UpdateEventAttributes_DEPRECATED = {
        tableDescription: tableDescription,
      }
      await updateEvent({eventId: eventId!, groupId: groupId!, eventData: updateEventAttrs})
      await saveUpdatedTableChanges()
      await refetchEvent()
      resetSelectedTables()
      navigate(-1)
    }
  }

  const onDiscardClicked = () => {
    if (presentChangesOnTable) {
      resetSelectedTables()
    } else if (isCreatingTables) {
      setIsCreatingTables(false)
    }
  }

  const onTableClick = async (tables: EventTable[]) => {
    if (isDeletingTables) {
      const deleteTablePromies = tables.map(async t => {
        return await deleteEventTable({eventId: eventId!, tableId: t._id})
      })
      await Promise.all(deleteTablePromies)
      await refetchEvent()
    } else setSelectedTables(tables)
  }

  const TableActions = () => (
    <div className='PoshTables-actions'>
      <div className='PoshTables-actionButtons'>
        <DeleteTablesButton isDeletingTables={isDeletingTables} onClick={onDeleteTablesClicked} />
        <CreateTablesButton isCreatingTables={isCreatingTables} onClick={onCreateTablesClicked} />
      </div>
    </div>
  )

  return (
    <>
      <PoshHelmet title={`Tables - ${eventResponse?.event.name}`} />
      {venueMap && <TableActions />}
      <Routes>
        <Route path={'*'} element={<PageNotFound />} />
        <Route path={SCAN_TABLES_PATH} element={<AdmissionsPage />} />
        <Route
          path={EDIT_TABLE_PATH}
          element={
            <>
              {selectedTables && (
                <EditTable
                  tableParams={isEmpty(changesOnTables) ? selectedTables : changesOnTables}
                  setTableParams={setChangesOnTables}
                  tables={selectedTables}
                />
              )}
            </>
          }
        />
        <Route
          path=''
          element={
            <>
              {isLoading ? (
                <SpinLoader />
              ) : (
                <>
                  <p>Table Section Description:</p>
                  <PoshMarkdownEditor
                    value={tableDescription ?? eventResponse?.event?.tableDescription}
                    onChange={e => setTableDescription(e)}
                  />
                  {tableFilterKeys && (
                    <TableFilter
                      selectedFilter={selectedFilter}
                      setSelectedFilter={setSelectedFilter}
                      isLightMode={false}
                      accentColor={'#fc0'}
                      filterKeys={tableFilterKeys}
                    />
                  )}
                  {venueMap ? (
                    <InteractiveVenueMap
                      tables={tablesToDisplay}
                      venueMap={venueMap}
                      decorations={venue?.decorations ?? []}
                      isLightMode={false}
                      onTableClick={onTableClick}
                      isEditing={isCreatingTables}
                      isDeleting={isDeletingTables}
                      isEditable
                      selectedFilterKey={selectedFilter}
                    />
                  ) : (
                    <VenueMapEmptyState />
                  )}
                </>
              )}
            </>
          }
        />
      </Routes>
      <SaveDiscardBottomBar
        onDiscard={onDiscardClicked}
        onSave={onSaveClicked}
        isOpen={presentChangesOnTable || !isEmpty(tableDescription)}
      />
    </>
  )
}
