import React from 'react'

import '../../styles.scss'

const PaymentPlanInfo = () => (
  <div className='CheckoutPage-Section'>
    <h5>Payment Plans</h5>
    <div className='PaymentPlans-Info'>
      <p className='text-small regularMargin'>
        POSH Payment Plans are available for this order. You can pay in installments so that it&apos;s easier to afford!
        🎉
      </p>
      <p className='text-small regularMargin'>
        <i>Price Shown Includes All Fees</i>
      </p>
      <p className='text-small regularMargin'>If you&apos;d like to use a payment plan, select one below.</p>
    </div>
  </div>
)

export default PaymentPlanInfo
