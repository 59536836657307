import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {grayCircularCloseUrl, whiteCircularAddUrl, whiteCircularPencilUrl} from 'components/assets/Icons'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

import {VisualEditorPage} from './Upgrade-dev'

export const AddIcon = () => <img className='Visuals-icon' src={whiteCircularAddUrl} />
export const EditIcon = () => <img className='Visuals-icon' src={whiteCircularPencilUrl} />
export const CloseIcon = () => <img className='Visuals-icon' src={grayCircularCloseUrl} />

const Visuals = () => {
  return (
    <Routes>
      {/* Note: the name of this route controls showing the header in EventRoutes.tsx. Do not change it without changing it there, too */}
      <Route path={''} element={<VisualEditorPage />} />
      <Route path={'*'} element={<PageNotFound />} />
    </Routes>
  )
}

export default Visuals
