// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YoutubeVideo__ghm53{display:flex;align-items:center;margin:5px 0;gap:15px;cursor:pointer;transition:.2s}.YoutubeVideo__ghm53:hover{background-color:rgba(255,255,255,.0509803922)}.YoutubeVideo__ghm53 img{width:90px;height:50px;object-fit:cover;border-radius:5px}", "",{"version":3,"sources":["webpack://./src/components/PageComponents/EventVisuals/Form/Input/YoutubeSearchInput/styles.module.scss"],"names":[],"mappings":"AAAA,qBACE,YAAA,CACA,kBAAA,CACA,YAAA,CACA,QAAA,CACA,cAAA,CACA,cAAA,CACA,2BACE,8CAAA,CAEF,yBACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".YoutubeVideo { \n  display: flex;\n  align-items: center;\n  margin: 5px 0;\n  gap: 15px;\n  cursor: pointer;\n  transition: 0.2s;\n  &:hover {\n    background-color: #ffffff0d;\n  }\n  img {\n    width: 90px;\n    height: 50px;\n    object-fit: cover;\n    border-radius: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"YoutubeVideo": "YoutubeVideo__ghm53"
};
export default ___CSS_LOADER_EXPORT___;
