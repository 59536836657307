import React, {useState} from 'react'

import {useLoginWithEmail} from 'apis/Auth/useLoginWithEmail'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {useToast} from 'components/toasts/ToastProvider'
import {ActionButtons} from 'pages/NewCheckoutPage/LoginForm/ActionButtons'

import {CreateEventFormDataModalStepProps} from '../util/createEventFormData'

export const CreateEventEmailLoginForm = (props: CreateEventFormDataModalStepProps) => {
  const {
    createEventFormData: {
      userData: {email},
    },
    setCreateEventFormData,
  } = props
  const {showToast} = useToast()
  const [password, setPassword] = useState<string | undefined>('')
  const {mutate: login, isLoading, isError} = useLoginWithEmail()

  const handleLogin = async () => {
    if (!password) {
      showToast({type: 'error', title: 'Please input a password'})
      return
    }
    login({email, password})
  }

  const handleCreateNewAccount = () => {
    setCreateEventFormData(u => ({...u, hasAccount: false}))
  }

  const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      await handleLogin()
    }
  }

  return (
    <div className='CreateEventLogin'>
      <h4 className='noMargin'>Log in to your POSH account</h4>
      <p className={'center'}>{email}</p>
      <Input
        value={password}
        type={'password'}
        className='fullWidth'
        required={true}
        onChange={e => setPassword(e.target.value)}
        placeholder={'Password'}
        onKeyDown={e => onKeyDown(e)}
      />
      <Button className='createEventGrad' disabled={isLoading || password?.length === 0} onClick={handleLogin}>
        Login
      </Button>
      {isError && <span style={{textAlign: 'center', color: 'red'}}>Invalid Email or Password</span>}
      {isError && <ActionButtons handleCreateNewAccount={handleCreateNewAccount} />}
    </div>
  )
}
