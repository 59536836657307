import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {getGroupAvi} from '@posh/model-types'
import {AccountRoleResponse} from 'apis/Roles'
import {PictureState} from 'components/form/ImagePicker'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useNavigateToGroupsPage} from 'hooks/navigation/useNavigateToGroupsDashboard'

import {useFetchAccountGroups} from '../../../apis/Accounts/useFetchAccountGroups'
import useSessionContext from '../../../domains/Auth/SessionContext'

interface GroupOptionAttributes {
  id: string
  name: string
  image?: string
  accountRole: AccountRoleResponse
}

const GroupOption = ({groupOptionAttrs}: {groupOptionAttrs: GroupOptionAttributes}) => {
  const {navigateToGroupsDashboardAndSetRole} = useNavigateToGroupsPage()

  const handlePickGroup = () =>
    navigateToGroupsDashboardAndSetRole({groupId: groupOptionAttrs.id, accountRole: groupOptionAttrs.accountRole})

  return (
    <a className='GroupImageButton' onClick={handlePickGroup}>
      <div className='GroupImageButton-content'>
        <div
          className='GroupImageButton-groupBackground'
          style={{backgroundImage: `url('${groupOptionAttrs.image}')`}}
        />
        <div className='GroupImageButton-filter' />
        <div className='GroupImageButton-name'>{groupOptionAttrs.name}</div>
        <div className='GroupImageButton-role'>{groupOptionAttrs.accountRole.roleKey}</div>
        <div className='GroupImageButton-cta'>Manage Organization &#187;</div>
      </div>
    </a>
  )
}

const GroupsSelector = () => {
  const [hasProfilePic, setHasProfilePic] = useState(false)
  const [isHostOrOwner, setIsHostOrOwner] = useState(false)
  const navigate = useNavigate()

  const {currentUser} = useSessionContext()
  const {data: accountGroupUnions, isFetching: accountGroupsIsFetching, isError} = useFetchAccountGroups()

  const picURL = useCallback(() => {
    if (currentUser) {
      if (currentUser?.avi) return currentUser.avi
      else if (currentUser?.instagram?.avi) return currentUser?.instagram?.avi
      else return null
    }
  }, [currentUser])

  const [iconPictureFile, setIconPictureFile] = useState<PictureState | null>(null)

  useEffect(() => {
    if (currentUser && currentUser.groups) {
      if (currentUser.avi || currentUser.instagram?.avi) {
        setHasProfilePic(true)
        setIconPictureFile({file: null, url: picURL()})
      }
      const roles = currentUser.groups.map(g => g.role)
      if (roles.includes('host') || roles.includes('owner')) setIsHostOrOwner(true)
    }
  }, [currentUser, picURL])

  const isFetching = accountGroupsIsFetching

  const Groups = () => {
    if (!accountGroupUnions) return null
    return (
      <>
        {accountGroupUnions!.map(groupAccountRoleUnions => {
          const {group, accountRole} = groupAccountRoleUnions

          const groupOptionAttrs: GroupOptionAttributes = {
            id: group._id,
            name: group.name,
            image: getGroupAvi(group),
            accountRole,
          }
          return !group.isDeleted && <GroupOption key={groupOptionAttrs.id} groupOptionAttrs={groupOptionAttrs} />
        })}
        <a className='GroupImageButton' onClick={() => navigate('/create_group')}>
          <div className='GroupImageButton-content'>
            <div className='GroupImageButton-groupBackground' style={{background: '#3a3b3d'}} />
            <div className='GroupImageButton-filter' />
            <div className='GroupImageButton-name'>+ New Organization</div>
            <div className='GroupImageButton-cta'>Create Organization &#187;</div>
          </div>
        </a>
      </>
    )
  }

  return (
    <div className='GroupsDash'>
      <div className='GroupsDash-inner'>
        <h2>My Organizations</h2>
        <div className='GroupsSelector'>
          {isError && <p>Something went wrong while fetching for your groups :(</p>}
          <div className='GroupsSelector-options'>{isFetching ? <SpinLoader height={80} /> : <Groups />}</div>
        </div>
      </div>
    </div>
  )
}

export default GroupsSelector
