import React from 'react'
import {useNavigate} from 'react-router-dom'

import {EventPublic} from 'apis/Events/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import moment from 'moment'

const UpcomingEvent = ({
  type,
  upcomingEvent,
  firstName,
  title,
}: {
  type: string
  upcomingEvent: EventPublic
  firstName?: string
  title?: string
}) => {
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const relativeStart = moment.tz(upcomingEvent.startUtc, upcomingEvent.timezone ?? '').calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: '[this] dddd',
    sameElse: '[on] MMMM DD',
  })

  const topText = (type: string) => {
    switch (type) {
      case 'account':
        return `${firstName} is going to`
      case 'organization':
        return `${title}'s next event is`
      default:
        return 'The next event'
    }
  }

  const onImageClick = () => {
    trackMixpanelEvent('Event Click- Attendee/Curator Profile Page', {
      nextEvent: false,
      upcomingEvent: false,
      previousEvent: false,
    })
    navigate(`/e/${upcomingEvent.url}`)
  }

  return (
    <>
      <hr />
      <div className='PProfile-upcomingEvent'>
        <div className='PProfile-upcomingEvent-text'>
          <p>{topText(type)}</p>
          <h3 onClick={() => navigate(`/e/${upcomingEvent.url}`)}>{upcomingEvent.name}</h3>
          <p>{relativeStart}</p>
        </div>
        <img src={upcomingEvent.flyer} className='PProfile-upcomingEvent-flyer' onClick={onImageClick} />
      </div>
    </>
  )
}

export default UpcomingEvent
