import React, {ButtonHTMLAttributes} from 'react'

import './styles.scss'

const filterIconUrl = ' https://assets.posh.vip/b2/filter.svg'

export const FilterButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button className='FilterButton' {...props}>
      <img className='Attendees-actionBarButton--image' src={filterIconUrl} />
      <p>{'FILTER'}</p>
    </button>
  )
}
