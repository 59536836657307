import React from 'react'
import {useParams} from 'react-router-dom'

import {useFetchAccountProfileByUsername} from 'apis/Accounts/useFetchAccountProfileByUsername'
import {useFetchPastEvents} from 'apis/PoshProfile/useFetchPastEvents'
import {useFetchUpcomingEvent} from 'apis/PoshProfile/useFetchUpcomingEvent'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
import PoshMobileNav from 'pages/PoshMobileNav'

import Profile from '..'

const UserProfile = () => {
  const {username} = useParams<{username: string}>()
  const {data: account, isFetching} = useFetchAccountProfileByUsername(username!)
  const {data: upcomingEvent} = useFetchUpcomingEvent(username!)
  const {data: pastEvents} = useFetchPastEvents(username!)

  const eventCount = (pastEvents ? pastEvents?.length : 0) + (upcomingEvent ? 1 : 0)

  if (!isFetching && !account) {
    return <PageNotFound />
  }

  if (!account) return null

  const statistics = !!account.profile
    ? [
        {label: 'events attended', value: eventCount},
        {label: 'guestlist clicks', value: account.profile?.guestlistClicks ?? 0},
      ]
    : []

  return (
    <>
      <PoshHeader desktopOnly />
      <div className='PProfileWrapper'>
        <Profile
          isFetching={isFetching}
          type='account'
          avi={account.avi}
          title={`${account.firstName} ${account.lastName}`}
          subtitle={`@${username}`}
          statistics={statistics}
          instagram={account.profile?.instagram}
          twitter={account.profile?.twitter}
          upcomingEvent={upcomingEvent}
          badges={account.badges}
          pastEvents={pastEvents}
          verified={account.profile?.poshVerified}
          firstName={account.firstName}
          accentColor={'#0026ff'}
        />
      </div>
      <PoshMobileNav />
    </>
  )
}

export default UserProfile
