import React from 'react'

import useFetchPendingCommunity from 'apis/Community/useFetchPendingCommunity'
import {isEmpty, isNull, isUndefined} from 'lodash'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import PendingArtists from './pendingArtists'
import PendingStaff from './pendingStaff'
import PendingVenues from './pendingVenues'

import './styles.scss'

const CommunityApprovals = () => {
  const {data: pendingCommunity} = useFetchPendingCommunity()
  const hasPendingArtists = pendingCommunity && !isNull(pendingCommunity) && !isEmpty(pendingCommunity.pendingArtists)
  const hasPendingVenues = pendingCommunity && !isNull(pendingCommunity) && !isEmpty(pendingCommunity.pendingVenues)
  const hasPendingStaff = pendingCommunity && !isNull(pendingCommunity) && !isEmpty(pendingCommunity.pendingStaff)
  return (
    <>
      <BackAndTitle title={'POSH Community'} />
      {hasPendingArtists && !isUndefined(pendingCommunity.pendingArtists) && (
        <PendingArtists artists={pendingCommunity.pendingArtists} />
      )}
      {hasPendingVenues && !isUndefined(pendingCommunity.pendingVenues) && (
        <PendingVenues venues={pendingCommunity.pendingVenues} />
      )}
      {hasPendingStaff && !isUndefined(pendingCommunity.pendingStaff) && (
        <PendingStaff staff={pendingCommunity.pendingStaff} />
      )}
    </>
  )
}

export default CommunityApprovals
