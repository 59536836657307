import React, {PropsWithChildren, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {getFullName} from '@posh/utils'
import getAvi from 'apis/Util/getAvi'
import classNames from 'classnames'
import {DB_BACKED_FEATURES, useIsFeatureEnabled} from 'components/FeatureFlag'
import {PoshLogo} from 'components/PoshLogo'

import useSessionContext from '../../../domains/Auth/SessionContext'
import {CurrentUser} from './CurrentUser'
import Notifications from './Notifications'
import SideBar from './SideBar'
import UnauthorizedPoshHeaderActionSection from './UnauthorizedPoshHeaderActionSection'

import './styles.scss'
import './navmenu.scss'

interface Props {
  isTranslucent?: boolean
  isHalfTranslucent?: boolean
  hideBanner?: boolean
  community?: boolean
  desktopOnly?: boolean
  hideMenuItems?: boolean
  className?: string
  style?: React.CSSProperties
}

const PoshHeader = (props: PropsWithChildren<Props>) => {
  const {
    isTranslucent,
    isHalfTranslucent,
    hideBanner,
    community,
    desktopOnly,
    hideMenuItems,
    children,
    className,
    style,
  } = props
  const {currentUser, userId} = useSessionContext()
  const navigate = useNavigate()

  const [isSidebarDeployed, setIsSidebarDeployed] = useState(false)

  const avi = getAvi(currentUser)

  const {isFeatureEnabled} = useIsFeatureEnabled(DB_BACKED_FEATURES.WEB_NAVIGATION_REDESIGN)
  if (isFeatureEnabled) return <></>

  return (
    <>
      {!hideBanner && <div className={classNames('HeaderBanner', {desktopOnly})} />}
      <div
        style={style}
        className={classNames('PoshHeader', className, {
          'is-translucent': isTranslucent,
          'is-half-translucent': isHalfTranslucent,
          desktopOnly,
        })}>
        <div className='PoshHeader-left'>
          {community ? (
            <img
              src='https://assets.posh.vip/b2/posh-community-small.png'
              onClick={() => navigate('/community')}
              className='PoshHeader-left-comLogo'
            />
          ) : (
            <PoshLogo />
          )}
        </div>
        {children}
        {userId ? (
          <div className='PoshHeader-right'>
            <div className='PoshHeader-right-container'>
              <span style={{flexDirection: 'row-reverse', display: 'flex', gap: '15px'}}>
                <CurrentUser fullName={getFullName(currentUser)} avi={getAvi(currentUser)} />
              </span>
              {!hideMenuItems && (
                <div className='PoshHeader-right-container'>
                  <Notifications />
                  <img
                    onClick={() => setIsSidebarDeployed(true)}
                    className={classNames('PoshHeader-left-hamburger', {cooked: isSidebarDeployed})}
                    src='https://assets.posh.vip/b2/menu.svg'
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <UnauthorizedPoshHeaderActionSection community={community ?? false} />
        )}
      </div>
      {userId && (
        <SideBar isSidebarDeployed={isSidebarDeployed} setIsSidebarDeployed={setIsSidebarDeployed} avi={avi} />
      )}
    </>
  )
}

export default PoshHeader
