import React from 'react'

import {PoshLoading as PoshWordmark, PoshSailBoat} from 'components/assets/Icons'

import {BaseNavigationItem} from '../BaseNavigationItem'
import {DesktopBaseNavigationItemProps} from '../BaseNavigationItem/desktop'

import styles from './styles.module.scss'

const LOGO_SIZE = 32
const WORDMARK_WIDTH = LOGO_SIZE * 4

export function DesktopLogoNavigationItem(props: Omit<DesktopBaseNavigationItemProps, 'body' | 'icon' | 'isSelected'>) {
  return (
    <BaseNavigationItem.Desktop
      body={
        <span style={{display: 'flex'}}>
          <PoshWordmark width={WORDMARK_WIDTH} />
        </span>
      }
      {...props}
      icon={
        <PoshSailBoat
          data-is-expanded={props.isExpanded}
          className={styles.Logo__rotateIconOnHover}
          color={'#ffcc00'}
          height={LOGO_SIZE}
          width={LOGO_SIZE}
        />
      }
      isSelected={false} // the logo does not have a selected state
      onClick={props.onClick}
    />
  )
}
