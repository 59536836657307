import React from 'react'
import {useNavigate} from 'react-router-dom'

import {TicketTierSoldOutNotificationMetadata} from '@posh/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {InfoOutlined} from 'components/assets/Icons'

export const TicketTierSoldOut = (props: TicketTierSoldOutNotificationMetadata) => {
  const {tierName, tierUrl, eventName, eventUrl, eventFlyer, eventId, orgId} = props
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const EVENT_OVERVIEW_PAGE = `/admin/groups/${orgId}/events/${eventId}/overview`

  const onClickEventFlyer = () => {
    trackMixpanelEvent('Event Click- Notifications Tab', {notificationType: 'ticketTierSoldOut'})
    navigate(EVENT_OVERVIEW_PAGE)
  }

  return (
    <div className='Notification-content'>
      <InfoOutlined className='Notification-content-info' />
      <p className='text-small'>
        <a href={tierUrl}>{tierName}</a> has sold out for <a href={EVENT_OVERVIEW_PAGE}>{eventName}</a>
      </p>
      <img src={eventFlyer} onClick={onClickEventFlyer} />
    </div>
  )
}
