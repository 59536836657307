import React, {useEffect} from 'react'
import Loader from 'react-loader-spinner'

import {rightArrow} from 'components/assets/Icons'
import Button from 'components/form/Button'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'
import CheckoutField from 'pages/NewCheckoutPage/CheckoutField/CheckoutField'
import CustomCheckoutFields from 'pages/NewCheckoutPage/CustomCheckoutFields'

export interface PhoneLoginFormFirstStepProps {
  phone: string
  setPhone: React.Dispatch<React.SetStateAction<string>>
  handlePhoneCheckClick: () => Promise<void>
  isSendingCode: boolean
  showSignUp: boolean
}

export const PhoneLoginFormFirstStep = (props: PhoneLoginFormFirstStepProps) => {
  const {phone, setPhone, handlePhoneCheckClick, isSendingCode, showSignUp} = props
  const {isRSVP, cartFormData} = useLiveCartContext()

  const hideButton = !phone || isRSVP

  const classname = isRSVP
    ? 'RSVPModal-Section'
    : `CheckoutPage-Section${hideButton ? '-WithoutButton' : '-WithButton'}`

  useEffect(() => {
    setPhone(cartFormData['phone_number']?.value ?? '')
  }, [cartFormData, setPhone])

  return (
    <div className={classname}>
      <CheckoutField
        field_type={'phone_number'}
        field_key={'phone_number'}
        value={cartFormData['phone_number']?.value}
        required={false}
        placeholder={'Phone Number'}
        defaultCountry={'US'}
        className={'CheckoutLargeTypeInput'}
        disabled={showSignUp}
      />
      {isRSVP && <CustomCheckoutFields />}
      {isRSVP ? (
        <Button
          className='fit center'
          onClick={handlePhoneCheckClick}
          disabled={isSendingCode}
          isLoading={isSendingCode}>
          Continue
        </Button>
      ) : (
        !showSignUp && (
          <button disabled={false} onClick={handlePhoneCheckClick}>
            {isSendingCode ? <Loader type='TailSpin' color='black' height={15} width={15} /> : <img src={rightArrow} />}
          </button>
        )
      )}
    </div>
  )
}
