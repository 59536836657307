import React from 'react'

import {amex, discover, jcb, mastercard, unknownCard, visa} from 'components/assets/Icons'
import Stripe from 'stripe'

import './styles.scss'

interface StripeCardComponentProps {
  onClick?: () => void
  card: Stripe.PaymentMethod.Card
  isSelected?: boolean
}

const StripeCardComponent = (props: StripeCardComponentProps) => {
  const {onClick, card, isSelected} = props
  const cardLogos = {amex, visa, jcb, mastercard, discover}
  const getLogo = () => {
    const brand = card.brand || 'none'
    if (brand in cardLogos) {
      return cardLogos[brand as keyof typeof cardLogos]
    } else return unknownCard
  }

  return (
    <div onClick={() => onClick && onClick()} className={`StripeCardSelector ${isSelected && 'selected'}`}>
      <div className='StripeCardSelector-radioCardContainer'>
        <input type='radio' name='paymentMethod' checked={isSelected} />
        <img className='StripeCardSelector-card' src={getLogo()} />
      </div>
      <p className='StripeCardSelector-digits'>•••• •••• •••• {card.last4}</p>
    </div>
  )
}

export default StripeCardComponent
