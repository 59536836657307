import React from 'react'
import {useNavigate} from 'react-router-dom'

import {FollowNotificationMetadata} from '@posh/types'

export const FollowedByNotification = (props: FollowNotificationMetadata) => {
  const {followerAvi, followerName, followerVerified} = props
  // TODO: Replace with "followerUsername" field in the props when available
  const followerUsername = 'FAKE_USERNAME'
  const navigate = useNavigate()

  const onClickProfilePic = () => {
    navigate(`/p/${followerUsername}`)
  }

  return (
    <div className='Notification-content'>
      <img src={followerAvi} onClick={onClickProfilePic} id='profilePic' />
      <p className='text-small'>
        <a href={`/p/${followerUsername}`}>{followerName}</a>
        {followerVerified && (
          <span>
            <img id='verifiedBadge' src='https://assets.posh.vip/b2/verify.png' />
          </span>
        )}{' '}
        just followed you. <a href={`/p/${followerUsername}`}>Click to view their profile.</a>
      </p>
    </div>
  )
}
