import React from 'react'

import {SettingsTabMap} from '../eventSettings'

interface EventSettingCardProps {
  onClick: () => void
  tab: SettingsTabMap
  isRSVPEvent?: boolean
}
export const EventSettingCard = (props: EventSettingCardProps) => {
  const {onClick, tab, isRSVPEvent} = props
  const {title, description, icon} = tab

  const cardTitle = isRSVPEvent && tab.rsvpVariant ? tab.rsvpVariant.title : title
  const cardDescription = isRSVPEvent && tab.rsvpVariant ? tab.rsvpVariant.description : description

  return (
    <div className='EventSettings-card' onClick={onClick}>
      <img src={`https://assets.posh.vip/b2/settings-icons/${icon}.svg`} />
      <div>
        <h5 className='EventSettings-card-title'>{cardTitle}</h5>
        <p className='EventSettings-card-description'>{cardDescription}</p>
      </div>
    </div>
  )
}
