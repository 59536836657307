export {ReactComponent as Triangle} from '../../assets/images/triangle.svg'
export {default as imageUrl, ReactComponent as Image} from '../../assets/images/image.svg'
export {ReactComponent as CaretDown} from '../../assets/images/caret-down.svg'
export {ReactComponent as CaretRight} from '../../assets/images/caret-right.svg'
export {ReactComponent as HamburgerMenu} from '../../assets/images/hamburger-menu.svg'
export {ReactComponent as Person} from '../../assets/images/person.svg'

export {ReactComponent as Compass} from '../../assets/images/icons/compass.svg'
export {ReactComponent as Charts, default as chartsUrl} from '../../assets/images/charts.svg'
export {ReactComponent as Camera, default as cameraUrl} from '../../assets/images/icons/camera.svg'
export {ReactComponent as Moon, default as moonUrl} from '../../assets/images/icons/moon.svg'
export {ReactComponent as Sun, default as sunUrl} from '../../assets/images/icons/sun.svg'
export {ReactComponent as Brush, default as brushUrl} from '../../assets/images/brush.svg'
export {ReactComponent as Tickets, default as ticketsUrl} from '../../assets/images/ticket.svg'
export {ReactComponent as Attendees, default as attendeesUrl} from '../../assets/images/attendees.svg'
export {ReactComponent as Hosts, default as hostsUrl} from '../../assets/images/hosts.svg'
export {ReactComponent as Settings, default as settingsUrl} from '../../assets/images/settings.svg'
export {ReactComponent as Services, default as servicesUrl} from '../../assets/images/services.svg'

export {ReactComponent as ChartsGold, default as chartsgoldUrl} from '../../assets/images/charts-gold.svg'
export {ReactComponent as BrushGold, default as brushUrlGold} from '../../assets/images/brush-gold.svg'
export {ReactComponent as TicketsGold, default as ticketsUrlGold} from '../../assets/images/ticket-gold.svg'
export {ReactComponent as TicketsWhite, default as ticketsUrlWhite} from '../../assets/images/ticket-white.svg'
export {ReactComponent as AttendeesGold, default as attendeesUrlGold} from '../../assets/images/attendees-gold.svg'
export {ReactComponent as HostsGold, default as hostsUrlGold} from '../../assets/images/hosts-gold.svg'
export {ReactComponent as SettingsGold, default as settingsUrlGold} from '../../assets/images/settings-gold.svg'
export {ReactComponent as ServicesGold, default as servicesUrlGold} from '../../assets/images/services-gold.svg'
export {ReactComponent as FilledPlus, default as filledPlusUrl} from '../../assets/images/icons/plus.svg'
export {ReactComponent as FilledMinus, default as filledMinusUrl} from '../../assets/images/icons/minus.svg'
export {default as deleteUrl} from '../../assets/images/x.png'
export {default as smallLogo} from '../../assets/images/p-small-sq-gold.png'
export {default as verifiedBadge} from '../../assets/images/icons/verified.png'
export {default as refreshUrl} from '../../assets/images/icons/refresh.png'
export {default as dots} from '../../assets/images/icons/dots.svg'
export {ReactComponent as InfoI} from '../../assets/images/icons/info-i.svg'

export {ReactComponent as Bank, default as bankUrl} from '../../assets/images/icons/bank.svg'

export {default as nydEventUrl} from '../../assets/images/nyd.jpg'
export {default as mission1Url} from '../../assets/images/mission1.jpg'
export {default as mission3Url} from '../../assets/images/mission3.jpg'
export {default as mission2Url} from '../../assets/images/mission2.jpg'

export {default as menuUrl} from '../../assets/images/icons/menu.svg'

export {default as editPencilUrl} from '../../assets/images/icons/edit.svg'
export {ReactComponent as Instagram, default as instagramUrl} from '../../assets/images/icons/instagram.svg'
export {
  ReactComponent as InstagramOutlineGold,
  default as instagramOutlineGoldUrl,
} from '../../assets/images/icons/instagram-outline-gold.svg'
export {default as instagramColoredUrl} from '../../assets/images/icons/instagram-gold.png'
export {ReactComponent as Linkedin, default as linkedinUrl} from '../../assets/images/icons/linkedin.svg'
export {
  ReactComponent as LinkedInSquareGold,
  default as linkedinSquareGoldUrl,
} from '../../assets/images/icons/linkedin-square-gold.svg'
export {ReactComponent as Person2, default as person} from '../../assets/images/icons/person.svg'
export {ReactComponent as PersonGold, default as personGold} from '../../assets/images/icons/person-gold.svg'
export {ReactComponent as Twitter, default as twitterUrl} from '../../assets/images/icons/twitter.svg'
export {
  ReactComponent as TwitterFillGold,
  default as twitterFillGoldUrl,
} from '../../assets/images/icons/twitter-fill-gold.svg'
export {ReactComponent as DownArrow, default as downArrowUrl} from '../../assets/images/icons/down-arrow.svg'
export {ReactComponent as RightArrow, default as rightArrow} from '../../assets/images/icons/right-arrow.svg'
export {default as rightCircularArrow} from '../../assets/images/icons/right-circular.png'
export {default as addUserFilled} from '../../assets/images/icons/add-user.png'
export {default as moneyBag} from '../../assets/images/icons/moneybag.png'
export {default as filledGear} from '../../assets/images/icons/filledgear.png'
export {ReactComponent as FilledPhone, default as filledPhoneUrl} from '../../assets/images/icons/filled_phone.svg'
export {ReactComponent as FilledChat, default as filledChatUrl} from '../../assets/images/icons/filled_chat.svg'
export {ReactComponent as AddUser, default as addUser} from '../../assets/images/icons/addUser.svg'
export {ReactComponent as Csv, default as csv} from '../../assets/images/icons/csv.svg'
export {ReactComponent as Check, default as check} from '../../assets/images/icons/check.svg'
export {ReactComponent as Add, default as add} from '../../assets/images/icons/add.svg'
export {ReactComponent as ArrowLeft, default as ArrowLeftUrl} from '../../assets/images/icons/arrow-left.svg'
export {ReactComponent as Eye, default as eyeUrl} from '../../assets/images/icons/eye.svg'
export {ReactComponent as EyeClosed} from '../../assets/images/icons/eye-closed.svg'
export {ReactComponent as RefreshIcon, default as refreshIconUrl} from '../../assets/images/icons/refresh.svg'
export {ReactComponent as DownChevron, default as downChevronUrl} from '../../assets/images/icons/down-chevron.svg'
export {default as filterSmUrl} from '../../assets/images/icons/filter-sm.svg'
export {ReactComponent as AlertSolid} from '../../assets/images/icons/alert-solid.svg'
export {ReactComponent as InfoOutlined} from '../../assets/images/icons/info-o.svg'
export {ReactComponent as InfoSolid} from '../../assets/images/icons/info.svg'
export {ReactComponent as KickbackLogo} from '../../assets/images/icons/kickback-logo.svg'

export {default as circleGreenCheckMarkIcon} from '../../assets/images/circle-green-check-mark.png'
export {default as circleRedX} from '../../assets/images/circle-red-x.png'

export {default as blankAvi} from '../../assets/images/blank-avi.png'

export {default as whiteCircularAddUrl} from '../../assets/images/icons/white-circular-add.png'
export {default as whiteCircularPencilUrl} from '../../assets/images/icons/white-circular-pencil.png'
export {default as grayCircularCloseUrl} from '../../assets/images/icons/gray-circular-close.png'
export {default as smartPhone} from '../../assets/images/phone.svg'
export {default as upload} from '../../assets/images/icons/upload.png'
export {default as addUserIcon} from '../../assets/images/icons/add-user.svg'
export {ReactComponent as Clock, default as clockUrl} from '../../assets/images/icons/clock.svg'
export {ReactComponent as Crown, default as crown} from '../../assets/images/icons/crown.svg'
export {default as filterUrl} from '../../assets/images/filter.svg'
export {default as speechUrl} from '../../assets/images/speech.svg'
export {default as tagWhiteUrl} from '../../assets/images/tag-white.svg'
export {default as importUrl} from '../../assets/images/import-white.svg'
export {default as exportUrl} from '../../assets/images/export-white.svg'
export {default as eventUrl} from '../../assets/images/event.svg'
export {ReactComponent as Bolt, default as bolt} from '../../assets/images/bolt.svg'
export {default as creditCard} from '../../assets/images/icons/credit-card.png'

export {default as deleteIcon} from '../../assets/images/delete.svg'
export {default as starIcon} from '../../assets/images/star.svg'
export {default as sparklerIcon} from '../../assets/images/sparkler.svg'
export {default as whiteStarIcon} from '../../assets/images/whiteStar.svg'
export {ReactComponent as Heart} from '../../assets/images/icons/heart.svg'
export {ReactComponent as FilledHeart} from '../../assets/images/icons/filled-heart.svg'

export {default as tagUrl} from '../../assets/images/tag.svg'
export {default as infoButton} from '../../assets/images/icons/info-button.svg'

export {default as firstScan} from '../../assets/images/first-scan.png'
export {default as warning} from '../../assets/images/warning.png'
export {default as invalid} from '../../assets/images/invalid.png'

export {default as moneyBagUrl} from '../../assets/images/money-bag.png'
export {default as audienceUrl} from '../../assets/images/audience.png'
export {default as addEmoji} from '../../assets/images/icons/add-emoji.png'
export {default as tableUrl} from '../../assets/images/table.png'
export {default as tableGoldUrl} from '../../assets/images/table-gold.png'
export {default as wallet} from '../../assets/images/icons/wallet.png'

export {default as admin} from '../../assets/images/admin-with-cogwheels.png'
export {default as host} from '../../assets/images/dance.png'
export {default as doorman} from '../../assets/images/doorman.png'

export {default as greenCheck} from '../../assets/images/icons/green-check.svg'
export {default as redX} from '../../assets/images/icons/red-x.svg'
export {default as link} from '../../assets/images/icons/link.png'
export {default as linkSvg} from '../../assets/images/icons/link.svg'
export {default as lock} from '../../assets/images/icons/lock.png'
export {default as linkRound} from '../../assets/images/icons/link-round.png'
export {default as poshGold} from '../../assets/images/icons/posh-gold.svg'
export {default as igGold} from '../../assets/images/icons/instagram-gold.png'
export {default as fbGold} from '../../assets/images/icons/facebook-gold.png'
export {default as linkedInGold} from '../../assets/images/icons/linkedin-gold.png'
export {default as qrCode} from '../../assets/images/icons/qr-code.png'
export {default as moreInfo} from '../../assets/images/icons/info-button.png'
export {ReactComponent as PoshLoading} from '../../assets/images/icons/poshLogo.svg'
export {ReactComponent as PoshSailBoat} from '../../assets/images/icons/posh-sail-boat.svg'

export {default as exportReport} from '../../assets/images/icons/export-report.svg'
export {default as refund} from '../../assets/images/icons/refund.svg'

export {default as stockGuest0} from '../../assets/images/stock-guests/stock-guest-0.png'
export {default as stockGuest1} from '../../assets/images/stock-guests/stock-guest-1.png'
export {default as stockGuest2} from '../../assets/images/stock-guests/stock-guest-2.png'
export {default as stockGuest3} from '../../assets/images/stock-guests/stock-guest-3.jpeg'
export {default as stockGuest4} from '../../assets/images/stock-guests/stock-guest-4.png'

export {ReactComponent as TextInputIcon} from '../../assets/images/icons/text-input-icon.svg'
export {ReactComponent as DropdownIcon} from '../../assets/images/icons/dropdown-icon.svg'
export {ReactComponent as CheckboxesIcon} from '../../assets/images/icons/checkboxes-icon.svg'
export {ReactComponent as FileUploadIcon} from '../../assets/images/icons/file-upload-icon.svg'
export {ReactComponent as PlusSign} from '../../assets/images/icons/plus-sign.svg'
export {ReactComponent as TrashIcon} from '../../assets/images/icons/trash.svg'
export {ReactComponent as Pencil} from '../../assets/images/icons/pencil.svg'
export {ReactComponent as PlusInCircle} from '../../assets/images/icons/plus-in-circle.svg'
export {ReactComponent as SixGrip} from '../../assets/images/icons/6-grip.svg'
export {ReactComponent as CloudUploadIcon} from '../../assets/images/icons/cloud-upload.svg'
export {ReactComponent as CircleX} from '../../assets/images/icons/circle-x.svg'
export {ReactComponent as QuestionMarkInSolidCircle} from '../../assets/images/icons/question-mark-in-solid-circle.svg'

export {ReactComponent as CircleBack} from '../../assets/images/icons/circle-back.svg'
export {ReactComponent as CircleForward} from '../../assets/images/icons/circle-forward.svg'
export {ReactComponent as TextSelection} from '../../assets/images/icons/text-selection.svg'
export {ReactComponent as PlainX} from '../../assets/images/icons/x.svg'
export {ReactComponent as IOSCellularSignal} from '../../assets/images/icons/cellular-signal.svg'
export {ReactComponent as IOSWifiSignal} from '../../assets/images/icons/wifi-signal.svg'
export {ReactComponent as IOSContact} from '../../assets/images/icons/ios-contact.svg'
export {ReactComponent as IOSBattery} from '../../assets/images/icons/battery.svg'

export {ReactComponent as Table} from '../../assets/images/icons/table.svg'
export {ReactComponent as Profile} from '../../assets/images/icons/profile.svg'
export {default as createEventTicketDuplicationUrl} from '../../assets/images/icons/create-event-duplicate-ticket.png'
export {ReactComponent as LocationPin} from '../../assets/images/icons/location-pin.svg'

// payment logos
export {default as amex} from '../../assets/payment-logos/Square Corners/SVG/american-express.svg'
export {default as visa} from '../../assets/payment-logos/Square Corners/SVG/visa.svg'
export {default as jcb} from '../../assets/payment-logos/Square Corners/SVG/jcb.svg'
export {default as mastercard} from '../../assets/payment-logos/Square Corners/SVG/mastercard.svg'
export {default as discover} from '../../assets/payment-logos/Square Corners/SVG/discover.svg'
export {default as unknownCard} from '../../assets/payment-logos/Rounded Corners/SVG/credit-card.svg'

// gallery
export {default as galleryActive} from '../../assets/images/icons/GalleryNavigationIcon.svg'
export {default as galleryInActive} from '../../assets/images/icons/GalleryNavigationInActive.svg'
export {default as circularAlert, ReactComponent as CircularAlert} from '../../assets/images/icons/CircleAlert.svg'

// tables
export {ReactComponent as PlusSolid} from '../../assets/images/icons/plus-solid.svg'
export {ReactComponent as ArrowsUpDown} from '../../assets/images/icons/arrows-up-down.svg'
export {ReactComponent as ArrowUp} from '../../assets/images/icons/arrow-up.svg'
export {ReactComponent as ArrowDown} from '../../assets/images/icons/arrow-down.svg'
export {ReactComponent as Refresh} from '../../assets/images/icons/refresh-2.svg'
export {ReactComponent as Search} from '../../assets/images/icons/search.svg'
export {ReactComponent as CopySquares} from '../../assets/images/icons/copy-squares.svg'

export {ReactComponent as TriangleDown} from '../../assets/images/icons/triangle-down.svg'
export {ReactComponent as DollarSign} from '../../assets/images/icons/dollar-sign.svg'
export {ReactComponent as Percent} from '../../assets/images/icons/percent.svg'
export {ReactComponent as ExternalLink} from '../../assets/images/icons/external-link.svg'
export {ReactComponent as Filter} from '../../assets/images/icons/filter.svg'
export {ReactComponent as Warning} from '../../assets/images/icons/warning.svg'
export {ReactComponent as OrdersOutlined} from '../../assets/images/icons/orders-o.svg'
export {ReactComponent as AttendeesSolid} from '../../assets/images/icons/attendees.svg'
export {ReactComponent as Map} from '../../assets/images/icons/map.svg'

// event visuals
export {ReactComponent as MusicNote} from '../../assets/images/icons/musical-note.svg'
export {ReactComponent as PhotoGallery} from '../../assets/images/icons/photo-gallery.svg'
export {ReactComponent as ChevronDown} from '../../assets/images/icons/chevron-down.svg'
import {ReactComponent as SpotifyLogo} from '../../assets/images/icons/spotify-logo.svg'
import {ReactComponent as YoutubeLogo} from '../../assets/images/icons/youtube-logo.svg'
export {ReactComponent as TextLines} from '../../assets/images/icons/text-lines.svg'
export {ReactComponent as EmailWithCircleArrow} from '../../assets/images/icons/email-with-circle-arrow.svg'
export {ReactComponent as PhoneWithCircleArrow} from '../../assets/images/icons/phone-with-circle-arrow.svg'
export {ReactComponent as Building} from '../../assets/images/icons/building.svg'
export {ReactComponent as ClockWithCircle} from '../../assets/images/icons/clock-with-circle.svg'
export {ReactComponent as Star} from '../../assets/images/icons/star.svg'
export {ReactComponent as Gallery} from '../../assets/images/icons/GalleryNavigationInActive.svg'
export {ReactComponent as Equalizer} from '../../assets/images/icons/equalizer.svg'

export {SpotifyLogo, YoutubeLogo}
export function SpotifyLogoGreen(props: Omit<React.SVGProps<SVGSVGElement>, 'color'>) {
  const SPOTIFY_GREEN = '#1DB954'
  return SpotifyLogo({color: SPOTIFY_GREEN, ...props})
}
export function YoutubeLogoRed(props: Omit<React.SVGProps<SVGSVGElement>, 'color'>) {
  const YOUTUBE_RED = '#FF0000'
  return YoutubeLogo({color: YOUTUBE_RED, ...props})
}
import {ReactComponent as ArrowLeft} from '../../assets/images/icons/arrow-left.svg'

const ArrowDirectionMap = {
  east: 'rotate(180deg)',
  north: 'rotate(-90deg)',
  south: 'rotate(90deg)',
  west: 'rotate(0deg)',
  northEast: 'rotate(135deg)',
  northWest: 'rotate(-135deg)',
  southEast: 'rotate(45deg)',
  southWest: 'rotate(-45deg)',
}
const ArrowDirectionMapKeys = Object.keys(ArrowDirectionMap) as (keyof typeof ArrowDirectionMap)[]
type ArrowDirection = (typeof ArrowDirectionMapKeys)[number]
export function ArrowWithDirection(props: React.SVGProps<SVGSVGElement> & {direction: ArrowDirection}) {
  return ArrowLeft({style: {transform: ArrowDirectionMap[props.direction]}, ...props})
}

import {ReactComponent as CaretUp} from '../../assets/images/icons/caret-up.svg'
interface CaretProps extends React.SVGProps<SVGSVGElement> {
  direction: 'up' | 'down' | 'left' | 'right'
}
const directionMap = {
  up: 'rotate(0deg)',
  down: 'rotate(180deg)',
  left: 'rotate(270deg)',
  right: 'rotate(90deg)',
}
export const Caret = (props: CaretProps) => {
  const {direction, ...rest} = props
  return CaretUp({style: {transform: directionMap[direction], transition: '0.3s'}, ...rest})
}
