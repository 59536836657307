import React from 'react'
import {useNavigate} from 'react-router-dom'

import {TipBadge} from 'components/TipBadge/TipBadge'

import './Service.styles.scss'

export interface ServiceProps {
  title: string
  path: string
  description: string
  image: string
  recommended?: boolean
  active?: boolean
  pending: boolean
  onChangeActive?: (title: string) => void
}

export const Service = (props: ServiceProps) => {
  const {title, path, description, image, recommended, active, pending, onChangeActive} = props
  const navigate = useNavigate()

  const onClick = () => {
    if (!active) return
    if (pending) {
      onChangeActive && onChangeActive(title)
    } else {
      navigate(`./${path}`)
    }
  }

  return (
    <div className={`ServiceContainer ${active ? '' : 'ServiceContainer-disabled'}`} onClick={onClick}>
      <img className='ServiceContainer-image' src={image} alt={`${title} event service`} />
      <div className='ServiceContainer-overlay' />
      <div className='ServiceContainer-content'>
        {recommended && (
          <div className='ServiceContainer-tip'>
            <TipBadge text='Recommended' type='none' glowing />
          </div>
        )}
        <div className='ServiceContainer-title'>{title}</div>
        <div className='ServiceContainer-description'>{description}</div>
        {!active && <TipBadge text='Coming Soon' type='none' color='grey' />}
      </div>
    </div>
  )
}
