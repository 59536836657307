import React, {useState} from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Fade from 'react-reveal/Fade'

import {DEFAULT_ACCOUNT_AVI} from '@posh/model-types'
import {getHSLFromName} from '@posh/types'
import {captureException} from '@sentry/react'
import {useAddProfileView} from 'apis/Accounts/useAddProfileView'
import {EventPublic} from 'apis/Events/types'
import {GuestList as GuestListProfiles} from 'apis/Events/useFetchEventGuestlist'
import {useLikeAttendee} from 'apis/Events/useLikeAttendee'
import {useUnlikeAttendee} from 'apis/Events/useUnlikeAttendee'
import {useMixpanel} from 'apis/MixPanelHandler'
import getAvi from 'apis/Util/getAvi'
import classNames from 'classnames'
import {verifiedBadge} from 'components/assets/Icons'
import SocialButton from 'components/SocialButton/SocialButton'
import useSessionContext from 'domains/Auth/SessionContext'
import setOptimizelyUserCustomAttribute from 'helpers/setOptimizelyUserCustomAttribute'
import {ProfilePreviewModalState} from 'pages/EventPage'
import {GuestlistViewMoreModal} from 'pages/EventPage/Guestlist/GuestlistViewMoreModal/GuestlistViewMoreModal'

import {PreviewProfileForModal} from './ProfilePreviewModal'

import './styles.scss'

interface GuestListProps {
  guestlistProfiles: GuestListProfiles
  colorModeModifier: string
  isLightMode: boolean
  event: EventPublic
  setProfilePreviewModal: React.Dispatch<React.SetStateAction<ProfilePreviewModalState>>
}

type GuestlistProfile = GuestListProfiles[number]

export const GuestListIcon = ({icon}: {icon: string}) => (
  <img
    className='GuestList-attendeeAvatarLarge'
    src={icon}
    onError={e => (e.currentTarget.src = DEFAULT_ACCOUNT_AVI)}
    alt='Avatar'
  />
)

const GuestList = (props: GuestListProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const [isExpanded, setIsExpanded] = useState(false)
  const {guestlistProfiles, colorModeModifier, event, setProfilePreviewModal} = props
  const {isAttendee, isRSVPEvent} = event
  const {mutate: likeAttendee} = useLikeAttendee()
  const {mutate: unlikeAttendee} = useUnlikeAttendee()
  const {mutate: addProfileView} = useAddProfileView()
  const {userId, currentUser} = useSessionContext()
  const [isViewMoreModalOpen, setIsViewMoreModalOpen] = useState(false)

  const onClickProfile = (account: GuestlistProfile) => {
    const selectedProfile: PreviewProfileForModal = {
      accountId: account.attendeeAccountId.toString(),
      profileAvi: account.attendeeAvi,
      instagramVerified: account.accountProfile.instagramVerified,
      poshVerified: account.accountProfile.poshVerified,
      firstName: account.attendeeFirstName,
      lastName: account.attendeeLastName,
      instagram: account.accountProfile.instagram,
      twitter: account.accountProfile.twitter,
    }
    setProfilePreviewModal({isOpen: true, profile: selectedProfile})
    addProfileView({accountId: account.attendeeAccountId.toString()})
    trackMixpanelEvent('Guestlist Profile Click', {
      whichGuest: account.attendeeAccountId.toString(),
      clickerWasAttendingEvent: isAttendee,
    })

    try {
      setOptimizelyUserCustomAttribute('Opt.GuestlistClickAudience')
    } catch (e) {
      captureException(e)
    }
  }
  const toggleExpanded = () => {
    if (isExpanded) {
      setIsExpanded(false)
    } else if (isAttendee) {
      setIsExpanded(true)
    } else {
      setIsViewMoreModalOpen(true)
    }
  }
  const onClickLikeButton = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    attendeeAccountId: string,
    currentlyLikedByViewer: boolean,
  ) => {
    if (currentlyLikedByViewer) {
      e.stopPropagation()
      return unlikeAttendee({eventId: event.id, attendeeId: attendeeAccountId})
    }
    if (isAttendee && getAvi(currentUser) !== DEFAULT_ACCOUNT_AVI) {
      e.stopPropagation()
      likeAttendee({eventId: event.id, attendeeId: attendeeAccountId})
      trackMixpanelEvent('Like Click', {
        whichGuest: attendeeAccountId,
        sucessfullyLikedUser: true,
        within: 'guestlist',
      })
    } else {
      trackMixpanelEvent('Like Click', {
        whichGuest: attendeeAccountId,
        sucessfullyLikedUser: false,
        within: 'guestlist',
      })
    }
  }
  const CONDENSED_GUESTLIST_LENGTH = 23

  const shouldShowProfile = (index: number) => {
    if (!isExpanded && index < CONDENSED_GUESTLIST_LENGTH) return true
    else return isExpanded
  }

  const containerClassName = classNames('EventPage-attendeeAvatarContainer')

  return (
    <>
      <div className={containerClassName} id='guestlist'>
        {guestlistProfiles?.map(
          (attendee, index) =>
            shouldShowProfile(index) && (
              <Fade bottom key={attendee.attendeeAccountId}>
                <div className='GuestList-attendeeAvatarLargeLink' onClick={() => onClickProfile(attendee)}>
                  {attendee.attendeeAvi == DEFAULT_ACCOUNT_AVI ? (
                    <div
                      className='GuestList-attendeeAvatarLargeLink-initials'
                      style={{background: getHSLFromName(attendee.attendeeFirstName)}}>
                      {attendee.attendeeFirstName[0]}
                      {attendee.attendeeLastName[0]}
                    </div>
                  ) : (
                    <GuestListIcon icon={attendee.attendeeAvi} />
                  )}
                  {(attendee.accountProfile.instagramVerified || attendee.accountProfile.poshVerified) && (
                    <img
                      className='GuestList-verifiedBadge'
                      src={
                        attendee.accountProfile.instagramVerified
                          ? verifiedBadge
                          : 'https://assets.posh.vip/b2/verify.png'
                      }
                    />
                  )}
                  {attendee.attendeeAccountId !== userId && (
                    <SocialButton
                      className='GuestList-socialButton'
                      onClick={e => onClickLikeButton(e, attendee.attendeeAccountId.toString(), attendee.likedByViewer)}
                      filled={attendee.likedByViewer}
                    />
                  )}
                </div>
              </Fade>
            ),
        )}
        {guestlistProfiles?.length > CONDENSED_GUESTLIST_LENGTH && (
          <Fade bottom>
            <div className='GuestList-expandRow'>
              <a className={'GuestList-expandButton ' + colorModeModifier} onClick={toggleExpanded}>
                {isExpanded ? 'Show Less' : 'Show More'}
              </a>
            </div>
          </Fade>
        )}
      </div>
      {isViewMoreModalOpen && (
        <GuestlistViewMoreModal
          width={420}
          noborder
          guestlistProfiles={[
            guestlistProfiles[CONDENSED_GUESTLIST_LENGTH + 3],
            guestlistProfiles[CONDENSED_GUESTLIST_LENGTH + 1],
            guestlistProfiles[CONDENSED_GUESTLIST_LENGTH],
            guestlistProfiles[CONDENSED_GUESTLIST_LENGTH + 2],
            guestlistProfiles[CONDENSED_GUESTLIST_LENGTH + 4],
          ]}
          isViewingInRSVPEvent={isRSVPEvent}
          isOpen={isViewMoreModalOpen}
          onClose={() => setIsViewMoreModalOpen(false)}
        />
      )}
    </>
  )
}

export default GuestList
