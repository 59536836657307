import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import useCreateCustomNumberCheckoutSession from 'apis/Groups/useCreateCustomNumberCheckoutSession'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useFetchGroupWithCustomNumber} from 'apis/Groups/useFetchGroupWithCustomNumber'
import useGetAvailableNumbers, {AvailableNumber} from 'apis/Groups/useGetAvailableNumbers'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useToast} from 'components/toasts/ToastProvider'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {isNull, isUndefined} from 'lodash'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import './styles.scss'

const CustomNumberSelection = () => {
  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)
  const {mutateAsync: createCustomNumberCheckoutCheckoutSession, isLoading} = useCreateCustomNumberCheckoutSession()
  const {mutateAsync: getAvailableNumbers, isLoading: isFetchingNumbers} = useGetAvailableNumbers()
  const groupAlreadyHasCustomNumber = !isUndefined(group?.groupPhoneNumber) && !isNull(group?.groupPhoneNumber)
  const [availableNumbers, setAvailableNumbers] = useState<AvailableNumber[]>([])
  const [areaCode, setAreaCode] = useState('')
  const {showToast} = useToast()
  const disabledTip = groupAlreadyHasCustomNumber
    ? 'You cannot purchase a custom number if you already have one. To change your custom number, contact support@posh.vip'
    : ''
  const handleCustomNumberSelection = async (groupCustomNumber: string) => {
    try {
      if (groupId) {
        const {session} = await createCustomNumberCheckoutCheckoutSession({groupId, groupCustomNumber})
        window.location.replace(session.url)
      }
    } catch (error) {
      showToast({type: 'error', title: error.response.data.error})
    }
  }

  const handleAreaCodeSearch = async () => {
    try {
      if (areaCode.length === 3 && areaCode.match(/^[0-9]+$/)) {
        const availableNumbersResponse = await getAvailableNumbers(areaCode)
        if (availableNumbersResponse.length > 0) {
          setAvailableNumbers(availableNumbersResponse)
        }
      } else {
        showToast({type: 'error', title: 'Please enter a valid three digit area code'})
      }
    } catch (error) {
      showToast({type: 'error', title: error.response.data.error})
    }
  }

  return (
    <div className='GroupPage'>
      <div className='GroupPage-content purpleGrad'>
        <div className='GroupPage-content-inner center CustomNumberSelection'>
          <BackAndTitle title={'Personalize your outreach!'} center />
          <p>
            Take your outreach to the next level with a custom phone number!
            <br />
            <b>Attendees will receive your texts from your own number, separate than other POSH curators.</b>
            <br />
            Custom numbers are only $19.99/month!
          </p>

          <p>Input your area code below to view available custom numbers.</p>

          <div className='inputWrapper'>
            <Input placeholder='Area Code (ex. 914)' onChange={e => setAreaCode(e.target.value)} />
            <Button onClick={() => handleAreaCodeSearch()}>Search</Button>
          </div>

          <hr style={{width: '100%', margin: '20px 0'}} />
          {isFetchingNumbers && <SpinLoader />}
          {!isFetchingNumbers &&
            availableNumbers.map(listing => (
              <div key={listing.phoneNumber} className='CustomNumbersContainer'>
                <div>{listing.friendlyName}</div>
                <div>{`${listing.locality}, ${listing.region}`}</div>
                <div data-tip={disabledTip}>
                  <Button
                    className='outline'
                    disabled={isLoading || groupAlreadyHasCustomNumber}
                    onClick={() => handleCustomNumberSelection(listing.phoneNumber)}>
                    Purchase
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <ReactTooltip className='poshToolTip' effect='solid' place='top' />
    </div>
  )
}

const CustomNumberConfirmation = () => {
  const {groupId} = useGroupMatchParams()
  const {domain} = useResourcePageParams()
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const sessionId = urlParams.get('session_id')
  const phone = urlParams.get('phone')

  if (!groupId || !sessionId || !phone) navigate(`/${domain}/groups/${groupId}/marketing/custom-number`)
  const {
    data: groupWithCustomNumber,
    isLoading,
    isError,
  } = useFetchGroupWithCustomNumber({
    groupId: groupId!,
    phone: decodeURI(phone!),
  })

  const headerMessage = () => {
    if (isLoading) return 'Loading...'
    else if (isError) return 'There was an error processing your request.'
    else return 'Your custom number has been successfully purchased!'
  }

  const bodyMessage = () => {
    if (isError) return 'Please contact support@posh.vip to troubleshoot this issue.'
    else return `Your custom number ${groupWithCustomNumber?.groupPhoneNumber} is configured and ready for use!`
  }

  return (
    <div className='GroupPage'>
      <div className='GroupPage-content purpleGrad'>
        <div className='GroupPage-content-inner center'>
          <h2>{headerMessage()}</h2>
          {isLoading ? <SpinLoader height={40} /> : <p>{bodyMessage()}</p>}
          {!isLoading && (
            <Button
              onClick={() => {
                navigate(`/${domain}/groups/${groupId}/marketing`)
              }}>
              Return to Marketing Page
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export {CustomNumberSelection, CustomNumberConfirmation}
