import React from 'react'

interface OnboardingProgressBarProps {
  completedSteps: number
  totalSteps: number
}

const OnboardingProgressBar = (props: OnboardingProgressBarProps) => {
  const {completedSteps, totalSteps} = props
  return (
    <div className='OnboardingFormCont-nav'>
      <img src='https://assets.posh.vip/b2/posh-community.png' />
      <div className='OnboardingFormCont-nav-progressBar'>
        <div
          className='OnboardingFormCont-nav-progressBar-inner'
          style={{width: `${(completedSteps / totalSteps) * 100}%`}}
        />
        <p>{completedSteps}</p>
      </div>
    </div>
  )
}

export default OnboardingProgressBar
