import React from 'react'

import styles from './styles.module.scss'

export function CurrentUser({fullName, avi}: {fullName: string; avi: string}) {
  return (
    <>
      <img className={styles.avi} src={avi} />
      <p className={styles.name}>{fullName}</p>
    </>
  )
}
