import React, {forwardRef, useEffect, useState} from 'react'

import {CurrencyCode, EOptimizelyEvents} from '@posh/types'
import {CartItem} from 'apis/Carts'
import {EventTable, TicketEventPage} from 'apis/Events/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {trackOptimizelyEvent} from 'apis/OptimizelyHandler'
import {deleteIcon} from 'components/assets/Icons'
import useCartContext from 'domains/Cart/CartContext'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import './styles.scss'

interface ItemRowProps {
  item: CartItem
  info: TicketEventPage | EventTable
  currency?: CurrencyCode
  showEventFees?: boolean
}

interface CartOverlayProps {
  isLightMode: boolean
  cartItems: CartItem[]
  unlockedTickets: TicketEventPage[]
  accentColor: string
  handleCheckoutRedirect: () => Promise<void>
  currency?: CurrencyCode
  showEventFees?: boolean
  eventTables?: EventTable[]
  onCheckout?: ({
    total,
    itemsWithInfo,
    currency,
  }: {
    total: number
    itemsWithInfo: Array<{item: CartItem; info: TicketEventPage | EventTable}>
    currency: CurrencyCode
  }) => void
  onChangeIsCartBreakdownExpanded?: (toggle: boolean) => void
  numberOfItems: number
}

const CartIcon = ({accentColor}: {accentColor: string}) => {
  return (
    <svg viewBox='0 0 512.003 512.003' xmlns='http://www.w3.org/2000/svg' fill={accentColor}>
      <g>
        <circle cx='211' cy='421.002' r='45' />
        <circle cx='391' cy='421.002' r='45' />
        <path d='m15 76.002h64.604c.835 3.007 76.745 276.318 71.938 259.014 1.802 6.489 7.72 10.986 14.458 10.986h270c6.694 0 12.583-4.438 14.429-10.884l61-210c1.289-4.526.381-9.39-2.446-13.154-2.842-3.75-7.28-5.962-11.982-5.962h-377.935l-13.608-49.014c-1.802-6.489-7.72-10.986-14.458-10.986h-76c-8.291 0-15 6.709-15 15s6.709 15 15 15zm361 210h-150c-8.291 0-15-6.709-15-15s6.709-15 15-15h150c8.291 0 15 6.709 15 15s-6.709 15-15 15zm-180-90h210c8.291 0 15 6.709 15 15s-6.709 15-15 15h-210c-8.291 0-15-6.709-15-15s6.709-15 15-15z' />
      </g>
    </svg>
  )
}

const ItemRow = (props: ItemRowProps) => {
  const {item, info, currency, showEventFees} = props
  const {tables, removeTable} = useCartContext()
  if (!info || item.quantity == 0) return null
  const isTicket = item.resourceType === 'ticket'
  const name = isTicket ? info.name : 'Table - ' + info.name
  const quantity = isTicket ? 'x' + item.quantity : ''
  const icon = isTicket ? `https://assets.posh.vip/b2/tickets+(2).svg` : 'https://assets.posh.vip/b2/table+(2).svg'

  return (
    <div className='CheckoutList-RowWrapper'>
      <div className={`CheckoutList-TicketTier`}>
        <img src={icon} />
        <div className='CheckoutList-TicketTier-Info'>
          <div>{name}</div>
          <div>
            {quantity}&nbsp;&nbsp;&nbsp;&nbsp;{getCurrencySymbol(currency)}
            {((showEventFees && 'totalPrice' in info ? info.totalPrice : info.price) * item.quantity).toFixed(2)}
          </div>
        </div>
      </div>
      {!isTicket && (
        <div onClick={() => removeTable(item.resourceId)} className='CheckoutList-RemoveTable'>
          <img src={deleteIcon} />
        </div>
      )}
    </div>
  )
}

const CartOverlay = forwardRef<HTMLDivElement, CartOverlayProps>((props, ref) => {
  const {
    isLightMode,
    cartItems,
    unlockedTickets,
    accentColor,
    handleCheckoutRedirect,
    currency,
    showEventFees,
    eventTables,
    onChangeIsCartBreakdownExpanded,
    numberOfItems,
  } = props
  const [isBreakdownShowing, setIsBreakdownShowing] = useState(true)
  useEffect(() => {
    onChangeIsCartBreakdownExpanded && onChangeIsCartBreakdownExpanded(isBreakdownShowing)
  }, [isBreakdownShowing, onChangeIsCartBreakdownExpanded])

  type ItemInfo = {
    item: CartItem
    info: TicketEventPage | EventTable
  }
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const itemsWithInfo: ItemInfo[] = []

  cartItems.forEach(i => {
    const info = unlockedTickets.find(t => t.id === i.resourceId)
    if (info) itemsWithInfo.push({item: i, info: info})
    if (eventTables) {
      const tableInfo = eventTables.find(t => t._id === i.resourceId)
      if (tableInfo) itemsWithInfo.push({item: i, info: tableInfo})
    }
  })

  let total = 0

  itemsWithInfo.forEach(i => {
    total += (showEventFees && 'totalPrice' in i.info ? i.info.totalPrice : i.info.price) * i.item.quantity
  })

  return (
    <>
      {isBreakdownShowing ? (
        <div className={`CheckoutList ${isLightMode ? 'lightmode' : ''}`} ref={ref}>
          <div className='CheckoutList-ticketButtonWrapper'>
            <img
              className='CheckoutList-ticketButtonWrapper-collapse'
              src='https://assets.posh.vip/b2/down.svg'
              onClick={() => {
                setIsBreakdownShowing(false)
              }}
            />
            <div className='CheckoutList-Tickets'>
              {cartItems &&
                itemsWithInfo.map(item => (
                  <ItemRow
                    item={item.item}
                    info={item.info}
                    key={item.info.name}
                    currency={currency}
                    showEventFees={showEventFees}
                  />
                ))}
            </div>

            <div
              className={`CheckoutList-button ${isLightMode ? 'lightmode' : ''}`}
              style={{background: accentColor}}
              onClick={() => {
                trackOptimizelyEvent(EOptimizelyEvents.EVENT_PAGE_CHECKOUT_BUTTON)
                handleCheckoutRedirect()
                if (props.onCheckout)
                  props.onCheckout({
                    total,
                    itemsWithInfo,
                    currency: currency || 'USD',
                  })
              }}>
              <div className='CheckoutList-button-shimmer' />
              <span>
                Checkout - {getCurrencySymbol(currency)}
                {total.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={`CartButtonFilter ${isLightMode ? 'lightmode' : ''}`}>
            <div
              className={`CartButton ${isLightMode ? 'lightmode' : ''}`}
              onClick={() => {
                trackMixpanelEvent('Checkout Cart Icon Clicked-  Checkout Page')
                setIsBreakdownShowing(true)
              }}
              style={{border: `1px solid ${accentColor}`}}>
              <CartIcon accentColor={accentColor} />
              <div className={`CartCount ${isLightMode ? 'lightmode' : ''}`} style={{background: accentColor}}>
                {numberOfItems}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
})

CartOverlay.displayName = 'CartOverlay'

export default CartOverlay
