import React from 'react'

import './styles.scss'

const DashboardFooter = () => {
  return (
    <div className='DashboardFooter'>
      <div>
        <a href='/'>
          <img
            className='DashboardFooter-logo'
            src='https://assets.posh.vip/b2/posh-logo-w-typeface.svg'
            alt='POSH logo'
          />
        </a>
      </div>
      <div className='DashboardFooter-links'>
        <a href='mailto:support@posh.vip' target='_blank' rel='noreferrer'>
          Support
        </a>
        <a href='/tos' target='_blank'>
          Terms of Service
        </a>
        <a href='/privacy' target='_blank'>
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

export default DashboardFooter
