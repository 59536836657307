import React from 'react'

type IconProps = {
  size: number
  primaryColor: string
  secondaryColor: string
  rotation?: number
}

export const KickbackIcon = (props: IconProps) => {
  return (
    <div style={{rotate: props.rotation !== undefined ? `${props.rotation}deg` : undefined}}>
      <svg
        width={props.size}
        height={props.size}
        viewBox='0 0 1122 1452'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <rect y='205.75' width='350' height='1016' rx='10' fill={props.primaryColor} />
        <path
          d='M347.658 718.498L347.658 571.673L670.037 210.923C673.831 206.677 679.255 204.25 684.95 204.25L1062.77 204.25C1071.31 204.25 1075.92 214.268 1070.36 220.755L673.934 683.553C654.935 705.732 627.191 718.498 597.987 718.498L347.658 718.498Z'
          fill={props.primaryColor}
        />
        <path
          d='M346.658 793.921L347.658 940.746L670.356 1221.34C673.996 1224.51 678.656 1226.25 683.479 1226.25L1060.03 1226.25C1068.99 1226.25 1073.43 1215.39 1067.04 1209.11L673.172 822.551C654.475 804.201 629.323 793.921 603.126 793.921L347.658 793.921Z'
          fill={props.primaryColor}
        />
        <path d='M126 761.75L350 569.25V942.75L126 761.75Z' fill={props.secondaryColor} />
      </svg>
    </div>
  )
}
