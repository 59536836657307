// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventVisualsMarkdownEditor__W3OGw{border-radius:10px;overflow:hidden}.EventVisualsMarkdownEditor__W3OGw.lightmode__lunEx .toolbar__shi1o{border-bottom:1px rgba(0,0,0,.2) solid}.EventVisualsMarkdownEditor__W3OGw.lightmode__lunEx .toolbar__shi1o>* div{filter:invert(0.5)}.EventVisualsMarkdownEditor__W3OGw.lightmode__lunEx .wrapper__0Dqn8{background-color:rgba(224,225,227,.4)}.EventVisualsMarkdownEditor__W3OGw .editor__fA6DJ{height:300px;padding:0px 10px}.EventVisualsMarkdownEditor__W3OGw .toolbar__shi1o{background-color:rgba(224,225,227,.1);border:none;margin-bottom:0;border-bottom:1px rgba(255,255,255,.2) solid}.EventVisualsMarkdownEditor__W3OGw .wrapper__0Dqn8{background-color:rgba(224,225,227,.1)}", "",{"version":3,"sources":["webpack://./src/components/PageComponents/EventVisuals/Form/Input/MarkdownInput/styles.module.scss"],"names":[],"mappings":"AAIA,mCACI,kBAAA,CACA,eAAA,CAEI,oEACI,sCAAA,CACA,0EACI,kBAAA,CAGR,oEACI,qCAda,CAkBrB,kDACI,YAAA,CACA,gBAAA,CAGJ,mDACI,qCAvBgB,CAwBhB,WAAA,CACA,eAAA,CACA,4CAAA,CAGJ,mDACI,qCA9BgB","sourcesContent":["\n$editor-bg-color-light : rgba(224, 225, 227, 0.4);\n$editor-bg-color-dark : rgba(224, 225, 227, 0.1);\n\n.EventVisualsMarkdownEditor {\n    border-radius: 10px;\n    overflow: hidden;\n    &.lightmode {\n        .toolbar {\n            border-bottom: 1px #00000033 solid;\n            > * div {\n                filter: invert(0.5);\n            }\n        }\n        .wrapper {\n            background-color: $editor-bg-color-light;\n        }\n    }\n\n    .editor {\n        height: 300px;\n        padding: 0px 10px;\n    }\n\n    .toolbar {\n        background-color: $editor-bg-color-dark;\n        border: none;\n        margin-bottom: 0;\n        border-bottom: 1px #ffffff33 solid;\n    }\n\n    .wrapper {\n        background-color: $editor-bg-color-dark;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EventVisualsMarkdownEditor": "EventVisualsMarkdownEditor__W3OGw",
	"lightmode": "lightmode__lunEx",
	"toolbar": "toolbar__shi1o",
	"wrapper": "wrapper__0Dqn8",
	"editor": "editor__fA6DJ"
};
export default ___CSS_LOADER_EXPORT___;
