// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GlassView__3\\+Xhr{border-radius:10px;transition:all .3s}.GlassView__3\\+Xhr.light__fN\\+mU{border:1px solid rgba(0,0,0,.1);background:rgba(255,255,255,.3)}.GlassView__3\\+Xhr.dark__B-M1R{border:1px solid rgba(221,221,221,.267);background:rgba(0,0,0,.3)}.GlassView__3\\+Xhr.noBorder__M\\+l5W{border:none}.GlassView__3\\+Xhr.scaleOnHover__nlC-T{cursor:pointer}.GlassView__3\\+Xhr.scaleOnHover__nlC-T:hover{transform:scale(1.05)}", "",{"version":3,"sources":["webpack://./src/components/GlassView/styles.module.scss"],"names":[],"mappings":"AAAA,mBACE,kBAAA,CACA,kBAAA,CACA,iCACE,+BAAA,CACA,+BAAA,CAEF,+BACE,uCAAA,CACA,yBAAA,CAEF,oCACE,WAAA,CAEF,uCACE,cAAA,CACA,6CACE,qBAAA","sourcesContent":[".GlassView {\n  border-radius: 10px;\n  transition: all 0.3s;\n  &.light {\n    border: 1px solid rgba(0, 0, 0, 0.1);\n    background: rgba(255, 255, 255, 0.3);\n  }\n  &.dark {\n    border: 1px solid rgba(221, 221, 221, 0.267);\n    background: rgba(0, 0, 0, 0.3);\n  }\n  &.noBorder {\n    border: none;\n  }\n  &.scaleOnHover {\n    cursor: pointer;\n    &:hover {\n      transform: scale(1.05);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GlassView": "GlassView__3+Xhr",
	"light": "light__fN+mU",
	"dark": "dark__B-M1R",
	"noBorder": "noBorder__M+l5W",
	"scaleOnHover": "scaleOnHover__nlC-T"
};
export default ___CSS_LOADER_EXPORT___;
