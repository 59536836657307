import React from 'react'

import Button from 'components/form/Button'

export const AudienceRepublicIntegration = () => {
  return (
    <div className='IntegrationsCont-integration'>
      <img
        src='https://assets.posh.vip/b2/settings-page-icons/e5xQJL_j_400x400.jpeg'
        className='IntegrationsCont-integration-logo'
      />
      <h5>Audience Republic</h5>
      <p>Expand your marketing arsenal</p>
      <Button className='dark light fullWidth'>
        Coming Soon
        {/* Connect
            <img src='https://assets.posh.vip/b2/settings-page-icons/right-arrow-white.svg' /> */}
      </Button>
    </div>
  )
}
