// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiSwitch__MmL0t{border-radius:999px;border:1px solid;display:flex;width:100%;padding:5px}.option__E38rQ{cursor:pointer;transition:.3s;text-align:center;justify-content:center;align-items:center;display:flex;padding:15px 0px;width:100%;border-radius:999px;position:relative;z-index:2}.option__E38rQ.selected__wq86Z{cursor:default}.GlassMultiSwitch__b34Ir{border-radius:999px;border:1px solid;display:flex;width:100%;padding:5px;border-color:rgba(255,255,255,.2);position:relative}.SelectedGlassOption__WzphB{border-radius:999px;transition:.35s ease-in-out;background:red;position:absolute;z-index:1}", "",{"version":3,"sources":["webpack://./src/components/form/MultiSwitch/styles.module.scss"],"names":[],"mappings":"AAAA,oBACE,mBAAA,CACA,gBAAA,CACA,YAAA,CACA,UAAA,CACA,WAAA,CAEF,eACE,cAAA,CACA,cAAA,CACA,iBAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,gBAAA,CACA,UAAA,CACA,mBAAA,CACA,iBAAA,CACA,SAAA,CACA,+BACE,cAAA,CAKJ,yBACE,mBAAA,CACA,gBAAA,CACA,YAAA,CACA,UAAA,CACA,WAAA,CACA,iCAAA,CACA,iBAAA,CAGF,4BACE,mBAAA,CACA,2BAAA,CACA,cAAA,CACA,iBAAA,CACA,SAAA","sourcesContent":[".MultiSwitch {\n  border-radius: 999px;\n  border: 1px solid;\n  display: flex;\n  width: 100%;\n  padding: 5px;\n}\n.option {\n  cursor: pointer;\n  transition: 0.3s;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  padding: 15px 0px;  \n  width: 100%;\n  border-radius: 999px;\n  position: relative;\n  z-index: 2;\n  &.selected {\n    cursor: default;\n  }\n\n}\n\n.GlassMultiSwitch {\n  border-radius: 999px;\n  border: 1px solid;\n  display: flex;\n  width: 100%;\n  padding: 5px;\n  border-color: rgba(255, 255, 255, 0.2);\n  position: relative;\n}\n\n.SelectedGlassOption {\n  border-radius: 999px;\n  transition: 0.35s ease-in-out;\n  background:red;\n  position: absolute;\n  z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MultiSwitch": "MultiSwitch__MmL0t",
	"option": "option__E38rQ",
	"selected": "selected__wq86Z",
	"GlassMultiSwitch": "GlassMultiSwitch__b34Ir",
	"SelectedGlassOption": "SelectedGlassOption__WzphB"
};
export default ___CSS_LOADER_EXPORT___;
