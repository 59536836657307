import React from 'react'

import reviewMap, {IReview} from './reviewMap'

import './styles.scss'

const Review = ({review}: {review: IReview}) => {
  return (
    <div className='reviewSection-review' key={review.organizer}>
      <div className='reviewSection-review-stars'>
        {Array(5)
          .fill(0)
          .map((_, i) => (
            <img key={i} src='https://assets.posh.vip/b2/favorite.svg' />
          ))}
      </div>
      <h5 className='reviewSection-review-title'>&quot;{review.title}&quot;</h5>
      <p className='reviewSection-review-quote text-small'>&quot;{review.quote}&quot;</p>
      <div className='reviewSection-review-author'>
        <img src={review.avi} />
        <p className='text-small'>
          <b>{review.organizer}</b> | {review.organization}
        </p>
      </div>
    </div>
  )
}

const ReviewSection = () => {
  return (
    <div className='LandingPage-section-inner reviewSection center'>
      <h2>What Our Users Say...</h2>
      <div className='reviewSection-reviews'>
        <div>
          <Review review={reviewMap[0]} />
          <Review review={reviewMap[1]} />
          <Review review={reviewMap[2]} />
          {/* <Review review={reviewMap[7]} /> */}
        </div>
        <div>
          <Review review={reviewMap[3]} />
          <Review review={reviewMap[4]} />
          <Review review={reviewMap[5]} />
        </div>
        <div>
          <Review review={reviewMap[9]} />
          <Review review={reviewMap[8]} />
          <Review review={reviewMap[6]} />
        </div>
      </div>
    </div>
  )
}

export default ReviewSection
