import React from 'react'
import {useNavigate} from 'react-router-dom'

import {CommunityArtist} from 'apis/Community'
import useUpdateCommunityClicks from 'apis/Community/useUpdateCommunityClicks'
import {useMixpanel} from 'apis/MixPanelHandler'
import useSessionContext from 'domains/Auth/SessionContext'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {useResourcePageParams} from 'pages/PoshAppLayout'

interface ArtistsProps {
  artists: CommunityArtist[]
  isTrending: boolean
}

const Artists = (props: ArtistsProps) => {
  const {domain} = useResourcePageParams()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {mutateAsync: updateCommunityClicks} = useUpdateCommunityClicks()
  const {artists, isTrending} = props
  const {groupId} = useGroupMatchParams()
  const {currentUser} = useSessionContext()
  const navigate = useNavigate()

  const navigateToArtist = async (url: string) => {
    const artist = artists?.find(a => a.url === url)
    if (!artist) return
    const artistName = artist.artistName ?? `${artist.firstName} ${artist.lastName}`
    await updateCommunityClicks({id: artist._id, category: 'artist'})
    const accountEmail = currentUser?.email
    const accountId = currentUser?._id
    const accountName = currentUser?.firstName + ' ' + currentUser?.lastName
    trackMixpanelEvent('Community Profile Clicked', {
      communityCategory: 'artists',
      communityName: artistName,
      accountEmail,
      accountId,
      accountName,
    })
    navigate(`/c/artist/${url}`)
  }

  return (
    <>
      <div className={`Community-catHolder${!isTrending ? ' wrap' : ''}`}>
        {artists.map(artist => (
          <div
            key={artist._id}
            className='Community-card artist'
            onClick={() => navigateToArtist(artist.url)}
            style={{
              backgroundImage: artist.avi ? `url(${artist.avi})` : `url('https://assets.posh.vip/b2/blank-avi.png')`,
            }}>
            <div className='Community-card-filter' />
            <div className='Community-card-info'>
              <div className='Community-card-info-title'>
                {artist.artistName ? artist.artistName : `${artist.firstName} ${artist.lastName}`}
              </div>
              <div className='Community-card-info-stats'>
                <div>
                  <img src='https://assets.posh.vip/b2/community/price-tag.svg' />
                  <span>
                    {artist.baseBookingFee
                      ? `$${artist.baseBookingFee.toLocaleString('en-US')} Booking Fee`
                      : 'Negotiable Booking Fee'}
                  </span>
                </div>
                {artist.spotifyFollowers && (
                  <div className='spotify'>
                    <img src='https://assets.posh.vip/b2/community/spotify.svg' />
                    <span>{`${artist.spotifyFollowers.toLocaleString('en-US')} Followers`}</span>
                  </div>
                )}
                {artist.tags && <div className='Community-card-type'>{artist.tags[0]?.value}</div>}
              </div>
            </div>
          </div>
        ))}
      </div>
      {isTrending && (
        <div
          className='Community-catHolder-more'
          onClick={() => navigate(`/${domain}/groups/${groupId}/community/all/artists`)}>
          More &rarr;
        </div>
      )}
    </>
  )
}

export default Artists
