import React, {useEffect, useState} from 'react'
import ReactCodeInput from 'react-code-input'
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input'

import {
  ConfirmAndAuthenticateAccountParams,
  useConfirmAndAuthenticateAccount,
} from 'apis/Accounts/useConfirmAndAuthenticateAccount'
import {FetchUnconfirmedAccountOutput} from 'apis/Accounts/useFetchUnconfirmedAccount'
import {useSendUnconfirmedAccountSignupCode} from 'apis/Auth/useSendUnconfirmedAccountSignupCode'
import {uploadImage} from 'apis/Util/useUploadImage'
import Button from 'components/form/Button'
import SingleImagePicker, {PictureState} from 'components/form/ImagePicker'
import Input from 'components/form/Input'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import {useDimensions} from 'hooks/useDimensions'
import {phoneStyle} from 'pages/NewCheckoutPage/LoginForm/PhoneLoginForm/secondStep'
import TOSCheckForm, {TOSCheck} from 'pages/OwnerPage/SignUp/TOSCheckForm'

interface ConfirmAccountEditorProps {
  unconfirmedAccount: FetchUnconfirmedAccountOutput
  invalidateAccount: () => void
}

export const ConfirmAccountEditor = (props: ConfirmAccountEditorProps) => {
  const {unconfirmedAccount, invalidateAccount} = props
  const {userId} = useSessionContext()
  const {mutateAsync: confirmAndAuthenticateAccount, isLoading} = useConfirmAndAuthenticateAccount()
  const {mutateAsync: sendUnconfirmedAccountSignupCode, isLoading: isSendingCode} =
    useSendUnconfirmedAccountSignupCode()
  const {showToast} = useToast()
  const {isMobile} = useDimensions()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verifyCode, setVerifyCode] = useState('')
  const [redirectLink, setRedirectLink] = useState('')
  const [phone, setPhone] = useState('')
  const [hasSentVerificationCode, setHasSentVerificationCode] = useState(false)
  const [checkForms, setCheckForms] = useState<TOSCheck>({
    tos: true,
    smsOptIn: true,
  })
  const [hasSubmittedOnceWithoutSMSOptIn, setHasSubmittedOnceWithoutSMSOptIn] = useState(false)
  const [profilePicture, setProfilePicture] = useState<PictureState>({
    url: 'https://assets.posh.vip/b2/default-avi.jpg',
    file: null,
  })
  const urlParams = new URLSearchParams(window.location.search)
  const encodedRedirectUrlFromParams = urlParams.get('r')

  useEffect(() => {
    if (redirectLink && userId) window.location.href = redirectLink
  }, [redirectLink, userId])

  const confirmAcount = async () => {
    try {
      if (!email && unconfirmedAccount.needsToSetEmail)
        throw new Error('You need to provide an email to confirm this account')
      if (password !== confirmPassword) throw new Error('Passwords do not match')
      if (password.length < 8) throw new Error('Password must be at least 8 characters')
      if (!checkForms.tos) throw new Error('You must agree to the terms of service to continue')
      if (!checkForms.smsOptIn && !hasSubmittedOnceWithoutSMSOptIn) {
        setHasSubmittedOnceWithoutSMSOptIn(true)
        return showToast({
          type: 'warning',
          title: 'Please opt in to text notifications for important event-related alerts.',
        })
      }
      if (!verifyCode && unconfirmedAccount.needsToVerifyPhone) throw new Error('Please enter the verification code')
      let avi
      if (profilePicture.file && profilePicture.url != 'https://assets.posh.vip/b2/default-avi.jpg')
        avi = await uploadImage(profilePicture.file)

      const confirmAccount: ConfirmAndAuthenticateAccountParams = {
        accountId: unconfirmedAccount.accountId,
        smsOptIn: checkForms.smsOptIn,
        email,
        password,
        confirmPassword,
        verificationCode: verifyCode,
        avi,
      }
      const response = await confirmAndAuthenticateAccount(confirmAccount)
      setRedirectLink(encodedRedirectUrlFromParams ?? response.redirectLink)
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }

  const continueFlow = async () => {
    try {
      if (unconfirmedAccount.needsToVerifyPhone && !hasSentVerificationCode) {
        if (!isPossiblePhoneNumber(phone)) throw new Error('Please enter a valid phone number')
        await sendUnconfirmedAccountSignupCode({phone, accountId: unconfirmedAccount.accountId})
        setHasSentVerificationCode(true)
      } else {
        await confirmAcount()
      }
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }

  return (
    <>
      <h3 className='noMargin'>{unconfirmedAccount.title}</h3>
      <p className='noMargin'>{unconfirmedAccount.description}</p>
      {!unconfirmedAccount.hideProfilePicSetting && (
        <SingleImagePicker
          className='ConfirmAccount-imagePicker'
          title='Profile Picture'
          showTitle={false}
          value={profilePicture}
          didReceiveFile={e => setProfilePicture(e)}
          icon={true}
        />
      )}
      {unconfirmedAccount.needsToVerifyPhone ? (
        <>
          {hasSentVerificationCode ? (
            <>
              <p>Enter the code we sent to {phone}</p>
              <ReactCodeInput
                className='CheckoutPage-codeInput'
                type='number'
                fields={6}
                name='phoneCode'
                inputMode={'tel'}
                inputStyle={phoneStyle(isMobile)}
                value={verifyCode}
                onChange={e => setVerifyCode(e)}
              />
            </>
          ) : (
            <>
              <PhoneInput
                className='AccountSettings-phoneInput'
                placeholder='Enter phone number'
                value={phone}
                onChange={e => setPhone(e ?? '')}
                required
                defaultCountry='US'
              />
              <Input
                className='ConfirmAccount-input'
                placeholder='Choose a password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
              />
              <Input
                className='ConfirmAccount-input'
                placeholder='Confirm password'
                type='password'
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                required
              />
            </>
          )}
        </>
      ) : (
        <>
          {unconfirmedAccount.needsToSetEmail && (
            <Input
              className='ConfirmAccount-input'
              placeholder='Set your email'
              type='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          )}
          <Input
            className='ConfirmAccount-input'
            placeholder='Choose a password'
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
          <Input
            className='ConfirmAccount-input'
            placeholder='Confirm password'
            type='password'
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            required
          />
        </>
      )}
      {!hasSentVerificationCode && (
        <TOSCheckForm checkForms={checkForms} setCheckForms={setCheckForms} paragraphStyles={{fontSize: '10px'}} />
      )}
      {redirectLink ? (
        <a href={redirectLink}>Redirect to Dashboard</a>
      ) : (
        <Button
          disabled={isLoading || isSendingCode || redirectLink.length > 0}
          onClick={continueFlow}
          className='blue'>
          <img src={unconfirmedAccount.buttonIcon} />
          {unconfirmedAccount.buttonText}
        </Button>
      )}
      <hr />
      <div>
        <p className='noMargin text-small'>Dont want to create an account?</p>
        <a className='noMargin text-small' onClick={invalidateAccount}>
          Click here to remove your data.
        </a>
      </div>
    </>
  )
}
