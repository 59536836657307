import React, {ComponentProps, useState} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'

import {eventPlaylistLocationSchema, TEventPlaylistLocation} from '@posh/model-types'
import {WHERE_OPTIONS, WhereOption} from '@posh/types'
import {useGetEventPlaylist} from 'apis/Events/playlists/useGetEventPlaylist'
import {
  UnderlinedDropdown,
  UnderlinedDropdownOption,
  UnderlinedDropdownOptionType,
} from 'components/Dropdown/UnderlinedDropdown'
import {useToast} from 'components/toasts/ToastProvider'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import {PartyFoul} from 'pages/PageNotFound/PartyFoul/PartyFoul'

import {EventPlaylist, EventPlaylistProps} from './EventPlaylist/EventPlaylist'

import styles from './EventPlaylistPage.module.scss'

const DARKEN_OPACITY = 0.5

const eventPlaylistBackgroundStyle = ({
  backgroundImage,
}: Pick<EventPlaylistProps, 'backgroundImage' | 'backgroundImagePalette'>): React.CSSProperties => {
  return {
    background: `linear-gradient(180deg, rgba(0, 0, 0, ${DARKEN_OPACITY}) 0%, rgba(0, 0, 0, ${DARKEN_OPACITY}) 100%), url(${backgroundImage}) lightgray`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }
}

const DEFAULT_LOCATION_PRESET = {
  type: 'preset',
  location: 'New York City',
} as const

const useEventPlaylistLocation = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const params = Object.fromEntries(searchParams.entries())
  const parsedSearchParams = eventPlaylistLocationSchema.optional().safeParse(params)

  const location: TEventPlaylistLocation = parsedSearchParams.success
    ? parsedSearchParams.data ?? DEFAULT_LOCATION_PRESET
    : DEFAULT_LOCATION_PRESET

  const changeLocation = (location: TEventPlaylistLocation) => {
    switch (location.type) {
      case 'preset':
        setSearchParams(location)
        break
      case 'custom':
        setSearchParams({
          type: location.type,
          location: location.location,
          // Need to convert to string for URLSearchParams.
          lat: location.lat.toString(),
          long: location.long.toString(),
        })
        break
    }
  }

  return {location, changeLocation}
}

export const EventPlaylistPage = () => {
  const slug = useParams<{slug: string}>().slug ?? ''
  const {location, changeLocation} = useEventPlaylistLocation()
  const [isLoadingNearMeLocation, setIsLoadingNearMeLocation] = useState(false)
  const {showToast} = useToast()

  const showGeolocationError = () => {
    showToast({
      type: 'error',
      title: 'You have not shared your location with POSH. Please share to get events near you.',
    })
  }

  const onSelectLocation: ComponentProps<typeof UnderlinedDropdown>['onSelect'] = (value, cityCoordinates) => {
    if (value === 'Near Me') {
      if (!('geolocation' in navigator)) {
        showGeolocationError()
      } else {
        setIsLoadingNearMeLocation(true)
        navigator.geolocation.getCurrentPosition(
          position => {
            setIsLoadingNearMeLocation(false)
            // request user location
            changeLocation({
              type: 'custom',
              location: 'Near Me',
              lat: position.coords.latitude,
              long: position.coords.longitude,
            })
          },
          () => {
            setIsLoadingNearMeLocation(false)
            showGeolocationError()
          },
        )
      }
    } else if (cityCoordinates) {
      changeLocation({
        type: 'custom',
        location: value,
        long: cityCoordinates[0],
        lat: cityCoordinates[1],
      })
    } else {
      changeLocation({
        type: 'preset',
        location: value as Extract<WhereOption, 'New York City' | 'Miami' | 'Los Angeles'>,
      })
    }
  }

  const whereOptions: UnderlinedDropdownOption[] = [...WHERE_OPTIONS]
    .filter(o => o !== location.location)
    .map(o => ({
      type: 'text' as UnderlinedDropdownOptionType,
      value: o,
    }))
  whereOptions.push({type: 'city-input'})

  const {data, isSuccess, isError} = useGetEventPlaylist({slug, location}, {keepPreviousData: true})

  const backgroundStyle = isSuccess ? eventPlaylistBackgroundStyle(data.playlist) : {}

  return (
    <div className={styles.EventPlaylistPage} style={backgroundStyle}>
      <PoshHeader />

      {isSuccess && (
        <EventPlaylist
          {...data.playlist}
          locationInput={
            <>
              {isLoadingNearMeLocation ? (
                <div style={{display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'flex-end'}}>
                  <h4 style={{fontSize: 30}}>finding location...</h4>
                </div>
              ) : (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                  {location.location !== 'Near Me' && <h4 style={{fontSize: 30}}>in</h4>}
                  <UnderlinedDropdown
                    selectedValue={location.location}
                    options={whereOptions}
                    onSelect={onSelectLocation}
                  />
                </div>
              )}
            </>
          }
        />
      )}
      {isError && <PartyFoul />}
    </div>
  )
}
