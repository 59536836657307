import React from 'react'

export const PoshLogo = () => {
  return (
    <>
      <a href='/'>
        <img className='PoshHeader-poshLogo' src='https://assets.posh.vip/b2/posh-logo-w-typeface.svg' />
      </a>
    </>
  )
}
