import React from 'react'
import ReactTooltip from 'react-tooltip'

import './styles.scss'

const VerifiedGroupBadge = ({size, organizerView, id}: {size: number; organizerView?: boolean; id?: string}) => {
  return (
    <>
      <ReactTooltip className='poshToolTip' effect='solid' place='top' id={id} />
      <div className='VerifiedGroupBadge'>
        <img
          style={{width: `${size}px`, height: `${size}px`}}
          src='https://assets.posh.vip/b2/verify.png'
          data-tip={`${organizerView ? 'Your organization' : 'This organizer'} is verified by POSH.`}
          data-for={id}
        />
      </div>
    </>
  )
}

export default VerifiedGroupBadge
