import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useGetSectionalData} from 'apis/Analytics/useGetSectionalData'
import Button from 'components/form/Button'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {CrossSection} from '../CrossSection/CrossSection'
import {AnalyticsSection} from '../Shared/AnalyticsSection/AnalyticsSection'
import {AttendeeLocation} from './AttendeeLocation/AttendeeLocation'
import {AttendeeRetention} from './charts/AttendeeRetention'
import {GenderRatio} from './charts/GenderRatio'
import {AttendeesErrorState as Error} from './error'
import {AttendeesLoadingState as Loading} from './loading'

import styles from './Attendees.module.scss'

interface AttendeeSectionProps {
  eventId: string
  groupId: string
  isRSVPEvent?: boolean
}

export const AttendeesSection = (props: AttendeeSectionProps) => {
  const {eventId, groupId, isRSVPEvent} = props
  const {domain} = useResourcePageParams()
  const navigate = useNavigate()

  const navigateToAttendeesPage = () => {
    navigate(`/${domain}/groups/${groupId}/events/${eventId}/attendees`)
  }

  const {data, isLoading, isError} = useGetSectionalData({
    eventId,
    groupId,
    dataPoints: ['attendeeGender', 'attendeeEventRetention'],
  })

  const genderCrossSection = data?.at(0)
  const attendeeRetentionCrossSection = data?.at(1)

  if (isLoading) return <Loading onNavigateToAttendeesPage={navigateToAttendeesPage} />
  if (isError) return <Error onNavigateToAttendeesPage={navigateToAttendeesPage} />

  return (
    <AnalyticsSection>
      <AnalyticsSection.Header>
        <AnalyticsSection.Title>Attendee</AnalyticsSection.Title>
        <Button onClick={navigateToAttendeesPage}>View Attendees</Button>
      </AnalyticsSection.Header>
      <CrossSection.Section className={styles.AttendeeSection}>
        {/* Cannot just iterate over server response here as we have some custom front end data for each (changing title, added subtitles)*/}
        <GenderRatio genderCrossSection={genderCrossSection} />
        <AttendeeRetention attendeeRetentionCrossSection={attendeeRetentionCrossSection} />
        {domain !== 'host' && <AttendeeLocation eventId={eventId} isRSVPEvent={isRSVPEvent} />}
      </CrossSection.Section>
    </AnalyticsSection>
  )
}
