import React from 'react'
import {Control, FieldErrors, useController} from 'react-hook-form'
import ReactTooltip from 'react-tooltip'

import {useGetTimezone} from 'apis/Util/useGetTimezone'
import {ClockWithCircle} from 'components/assets/Icons'
import {ToggleableEye} from 'components/assets/ToggleableEye'
import {EventVisualsCategorySelect} from 'components/PageComponents/EventVisuals/Form/Dropdown/EventVisualsCategoryDropdown'
import {EventVisualsDateTimeInput} from 'components/PageComponents/EventVisuals/Form/Input/DateInput'
import {EventVisualsMarkdownEditor} from 'components/PageComponents/EventVisuals/Form/Input/MarkdownInput'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {EventTitleFontStyleProvider} from 'components/Text/EventTitleFontStyleProvider'
import {useDimensions} from 'hooks/useDimensions'
import moment from 'moment-timezone'

import {useEventVisualsContext} from '../../context/EventVisualsContext'
import {EventVisualsCombinedVenueAddressInput} from './inputs/EventVisualsCombinedVenueAddressInput'

import styles from './styles.module.scss'

interface EventDetailsFormSectionProps {
  control: Control<EventVisualsForm>
  errors: FieldErrors<EventVisualsForm>
  showShortDescription?: boolean
  showDescription?: boolean
}

const ICON_SIZE = 20

function Timezone(props: {timezone: string | undefined; isGettingTimezone: boolean}) {
  const {isGettingTimezone} = props
  const timezone = props.timezone || moment.tz.guess()
  const text = (() => {
    if (isGettingTimezone) return 'loading...'
    return timezone.replace(/_/g, ' ').replace(/\//g, ', ')
  })()
  return (
    <p className='text-xs noMargin italic' style={{color: 'white'}}>
      Timezone: {text}
    </p>
  )
}

export function EventDetailsFormSection(props: EventDetailsFormSectionProps) {
  const {control, errors, showShortDescription = true, showDescription = false} = props
  const {isMobile} = useDimensions()
  const {
    palette: {accentColor, lightmode: lightMode, textContrasting},
    fontFamily,
  } = useEventVisualsContext()

  const {
    field: {onChange: setDisplayEndTime, value: displayEndTime},
  } = useController({
    control,
    name: 'displayEndTime',
  })

  const {
    field: {value: startUtc},
  } = useController({
    control,
    name: 'startUtc',
  })
  const {
    field: {value: endUtc},
  } = useController({
    control,
    name: 'endUtc',
  })
  const {
    field: {value: coordinates},
  } = useController({
    control,
    name: 'location.coordinates',
  })
  const {
    field: {value: formTimezone},
  } = useController({
    control,
    name: 'timezone',
  })
  const {
    field: {onChange: setDescriptionOnForm, value: description},
  } = useController({
    control,
    name: 'description',
  })

  const {
    data,
    refetch: refetchTimezone,
    isLoading: isGettingTimezone,
  } = useGetTimezone(
    {lat: coordinates[1], lon: coordinates[0]},
    {enabled: !!(coordinates[0] && coordinates[1]), initialData: {timezone: formTimezone ?? ''}},
  )
  const timezone = data?.timezone

  const startUtcError = errors.startUtc?.message
  const endUtcError = errors.endUtc?.message

  return (
    <>
      <EventVisualsSection headerText='Event Details'>
        <EventVisualsTextInput.Text.Controlled
          control={control}
          lightMode={lightMode}
          accentColor={accentColor}
          name='name'
          placeholder='My Event'
          autoComplete='none'
          rules={{required: 'Event name is required'}}
          size={isMobile ? 30 : 36}
          fontFamily={fontFamily}
        />
        <ReactTooltip id='timezone' effect='solid' place='right'>
          <Timezone timezone={timezone} isGettingTimezone={isGettingTimezone} />
        </ReactTooltip>
        <div className={styles.StartEndDatesContainer} data-tip data-for='timezone'>
          <EventVisualsDateTimeInput.DateTime.Controlled
            control={control}
            name='startUtc'
            placeholder='Start time'
            rules={{
              required: 'Start time is required',
              validate: value => {
                if (endUtc && value && value > endUtc) return 'Start time must be before end time'
                return true
              },
            }}
            accentColor={accentColor}
            lightMode={lightMode}
            leftIcon={<ClockWithCircle height={ICON_SIZE} width={ICON_SIZE} color={accentColor} />}
            timezone={timezone}
          />
          <EventVisualsDateTimeInput.DateTime.Controlled
            control={control}
            name='endUtc'
            accentColor={accentColor}
            placeholder='End time'
            rules={{
              required: 'End time is required',
              validate: value => {
                if (startUtc && value && value < startUtc) return 'End time must be after start time'
                return true
              },
            }}
            lightMode={lightMode}
            rightIcon={
              <ToggleableEye
                size={ICON_SIZE}
                onClick={() => setDisplayEndTime(!displayEndTime)}
                visible={displayEndTime || false}
                color={textContrasting}
              />
            }
            timezone={timezone}
          />
        </div>
        {(!!startUtcError || !!endUtcError) && (
          <p className='error text-small noMargin'>{startUtcError || endUtcError}</p>
        )}
        <EventVisualsCombinedVenueAddressInput
          control={control}
          onChangeAddress={() => {
            refetchTimezone()
          }}
        />
        <EventVisualsCategorySelect.Controlled
          control={control}
          parentCategoryFormName='parentCategory'
          subCategoryFormName='subCategory'
          accentColor={accentColor}
          lightMode={lightMode}
        />
        {showShortDescription && (
          <EventVisualsTextInput.Text.Controlled
            control={control}
            lightMode={lightMode}
            accentColor={accentColor}
            name='shortDescription'
            placeholder='Short Event Summary (optional)'
          />
        )}
        {showDescription && (
          <EventVisualsMarkdownEditor
            placeholder={'Show your attendees what they can expect from your event.'}
            value={description ?? ''}
            onChange={setDescriptionOnForm}
          />
        )}
      </EventVisualsSection>
      <EventTitleFontStyleProvider eventTitleFont={fontFamily} />
    </>
  )
}
