import React, {useEffect, useState} from 'react'

import {EventTicketAndTicketSold} from 'apis/Events/types'
import {kickbackEquationOptions, kickbackTypeOptions} from 'apis/Kickback/KickbackOffer'
import {UpdateKickbackOfferProps, useCreateKickBackOffer} from 'apis/Kickback/useCreateKickBackOffer'
import {FetchAffiliatesResponse} from 'apis/Kickback/useFetchAffiliates'
import {useMixpanel} from 'apis/MixPanelHandler'
import {addUserFilled} from 'components/assets/Icons'
import {NumberInput} from 'components/DynamicForm/Inputs/NumberInput/NumberInput'
import {Select} from 'components/DynamicForm/Inputs/Select/Select'
import Button from 'components/form/Button'
import {sanitizeNumber} from 'components/form/Input'
import RequirePermissions from 'components/RequirePermissions'
import {useToast} from 'components/toasts/ToastProvider'
import pluralize from 'helpers/pluralize'
import {capitalize} from 'lodash'
import Multiselect from 'multiselect-react-dropdown'
import multiSelectStyles from 'pages/GroupPage/Marketing/Filter/multi-select-styles'

import {AFFILIATE_KICKBACK_EXPLANATION, PUBLIC_ATTENDEE_KICKBACK_EXPLANATION} from '../constants'
import {KickbackOfferType} from '../types'

import './styles.scss'

interface AddKickBackOfferProps {
  tickets: EventTicketAndTicketSold[]
  refetch?: () => void
  eventId?: string
  setIsAddingKickBack: React.Dispatch<React.SetStateAction<KickbackOfferType | undefined>>
  offerType: KickbackOfferType
  setIsShowingAddAffiliate: React.Dispatch<React.SetStateAction<boolean>>
  existingAffiliates: FetchAffiliatesResponse
}

export const AddKickBackOffer = (props: AddKickBackOfferProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {tickets, refetch, eventId, setIsAddingKickBack, offerType, setIsShowingAddAffiliate, existingAffiliates} =
    props
  const [newKickBack, setNewKickBack] = useState<UpdateKickbackOfferProps>({
    entity: eventId!,
    entityType: 'event',
    value: undefined,
    type: 'flat',
    equation: 'perOrder',
    enabled: true,
    limitedTo: [],
  })
  const {mutateAsync: createKickBackOffer, isLoading} = useCreateKickBackOffer()
  const {showToast} = useToast()
  useEffect(() => {
    if (newKickBack.equation === 'perOrder') setNewKickBack({...newKickBack, appliesTo: undefined})
    else setNewKickBack({...newKickBack, appliesTo: tickets[0].ticket.id})
  }, [newKickBack.equation])

  const handleCreation = async () => {
    try {
      trackMixpanelEvent('Create Kickback Offer-  Curator Event Kickback Page', newKickBack)
      if (offerType === 'affiliate' && !newKickBack.limitedTo?.length) {
        return showToast({type: 'error', title: 'Please select at least one affiliate'})
      }
      await createKickBackOffer(newKickBack)
      if (refetch) refetch()
      setIsAddingKickBack(undefined)
    } catch (err: any) {
      const modalResTitle: string = err.response.data.error.toString()
      showToast({type: 'error', title: modalResTitle})
    }
  }

  const offerTypeBeneficiary = offerType === 'affiliate' ? 'affiliate' : 'attendee'

  return (
    <>
      <div className='AddKickBack'>
        <div className='AddKickBack-backBtn'>
          <img
            src='https://assets.posh.vip/b2/left-arrow-in-circular-button-black-symbol.svg'
            onClick={() => setIsAddingKickBack(undefined)}
            className='AddKickBack-backBtn'
          />
        </div>
        <div className='AddKickBack-section'>
          <h3>Create {capitalize(offerType)} Kickback Offer</h3>
          <p className='noMargin'>
            {offerType === 'affiliate' ? AFFILIATE_KICKBACK_EXPLANATION : PUBLIC_ATTENDEE_KICKBACK_EXPLANATION}
          </p>
          <p className='noMargin'>
            Below, configure the commission amount {pluralize(offerTypeBeneficiary, 2)} will receive for each purchase
            through their link. Commission is paid out instantly to a debit card the {offerTypeBeneficiary} has
            connected.
          </p>
          <div className='AddKickBack-inputs'>
            <div className='AddKickBack-inputSection'>
              <label>Price Per Kickback</label>
              <p>This is the amount each Kickback {offerTypeBeneficiary} will make per order they sell</p>
              <div className='AddKickBack-priceInputWrapper'>
                <Select
                  onChange={v => setNewKickBack({...newKickBack, type: v})}
                  value={newKickBack.type}
                  options={kickbackTypeOptions}
                />
                <NumberInput
                  placeholder={'Price'}
                  value={newKickBack.value}
                  onChange={v => setNewKickBack({...newKickBack, value: sanitizeNumber(v)})}
                />
              </div>
            </div>
            <div className='AddKickBack-inputSection'>
              <label>Applies</label>
              <p>Choose what the price of your Kickback gets deducted from</p>
              <Select
                onChange={v => setNewKickBack({...newKickBack, equation: v})}
                value={newKickBack.equation}
                options={kickbackEquationOptions}
              />
            </div>
            {newKickBack?.equation !== 'perOrder' && (
              <div className='AddKickBack-inputSection'>
                <label>Limited to Ticket Type</label>
                <Select
                  onChange={v => setNewKickBack({...newKickBack, appliesTo: v})}
                  value={newKickBack.appliesTo}
                  options={tickets.map(t => ({
                    label: t.ticket.name,
                    value: t.ticket.id,
                  }))}
                />
              </div>
            )}
          </div>
          {offerType === 'affiliate' && (
            <>
              <p className='noMargin'>Choose which specific affiliates you want this offer limited to</p>
              <div className='AddKickBack-add-affiliates'>
                {existingAffiliates && existingAffiliates.length > 0 && (
                  <Multiselect
                    selectedValues={newKickBack.limitedTo?.map(id => ({
                      name: existingAffiliates.find(affiliate => affiliate.affiliateId === id)?.firstName,
                      id,
                    }))}
                    options={existingAffiliates.map(affiliate => ({
                      name: affiliate.firstName + ' ' + affiliate.lastName,
                      id: affiliate.affiliateId,
                    }))}
                    onSelect={value =>
                      setNewKickBack({...newKickBack, limitedTo: value.map((v: {id: string}) => v.id)})
                    }
                    onRemove={value =>
                      setNewKickBack({...newKickBack, limitedTo: value.map((v: {id: string}) => v.id)})
                    }
                    displayValue='name'
                    style={multiSelectStyles}
                    closeOnSelect={true}
                    placeholder={'Select Existing Event Affiliates'}
                  />
                )}
                <RequirePermissions
                  requiredPermissions={[
                    {
                      permissionKey: 'add_affiliate',
                      applicableScopes: ['event', 'group'],
                    },
                  ]}>
                  <Button onClick={() => setIsShowingAddAffiliate(true)}>
                    <img src={addUserFilled} style={{filter: 'brightness(0.1)'}} />
                    Invite Affiliates
                  </Button>
                </RequirePermissions>
              </div>
            </>
          )}
          <Button
            onClick={handleCreation}
            disabled={
              !newKickBack.value || isLoading || (offerType === 'affiliate' && newKickBack.limitedTo?.length == 0)
            }
            style={{maxWidth: '311px'}}>
            Create Kickback Offer
          </Button>
        </div>
      </div>
    </>
  )
}
