import React from 'react'

import {OrderModel} from 'apis/Orders'
import {Card} from 'components/Card'

const OrderCardTables = ({order}: {order: OrderModel}) => {
  return (
    <Card title={'Tables'} animationIndex={0}>
      <table className='OrderPage-ticketsTable'>
        {order.tables.map((t, index) => (
          <tr className='OrderPage-ticketsTable-ftd' key={index}>
            <td>
              <table>
                <tr>
                  <td>
                    <div className='OrderPage-ticketsTable-iconHolder'>
                      <img src='https://assets.posh.vip/b2/table+(1).svg' />
                    </div>
                  </td>
                  <td>
                    <span className='OrderPage-ticketsTable-name'>{t.name}</span>
                    <br />${t.price.toFixed(2)}
                  </td>
                  <td>x1</td>
                  <td>${t.price.toFixed(2)}</td>
                </tr>
              </table>
            </td>
          </tr>
        ))}
        {order.promoCode && order.discount && (
          <tr>
            <td style={{border: 'none'}}></td>
            <td style={{border: 'none'}}></td>
            <td style={{border: 'none'}} className='OrderPage-ticketsTable-promoCode'>
              <span>Promo Code Applied:</span>
              <br />
              {order.promoCode}
            </td>
            <td style={{border: 'none'}}>-${order.discount.toFixed(2)}</td>
          </tr>
        )}
      </table>
    </Card>
  )
}

export default OrderCardTables
