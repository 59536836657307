import React, {useEffect, useState} from 'react'

import {ImageApis} from 'apis/Util'
import {useDownloadUnsplashImage} from 'apis/Util/useDownloadUnsplashImage'
import {ImageSearchResponse, useImageSearch} from 'apis/Util/useImageSearch'
import classNames from 'classnames'

interface ImageSelectorProps {
  search: string
  onClick: (image: string) => void
  selectedApi: ImageApis
}

export const ImageSelector = (props: ImageSelectorProps) => {
  const {search, onClick, selectedApi} = props
  const {mutateAsync: searchGiphy} = useImageSearch()
  const {mutateAsync: downloadUnsplashImage} = useDownloadUnsplashImage()
  const [images, setImages] = useState<ImageSearchResponse[]>([])
  const [loading, setLoading] = useState(false)

  const searchImagesAsync = async () => {
    const imageResponse = await searchGiphy({search, selectedApi})
    setImages(imageResponse)
    setLoading(false)
  }

  // debounce search
  useEffect(() => {
    setLoading(true)
    const timeout = setTimeout(() => {
      searchImagesAsync()
    }, 500)
    return () => clearTimeout(timeout)
  }, [search])

  useEffect(() => {
    setLoading(true)
    searchImagesAsync()
  }, [selectedApi])

  const handleClick = async (image: string, downloadUrl?: string) => {
    if (selectedApi === 'unsplash' && downloadUrl) {
      await downloadUnsplashImage({downloadUrl})
      onClick(image)
    } else onClick(image)
  }

  return (
    <>
      <div className={classNames('ImageSearchModal-results', {fetchingImages: loading})}>
        {images.map((img, i) => (
          <div key={i} className='ImageSearchModal-imageWrapper'>
            <img src={img.image} onClick={() => handleClick(img.image, img.downloadUrl)} />
            {img.photographer && img.photographerUrl && (
              <div className='ImageSearchModal-imageWrapper-photoCreds'>
                <span>by&nbsp;</span>
                <a href={img.photographerUrl} target='_blank' rel='noreferrer'>
                  {img.photographer}
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
      {selectedApi === 'giphy' && (
        <div className='ImageSearchModal-attribution'>
          <a href='https://giphy.com/' target='_blank' rel='noreferrer'>
            <img src='https://images.posh.vip/PoweredBy_200px-Black_HorizText.png' />
          </a>
        </div>
      )}
    </>
  )
}
