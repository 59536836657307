import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'
import {isUndefined} from 'lodash'

import useSessionContext from '../../domains/Auth/SessionContext'
import {AccountModel} from '.'

export async function fetchAccountData(accountId: string): Promise<AccountModel> {
  const response = await PoshApi.fetchAccountData(accountId)
  const data = response.data
  return data.account
}

export const fetchAccountQueryKey = (userId: string) => ['account', userId]

export function useFetchAccount(userId: string) {
  const {setCurrentUser, token} = useSessionContext()
  return useQuery(['account', userId], () => fetchAccountData(userId), {
    enabled: false,
    onSuccess: account => {
      const phoneOrEmailExists = !isUndefined(account.phone) || !isUndefined(account.email)
      if (token && phoneOrEmailExists) {
        localStorage.setItem('currentUser', JSON.stringify(account))
        setCurrentUser(account)
      }
    },
  })
}

export function useManualFetchAccount(userId?: string) {
  return useQuery(
    ['account', userId],
    () => {
      if (!userId) return
      return fetchAccountData(userId)
    },
    {
      enabled: false,
    },
  )
}
