import React, {useState} from 'react'

import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {LoginLogo, LoginSvg} from 'pages/LoginPage/LoginLinks'

import {useSendPasswordEmail} from '../../../apis/Accounts/useSendPasswordEmail'

import './index.scss'

const RequestPasswordReset = () => {
  const [email, setEmail] = useState<string>('')
  const {mutateAsync: send, isLoading} = useSendPasswordEmail()
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const onSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setErrorMessage('')
    setSuccessMessage('')
    if (email.length === 0) return setErrorMessage('Please enter your email.')
    try {
      await send({email: email.toLowerCase()})
      return setSuccessMessage('Email is sent and should arrive soon!')
    } catch (error) {
      return setErrorMessage(error.response.data.message)
    }
  }
  return (
    <>
      <PoshHelmet title='POSH - Reset Password' />
      <div className='RequestPasswordReset'>
        <LoginSvg />
        <form className='RequestPasswordReset-container'>
          <LoginLogo />
          <label className='white'>What is your email?</label>
          <Input
            type='text'
            className='square'
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder='Email'
          />
          {!successMessage && (
            <Button
              className='gold fullWidth'
              type='submit'
              disabled={isLoading}
              isLoading={isLoading}
              onClick={onSubmit}>
              Reset Password
            </Button>
          )}
          {errorMessage && <p className='error center noMargin'>{errorMessage}</p>}
          {successMessage && <p className='success center noMargin'>{successMessage}</p>}
        </form>
      </div>
    </>
  )
}

export default RequestPasswordReset
