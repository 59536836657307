import React, {useState} from 'react'
import {Route, Routes} from 'react-router-dom'

import {useCheckForRefundRequest} from 'apis/Events/useCheckForRefundRequest'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {exportReport, refund} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {get} from 'lodash'
import {NewCustomCheckoutFields} from 'pages/EventManagementPages/Settings/NewCustomCheckoutFields'

import useFetchEvent from '../../../apis/Events/useFetchEvent'
import {useResourcePageParams} from '../../PoshAppLayout'
import {EventExportModal} from '../EventExport'
import {RefundRequestModal} from '../RefundRequestModal'
import {EventCheckoutSettings} from './EventCheckoutSettings'
import {EventCovidSettings} from './EventCovidSettings'
import {EventEmbed} from './EventEmbed'
import {EventMarketingSettings} from './EventMarketingSettings'
import {EventPaymentPlanSettings} from './EventPaymentPlanSettings'
import {EventPoliciesSettings} from './EventPoliciesSettings'
import {getEventSettingsTabMap} from './eventSettings'
import {EventSettingCards} from './EventSettingsCards'
import {EventThirdPartySettings} from './EventThirdPartySettings'

import './styles.scss'

const Settings = () => {
  const {eventId} = useResourcePageParams()
  const {data: eventData} = useFetchEvent(eventId!)
  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)
  const isPaymentPlanEnabled = get(eventData, 'event.paymentPlans', []).length > 0
  const {
    data: checkRefundRequest,
    isSuccess: checkSuccess,
    refetch: triggerCheckRefetch,
    isFetching: isCheckFetching,
  } = useCheckForRefundRequest(eventId!)

  const settingsTabMap = getEventSettingsTabMap({
    isPaymentPlanEnabled,
    isVerified: group?.verified,
    hasThirdPartySite: group?.hasThirdPartySite ?? false,
  })

  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)
  const handleRefundClick = () => {
    setIsRefundModalOpen(true)
  }

  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const handleExportClick = () => {
    setIsExportModalOpen(true)
  }

  return (
    <>
      <PoshHelmet title={`Settings - ${get(eventData, 'event.name', '')}`} />
      <span className='EventUtilities'>
        <Button className='EventUtilities-small-btn outline dark light' onClick={handleExportClick}>
          <img src={exportReport} /> Export Event Report
        </Button>
        {checkSuccess && !eventData?.event?.isRSVPEvent && (
          <Button
            className='EventUtilities-small-btn outline dark light'
            onClick={handleRefundClick}
            disabled={!!checkRefundRequest?.previousRequest}>
            <img src={refund} /> {checkRefundRequest?.requestRefundBtnDisplay ?? ''}
          </Button>
        )}
      </span>
      <RefundRequestModal
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
        triggerCheckRefetch={triggerCheckRefetch}
        isCheckFetching={isCheckFetching}
      />
      <EventExportModal isOpen={isExportModalOpen} onClose={() => setIsExportModalOpen(false)} />
      <div className='EventSettings'>
        {eventData && (
          <Routes>
            <Route
              path='checkout'
              element={<EventCheckoutSettings event={eventData.event} currency={group?.currency ?? 'USD'} />}
            />
            <Route path='marketing' element={<EventMarketingSettings event={eventData.event} />} />
            <Route path='policies' element={<EventPoliciesSettings event={eventData.event} />} />
            <Route path='thirdparty' element={<EventThirdPartySettings event={eventData.event} />} />
            <Route path='covid' element={<EventCovidSettings event={eventData.event} />} />
            <Route path='paymentplans' element={<EventPaymentPlanSettings event={eventData.event} />} />
            <Route path='embed' element={<EventEmbed />} />
            <Route path='new_custom_checkout_fields' element={<NewCustomCheckoutFields />} />
            <Route path='*' element={<EventSettingCards event={eventData.event} settingsTabMap={settingsTabMap} />} />
          </Routes>
        )}
      </div>
    </>
  )
}

export default Settings
