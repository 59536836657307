import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetEventsForEventPlaylistOptions = ReactQueryOptions['events']['playlists']['manage']['getEventsForEventPlaylist']
export type GetEventsForEventPlaylistInput = RouterInputs['events']['playlists']['manage']['getEventsForEventPlaylist']
export type GetEventsForEventPlaylistOutput = RouterOutput['events']['playlists']['manage']['getEventsForEventPlaylist']

export type TEventForEventPlaylist = GetEventsForEventPlaylistOutput['events'][number]

export function useGetEventsForEventPlaylist(
  input: GetEventsForEventPlaylistInput,
  opts?: GetEventsForEventPlaylistOptions,
) {
  return trpc.events.playlists.manage.getEventsForEventPlaylist.useQuery(input, opts)
}
