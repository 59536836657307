import React, {useEffect, useState} from 'react'

import {useLoginWithEmail} from 'apis/Auth/useLoginWithEmail'
import {useUpdateCartWithAccountId} from 'apis/Carts/useUpdateCartWithAccountId'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import CheckoutButton from 'components/form/CheckoutButton'
import {TextFieldVariants} from 'components/form/TextField'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'
import {LoginFlow} from 'pages/LoginPage'
import CheckoutField from 'pages/NewCheckoutPage/CheckoutField/CheckoutField'

import {ActionButtons} from '../ActionButtons'

export interface EmailLoginFormSecondStepProps {
  setHasAccount: React.Dispatch<React.SetStateAction<boolean>>
  email: string
  setShowSignUp: React.Dispatch<React.SetStateAction<boolean>>
  setStartedWithEmail: React.Dispatch<React.SetStateAction<boolean>>
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setLoginFlow: React.Dispatch<React.SetStateAction<LoginFlow>>
}

export const EmailLoginFormSecondStep = (props: EmailLoginFormSecondStepProps) => {
  const {setHasAccount, email, setShowSignUp, setStartedWithEmail, setLoginFlow, setEmail} = props
  const {cartFormData, isRSVP, cartId} = useLiveCartContext()
  const [password, setPassword] = useState<string | undefined>('')
  const [error, setError] = useState(false)
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {mutate: updateCartWithAccountId} = useUpdateCartWithAccountId()
  const {
    mutateAsync: login,
    isLoading,
    isError,
  } = useLoginWithEmail({
    onSuccess: () => {
      updateCartWithAccountId({cartId})
    },
  })
  useEffect(() => {
    setPassword(cartFormData['password']?.value)
  }, [cartFormData])

  useEffect(() => {
    if (isError) {
      setError(true)
    }
  }, [isError])

  const handleLogin = async () => {
    trackMixpanelEvent('Email Auth Clicked-  Email Auth Form')
    if (!password) {
      trackMixpanelEvent('Email Auth Fail-  Email Auth Form')
      setError(true)
      return
    }
    try {
      await login({email, password})
      trackMixpanelEvent('Email Auth Success-  Email Auth Form')
    } catch (e: any) {
      trackMixpanelEvent('Email Auth Fail-  Email Auth Form')
      setError(true)
    }
  }

  const handleCreateNewAccount = () => {
    setHasAccount(false)
    setError(false)
    setShowSignUp(false)
    setStartedWithEmail(false)
    setLoginFlow('phone')
    setEmail('')
    cartFormData['email'].value = ''
  }

  const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      await handleLogin()
    }
  }
  return (
    <>
      {isRSVP && <h4 className={'center'}>Login to RSVP</h4>}
      <p className={'center noMargin'}>{email}</p>
      <CheckoutField
        field_type={'input'}
        field_key={'password'}
        value={cartFormData['password']?.value}
        variant={TextFieldVariants.DARK}
        placeholder={'Password'}
        nativeProps={{
          type: 'password',
          onKeyDown: e => onKeyDown(e),
          className: 'CheckoutLargeType',
          autoFocus: true,
        }}
        isCustomField={false}
      />
      {isRSVP ? (
        <Button className='fit center' disabled={isLoading} isLoading={isLoading} onClick={handleLogin}>
          RSVP
        </Button>
      ) : (
        <CheckoutButton className={'Gold'} disabled={isLoading} onClick={handleLogin}>
          {isLoading ? 'LOGGING IN' : 'LOGIN'}
        </CheckoutButton>
      )}
      {error && <p className='error center noMargin'>LOGIN FAILED</p>}
      {!isRSVP && <ActionButtons handleCreateNewAccount={handleCreateNewAccount} />}
    </>
  )
}
