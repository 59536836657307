import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface CreateRefundRequestParams {
  eventId: string
  reason: string
  description: string
  contact: string
}

export async function createRefundRequest(params: CreateRefundRequestParams): Promise<string> {
  const response = await PoshApi.createRefundRequest(params)
  return response.data
}

export function useCreateRefundRequest() {
  return useMutation((params: CreateRefundRequestParams) => createRefundRequest(params))
}
