import React from 'react'

import './styles.scss'

const PoshLoaderTransition = ({
  pageIsChanging,
  fadeOutTransition,
}: {
  pageIsChanging: boolean
  fadeOutTransition: boolean
}) => {
  if (!pageIsChanging) return <></>
  return (
    <div className={`PoshLoaderTransition ${fadeOutTransition ? 'fadeOutTransition' : ''}`}>
      <div>
        <img className='PoshLoaderTransition-boat' src='https://assets.posh.vip/b2/Posh-Boat-Logo.svg' />
        <svg
          version='1.1'
          id='squiggly-line-svg'
          xmlns='http://www.w3.org/2000/svg'
          x='0px'
          y='0px'
          viewBox='0 0 92.8 12'
          className='PoshLoaderTransition-waves'>
          <path d='M1.4,6c0,0,9-9,18,0s18,0,18,0s9-9,18,0s18,0,18,0s9-9,18,0' />
        </svg>
      </div>
    </div>
  )
}

export default PoshLoaderTransition
