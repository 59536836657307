import React, {useState} from 'react'

import {FetchEventsAdminOutputItem, useFetchEvents} from 'apis/AdminDashboard/events/useFetchAdminEvents'
import {useUpdateEventPoshAdmin} from 'apis/AdminDashboard/events/useUpdateEventPoshAdmin'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useToast} from 'components/toasts/ToastProvider'
import {flatten} from 'lodash'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import CRUDTable, {CRUDTableDataMap, CRUDTableMap} from '../CRUDTable'

const EventTableMap: CRUDTableMap = [
  {
    label: 'Id',
    key: 'id',
    type: 'string',
    editable: false,
  },
  {
    label: 'Event Name',
    key: 'Name',
    type: 'string',
    editable: true,
  },
  {
    label: 'Posh Fee',
    key: 'Posh Fee',
    type: 'string',
    editable: false,
  },
  {
    label: 'Start',
    key: 'Start',
    type: 'string',
    editable: false,
  },
  {
    label: 'End',
    key: 'End',
    type: 'string',
    editable: false,
  },
  {
    label: 'Created At',
    key: 'Created At',
    type: 'string',
    editable: false,
  },
  {
    label: 'Url',
    key: 'Url',
    type: 'string',
    editable: true,
  },
  {
    label: 'Status',
    key: 'Status',
    type: 'multiSelect',
    editable: true,
    options: ['live', 'deleted', 'draft'],
  },
  {
    label: 'Featured',
    key: 'Featured',
    type: 'multiSelect',
    editable: true,
    options: ['false', 'true'],
  },
]

export interface EventData extends CRUDTableDataMap {
  key: 'event'
  data: FetchEventsAdminOutputItem[]
}

const PoshEvents = () => {
  const {showToast} = useToast()
  const [searchText, setSearchText] = useState('')

  const {data, fetchNextPage, refetch, isLoading: loading} = useFetchEvents({limit: 100, searchParam: searchText})

  const {mutate: updateEvent} = useUpdateEventPoshAdmin({
    onSuccess() {
      refetch()
    },
    onError: error => showToast({type: 'error', title: 'Error updating Event: ' + error.message}),
  })

  const allEvents = flatten(data?.pages.map(i => i.items))

  const fetchMoreUsers = () => {
    fetchNextPage({pageParam: data?.pages[data?.pages.length - 1].nextCursor ?? undefined})
  }

  const onUpdate = async ({id, item}: {id: string; item?: FetchEventsAdminOutputItem}) => {
    if (!item) return
    updateEvent({
      eventId: item.id,
      url: item.Url,
      name: item.Name,
      status: item.Status as 'live' | 'deleted' | 'draft',
      featured: item.Featured as 'true' | 'false',
    })
  }

  const copyEventId = async ({id}: {id: string}) => {
    const event = allEvents?.find(c => c.id === id)
    if (event) {
      navigator.clipboard.writeText(event.id)
      showToast({type: 'success', title: `Copied Event id to clipboard`})
    }
  }

  const CRUDPayload: EventData = {
    key: 'event',
    data: allEvents as FetchEventsAdminOutputItem[],
    refetchDataFunction: refetch,
    entityName: 'Posh Event',
    updateFunction: onUpdate,
    copyFunction: copyEventId,
  }

  return (
    <>
      <BackAndTitle title={'POSH Events'} />
      {loading && <SpinLoader height={40} />}
      <Input
        style={{margin: 'auto', display: 'block', width: '400px'}}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        placeholder='Search'
      />
      <CRUDTable payload={CRUDPayload} map={EventTableMap} />
      <Button
        style={{margin: 'auto', textAlign: 'center'}}
        onClick={() => {
          fetchMoreUsers()
        }}>
        Load More Users
      </Button>
    </>
  )
}

export default PoshEvents
