interface Feature {
  name: string
  hasFeature: boolean
}

interface Competitor {
  name: string
  logo: string
  features: Feature[]
}

const competitorMap: Competitor[] = [
  {
    name: 'Eventbrite',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Eventbrite_Logo.svg/2560px-Eventbrite_Logo.svg.png',
    features: [
      {
        name: 'Custom Branding',
        hasFeature: false,
      },
      {
        name: 'Free & Paid Ticketing',
        hasFeature: true,
      },
      {
        name: 'Instant Daily Payouts',
        hasFeature: false,
      },
      {
        name: 'Community Management',
        hasFeature: false,
      },
      {
        name: 'Unlimited SMS Blasts',
        hasFeature: false,
      },
      {
        name: 'Affiliate Payouts',
        hasFeature: false,
      },
    ],
  },
  {
    name: 'Tixr',
    logo: 'https://assets.website-files.com/61b289272ef894745513a32d/6293b5f0d8cf040e06c226ef_tixr-white.png',
    features: [
      {
        name: 'Custom Branding',
        hasFeature: false,
      },
      {
        name: 'Free & Paid Ticketing',
        hasFeature: true,
      },
      {
        name: 'Instant Daily Payouts',
        hasFeature: false,
      },
      {
        name: 'Community Management',
        hasFeature: false,
      },
      {
        name: 'Unlimited SMS Blasts',
        hasFeature: false,
      },
      {
        name: 'Affiliate Payouts',
        hasFeature: false,
      },
    ],
  },
  {
    name: 'POSH',
    logo: 'https://assets.posh.vip/b2/posh-rectangle-typeface.svg',
    features: [
      {
        name: 'Custom Branding',
        hasFeature: true,
      },
      {
        name: 'Free & Paid Ticketing',
        hasFeature: true,
      },
      {
        name: 'Instant Daily Payouts',
        hasFeature: true,
      },
      {
        name: 'Community Management',
        hasFeature: true,
      },
      {
        name: 'Unlimited SMS Blasts',
        hasFeature: true,
      },
      {
        name: 'Affiliate Payouts',
        hasFeature: true,
      },
    ],
  },
  {
    name: 'Partiful',
    logo: 'https://uploads-ssl.webflow.com/5faf3603edfe9d85e09a1fad/62f281761df5947cac9a898d_logo.png',
    features: [
      {
        name: 'Custom Branding',
        hasFeature: false,
      },
      {
        name: 'Free & Paid Ticketing',
        hasFeature: false,
      },
      {
        name: 'Instant Daily Payouts',
        hasFeature: false,
      },
      {
        name: 'Community Management',
        hasFeature: false,
      },
      {
        name: 'Unlimited SMS Blasts',
        hasFeature: true,
      },
      {
        name: 'Affiliate Payouts',
        hasFeature: false,
      },
    ],
  },
  {
    name: 'DICE',
    logo: 'https://assets.posh.vip/b2/DICE_logo_2022.svg.png',
    features: [
      {
        name: 'Custom Branding',
        hasFeature: false,
      },
      {
        name: 'Free & Paid Ticketing',
        hasFeature: true,
      },
      {
        name: 'Instant Daily Payouts',
        hasFeature: false,
      },
      {
        name: 'Community Management',
        hasFeature: false,
      },
      {
        name: 'Unlimited SMS Blasts',
        hasFeature: false,
      },
      {
        name: 'Affiliate Payouts',
        hasFeature: false,
      },
    ],
  },
]

export default competitorMap
