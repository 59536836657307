import React, {useState} from 'react'

import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'
import {isEmpty} from 'lodash'

import {ContactInformationModal, RequestDetailsModal} from '../../modals'
import {EventOwners, PendingTableProps} from '../../types'
import {PendingDynamicBody} from './dynamicBody'

export const PendingTable = (props: PendingTableProps) => {
  const {refunds} = props

  // Confirmation Modal
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [confirmationText, setConfirmationText] = useState({
    title: '',
    bodyText: '',
    confirmationMessage: '',
    confirmButtonText: '',
  })
  const [confirmationHandlers, setConfirmationHandlers] = useState({
    onClose: () => setIsConfirmationModalOpen(false),
    onClick: () => setIsConfirmationModalOpen(false),
  })
  const confirmationModalProps = {
    text: setConfirmationText,
    click: setConfirmationHandlers,
    open: (flag: boolean) => setIsConfirmationModalOpen(flag),
  }

  // Contact Modal
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [contactOwners, setContactOwners] = useState<EventOwners[]>([])
  const contactModalStates = {
    setContactOwners: setContactOwners,
    setIsContactModalOpen: setIsContactModalOpen,
  }

  // Request Details Modal
  const [isRequestDetailsModalOpen, setIsRequestDetailsModalOpen] = useState(false)
  const [requestDetails, setRequestDetails] = useState({
    reason: '',
    description: '',
    supportInstructions: '',
  })
  const requestDetailsModalStates = {
    setIsRequestDetailsModalOpen: setIsRequestDetailsModalOpen,
    setRequestDetails: setRequestDetails,
  }

  return (
    <>
      {isEmpty(refunds) ? (
        <p>No Pending Requests</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Organizer</th>
              <th>Event Name (Link)</th>
              <th>Event GMV</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {refunds.map(refund => (
              <PendingDynamicBody
                key={refund._id.toString()}
                refund={refund}
                confirmationModalStates={confirmationModalProps}
                contactModalStates={contactModalStates}
                requestDetailsStates={requestDetailsModalStates}
              />
            ))}
          </tbody>
        </table>
      )}
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={confirmationHandlers.onClose}
        onClick={confirmationHandlers.onClick}
        confirmButtonText={confirmationText.confirmButtonText}
        title={confirmationText.title}
        bodyText={confirmationText.bodyText}
        confirmationMessage={confirmationText.confirmationMessage}
        successMessage={false}
      />
      <ContactInformationModal
        isOpen={isContactModalOpen}
        onClose={() => {
          setIsContactModalOpen(false)
        }}
        owners={contactOwners}
      />
      <RequestDetailsModal
        isOpen={isRequestDetailsModalOpen}
        onClose={() => {
          setIsRequestDetailsModalOpen(false)
        }}
        reason={requestDetails.reason}
        description={requestDetails.description}
        supportInstructions={requestDetails.supportInstructions}
      />
    </>
  )
}
