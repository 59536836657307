import React from 'react'
import {useNavigate} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {Affiliate, FetchAffiliatesResponse} from 'apis/Kickback/useFetchAffiliates'
import {isEmpty} from 'lodash'

import './styles.scss'

interface TopAffilationsProps {
  affiliates?: FetchAffiliatesResponse
}

interface AffiliateCardProps {
  affiliate: Affiliate
}

const AffiliateCard = (props: AffiliateCardProps) => {
  const {affiliate} = props
  const navigate = useNavigate()
  return (
    <div
      className={`TopAffiliations-Card ${affiliate.isDisabled ? 'disabled' : ''}`}
      onClick={() => {
        if (!affiliate.isDisabled) navigate(affiliate.trackingLink)
      }}>
      <img
        data-tip={
          affiliate.isDisabled ? `${affiliate.firstName} cannot generate revenue until they have enabled payouts` : ''
        }
        src={affiliate.avi}
      />
      <h3>{affiliate.firstName + ' ' + affiliate.lastName}</h3>
      <p style={{margin: 0}}>
        ${affiliate.revenueGenerated.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}{' '}
        Rev. Generated
      </p>
      <p style={{margin: 0}}>{affiliate.clicks} Clicks</p>
    </div>
  )
}

const TopAffilations = (props: TopAffilationsProps) => {
  const {affiliates} = props
  return (
    <div className='TopAffiliations'>
      {!isEmpty(affiliates) ? (
        affiliates?.map((affiliate, index) => <AffiliateCard key={index} affiliate={affiliate} />)
      ) : (
        <p className='TopAffiliations-emptyMessage'>You don&apos;t have any affiliates yet.</p>
      )}
      <ReactTooltip className='poshToolTip' />
    </div>
  )
}

export default TopAffilations
