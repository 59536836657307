import React from 'react'

import {link} from 'components/assets/Icons'

interface TrackingLinkCardProps {
  trackingLink?: string
}

export const TrackingLinkCard = (props: TrackingLinkCardProps) => {
  const {trackingLink} = props
  if (!trackingLink) return null
  return (
    <div className='ApprovalModal-Content-trackingLinkWrapper'>
      <img src={link} />
      <p className='noMargin'>{trackingLink}</p>
    </div>
  )
}
