import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {CommunityCategory, GalleryImage} from 'apis/Community'
import {CreateBookingRequestData} from 'apis/Community/useCreateBookingRequest'
import {getGalleryImageUrls} from 'pages/CommunityDashboard/dashboardHelpers'

import EntityPictureGallery from '../EntityPictureGallery'
import EntityBookingRequest from './EntityBookingRequest'
import EntityDetails from './EntityDetails'
import CommunityStaffModal from './StaffModal'
import SuccessfulBookingRequest from './SuccessfulBookingRequest'

export interface DetailEntity {
  id?: string
  title?: string
  description?: string
  clarifier?: string
  price?: string
  yearsOfExperience?: number
  people?: string
  image?: string
  website?: string
  email?: string
  instagram?: string
  communityCategory?: CommunityCategory
  spotifyUrl?: string
  spotifyFollowers?: string
  galleryImages?: GalleryImage[]
}

interface DetailsModalProps {
  selectedDetailEntity: DetailEntity
  setDetailsModalOpen: (value: React.SetStateAction<boolean>) => void
}

const DetailsModal = (props: DetailsModalProps) => {
  const {selectedDetailEntity, setDetailsModalOpen} = props
  const navigate = useNavigate()
  const [showBookingRequest, setShowBookingRequest] = useState(false)
  const [successfulRequestMade, setSuccessfulRequestMade] = useState(false)
  const [bookingDetails, setBookingDetails] = useState<CreateBookingRequestData>({
    communityId: selectedDetailEntity.id!,
    message: '',
    location: '',
    bookingDate: new Date(),
    startTime: undefined,
    endTime: undefined,
    communityCategory: selectedDetailEntity.communityCategory!,
    eventName: '',
  })
  const [gallery, setGallery] = useState<string[] | undefined>(undefined)

  useEffect(() => {
    if (
      selectedDetailEntity.galleryImages &&
      selectedDetailEntity.galleryImages.length > 0 &&
      selectedDetailEntity.image
    ) {
      const galleryImages = getGalleryImageUrls(selectedDetailEntity.galleryImages)
      setGallery([selectedDetailEntity.image, ...galleryImages])
    }
  }, [selectedDetailEntity])

  const handleSignUpRedirect = () => {
    navigate('/community/signup')
  }

  return (
    <>
      <div className='CommunityModal'>
        {selectedDetailEntity.communityCategory == 'staff' ? (
          <CommunityStaffModal selectedDetailEntity={selectedDetailEntity} setDetailsModalOpen={setDetailsModalOpen} />
        ) : (
          <div className='cDetailsModal'>
            {gallery && gallery.length > 1 ? (
              <EntityPictureGallery gallery={gallery} />
            ) : (
              <img
                src={selectedDetailEntity.image ?? 'https://assets.posh.vip/b2/default-avi.jpg'}
                className='cDetailsModal-cover'
              />
            )}
            {!showBookingRequest && (
              <EntityDetails
                selectedDetailEntity={selectedDetailEntity}
                showBookingRequest={showBookingRequest}
                setShowBookingRequest={setShowBookingRequest}
                handleSignUpRedirect={handleSignUpRedirect}
              />
            )}
            {!successfulRequestMade && showBookingRequest && (
              <EntityBookingRequest
                selectedDetailEntity={selectedDetailEntity}
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                setSuccessfulRequestMade={setSuccessfulRequestMade}
                handleSignUpRedirect={handleSignUpRedirect}
              />
            )}
            {!successfulRequestMade && (
              <img
                src='https://assets.posh.vip/b2/cancel.svg'
                className='cDetailsModal-x'
                onClick={() => setDetailsModalOpen(false)}
              />
            )}
            {successfulRequestMade && showBookingRequest && (
              <SuccessfulBookingRequest
                selectedDetailEntity={selectedDetailEntity}
                bookingDetails={bookingDetails}
                setDetailsModalOpen={setDetailsModalOpen}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default DetailsModal
