import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {useGetServiceBookings} from 'apis/Services/useGetServiceBookings'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {get} from 'lodash'
import moment from 'moment'

import useFetchEvent from '../../../apis/Events/useFetchEvent'
import {useResourcePageParams} from '../../PoshAppLayout'
import {ActiveBookings} from './components/ActiveBookings'
import {PhotographyServiceFlow} from './components/PhotographyService/PhotographyServiceFlow'
import {Services} from './components/Services'

import './styles.scss'

const ServicesPage = () => {
  const {eventId, groupId} = useResourcePageParams()
  const {data: eventData} = useFetchEvent(eventId!)
  const eventStartDate = moment.utc(eventData?.event.startUtc).toDate()

  const {data, isSuccess} = useGetServiceBookings({eventId: eventId!})

  return (
    <div>
      <PoshHelmet title={`Services - ${get(eventData, 'event.name', '')}`} />
      <Routes>
        <Route
          path=''
          element={
            <>
              <div className='eventSection noPadding' style={{borderTop: 'none', marginBottom: 15}}>
                <div className='eventSection-header'>
                  <h3>Event Services</h3>
                </div>

                <p className='noMargin'>
                  Professional event services elevate your event&apos;s quality and increase ticket sales. POSH&apos;s
                  AI booking system vets professionals, coordinates event details, and handles payouts for you.
                </p>
              </div>

              {isSuccess && data.bookings.length > 0 && (
                <div className='eventSection noMargin'>
                  <ActiveBookings bookings={data.bookings} />
                </div>
              )}

              <div className='eventSection'>
                <Services pending={false} />
              </div>
            </>
          }
        />
        <Route
          path='photographer'
          element={
            <PhotographyServiceFlow time={eventStartDate} pending={false} eventId={eventId!} groupId={groupId!} />
          }
        />
      </Routes>
    </div>
  )
}

export default ServicesPage
