import React, {useEffect, useState} from 'react'
import ReactCodeInput from 'react-code-input'

import {useCheckLoginCode} from 'apis/Auth/useCheckLoginCode'
import {useCheckSignupCode} from 'apis/Auth/useCheckSignupCode'
import {useUpdateCartWithAccountId} from 'apis/Carts/useUpdateCartWithAccountId'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'
import useGetCurrentPathWithParams from 'helpers/useGetCurrentPathWithParams'
import {useDimensions} from 'hooks/useDimensions'

export interface PhoneLoginFormSecondStepProps {
  phone: string
  hasExistingAccount: boolean
  needsToConfirmAccount: boolean
  setSignupToken: React.Dispatch<React.SetStateAction<string | undefined>>
  setHasAccount: React.Dispatch<React.SetStateAction<boolean>>
  setShowSignUp: React.Dispatch<React.SetStateAction<boolean>>
}

export const phoneStyle = (isMobile: boolean) => {
  return {
    fontSize: isMobile ? '16px' : '19px',
    padding: isMobile ? '12px 12px' : '14px 15px',
    border: '1px solid #303133',
    borderRadius: '5px',
    background: '0 0',
    color: 'white',
    maxWidth: '50px',
    TextAlign: 'center',
    margin: isMobile ? '4px 3px' : '7px 4px',
  }
}

export const codeComponentStyle = {
  display: 'flex !important',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '10px',
}

export const PhoneLoginFormSecondStep = (props: PhoneLoginFormSecondStepProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {phone, hasExistingAccount, setSignupToken, setHasAccount, setShowSignUp, needsToConfirmAccount} = props
  const [token, setToken] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const {isRSVP, cartId} = useLiveCartContext()
  const {mutate: updateCartWithAccountId} = useUpdateCartWithAccountId()
  const {
    mutateAsync: checkLoginCode,
    isError: isErrorPhoneLogin,
    isLoading: isCheckingLoginCode,
  } = useCheckLoginCode(phone, {
    onSuccess: (_, {setSessionOnSuccess = true}) => {
      if (setSessionOnSuccess) {
        updateCartWithAccountId({cartId})
      }
    },
  })
  const {
    mutateAsync: checkSignupCode,
    isError: isErrorPhoneSignup,
    isLoading: isCheckingSignupCode,
  } = useCheckSignupCode()
  const {encodedPath} = useGetCurrentPathWithParams()

  const isLoading = isCheckingLoginCode || isCheckingSignupCode

  const {isMobile} = useDimensions()
  const text = `Enter the verification code sent to ${phone} ${
    hasExistingAccount ? ' to login to your POSH account' : ' to create your POSH account'
  }`
  const buttonClassName = isRSVP ? 'fit center' : 'CheckoutButton bold gold'
  const buttonText = () => {
    if (isRSVP) return needsToConfirmAccount ? 'Confirm Account' : hasExistingAccount ? 'RSVP' : 'Continue'
    return needsToConfirmAccount ? 'Confirm Account' : hasExistingAccount ? 'Login' : 'Join POSH'
  }
  const onVerify = async () => {
    trackMixpanelEvent('Verify Phone Number Login Click-  Checkout')
    setErrorMessage('')
    if (token.length !== 6) {
      const message = 'Please input a valid verification code'
      trackMixpanelEvent('Verify Phone Number Fail-  Checkout', {error: message})
      setErrorMessage(message)
    } else {
      try {
        if (needsToConfirmAccount === true) {
          const account = await checkLoginCode({phone, token, setSessionOnSuccess: false})
          const newHref = `/confirm-account/${account.userId}?r=${encodedPath}`
          window.location.href = newHref
        } else if (hasExistingAccount === true) {
          await checkLoginCode({phone, token})
        } else {
          const {token: signupTokenRes} = await checkSignupCode({phone, token})
          setSignupToken(signupTokenRes)
          setHasAccount(false)
          setShowSignUp(true)
        }
        trackMixpanelEvent('Verify Phone Number Success-  Checkout')
      } catch (error: any) {
        trackMixpanelEvent('Verify Phone Number Fail-  Checkout', {error: error.response.data.error})
        setErrorMessage(error.response.data.error as string)
      }
    }
  }

  useEffect(() => {
    if (token.length === 6) onVerify()
  }, [token])

  return (
    <div className={isRSVP ? 'RSVPModal-Section' : ''}>
      {isRSVP ? <h4 className='center'>{text}</h4> : <p className='center noMargin'>{text}</p>}
      <ReactCodeInput
        className='CheckoutPage-codeInput'
        type='number'
        autoFocus
        fields={6}
        name='phoneCode'
        inputMode={'tel'}
        inputStyle={phoneStyle(isMobile)}
        value={token}
        onChange={e => setToken(e)}
      />
      {(isErrorPhoneLogin || isErrorPhoneSignup) && <p className='error center'>{errorMessage}</p>}
      <Button className={buttonClassName} type='submit' disabled={isLoading} isLoading={isLoading} onClick={onVerify}>
        {buttonText()}
      </Button>
    </div>
  )
}
