import React from 'react'
import {useParams} from 'react-router-dom'

import {useFetchOrdersAndEvents} from 'apis/Accounts/useFetchOrdersAndEvents'
import {useGetGroupAccount} from 'apis/Groups/useGetGroupAccount'
import CheckoutFieldResponses from 'components/CheckoutFieldResponses/CheckoutFieldResponses'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import OrderCardCustomer from 'pages/GroupPage/Orders/OrderPage/OrderPageCards/customer'
import OrderCardOrders from 'pages/GroupPage/Orders/OrderPage/OrderPageCards/orders'
import CustomerCardTimeline from 'pages/GroupPage/Orders/OrderPage/OrderPageCards/timelinecustomer'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import './styles.scss'

export default function AttendeeProfilePage() {
  const {attendeeId} = useParams<{attendeeId: string}>()
  const {groupId} = useParams<{groupId: string}>()
  const {data: account} = useGetGroupAccount({
    groupId: groupId!,
    accountId: attendeeId!,
  })
  const {data: orderAndEvents} = useFetchOrdersAndEvents(attendeeId)

  return (
    <div className='GroupPage'>
      <div className='GroupPage-content'>
        <div className='GroupPage-content-inner'>
          {account ? (
            <>
              <div className='OrderPage-header'>
                <h1>
                  <BackAndTitle title={`${account.firstName} ${account.lastName}`} />
                </h1>
              </div>

              <div className='GroupPage-cols'>
                <div className='small'>
                  <OrderCardCustomer account={account} />
                </div>
                <div className='expand'>
                  <OrderCardOrders orders={orderAndEvents?.map(oe => oe.order)} />
                  <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_orders_list', ['group'])]}>
                    <CustomerCardTimeline account={account} orders={orderAndEvents?.map(oe => oe.order)} />
                  </RequirePermissions>
                  <CheckoutFieldResponses
                    responses={orderAndEvents?.map(oe => ({
                      eventName: oe.event.name,
                      eventResponses: oe.order.customFieldResponses,
                    }))}
                  />
                </div>
              </div>
            </>
          ) : (
            <SpinLoader height={100} />
          )}
        </div>
      </div>
    </div>
  )
}
