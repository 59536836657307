import React, {useEffect, useState} from 'react'

import {useGetAccount} from 'apis/Accounts/useGetAccount'
import {useUpdateOwnAccount} from 'apis/Accounts/useUpdateOwnAccount'
import useCheckIsAffiliate from 'apis/Kickback/useCheckIsAffiliate'
import {PictureState} from 'components/form/ImagePicker'
import useSessionContext from 'domains/Auth/SessionContext'
import {isDefaultAvi} from 'helpers/User/isDefaultAvi'
import {useUploadImage} from 'hooks/uploadImage/useUploadImage'

import './styles.scss'

function Initials({firstName, lastName}: {firstName: string; lastName: string}) {
  return (
    <div className='UploadAvi-initials'>
      <h3>
        {firstName.charAt(0)}
        {lastName.charAt(0)}
      </h3>
    </div>
  )
}

export default function UploadAvi({useInitials, size = 200}: {useInitials?: boolean; size?: number | string}) {
  const {userId, currentUser: cachedCurrentUser, updateLocalAccountAvi} = useSessionContext()
  const {mutate: updateAccount} = useUpdateOwnAccount()
  const {data: accountData, isSuccess: isSuccessAccount} = useGetAccount()
  const accountAvi = accountData?.account.avi

  const {onFileChange, acceptedFileTypes} = useUploadImage({
    imageType: 'account-avi',
    onSuccess: async (imageId, imageUrl) => {
      updateAccount({
        avi: imageUrl,
        aviImageId: imageId,
      })
      updateLocalAccountAvi(imageUrl)
    },
  })

  const {data: isAnAffiliate, isFetching: isFetchingIsAffiliate} = useCheckIsAffiliate(userId!)

  const cachedProfilePic = (() => {
    if (!cachedCurrentUser) return null
    if (cachedCurrentUser?.avi) return cachedCurrentUser.avi
    else if (cachedCurrentUser?.instagram?.avi) return cachedCurrentUser?.instagram?.avi
    else return null
  })()

  const hasProfilePic = cachedProfilePic && !isDefaultAvi(cachedProfilePic)

  const [iconPictureFile, setIconPictureFile] = useState<PictureState | null>(null)

  useEffect(() => {
    console.log('cachedProfilePic', cachedProfilePic)
    if (cachedProfilePic && !iconPictureFile?.url) {
      setIconPictureFile({file: null, url: cachedProfilePic})
    }
  }, [cachedProfilePic])

  return (
    <div className='UploadAvi' style={{height: size, width: size}}>
      {cachedCurrentUser && isSuccessAccount && (
        <>
          {hasProfilePic ? (
            <>
              {iconPictureFile?.url && (
                <img style={{height: size, width: size}} className='UploadAvi-Avi' src={accountAvi} />
              )}
            </>
          ) : {useInitials} ? (
            <Initials firstName={cachedCurrentUser.firstName!} lastName={cachedCurrentUser.lastName!} />
          ) : (
            <img
              style={{height: size, width: size}}
              className='UploadAvi-Avi'
              src='https://assets.posh.vip/b2/default-avi.jpg'
            />
          )}
        </>
      )}
      <div className={`UploadAvi-Upload ${!isFetchingIsAffiliate && isAnAffiliate && 'affiliate'}`}>
        <label>
          <img src='https://assets.posh.vip/b2/dslr-camera.svg' />
          <input
            className='UploadAvi-inputContainer'
            type='file'
            accept={acceptedFileTypes}
            multiple={false}
            onChange={onFileChange}
          />
        </label>
      </div>
    </div>
  )
}
