import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'

import useFetchAffiliations, {AffiliationResponse} from 'apis/Kickback/useFetchAffiliations'
import {useGetKickbackEligibility} from 'apis/Kickback/useGetKickbackEligibility'
import {useMixpanel} from 'apis/MixPanelHandler'
import CopyBlock from 'components/CopyBlock'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import setOptimizelyUserCustomAttribute from 'helpers/setOptimizelyUserCustomAttribute'
import WarningBlock from 'pages/GroupPage/PageComponents/WarningBlock'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import {useFetchKickBackPayoutLink} from '../../apis/Kickback/useFetchKickBackPayoutLink'
import AffiliatePayouts from './AffiliatePayouts'

import './styles.scss'

interface AffiliationCardProps {
  affiliation: AffiliationResponse
  hasntCompletedStripeOnboard: boolean
  isNewestAffiliation?: boolean
}

const AffiliationCard = (props: AffiliationCardProps) => {
  const {affiliation, hasntCompletedStripeOnboard} = props
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const viewExpandedAffiliation = () => {
    trackMixpanelEvent('Expand Kickback Link Row-  Kickback Active Link Page', {
      cardConnected: !hasntCompletedStripeOnboard,
    })
    navigate(`/dashboard/affiliation/${affiliation.id}`)
  }

  const onCopyKickbackLink = () => {
    setOptimizelyUserCustomAttribute('Opt.EventPageKickbackClicks', null)
    trackMixpanelEvent('Kickback Link Copy- Kickback Dashboard Page')
  }
  return (
    <div className='KickbackLinks'>
      <div className='KickbackLink'>
        <div className='KickbackLink-CopyTip'></div>
        <img className='KickbackLink-Flyer' src={affiliation.flyer}></img>
        <div className='KickbackLink-Info'>
          <div className='KickbackLink-Info-Info'>
            <div className='KickbackLink-Info-Info-Org'>{affiliation.groupName}</div>
            <div className='KickbackLink-Info-Info-TitleWrapper'>
              <div className='KickbackLink-Info-Info-EventTitle'>{affiliation.eventName}</div>
              <div className='KickbackLink-Info-Info-ActionButtonMobile' onClick={viewExpandedAffiliation}>
                <img src='https://assets.posh.vip/b2/right-arrow+(2).svg' />
              </div>
            </div>
            {hasntCompletedStripeOnboard ? (
              <WarningBlock text={'Connect a debit card to view your link!'} />
            ) : (
              <CopyBlock links={affiliation.link} onCopy={onCopyKickbackLink} />
            )}
          </div>
          <div className='KickbackLink-Info-Stats'>
            <div className='KickbackLink-Info-Stats-Stat'>
              <div className='KickbackLink-Info-Stats-Stat-Stat'>{affiliation.clicks}</div>
              <div className='KickbackLink-Info-Stats-Stat-Label'>Clicks</div>
            </div>
            <div className='KickbackLink-Info-Stats-Stat'>
              <div className='KickbackLink-Info-Stats-Stat-Stat'>{affiliation.ticketsSold}</div>
              <div className='KickbackLink-Info-Stats-Stat-Label'>Tickets Sold</div>
            </div>
            <div className='KickbackLink-Info-Stats-Stat'>
              <div className='KickbackLink-Info-Stats-Stat-Stat'>
                {getCurrencySymbol(affiliation.currency) + affiliation.totalSales.toFixed(2)}
              </div>
              <div className='KickbackLink-Info-Stats-Stat-Label'>Earnings</div>
            </div>
          </div>
          <div className='KickbackLink-Info-ActionButton' onClick={viewExpandedAffiliation}>
            <img src='https://assets.posh.vip/b2/right-arrow+(2).svg' />
          </div>
        </div>
      </div>
    </div>
  )
}

interface ActiveLinksProps {
  affiliations: AffiliationResponse[] | undefined
  hasntCompletedStripeOnboard: boolean
}

const ActiveLinks = (props: ActiveLinksProps) => {
  const {affiliations, hasntCompletedStripeOnboard} = props
  return (
    <div className='ActiveLinks'>
      <h2>Active Links</h2>
      {affiliations &&
        affiliations.map((affiliation, index) => (
          <AffiliationCard
            key={index}
            isNewestAffiliation={index === 0}
            affiliation={affiliation}
            hasntCompletedStripeOnboard={hasntCompletedStripeOnboard}
          />
        ))}
    </div>
  )
}

const KickbackDashboard = () => {
  type KicbackTabs = 'links' | 'payouts'

  const {userId} = useSessionContext()
  const {data: affiliations} = useFetchAffiliations()
  const {
    data: kickbackEligibility,
    isFetching,
    refetch: refetchKickbackEligibility,
  } = useGetKickbackEligibility({accountId: userId!}, {enabled: !!userId})
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {pathname} = useLocation()
  const [stripeSetupLink, setStripeSetupLink] = useState('')
  const [stripeSetupLinkIsFetching, setStripeSetupLinkIsFetching] = useState(false)
  const [selectedTab, setSelectedTab] = useState<KicbackTabs>('links')
  const {mutateAsync: fetchKickbackPayoutLink} = useFetchKickBackPayoutLink()
  const {showToast} = useToast()
  const [searchParams] = useSearchParams()

  const handleCreateStripeSetupLink = async () => {
    trackMixpanelEvent('Finish Connecting Card Button-  User Kickbacks Config Page', {buttonText: connectCardText()})
    setStripeSetupLinkIsFetching(true)
    try {
      // pathname here really means where the stripe link should redirect back to
      const link = await fetchKickbackPayoutLink({
        userId: userId!,
        pathname: searchParams.get('stripeRedirect') ?? pathname,
      })
      setStripeSetupLink(link)
      refetchKickbackEligibility()
    } catch (error) {
      showToast({type: 'error', title: error.response.data.error})
      setStripeSetupLinkIsFetching(false)
    }
  }

  useEffect(() => {
    if (stripeSetupLink) window.location.replace(stripeSetupLink)
  }, [stripeSetupLink])

  const connectCardText = () => {
    if (kickbackEligibility && kickbackEligibility.connectId && !kickbackEligibility.payoutsEnabled)
      return 'Finish Connecting Card'
    else return 'Connect Card'
  }

  const hasntCompletedStripeOnboard = () => {
    if (!isFetching && kickbackEligibility && !kickbackEligibility.payoutsEnabled) return true
    else return false
  }

  return (
    <>
      <div className='GroupsDash KickbackDashboard'>
        <div className='GroupsDash-Inner'>
          <div className='KickbackDashboard-navigation'>
            <img src='https://assets.posh.vip/b2/kickback.png' className='KickbackDashboard-Logo' />
            {!hasntCompletedStripeOnboard() && (
              <div className='KickbackDashboard-navItems'>
                <div
                  onClick={() => setSelectedTab('links')}
                  className={`KickbackDashboard-navItems-option ${selectedTab === 'links' && 'selected'}`}>
                  LINKS
                </div>
                <div
                  onClick={() => setSelectedTab('payouts')}
                  className={`KickbackDashboard-navItems-option ${selectedTab === 'payouts' && 'selected'}`}>
                  PAYOUTS
                </div>
              </div>
            )}
          </div>
          {hasntCompletedStripeOnboard() && (
            <>
              <div className='DebitCardAlert'>
                You need to connect a debit card to receive kickbacks!
                {
                  <button disabled={stripeSetupLinkIsFetching} onClick={() => handleCreateStripeSetupLink()}>
                    {stripeSetupLinkIsFetching ? (
                      <>Redirecting to Stripe Onboarding...</>
                    ) : (
                      <>
                        <img src='https://assets.posh.vip/b2/debit-card.svg'></img>
                        {connectCardText()}
                      </>
                    )}
                  </button>
                }
              </div>
              {stripeSetupLink && (
                <a href={stripeSetupLink} className='DebitCardAlert-manualRedirect'>
                  Click here to redirect to Stripe Onboarding
                </a>
              )}
            </>
          )}
          {selectedTab === 'links' ? (
            <>
              {!isFetching && (
                <ActiveLinks affiliations={affiliations} hasntCompletedStripeOnboard={hasntCompletedStripeOnboard()} />
              )}
            </>
          ) : (
            <>{kickbackEligibility && kickbackEligibility.connectId && <AffiliatePayouts />}</>
          )}
        </div>
      </div>
    </>
  )
}

export default KickbackDashboard
