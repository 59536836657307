import {TicketEventPage} from 'apis/Events/types'

const getTicketDisplayDetails = ({
  price: priceWithoutFees,
  totalPrice: priceWithFees,
  sellInMultiples,
  purchaseMin,
  quantityAvailable,
  showFeesInPrice,
  closed,
}: TicketEventPage & {showFeesInPrice: boolean}) => {
  let singleTicketPrice
  if (showFeesInPrice) {
    singleTicketPrice = priceWithFees
  } else {
    singleTicketPrice = priceWithoutFees
  }

  let ticketDisplayPrice
  const isTicketPack = sellInMultiples && purchaseMin && Boolean(purchaseMin)
  if (isTicketPack) {
    ticketDisplayPrice = singleTicketPrice * purchaseMin
  } else {
    ticketDisplayPrice = singleTicketPrice
  }

  const isSoldOut = quantityAvailable <= 0 || closed

  return {ticketDisplayPrice, isSoldOut, isTicketPack}
}

export default getTicketDisplayDetails
