import React, {useRef} from 'react'

import {EventParentComment} from 'apis/comments/useFetchEventParentComments'
import {useAddCommentReaction} from 'apis/Events/useAddCommentReaction'
import {addEmoji} from 'components/assets/Icons'
import useCheckIfClickedOutsideDiv from 'components/helpers/clickedOutsideDiv'
import useSessionContext from 'domains/Auth/SessionContext'
import Picker, {SKIN_TONE_NEUTRAL} from 'emoji-picker-react'
import {trpc} from 'lib/trpc'

import './styles.scss'
interface EmojiSectionProps {
  comment: EventParentComment
  eventId: string
  setShouldShowEmojiPicker?: React.Dispatch<React.SetStateAction<string>>
  shouldShowEmojiPicker?: string
  isLightMode: boolean
  refetch?: () => Promise<void>
}
interface EmojiReactionProps {
  emoji: string
  reactions: string[]
  isLightMode: boolean
  handleEmojiClick: (emoji: string) => Promise<void>
}

const EmojiReaction = (props: EmojiReactionProps) => {
  const {emoji, reactions, handleEmojiClick, isLightMode} = props
  const {userId} = useSessionContext()
  const currentUserHasReacted = userId ? reactions.includes(userId?.toString()) : false

  return (
    <>
      {reactions && reactions.length > 0 && (
        <div
          onClick={() => handleEmojiClick(emoji)}
          className={`EmojiReaction ${currentUserHasReacted ? 'reacted' : ''} ${isLightMode ? 'lightmode' : ''}`}>
          <div className='EmojiReaction-emoji'>{emoji}</div>
          <div className='EmojiReaction-count'>{reactions.length}</div>
        </div>
      )}
    </>
  )
}

export const EmojiSection = (props: EmojiSectionProps) => {
  const {comment, eventId, refetch, setShouldShowEmojiPicker, shouldShowEmojiPicker, isLightMode} = props
  const emojiPickerRef = useRef(null)
  const hideEmojiPicker = () => setShouldShowEmojiPicker && setShouldShowEmojiPicker('')
  useCheckIfClickedOutsideDiv(emojiPickerRef, hideEmojiPicker)
  const queryClient = trpc.useContext()

  const commentReactions = comment.reactions
  const {mutateAsync: addCommentReaction} = useAddCommentReaction()
  const handleEmojiClick = async (emoji: string) => {
    await addCommentReaction({eventId, commentId: comment._id, emoji})
    queryClient.comments.fetchEventParentComments.invalidate()
    refetch && refetch()
    setShouldShowEmojiPicker && setShouldShowEmojiPicker('')
  }

  return (
    <div className='EmojiSection'>
      {commentReactions && (
        <div className='EmojiSection-reactions'>
          {Object.keys(commentReactions).map((emoji, index) => (
            <EmojiReaction
              key={index}
              emoji={emoji}
              reactions={commentReactions[emoji]}
              handleEmojiClick={handleEmojiClick}
              isLightMode={isLightMode}
            />
          ))}
        </div>
      )}
      {shouldShowEmojiPicker && shouldShowEmojiPicker === comment._id && (
        <div style={{zIndex: '100'}} ref={emojiPickerRef}>
          <Picker
            pickerStyle={{
              position: 'absolute',
              bottom: '40px',
              right: '0',
              zIndex: '101',
              backgroundColor: '#fff',
              borderRadius: '20px',
              animation: 'fadein 0.3s',
            }}
            onEmojiClick={(event, emojiObject) => handleEmojiClick(emojiObject.emoji)}
            disableAutoFocus={true}
            skinTone={SKIN_TONE_NEUTRAL}
            groupNames={{smileys_people: 'PEOPLE'}}
            native
          />
        </div>
      )}
      <div className={`EmojiSection-addEmoji ${isLightMode ? 'lightmode' : ''}`}>
        <img src={addEmoji} onClick={() => setShouldShowEmojiPicker && setShouldShowEmojiPicker(comment._id)} />
      </div>
    </div>
  )
}

export default EmojiSection
