import React, {HTMLAttributes, PropsWithChildren} from 'react'

import classNames from 'classnames'
import {QuestionMarkInSolidCircle} from 'components/assets/Icons'

import './index.scss'

export function WithToolTip(props: PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>) {
  const {info, onClick, image, children, align, className, ...htmlProps} = props
  return (
    <div className={classNames('PoshInfo', className)} onClick={onClick} {...htmlProps}>
      {children}
      {info && (
        <div
          className={classNames(
            'PoshInfo-data',
            {['PoshInfo-data-right']: align === 'right'},
            {['PoshInfo-data-left']: align === 'left'},
          )}>
          <span>{info}</span>
          {image && <img src={image} />}
        </div>
      )}
    </div>
  )
}

interface Props {
  info?: string
  image?: string
  onClick?: () => void
  align?: 'left' | 'right'
}

const COLOR = '#cfd0d2'
const SIZE = 16

const PoshInfo = (props: Props) => {
  const {info, image, onClick} = props

  return (
    <WithToolTip info={info} onClick={onClick} image={image}>
      <QuestionMarkInSolidCircle fill={COLOR} height={SIZE} width={SIZE} />
    </WithToolTip>
  )
}

export default PoshInfo
