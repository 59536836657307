import React, {useRef} from 'react'

import classNames from 'classnames'
import {greenCheck, redX} from 'components/assets/Icons'
import useCheckIfClickedOutsideDiv from 'components/helpers/clickedOutsideDiv'
import pluralize from 'helpers/pluralize'
import {capitalize} from 'lodash'

import {ApproveOrDeny} from '../ApprovalSection'
import useApprovalCrmContext from '../ApprovalSection/ApprovalContextProvider/ApprovalCrmContext'

import './styles.scss'

interface ApprovalButtonProps {
  variant: ApproveOrDeny
  onClick: (w: ApproveOrDeny) => void
  expanded: boolean
  disappear: boolean
  inCRM: boolean
  insideModal: boolean
  text: {expanded: string; normal: string}
}

const ApprovalButton = ({variant, expanded, inCRM, insideModal, disappear, onClick, text}: ApprovalButtonProps) => {
  return (
    <button
      className={classNames('ApprovalButtons-button', variant, {
        expanded,
        disappear,
        crm: inCRM,
        insideModal,
      })}
      onClick={() => onClick(variant)}>
      <div className='ApprovalButtons-button-OuterWrapper'>
        <div className='ApprovalButtons-button-InnerWrapper'>
          <img src={variant === 'approve' ? greenCheck : redX} />
          {!inCRM && <p className='noMargin'>{expanded ? text.expanded : text.normal}</p>}
        </div>
      </div>
    </button>
  )
}

interface ApprovalButtonsProps {
  isCrmRow: boolean
  expandedType: ApproveOrDeny | undefined
  handleExpandButton: (which: ApproveOrDeny) => void
  handleUpdateApprovalStatus: (status: ApproveOrDeny) => void
  handleCancelConfirmation: () => void
  variant?: 'normal' | 'large'
}

const ApprovalButtons = (props: ApprovalButtonsProps) => {
  const {isCrmRow, expandedType, handleExpandButton, handleUpdateApprovalStatus, handleCancelConfirmation, variant} =
    props
  const {selectedApprovalIds} = useApprovalCrmContext()
  const isInExpandedState = expandedType !== undefined
  const ref = useRef<HTMLDivElement>(null)
  useCheckIfClickedOutsideDiv(ref, handleCancelConfirmation)

  const onClick = (which: ApproveOrDeny) => {
    if (isInExpandedState) {
      handleUpdateApprovalStatus(which)
    } else {
      handleExpandButton(which)
    }
  }

  const selectedAttendeesCount = selectedApprovalIds.length
  const attendeesText = `${selectedAttendeesCount} ${capitalize(pluralize('ATTENDEE', selectedAttendeesCount))}`
  const text = (action: ApproveOrDeny) => {
    if (variant === 'large') {
      return {
        expanded: `CONFIRM ${action.toUpperCase()} ${attendeesText}`,
        normal: `${action.toUpperCase()} ${attendeesText}`,
      }
    }
    return {
      expanded: `Confirm ${capitalize(action)}`,
      normal: capitalize(action),
    }
  }

  return (
    <div
      className={classNames('ApprovalButtons', {
        crm: isCrmRow,
        expanded: isInExpandedState,
        large: variant === 'large',
      })}
      ref={ref}>
      <ApprovalButton
        variant='approve'
        expanded={expandedType === 'approve'}
        inCRM={isCrmRow}
        onClick={onClick}
        disappear={expandedType === 'deny'}
        text={text('approve')}
        insideModal={!isCrmRow && variant !== 'large'}
      />
      <ApprovalButton
        variant='deny'
        expanded={expandedType === 'deny'}
        inCRM={isCrmRow}
        onClick={onClick}
        disappear={expandedType === 'approve'}
        text={text('deny')}
        insideModal={!isCrmRow && variant !== 'large'}
      />
    </div>
  )
}

interface LoadingDeactivatedApprovalButtonProps {
  isCrmRow: boolean
}

export const LoadingDeactivatedApprovalButton = (props: LoadingDeactivatedApprovalButtonProps) => {
  const {isCrmRow} = props
  return (
    <div className={`ApprovalButtons ${isCrmRow ? 'crm' : ''}`}>
      <button disabled className={`ApprovalButtons-button disabled ${isCrmRow ? 'crm' : ''}`}>
        <p className='noMargin'>CONFIRMING...</p>
      </button>
    </div>
  )
}

export const LargeLoadingDeactivatedApprovalButton = () => {
  return (
    <div className={`ApprovalButtons large`}>
      <button disabled className={`ApprovalButtons-button large disabled`}>
        <p className='noMargin'>CONFIRMING...</p>
      </button>
    </div>
  )
}

export default ApprovalButtons
