import React, {useState} from 'react'
import {useForm} from 'react-hook-form'

import {zodResolver} from '@hookform/resolvers/zod'
import {isValidUrl, prependHttpsProtocolIfMissing} from '@posh/model-types'
import {EventLineUp} from 'apis/Events/types'
import classNames from 'classnames'
import {TrashIcon} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {FileInput} from 'components/form/FilePicker'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {EventVisualsDateTimeInput} from 'components/PageComponents/EventVisuals/Form/Input/DateInput'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {useUploadImage} from 'hooks/uploadImage/useUploadImage'
import {z} from 'zod'

import {BaseLineupItem, EditButton, LineupItemProps} from './BaseLineupItem'

import styles from './styles.module.scss'

type EditableProps = LineupItemProps & {
  onSaveItem(item: EventLineUp): void
  onDeleteItem(): void
  onCancelEditItem?: () => void
}

function Avi({avi, isUploading}: {avi: string | undefined; isUploading: boolean}) {
  if (isUploading) return <SpinLoader height={100} />
  else if (avi) return <img className={styles.avi} src={avi} alt='Avatar' />
  return <div className={styles.aviPlaceholder} style={{backgroundColor: 'grey'}} />
}

const lineupResolver = zodResolver(
  z.object({
    name: z.string(),
    avi: z.string(),
    link: z
      .string()
      .transform(v => prependHttpsProtocolIfMissing(v))
      .refine(isValidUrl),
    time: z
      .object({
        start: z.union([z.date().transform(d => d.toISOString()), z.string().datetime()]).optional(),
        end: z.union([z.date().transform(d => d.toISOString()), z.string().datetime()]).optional(),
      })
      .optional(),
  }),
)

function Editing(props: Omit<EditableProps, 'item' | 'onClick'> & {item: EventLineUp | undefined}) {
  const {accentColor, lightmode, onSaveItem, onDeleteItem, onCancelEditItem} = props

  const {
    control,
    formState: {isValid},
    handleSubmit,
    setValue,
    getValues,
  } = useForm<EventLineUp>({
    defaultValues: props.item,
    resolver: lineupResolver,
    mode: 'onTouched',
  })
  const avi = getValues('avi')

  const {uploadImage, isUploading} = useUploadImage({
    imageType: 'lineup-photo',
    onSuccess: (_: string, url: string) => setValue('avi', url, {shouldValidate: true, shouldDirty: true}),
  })

  const onChooseImage = (file: File | File[]) => {
    if (Array.isArray(file)) return // multiple files not supported
    else uploadImage(file)
  }

  return (
    <div className={classNames(styles.EventPageLineupItem, styles.editing, {[`${styles.lightMode}`]: lightmode})}>
      <div className={styles.imageNameTimeContainer}>
        <div className={styles.imageContainer}>
          <FileInput.Image onChooseFile={onChooseImage} className={styles.editButton}>
            <EditButton accentColor={accentColor} lightMode={lightmode} />
          </FileInput.Image>
          <Avi avi={avi} isUploading={isUploading} />
        </div>
        <div className={styles.nameTimeContainer}>
          <EventVisualsTextInput.Text.Controlled
            accentColor={accentColor}
            lightMode={lightmode}
            placeholder='Name'
            control={control}
            name='name'
            rules={{required: 'Name is required'}}
          />
          <div className={styles.timeContainer}>
            <EventVisualsDateTimeInput.Time.Controlled
              accentColor={accentColor}
              lightMode={lightmode}
              placeholder='Start time'
              control={control}
              name='time.start'
            />
            <div className={styles.timeSeparator} style={{backgroundColor: lightmode ? 'black' : 'white'}} />
            <EventVisualsDateTimeInput.Time.Controlled
              accentColor={accentColor}
              lightMode={lightmode}
              placeholder='End time'
              control={control}
              name='time.end'
            />
          </div>
        </div>
      </div>
      <div style={{width: '100%'}}>
        <EventVisualsTextInput.Text.Controlled
          accentColor={accentColor}
          lightMode={lightmode}
          placeholder='Link'
          control={control}
          name='link'
          rules={{required: 'Link is required'}}
          autoCapitalize='none'
        />
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          className='fullWidth'
          onClick={() => {
            if (!isValid) onCancelEditItem?.()
            else onDeleteItem()
          }}>
          <TrashIcon />
          {isValid ? 'Delete' : 'Cancel'}
        </Button>
        <Button
          className='fullWidth gold'
          onClick={() => {
            if (!isValid) return
            handleSubmit(onSaveItem)()
          }}
          disabled={!isValid}>
          Save
        </Button>
      </div>
    </div>
  )
}

export function EditableLineupItem(props: Omit<EditableProps, 'onClick'> & {isEditing?: boolean}) {
  const [isEditing, setIsEditing] = useState(!!props.isEditing)
  if (isEditing)
    return (
      <Editing
        {...props}
        onSaveItem={item => {
          props.onSaveItem(item)
          setIsEditing(false)
        }}
        onDeleteItem={() => {
          props.onDeleteItem()
          setIsEditing(false)
        }}
        onCancelEditItem={() => setIsEditing(false)}
      />
    )
  return <BaseLineupItem {...props} editable={true} onClick={() => setIsEditing(true)} />
}

EditableLineupItem.Editing = Editing
