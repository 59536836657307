import React from 'react'
import QRCode from 'react-qr-code'

import './styles.scss'

interface EventQRCodeProps {
  url: string
  onDownload?: () => void
}

export const EventQRCode = (props: EventQRCodeProps) => {
  const {url} = props

  const handleDownload = () => {
    if (props.onDownload) props.onDownload()
    const svg = document.querySelector(`.EventQRCode-code`)
    const svgData = new XMLSerializer().serializeToString(svg!)
    const blob = new Blob([svgData], {type: 'image/svg+xml'})
    const url = URL.createObjectURL(blob)
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = `Event-${url}-QR.svg`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    URL.revokeObjectURL(url)
  }

  return (
    <>
      {
        <div className='EventQRCode'>
          <QRCode
            className='EventQRCode-code'
            value={`https://posh.vip/e/${url}`}
            bgColor={'#121215'}
            fgColor={'#ffcc00'}
            size={150}
          />
          <a className='EventQRCode-download' target='_' onClick={handleDownload}>
            <img src='https://assets.posh.vip/b2/downloading.svg' />
          </a>
        </div>
      }
    </>
  )
}
