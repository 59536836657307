import React from 'react'

import {CurrencyCode} from '@posh/types'
import {RefundMetaData} from 'apis/Orders'
import {Card} from 'components/Card'
import moment from 'moment'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

interface OrderCardRefundProps {
  refundMetaData: RefundMetaData
  isPaymentPlan: boolean
  currency: CurrencyCode
}

const OrderCardRefund = (props: OrderCardRefundProps) => {
  const {refundMetaData, isPaymentPlan, currency} = props
  const {refundedAt, totalRefunded} = refundMetaData
  const symbol = getCurrencySymbol(currency)
  return (
    <Card
      title={'Refund'}
      animationIndex={1}
      className='OrderPage-refundCard'
      info={'POSH will not issue refunds without your permission. Processing fees are not refunded.'}>
      <p>
        This {isPaymentPlan ? 'payment plan' : 'order'} was refunded on{' '}
        {moment(refundedAt).format('MMM Do, YYYY [at] h:mm a')}.
      </p>
      <table>
        <tbody>
          <tr>
            <td>Amount</td>
            <td>
              {symbol}
              {totalRefunded?.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  )
}

export default OrderCardRefund
