import React, {useEffect, useState} from 'react'
import Marquee from 'react-fast-marquee'
import Loader from 'react-loader-spinner'

interface SongPlayerParams {
  isLightMode: boolean
  accentColor: string
  previewLink: string
  artist?: string
  name?: string
}

const SongPlayer = (params: SongPlayerParams) => {
  const {isLightMode, accentColor, previewLink, artist, name} = params
  const [isPlayingAudio, setIsPlayingAudio] = useState(false)
  const [audio] = useState(new Audio(previewLink))

  useEffect(() => {
    if (isPlayingAudio) audio.play()
    else audio.pause()
  }, [isPlayingAudio])

  useEffect(() => {
    audio.addEventListener('ended', () => setIsPlayingAudio(false))
    return () => {
      audio.removeEventListener('ended', () => setIsPlayingAudio(false))
      audio.pause()
      audio.currentTime = 0
    }
  }, [])

  const handlePlayPause = () => {
    if (!isPlayingAudio) audio.play()
    setIsPlayingAudio(!isPlayingAudio)
  }

  return (
    <button
      style={{background: accentColor}}
      className={`EventPage-playMusic ${isPlayingAudio && 'playing'} ${isLightMode && 'lightmode'}`}
      onClickCapture={handlePlayPause}>
      {!isPlayingAudio ? (
        <img src='https://assets.posh.vip/psh-assets/icons/play.png' />
      ) : (
        <Loader type='Bars' color={isLightMode ? 'white' : 'black'} width={25} height={25} />
      )}
      <Marquee gradient={false}>
        <p>{name}</p>
        <p>{`-`}</p>
        <p>{artist}</p>
      </Marquee>
    </button>
  )
}

export default SongPlayer
