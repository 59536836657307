import React from 'react'
import {useNavigate} from 'react-router-dom'

import Button from 'components/form/Button'

import {default as BirthdayBoyHat} from '../../../assets/images/birthday-boy-hat.png'
import {default as Fowl} from '../../../assets/images/fowl.png'

import styles from './PartyFoul.module.scss'

export const PartyFoul = ({
  errorText = "The page you're looking for can't be found. We let the team know and are working on a fix.",
}: {
  errorText?: string
}) => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)

  return (
    <div className={styles.PartyFoulContainer}>
      {/**
       * The ellipses from the Akira font do not match the designs
       * (Akira periods are square, designs have round periods)
       * */}
      <h1 className='center akira'>
        PARTY FOUL<span style={{fontFamily: 'DM Sans'}}> . . .</span>
      </h1>
      <p className='center'>{errorText}</p>
      <div className={styles.ButtonFowlContainer}>
        <img src={Fowl} alt='Party Foul' className={styles.FowlImage} />
        <img src={BirthdayBoyHat} alt='Birthday Boy Hat' className={styles.BirthdayHat} />
        <Button className='gold' onClick={goBack}>
          Go Back
        </Button>
      </div>
    </div>
  )
}
