import React, {useState} from 'react'

import {EventTicketAndTicketSold, PromoCode} from 'apis/Events/types'
import {circleGreenCheckMarkIcon, circleRedX} from 'components/assets/Icons'
import Input from 'components/form/Input'
import PoshInfo from 'components/form/PoshInfo'
import Select from 'components/form/Select'

import plusIcon from '../../../assets/images/icons/plus-yellow.svg'

interface TrackingLinkTableHeadersProps {
  isMobile: boolean
  hasTables: boolean
}

export const TrackingLinkTableHeaders = (props: TrackingLinkTableHeadersProps) => {
  const {isMobile, hasTables} = props
  return (
    <tr>
      <th>Name</th>
      {!isMobile && <th>Clicks</th>}
      {!isMobile && <th>Tickets Sold</th>}
      {!isMobile && hasTables && <th>Tables Sold</th>}
      {!isMobile && (
        <th>
          Conversion Rate
          <PoshInfo info={'% of clicks that resulted in a sale'} />
        </th>
      )}
      <th>Revenue Generated</th>
      <th />
    </tr>
  )
}

export const PromoCodeTableHeader = ({isMobile}: {isMobile: boolean}) => (
  <tr>
    <th>Promo Code</th>
    <th>Discount</th>
    <th>Uses</th>
    {!isMobile && <th>Limit</th>}
    {!isMobile && <th>Limited To</th>}
  </tr>
)

export const AddButton = (props: {onClick?: () => void}) => (
  <div className='AddButton'>
    <img onClick={props.onClick} src={plusIcon} />
  </div>
)

export const YesButton = (props: {onClick?: () => void}) => (
  <div onClick={props.onClick} className='YesButton'>
    <img src={circleGreenCheckMarkIcon} />
  </div>
)

export const NoButton = (props: {onClick?: () => void}) => (
  <div onClick={props.onClick} className='NoButton'>
    <img src={circleRedX} />
  </div>
)

export const AddTrackingLinkEditor = (props: {onYes: (link: string) => void; onNo: () => void}) => {
  const [trackingLink, setTrackingLink] = useState('')

  return (
    <div className='EditorRow'>
      <div className='EditorRow-flex'>
        <Input placeholder='Tracking Link' value={trackingLink} onChange={e => setTrackingLink(e.target.value)} />
        <div className='buttonWrapper'>
          <YesButton onClick={() => props.onYes(trackingLink)} />
          <NoButton onClick={() => props.onNo()} />
        </div>
      </div>
    </div>
  )
}

// TODO: Add this to a separate page
export const AddPromoCodeEditor = (props: {
  onYes: (link: PromoCode) => void
  onNo: () => void
  tickets: EventTicketAndTicketSold[] | undefined
  currencySymbol: string
}) => {
  const [promoCode, setPromoCode] = useState({
    code: '',
    type: 'flat',
    value: 5,
  } as PromoCode)
  const {tickets, currencySymbol} = props

  return (
    <>
      <div className='EditorRow'>
        <div className='EditorRow-inputs'>
          <Input
            placeholder='Code'
            value={promoCode.code}
            onChange={e => setPromoCode(promoCode => ({...promoCode, code: e.target.value}))}
            style={{maxWidth: '250px'}}
          />

          <Select
            value={promoCode.type}
            onChange={e => setPromoCode({...promoCode, type: e.target.value as 'flat' | 'percent'})}
            style={{maxWidth: '160px'}}>
            <option value='flat'>Flat Rate</option>
            <option value='percent'>Percentage</option>
          </Select>

          <div className='inputWrapper'>
            {promoCode.type == 'flat' && <span>{currencySymbol}</span>}
            <Input
              type='number'
              step='0.01'
              min='0'
              placeholder='amount'
              value={promoCode.value ?? 0}
              style={{maxWidth: '120px'}}
              onChange={e => setPromoCode(promoCode => ({...promoCode, value: parseFloat(e.target.value) || 0}))}
            />
            <span>{(promoCode.type == 'percent' || promoCode.type == 'percentage') && '%'} off</span>
          </div>
          <div className='inputWrapper'>
            <Input
              type='number'
              step='1'
              placeholder='Use Limit'
              value={promoCode.limit}
              style={{maxWidth: '120px'}}
              onChange={e => setPromoCode(promoCode => ({...promoCode, limit: parseInt(e.target.value) || 0}))}
            />
          </div>
        </div>
        {tickets && tickets.length > 0 && (
          <div className='inputWrapper'>
            <label>Limited To</label>
            <Select
              value={promoCode.limitedToTicket}
              onChange={e => setPromoCode({...promoCode, limitedToTicket: e.target.value})}>
              <option value=''>None</option>
              {tickets!.map(ticket => (
                <option key={ticket.ticket.id} value={ticket.ticket.id}>
                  {ticket.ticket.name}
                </option>
              ))}
            </Select>
          </div>
        )}

        <div className='EditorRow-buttons'>
          <YesButton
            onClick={() => {
              if (promoCode.code) props.onYes(promoCode)
              else props.onNo()
            }}
          />
          <NoButton onClick={() => props.onNo()} />
        </div>
      </div>
    </>
  )
}
