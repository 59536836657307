import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface UpdateKickBackActivationProps {
  id: string
  enabled: boolean
}

const updateKickBackActivation = async (params: UpdateKickBackActivationProps) => {
  const response = await PoshApi.updateKickBackActivation(params)
  return response.data
}

export function useUpdateKickBackActivation() {
  return useMutation<unknown, unknown, UpdateKickBackActivationProps>(params => updateKickBackActivation(params))
}
