// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountStatusRow__TRAQp{display:flex;align-items:center;justify-content:space-between}.AccountStatusRow__TRAQp .Text__CN3tW{color:#fff;display:inline-flex;margin:0;font-size:1.1rem;color:rgba(255,255,255,.7607843137)}.AccountStatusRow__TRAQp .PoshInfo{margin:0}.AccountStatusRow__TRAQp .Badge-oval{font-size:.8rem}", "",{"version":3,"sources":["webpack://./src/pages/GroupPage/Finance/AccountStatusRow.module.scss"],"names":[],"mappings":"AAEA,yBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,sCACE,UAAA,CACA,mBAAA,CACA,QAAA,CACA,gBAAA,CACA,mCAAA,CAGF,mCACE,QAAA,CAGF,qCACE,eAAA","sourcesContent":["@import '@global-styles/color_pallete';\n\n.AccountStatusRow {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  .Text {\n    color: white;\n    display: inline-flex;\n    margin: 0;\n    font-size: 1.1rem;\n    color: #ffffffc2;\n  }\n\n  & :global(.PoshInfo) {\n    margin: 0;\n  }\n\n  & :global(.Badge-oval) {\n    font-size: 0.8rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountStatusRow": "AccountStatusRow__TRAQp",
	"Text": "Text__CN3tW"
};
export default ___CSS_LOADER_EXPORT___;
