import {Group} from 'apis/Groups'

export type GroupGmvVerified = Pick<Group, 'gmv' | 'verified'>

export const GMVRankMap = {
  Explorer: {
    min: 0,
    max: 499,
    perks: [],
  },
  Merchant: {
    min: 500,
    max: 2499,
    perks: ['POSH Sticker Packet*'],
  },
  Salesman: {
    min: 2500,
    max: 9999,
    perks: ['CSV Import Complimentary Tickets', 'CSV Import Promo Codes'],
  },
  Boss: {
    min: 10000,
    max: 99999,
    perks: ['Free POSH Merch Box*'],
  },
  Captain: {
    min: 100000,
    max: 499999,
    perks: ['Organization gets a verified badge on POSH'],
  },
  Tycoon: {
    min: 500000,
    max: 999999,
    perks: ['2 Bottles of Favorite Alcohol (21+)*'],
  },
  Baron: {
    min: 1000000,
    max: 1999999,
    perks: ['Dinner with the POSH team*'],
  },
  Godfather: {
    min: 2000000,
    max: 9999999,
    perks: ['Table at club of choice*'],
  },
  Musk: {
    min: 10000000,
    max: 100000000,
    perks: ['Custom-wrapped Tesla Model 3*'],
  },
} as const

export type GMVRankName = keyof typeof GMVRankMap

export const useRankThreshold = (group: GroupGmvVerified, rank: GMVRankName) => {
  if (group.verified) return true
  const gmv = group.gmv ?? 0
  const tier = GMVRankMap[rank]
  return gmv >= tier.min
}
